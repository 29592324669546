/**
 * These return values are mapped to error messages in CS:
 * query dashboard > required dialog > error messages
 */
const getMissingSearchField = (values, queryStatusValue) => {
  if (values.fromDate && queryStatusValue) {
    return 'to';
  }
  if (values.fromDate && values.toDate) {
    return 'fromNTo';
  }
  if (values.fromDate) {
    return 'statusNTo';
  }
  if (values.toDate && queryStatusValue) {
    return 'from';
  }
  if (values.toDate) {
    return 'statusNFrom';
  }
  return `status`;
};

const isPassingSearchCriteria = (values, queryStatusValue) => {
  if (values.client) {
    if (values.externalRefId || (values.srId && !queryStatusValue)) {
      return true;
    } else if (queryStatusValue && values.fromDate && values.toDate) {
      return true;
    } else if (values.externalRefId || (values.srId && queryStatusValue)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
export {getMissingSearchField, isPassingSearchCriteria};
