import {Typography} from '@mui/material';
import React, {Fragment} from 'react';
import {Box} from '@mui/system';

interface IProps {
  data: any;
  addOn?: any;
}

const TabHeading: React.FC<IProps> = (props) => {
  const {data, addOn = null} = props;
  const WrapperForAddOn = ({children, condition, wrapper}) =>
    condition ? wrapper(children) : children;

  return (
    <Fragment>
      <WrapperForAddOn
        condition={addOn}
        wrapper={(children) => <Box sx={{display: 'flex', flexDirection: 'row'}}>{children}</Box>}>
        <Typography variant="h2">{data.heading}</Typography>
        {addOn}
      </WrapperForAddOn>
      <Typography variant="h6" component="h3">
        {data.subtitle}
      </Typography>
    </Fragment>
  );
};

export default TabHeading;
