import globalSpacing from '../../../Theme/Spacing';

const styles = {
  rowBox: {
    marginTop: globalSpacing.sm,
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
  },
};

export default styles;
