import Form from 'Components/Form/FormWrapper';
import React, {useEffect, useState} from 'react';
import {bookEventDetails, bookEventSRSection, buttonList} from './BookEvent';
import {initialValues, validationSchema} from './bookEventSchema';
//import {dateConversionToISOFormatIgnoreTimeZone} from 'Utils/helpers/DateValidation';
import {Box, Button, Grid, Paper, Stack, Typography} from '@mui/material';
import SnackBar from 'Components/SnackBar';
import Wrapper from 'Components/Wrapper';
import {useSnackBar} from 'Hooks';
import {isUpdateSRList} from 'Redux/Slices/SRListFilter';
import {searchSRByID} from 'Redux/Slices/selectedSR';
import {createSR, updateSR} from 'Redux/Slices/serviceRequest';
import globalSpacing from 'Theme/Spacing';
import {
  CREATE_SR_CANCEL_ERROR,
  SR_STATUS,
  SR_TYPES,
  UPDATE_SR_ERROR,
} from 'Utils/constants/genericConstants';
import {getBookingFee, hideShowFormFields, isPaymentSuccessful} from 'Utils/helpers/BookCashbckSR';
import {
  getDeliveryAddress,
  getNotificationParams,
  getSeatDetails,
  getTicketItemDetails,
  getTotalActualPrice,
  getTotalPriceAfterDiscount,
} from 'Utils/helpers/BookEventSR';
import {dateConversionToISOFormatIgnoreTimeZone, getDate} from 'Utils/helpers/DateValidation';
import {parseToFloat, roundOff} from 'Utils/helpers/Numeric';
import {updateFormContent} from 'Utils/helpers/SRDetailViewHelper';
import {isDisableFormFields} from 'Utils/helpers/SRListHelpers';
import {useDispatch, useSelector} from 'react-redux';
import {hiddenAddressFields} from '../BookCashbackEventSR/BookCashbackEvent';
import AddTicketDialog from './components/AddTicketDialog';
import TicketListView from './components/TicketListView';

interface IProps {
  srFormDetails?: any;
  closeDialogMethod?: any;
}

const BookEventSR: React.FC<IProps> = (props) => {
  const discoutnPercentage = 0.25;
  const [beformFields, setBEFormFields] = useState<any>(bookEventSRSection);
  const [beEventformFields, setFormEventDetails] = useState<any>(bookEventDetails); // setBCEventFormFields
  const [buttonSection, setButtonSection] = useState(buttonList);
  const [initialFormValues, setInitialFormValues] = useState<any>(initialValues);
  const ownerID = useSelector((state: any) => state?.login?.userName);
  const selectedSR = useSelector((state: any) => state.selectedSR?.getServiceRequestById);
  const tenantConfig = useSelector((state: any) => state?.login?.tenantConfig);
  const {membershipEnabled} = tenantConfig;
  const selectedMemberDetails = useSelector((state: any) => state?.selectedMember).selectedMember;
  const [ticketItemDetails, setTicketItemDetails] = useState<any[]>([]);
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [isSnackBarOpen, closeSnackBar] = useSnackBar(error);
  const [openAddTicketDialog, isOpenTicketDialog] = useState(false);
  const [ticket, setTicket] = useState<any[]>([]);
  const [currentTicketItemId, setCurrentTicketItemId] = useState<string>('');
  const [deletedTicketLineIds] = useState<string[]>([]);

  useEffect(() => {
    if (selectedSR) {
      updateSRFormValues();
      if (checkSrStutus()) {
        setformFields(true);
      } else {
        setformFields(false);
      }
    } else {
      setformFields(false);
      setBEFormFields(bookEventSRSection);
      setFormEventDetails(bookEventDetails);
      selectedMemberDetials();
      setBEFormFields(hideShowFormFields(beformFields, hiddenAddressFields, true));
    }

    const ticketLineId =
      (selectedSR?.additionalData?.units &&
        selectedSR?.additionalData.units.length > 0 &&
        selectedSR?.additionalData.units[0]?.additionalInfo?.ticketLineId) ||
      '';
    setCurrentTicketItemId(ticketLineId);
  }, [selectedSR]);

  const selectedMemberDetials = () => {
    const selectedMember = selectedMemberDetails.currentMemberDetails;
    initialFormValues.phoneNumber = selectedMember?.homePhone;
    initialFormValues.mobileNumber = selectedMember?.mobilePhone;
    initialFormValues.alternatePhoneNumber = selectedMember?.businessPhone;
    initialFormValues.alternateEmail = selectedMember?.clientEmail;
    setInitialFormValues(initialFormValues);
  };
  const updateSRFormValues = () => {
    const {additionalData} = selectedSR;
    const formValues = {
      ...additionalData,
      venueName: additionalData?.details?.venue,
      otherVenueName: additionalData?.details?.otherVenueName,
      venueCity: additionalData?.details?.city,
      eventName: additionalData?.details?.eventName,
      eventCategory: additionalData?.details?.category,
      eventDateTime: getDate(additionalData?.details?.dateTime),
      seatDetails: getSeatDetails(additionalData.details?.seats),
      ...additionalData?.deliveryAddress,
    };
    setInitialFormValues(formValues);
    setTicketItemDetails(additionalData?.units);
    setBEFormFields(
      hideShowFormFields(beformFields, hiddenAddressFields, formValues.isUseMailingAddress),
    );
  };

  const handleFormSubmit = (values) => {
    createSrRequest(values);
  };

  const createAdditionalData = async (formValues) => {
    const additionalData = selectedSR?.additionalData;
    const selectedMember = selectedMemberDetails.currentMemberDetails;
    const totalAmount = await getTotalActualPrice(ticketItemDetails, formValues.postageFee);
    const totalPriceAfterDiscount = await getTotalPriceAfterDiscount(
      ticketItemDetails,
      formValues.postageFee,
      formValues.extraDiscountPercentage,
    );

    return {
      source: 'ng-engage',
      bookingReference: formValues.bookingReference,
      currency: 'GBP',
      unitCurrency: formValues.currency,
      customerEmail: selectedMember.primaryEmail,
      customerFirstName: selectedMember.firstName,
      customerLastName: selectedMember.lastName,
      customerPhoneNumber: selectedMember.mobilePhone,
      service: props.srFormDetails?.serviceType,
      details: {
        dateTime: dateConversionToISOFormatIgnoreTimeZone(new Date(formValues.eventDateTime)),
        eventName: formValues.eventName,
        category: formValues.eventCategory,
        seats: [
          {
            block: '',
            row: '',
            seat: '',
            additionalDetails: formValues.seatDetails,
          },
        ],
        venue: formValues.venueName,
        city: formValues.venueCity,
        otherVenueName: formValues.otherVenueName,
      },
      totalBookingFee: roundOff(getBookingFee(ticketItemDetails, 'bookingFee', 'quantity'), 2),
      postageFee: roundOff(formValues.postageFee, 2),
      units: [...ticketItemDetails],
      totalActualPrice: totalAmount,
      totalPriceAfterDiscount: roundOff(parseToFloat(totalPriceAfterDiscount), 2),
      extraCashbackPercentage: parseToFloat(formValues.extraCashbackPercentage),
      extraCashback: roundOff(parseToFloat(formValues.extraCashback), 2),
      payments: additionalData?.payments || [],
      deliveryAddress: getDeliveryAddress(formValues, selectedMember),
      deliveryName: formValues.isUseMailingAddress
        ? selectedMember.firstName + ' ' + selectedMember.lastName
        : formValues.deliveryName,
      deliveryType: formValues.deliveryType,
      ticketProvider: formValues.ticketProvider,
      bookingDate: additionalData?.bookingDate || '', //dateConversionToISOFormatIgnoreTimeZone(new Date()),
      cancellationReason: formValues.cancellationReason,
      channel: formValues.channel,
      subStatus: formValues.subStatus,
      comments: formValues.comments,
      alternateEmail: formValues.alternateEmail,
      isUseMailingAddress: formValues.isUseMailingAddress,
      deletedLineIdList: deletedTicketLineIds || [],
      onSaleRegRequestId: additionalData?.onSaleRegRequestId,
      onSalePvId: additionalData?.onSalePvId,
    };
  };

  const createSrRequest = async (formValues) => {
    confirmedSRFieldsRequired(false);
    let status = selectedSR?.srStatus || SR_STATUS.OPEN;

    if (formValues?.buttonAction) {
      switch (formValues?.buttonAction) {
        case 'Save':
          status = selectedSR?.srStatus || SR_STATUS.OPEN;
          break;
        case 'Cancel':
          status = SR_STATUS.CANCELLED;
          break;
        case 'Confirmed':
          status = SR_STATUS.CONFIRMED;
          break;
      }
    }
    if (status == SR_STATUS.CANCELLED && formValues.cancellationReason === '') {
      setError(CREATE_SR_CANCEL_ERROR);
      return false;
    }
    if (selectedMemberDetails && selectedMemberDetails.currentMemberDetails) {
      const selectedMember = selectedMemberDetails.currentMemberDetails;
      const notificationParams = getNotificationParams(
        selectedMemberDetails,
        formValues.alternateEmail,
        membershipEnabled,
      );

      if (selectedSR?.id) {
        let updateSRVariable = {
          id: selectedSR?.id,
          requestObj: {
            commands: [
              {
                commandName: 'updateSR',
                commandId: selectedSR?.id,
                ...selectedSR,
                status: status,
                summary: formValues.eventName,
                additionalData: await createAdditionalData(formValues),
                audit: {...selectedSR.audit, modifiedBy: ownerID.toLowerCase()},
              },
            ],
          },
          notificationParams: notificationParams,
        };
        if (status === SR_STATUS.CONFIRMED) {
          if (
            !isPaymentSuccessful(selectedSR) &&
            !formValues.deliveryType &&
            !formValues.seatDetails &&
            !formValues.bookingReference
          ) {
            confirmedSRFieldsRequired(true);
            setError(
              'Please fill the Ticket Delivery, Seat Details, BookingReference details and ensure that the payments are present to proceed',
            );
            return false;
          }
        }
        try {
          let resp = await dispatch(updateSR(updateSRVariable)).unwrap();

          let srUpdateStatus = resp?.data?.updateServiceRequest[0]?.status;
          if (srUpdateStatus?.toUpperCase() === 'SUCCESS') {
            let payload = {
              id: selectedSR?.id,
            };
            await dispatch(searchSRByID(payload)).unwrap();
            props.closeDialogMethod(false);
            dispatch(
              isUpdateSRList({
                isRefreshSRList: true,
              }),
            );
            setError('');
          } else if (srUpdateStatus?.toUpperCase() === 'FAILURE') {
            let srUpdateErrors = resp?.data?.updateServiceRequest?.[0]?.errors;
            setError(srUpdateErrors);
          } else console.log('Something Went Wrong');
        } catch (error: any) {
          setError(UPDATE_SR_ERROR);
        }
      } else {
        let createRequest = {
          serviceRequest: {
            id: null,
            type: SR_TYPES.BOOK_EVENT,
            subType: '',
            member: {
              id: selectedMember.memberId,
              membershipId: selectedMemberDetails.membershipId,
            },
            owner: null,
            activities: [],
            description: '',
            summary: formValues.eventName,
            status: status,
            additionalData: await createAdditionalData(formValues),
            audit: {
              createdBy: ownerID.toLowerCase(),
            },
          },
          notificationParams: notificationParams,
        };
        let resp = await dispatch(createSR(createRequest)).unwrap();
        if (resp) {
          props.closeDialogMethod(false);
          dispatch(
            isUpdateSRList({
              isRefreshSRList: true,
            }),
          );
        }
      }
    }
  };

  const clearMessageOnClose = () => setError('');

  const onUpdateItemClick = (data) => {
    setCurrentTicketItemId(data.ticketLineId);
    setTicket(data);
    isOpenTicketDialog(true);
  };

  const onRemoveItemClick = (data) => {
    setCurrentTicketItemId(data?.ticketLineId);
    if (checkSrStutus()) return;
    if (currentTicketItemId === data?.ticketLineId) {
      setCurrentTicketItemId('');
    }
    let tempArr: any[] = [];
    deletedTicketLineIds.push(data?.ticketLineId);
    for (let i = 0; i < ticketItemDetails.length; i++) {
      if (ticketItemDetails[i]?.additionalInfo?.ticketLineId === data?.ticketLineId) {
        continue;
      }
      tempArr.push(ticketItemDetails[i]);
    }
    setTicketItemDetails(tempArr);
  };

  const handleAddTicketClick = async () => {
    setTicket([]);
    setCurrentTicketItemId('');
    isOpenTicketDialog(true);
  };

  const handleTicketOnSubmit = (ticket) => {
    if (ticket['buttonAction'] === 'Add' || ticket['buttonAction'] === 'Update') {
      if (currentTicketItemId) {
        let tempArr = [...ticketItemDetails];
        let ticketItemIndex = tempArr.findIndex(
          (item) => item?.additionalInfo?.ticketLineId === currentTicketItemId,
        );
        let ticketItem = getTicketItemDetails(ticket, tempArr, discoutnPercentage);
        ticketItem.additionalInfo.ticketLineId = currentTicketItemId;
        tempArr[ticketItemIndex] = ticketItem;
        setTicketItemDetails(tempArr);
      } else {
        let tempArr = (ticketItemDetails && [...ticketItemDetails]) || [];
        const ticketItem = getTicketItemDetails(ticket, tempArr, discoutnPercentage);
        tempArr.push(ticketItem);
        setCurrentTicketItemId(ticketItem.additionalInfo.ticketLineId);
        setTicketItemDetails(tempArr);
      }
      setTicket([]);
    }
  };

  const confirmedSRFieldsRequired = (value) => {
    let updatedFormContent = updateFormContent(beformFields, {
      deliveryType: {
        targetAttribute: 'required',
        targetValue: value,
      },
    });
    setBEFormFields(updatedFormContent);

    let targetFormFields = {
      bookingReference: {
        targetAttribute: 'required',
        targetValue: value,
      },
      seatDetails: {
        targetAttribute: 'required',
        targetValue: value,
      },
    };

    let updatedEventFormContent = updateFormContent(beEventformFields, targetFormFields);
    setFormEventDetails(updatedEventFormContent);
  };

  const handleMailingAddressClick = (e, setField) => {
    setBEFormFields(hideShowFormFields(beformFields, hiddenAddressFields, e.target.checked));
    let updatedFormContent = updateFormContent(beformFields, {
      deliveryName: {
        targetAttribute: 'required',
        targetValue: !e.target.checked,
      },
    });
    setBEFormFields(updatedFormContent);
    if (e.target.checked) {
      const selectedMember = selectedMemberDetails.currentMemberDetails;
      setField('mobileNumber', selectedMember?.mobilePhone);
      setField('alternateEmail', selectedMember?.clientEmail);
    } else {
      setField('mobileNumber', '');
      setField('alternateEmail', '');
    }
  };

  const checkSrStutus = () => {
    if (
      selectedSR &&
      selectedSR.type === SR_TYPES.BOOK_EVENT &&
      (selectedSR.status === SR_STATUS.CANCELLED || selectedSR.status === SR_STATUS.CONFIRMED)
    ) {
      return true;
    }
  };
  const customEvents = {
    isUseMailingAddress: {onChange: handleMailingAddressClick},
  };

  const setformFields = (isDisabled) => {
    let disabledFormBCFields = updateFormContent(
      beformFields,
      isDisableFormFields(beformFields.fields, isDisabled),
    );
    setBEFormFields(disabledFormBCFields);

    let disabledFormEventFields = updateFormContent(
      beEventformFields,
      isDisableFormFields(beEventformFields.fields, isDisabled),
    );
    setFormEventDetails(disabledFormEventFields);

    const targetButtonFields = {
      saveButton: {
        targetAttribute: 'isDisabled',
        targetValue: isDisabled,
      },
      cancelButton: {
        targetAttribute: 'isDisabled',
        targetValue: isDisabled,
      },
      confirmedButton: {
        targetAttribute: 'isDisabled',
        targetValue: isDisabled,
      },
    };
    let updateButtonList = updateFormContent(buttonSection, targetButtonFields);
    setButtonSection(updateButtonList);

    const eventFixedDisabledFields = {
      bookingDate: {
        targetAttribute: 'isDisabled',
        targetValue: true,
      },
      totalBookingFee: {
        targetAttribute: 'isDisabled',
        targetValue: true,
      },
      totalActualPrice: {
        targetAttribute: 'isDisabled',
        targetValue: true,
      },
      totalPriceAfterDiscount: {
        targetAttribute: 'isDisabled',
        targetValue: true,
      },
    };
    setFormEventDetails(updateFormContent(beEventformFields, eventFixedDisabledFields));
  };

  return (
    <Wrapper>
      <Form
        onSubmit={handleFormSubmit}
        validationSchema={validationSchema}
        initialValues={initialFormValues}
        section1={beformFields}
        section2={beEventformFields}
        buttonStack={buttonList}
        customEvents={customEvents}
      />
      <Paper sx={{marginY: globalSpacing.s}}>
        <Stack spacing={globalSpacing.s} p={globalSpacing.m} mt={0}>
          <Box
            component="span"
            mt={0}
            display="flex"
            justifyContent="space-between"
            alignItems="center">
            <Typography variant="h6">Ticket Details</Typography>
            <Button variant="contained" onClick={handleAddTicketClick} disabled={checkSrStutus()}>
              Add
            </Button>
          </Box>
          <Grid container spacing={globalSpacing.s}>
            <TicketListView
              tableData={ticketItemDetails}
              onRemoveItemClick={onRemoveItemClick}
              onUpdateItemClick={onUpdateItemClick}
            />
          </Grid>
        </Stack>
        <AddTicketDialog
          openAddTicketDialog={openAddTicketDialog}
          isOpenTicketDialog={isOpenTicketDialog}
          handleOnSubmit={handleTicketOnSubmit}
          ticket={ticket}
          setTicket={setTicket}
        />
      </Paper>
      <SnackBar
        isSnackBarOpen={isSnackBarOpen}
        closeSnackBar={closeSnackBar}
        errorMessage={error}
        clearMessageOnClose={clearMessageOnClose}
      />
    </Wrapper>
  );
};
export default BookEventSR;
