import {createSlice, createAsyncThunk, createEntityAdapter} from '@reduxjs/toolkit';
import {gqlSearchSRByType} from 'GQL/MethodsBFF/ServiceRequest';

interface CashbackSRList {}

const selectedCashbackSRAdapter: any = createEntityAdapter<CashbackSRList>({});

const initialState = selectedCashbackSRAdapter.getInitialState({
  status: 'idle',
  error: '',
});

export const searchSRByTypeCashback: any = createAsyncThunk(
  'serviceRequest/searchSRByTypeCashback',
  async (data: any, {getState}) => {
    const state: any = getState();
    const headers = {
      'tenant-id': state?.login.tenantID,
      'user-access-token': state?.login.loginInfo?.access_token,
      'client-access-token': state?.login.loginInfo?.service_token,
    };
    return await gqlSearchSRByType(data, headers);
  },
);

const cashbackSRList = createSlice({
  name: 'cashbackSRList',
  initialState,
  reducers: {
    resetCashbackSRData: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(searchSRByTypeCashback.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(searchSRByTypeCashback.fulfilled, (state, action) => {
        state.status = 'succeeded';
        selectedCashbackSRAdapter.setAll(
          state,
          action?.payload?.data?.getServiceRequestsByType?.searchServiceRequestsOutputDtoList || [],
        );
      })
      .addCase(searchSRByTypeCashback.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default cashbackSRList.reducer;

export const {selectAll: selectAllCashbackSRList} = selectedCashbackSRAdapter.getSelectors(
  (state) => state.cashbackSRList,
);

export const {resetCashbackSRData} = cashbackSRList.actions;
