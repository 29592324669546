const styles = {
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  tabsContainer: {
    borderBottom: 1,
    borderColor: 'divider',
  },
  tabs: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    paddingX: '5vw',
  },
  tabText: {
    display: 'flex',
    flex: 1,
    marginX: '10px',
    marginY: '10px',
    textTransform: 'none',
    color: 'inherit',
    fontFamily: 'headline-regular',
    '&.Mui-selected': {
      color: 'inherit',
      fontFamily: 'headline-bold',
    },
  },
  tabPanelContainer: {
    justifyContent: 'center',
    flex: 1,
  },
};

export default styles;
