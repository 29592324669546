import {globalConfig} from '../../Config/config';
import {Queries} from '../GQLConstants';
import {gqlCall, GQLType} from '../utils';

/**
 * @param {object} variables {referenceId: string, pageSize: number, pageNo: number}
 * @description
 * referenceId is internal member reference
 */
const gqlGiftingHistory: any = async (variables) => {
  try {
    const data = await gqlCall(
      GQLType.QUERY,
      Queries.GET_GIFTING_HISTORY,
      variables,
      null,
      globalConfig._config.BFF.astBFF,
      false,
    );

    return data;
  } catch (error) {
    return {error};
  }
};

export {gqlGiftingHistory};
