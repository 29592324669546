export const roundOff = (num, places) => {
  const x = Math.pow(10, places);
  return Math.round(num * x) / x;
};

export const parseToFloat = (value) => {
  try {
    return isNaN(parseFloat(value)) ? 0.0 : parseFloat(value);
  } catch (error: any) {
    return 0.0;
  }
};

export const isContainsNumbers = (str) => {
  if (!str) {
    return false;
  }
  return Boolean(str.match(/\d/g));
};
