/* eslint-disable no-unused-vars */
import {format} from 'date-fns';

const updateQueryValues = (values, agentId, dateFormat, existingComments) => ({
  id: values.ticketN,
  requestObj: {
    additionalData: {
      comments: commentFormatting(values.comments, agentId, dateFormat, existingComments),
    },
    audit: {
      modifiedBy: agentId,
      modifiedOn: new Date().toISOString(),
    },
  },
});

const createQueryValues = (values, id, externalRefId, agentId, dateFormat) => ({
  serviceRequest: {
    id: null, //this is null at request, response will return query ticket n
    type: `${values.type} Enquiry`, //query type
    member: {id: id}, //mem id
    description: values.description, // COUPON only
    summary: values.description, //duplicate above
    status: 'Open', //default value
    additionalData: {
      externalRefId: externalRefId,
      reason: values.escalationReason,
      merchantName: values.merchantName, //cb clo coupons
      orderNumber: values.orderNumber, //clo cb
      transactionDate: values.transactionDate ? values.transactionDate.toISOString() : null, //clo cb coupons
      transactionAmount: values.transactionAmount, //clo cb
      itemPurchased: values.purchasedItems, //clo cb
      paymentMethod: values.paymentMethod, // clo cb
      comments: values.comments ? commentFormatting(values.comments, agentId, dateFormat) : null, //follow up and general
      channel: 'Form', //default value
      service: 'General', //default value
    },
    audit: {
      createdBy: agentId,
    },
  },
});

const commentFormatting = (comment, agentId, dateFormat, existingComments = '') => {
  // FORMAT OF THE COMMENT test\n\n- test3@yopmail.com 05/09/2022 09:25:33 AM\n\ntest\n\n- test3@yopmail.com 05/09/2022 09:24:56 AM
  const localDateNTime = format(new Date(), `${dateFormat} hh:mm:ss aa`);
  const newComment = `${comment}\n\n- ${agentId} ${localDateNTime}`;
  return existingComments ? `${newComment}\n\n${existingComments}` : newComment;
};

export {updateQueryValues, createQueryValues};
