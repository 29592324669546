import {Box, useTheme} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * @description This component controls the width if it's children
 * according to preset breakpoints from the theme
 * To be used on all presentational components but header, footer and tab bar
 */

interface IProps {
  children?: React.ReactNode;
  customStyle?: any;
}

const Wrapper: React.FC<IProps> = (props) => {
  const s = {
    [useTheme().breakpoints.down('xl')]: {
      width: '90vw',
    },
    [useTheme().breakpoints.up('xl')]: {
      width: '73vw',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  };
  const wrapper = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <Box sx={wrapper}>
      <Box sx={s} style={props.customStyle}>
        {props.children}
      </Box>
    </Box>
  );
};

Wrapper.propTypes = {children: PropTypes.node};

export default Wrapper;
