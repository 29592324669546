import {Paper, Divider} from '@mui/material';
import React, {Fragment} from 'react';
import {useSelector} from 'react-redux';
import DetailListItem from './components/DetailListItem';
import styles from './styles';
import {TabHeading, Wrapper} from 'Components';
import {customerDetails} from './customerDetails';

const TabProfile = () => {
  const {selectedMember} = useSelector((state: any) => state?.selectedMember);
  const currentMemberDetails = selectedMember.currentMemberDetails;

  const extractedMember = {
    email: currentMemberDetails?.primaryEmail || currentMemberDetails?.clientEmail,
    address: `${currentMemberDetails?.addressLine1 || 'Address not available'}, ${
      currentMemberDetails?.city || 'City not available'
    }, ${currentMemberDetails?.country || 'Country not available'}`,
    postalCode: currentMemberDetails?.postalCode,
  };

  return (
    <Wrapper>
      <TabHeading data={customerDetails} />
      <Paper sx={styles.paper}>
        {/* customer_detail from CS is array of objects with key value pairs, each key has to be the same as the key from api result value that we want to display, since that's not possible we have extratedMember that has same keys as array*/}
        {customerDetails?.fields?.map((item, index) => {
          if (currentMemberDetails[item.key]) {
            return (
              <Fragment key={item.key}>
                <DetailListItem label={item.value} value={currentMemberDetails[item.key]} />
                {index < customerDetails?.fields?.length - 1 && <Divider light />}
              </Fragment>
            );
          } else {
            return (
              <Fragment key={item.key}>
                <DetailListItem label={item.value} value={extractedMember[item.key]} />
                {index < customerDetails?.fields?.length - 1 && <Divider light />}
              </Fragment>
            );
          }
        })}
      </Paper>
    </Wrapper>
  );
};

export default TabProfile;
