import React from 'react';
import SelectBox from './formElements/SelectBox';
import TextBox from './formElements/TextBox';
import CustomCheckbox from './formElements/CustomCheckbox';
import CustomDateTimePicker from './formElements/DateTimePicker';
import DatePickerBox from './formElements/DatePickerBox';
import {CustomButton} from './formElements/CustomButton';
import CommentBox from './formElements/CommentBox';
import TextareaBox from './formElements/TextareaBox';

let formFieldsMap = {
  text: function (field: any, props: any, customProps) {
    return (
      <TextBox
        key={field.dataTest + field.name}
        field={field}
        formikProp={props}
        customEvents={customProps.customEvents}
      />
    );
  },
  select: function (field: any, props: any, customProps) {
    return (
      <SelectBox
        key={field.dataTest + field.name}
        field={field}
        customEvents={customProps.customEvents}
        formikProp={props}
      />
    );
  },
  checkbox: function (field: any, props: any, customProps) {
    return (
      <CustomCheckbox
        formikProp={props}
        key={field.dataTest + field.name}
        field={field}
        customEvents={customProps.customEvents}
        formValues={props.initialValues}
      />
    );
  },
  commentBox: function (field: any, props: any, customProps) {
    return (
      <CommentBox
        key={field.dataTest + field.name}
        field={field}
        customEvents={customProps.customEvents}
        formValues={props.initialValues}
        formikProp={props}
      />
    );
  },
  textarea: function (field: any, props: any, customProps) {
    return (
      <TextareaBox
        key={field.dataTest + field.name}
        field={field}
        customEvents={customProps.customEvents}
        formValues={props.initialValues}
        formikProp={props}
      />
    );
  },
  dateTimePicker: function (field: any, props: any, customProps) {
    return (
      <CustomDateTimePicker
        key={field.dataTest + field.name}
        field={field}
        customEvents={customProps.customEvents}
        formValues={props.initialValues}
      />
    );
  },
  datePicker: function (field: any, props: any, customProps) {
    return (
      <DatePickerBox
        key={field.dataTest + field.name}
        field={field}
        formValues={customProps.initialValues}
        customEvents={props.customEvents}
      />
    );
  },
  button: function (field: any, props: any, customProps) {
    return (
      <CustomButton
        key={field.dataTest + field.name}
        field={field}
        customEvents={customProps.customEvents}
        formikProp={props}
        setSubmitting={props.setSubmitting}
      />
    );
  },
};

export default formFieldsMap;
