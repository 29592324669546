import globalSpacing from '../../Theme/Spacing';

const styles = {
  card: {
    // marginX: globalSpacing.xs,
    // marginY: globalSpacing.sm,
    border: 1,
  },
  cardActionArea: {
    padding: globalSpacing.sm,
  },
  title: {
    maxWidth: '40%',
    wordBreak: 'break-word',
    marginBottom: globalSpacing.xxs,
  },
};

export default styles;
