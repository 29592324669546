import {IconButton} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import globalSpacing from 'Theme/Spacing';

const CloseIconDialog = ({callback, image}) => {
  return (
    <IconButton
      aria-label="close"
      onClick={() => callback()}
      sx={{
        position: 'absolute',
        right: globalSpacing.xs,
        top: globalSpacing.xs,
      }}>
      <img src={image} />
    </IconButton>
  );
};

CloseIconDialog.propTypes = {
  callback: PropTypes.func,
  image: PropTypes.string,
};

export default CloseIconDialog;
