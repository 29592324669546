import globalSpacing from 'Theme/Spacing';

const styles = {
  stack: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    alignItems: 'center',
    paddingX: globalSpacing.l,
    paddingY: globalSpacing.m,
  },
};

export default styles;
