import * as Yup from 'yup';

export const validationSchema = Yup.object({
  subType: Yup.string().required(),
});

export const initialValues = {
  benefit: '',
  subType: '',
  subStatus: '',
  channel: 'Phone',
  owner: '',
  thirdParty: '',
  thirdPartyNotes: '',
  eventDateTime: null,
  complaintDetail: '',
  complaintNature: '',
  complaintReason: '',
  complainant: '',
  compensationType: '',
  compensationAmount: '',
  receivedBy: '',
  replyTo: '',
  severity: '',
  dateReceived: null,
  complaintResolution: '',
  //second section
  fosRights: false,
  fos1: false,
  fos2: false,
  upheld: false,
  isControllable: false,
  rework: false,
  compensation: false,
  additionalUpdate: false,
  refund: false,
  refundAmount: '',
  redress: false,
  redressAmount: '',
  redressAction: '',
  qaFlag: false,
  dateAcknowledgement: null,
  referredToThirdParty: false,
  resolutionChannel: '',
  dateResolved: null,
  summaryResolution: false,
  finalResponse: false,
  isCompleted: '',
  comments: '',
};

export const transcriptInitialState = {
  enabled: false,
  transcriptData: null,
};
