import * as Yup from 'yup';

export const validationSchema = Yup.object({
  type: Yup.string().required('Required'),
});

export const initialValues = {
  type: '',
  subType: '',
  status: 'Not Started',
  priority: 'Medium',
  channel: '',
  plannedStartDate: null,
  id: '',
  comments: '',
  freeText: '',
  templateId: '',
};
