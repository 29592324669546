export const MEMBER_SEARCH = {
  button_text: 'SEARCH',
  heading: 'Agent Support Tool',
  input_label: 'Member Identifier',
  member_search_text: 'Please enter Member Identifier',
  multiple_search_results: {
    heading: 'Search Results',
    table_fields: {
      identifier: 'Unique Identifier',
      status: 'Loyalty Status',
    },
  },
  no_search_result: {
    description:
      'We searched our records and did not find any customer matching your search. Please search again.',
    heading: 'No matching customer records found',
  },
  subtitle: 'Member Search',
  title: 'Search Member Page',
};
