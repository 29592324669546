import {Checkbox, MenuItem, TextField, useTheme} from '@mui/material';
import React, {useState} from 'react';
import globalSpacing from '../../Theme/Spacing';
import ClearInputIcon from 'Components/InputAdornments/ClearInputIcon';
import PasswordIcon from 'Components/InputAdornments/PasswordIcon';

interface IProps {
  label: string;
  name: string;
  onChange: any;
  inline?: boolean;
  value?: string;
  reasonsMenu?: any;
  queryTypesMenu?: any;
  queryStatusMenu?: any;
  filterMenu?: any;
  disabled?: boolean;
  reset?: any;
  size?: any;
  isMultipleSelect?: boolean;
  autoFocus?: any;
}

const CustomTextInput: React.FC<IProps> = (props) => {
  const {
    label,
    name,
    onChange,
    inline,
    value,
    reasonsMenu = [],
    queryTypesMenu = [],
    queryStatusMenu = [],
    filterMenu = [],
    disabled = false,
    reset = null,
    size,
    autoFocus,
  } = props;
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const type =
    name === 'phoneNumber' ? 'number' : name !== 'password' || showPassword ? 'text' : 'password';
  const isSelectType =
    name === 'client' ||
    name === 'type' ||
    name === 'escalationReason' ||
    name === 'filter' ||
    name === 'status' ||
    name === 'srType' ||
    name === 'createdBy' ||
    name === 'owner' ||
    name === 'subType';

  const isMultipleSelect = props?.isMultipleSelect || false;

  const showAdornment =
    name === 'password' ? (
      <PasswordIcon toggle={() => setShowPassword(!showPassword)} showPassword={showPassword} />
    ) : reset && value ? (
      <ClearInputIcon reset={reset} />
    ) : null;

  const menu =
    name === 'type' || name === 'client' || name === 'subType' || name === 'srType'
      ? queryTypesMenu?.map((query, index) => (
          <MenuItem key={index} value={query}>
            {query}
          </MenuItem>
        ))
      : name === 'escalationReason'
      ? reasonsMenu?.map((reason: any) => (
          <MenuItem key={reason.key} value={reason.value}>
            {reason.value}
          </MenuItem>
        ))
      : name === 'filter'
      ? filterMenu?.map((filter, index) => (
          <MenuItem key={index} value={filter}>
            {filter}
          </MenuItem>
        ))
      : name === 'status' && !props.isMultipleSelect
      ? queryStatusMenu?.map((status, index) => (
          <MenuItem key={index} value={status}>
            {status}
          </MenuItem>
        ))
      : name === 'status' && props.isMultipleSelect
      ? queryStatusMenu?.map((status, index) => (
          <MenuItem key={index} value={status}>
            <Checkbox
              sx={{
                '&.MuiCheckbox-root': {
                  color: theme.palette.primary.dark,
                },
              }}
              checked={(props?.value || '').indexOf(status) > -1}
            />
            {status}
          </MenuItem>
        ))
      : name === 'createdBy'
      ? queryTypesMenu?.map((query, index) => (
          <MenuItem key={index} value={query.userName}>
            {query.userName}
          </MenuItem>
        ))
      : name === 'owner'
      ? queryTypesMenu?.map((query, index) => (
          <MenuItem key={index} value={query.id}>
            {query.userName}
          </MenuItem>
        ))
      : null;

  return (
    <TextField
      sx={{
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.08)',
        borderRadius: `${theme.shape.borderRadius}px`,
        marginRight: inline ? globalSpacing.s : null,
        flex: name === 'filter' ? 0.3 : 1,
        width: name === 'filter' ? '200px' : 'inherit',
        outline: 'none',
        '& .MuiOutlinedInput-root': {
          background: name === 'filter' ? theme.palette.background.paper : null,
          '&.Mui-focused': {
            '& fieldset': {
              borderColor: theme.palette.action.active,
            },
          },
        },
        '& .MuiInputLabel-root': {
          color: theme.palette.text.primary,
          '&.MuiInputLabel-shrink': {
            color: theme.palette.text.primary,
          },
        },
      }}
      margin={'none'}
      label={label}
      type={type}
      select={isSelectType}
      // variant="filled"
      autoFocus={autoFocus ? true : false}
      //TODO scrollbar styling for multiline input
      multiline={name === 'comments' ? true : false}
      disabled={disabled}
      minRows={2}
      maxRows={2}
      InputProps={{
        endAdornment: showAdornment,
      }}
      InputLabelProps={{
        shrink: true,
        sx: {
          fontSize: 17,
          fontWeight: 'bold',
          color: theme.palette.text.primary,
          '&.MuiInputLabel-shrink.Mui-disabled': {
            color: theme.palette.text.disabled,
          },
        },
      }}
      SelectProps={{
        multiple: isMultipleSelect,
        value: props.value,
      }}
      onChange={onChange}
      name={name}
      value={value === undefined ? '' : value}
      size={size ? size : 'medium'}
      color="primary">
      {isSelectType && menu}
    </TextField>
  );
};

export default CustomTextInput;
