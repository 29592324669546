import React, {useState} from 'react';
import {
  Typography,
  IconButton,
  TableRow,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  Button,
} from '@mui/material';
import globalSpacing from 'Theme/Spacing';
import styles from './styles';
import CommentsDialog from 'Pages/SRDashboard/components/ResultsTable/CommentsDialog';
import {useCSContext} from 'Context/ContentStackContext';
import CustomTableCell from 'Pages/SRDashboard/components/ResultsTable/CustomTableCell';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {SortOrder} from 'Utils/enums';

const TableView = (props) => {
  let {
    headers,
    isCommentColumn,
    hideColumns,
    sortingEnabled,
    sortOrder,
    currentSortCriteria,
    onRowClick = () => {},
  } = props;
  const {artifacts} = useCSContext();
  const [openCommentsDialog, setOpenCommentsDialog] = useState(false);
  const [commentData, setComment] = useState('');

  const hideCommentModal = (isOpen) => {
    setOpenCommentsDialog(isOpen);
  };

  const showCommentModal = (e, data) => {
    e.stopPropagation();
    setComment(data?.srData?.additionalData?.comments);
    setOpenCommentsDialog(true);
  };

  const renderTableHeaders = () => {
    headers = isCommentColumn ? [...headers, {label: 'Comments', value: 'moreInfo'}] : [...headers];
    return (
      <TableRow
        sx={{
          backgroundColor: 'primary.light',
        }}>
        {headers?.map((item: any, index) => {
          if (hideColumns && hideColumns[item.value]) {
            return null;
          }
          return (
            <TableCell
              key={item.label}
              align={'left'}
              sx={{paddingY: globalSpacing.sm, fontWeight: 600}}>
              <div
                onClick={
                  sortingEnabled
                    ? () => (props.sortTable && props.sortTable(item.value)) || {}
                    : undefined
                }>
                {item.label}
                {sortingEnabled && currentSortCriteria === item.value ? (
                  sortOrder === SortOrder.ASCENDING ? (
                    <ArrowDropUpIcon sx={{fontSize: 18}} />
                  ) : sortOrder === SortOrder.DESCENDING ? (
                    <ArrowDropDownIcon sx={{fontSize: 18}} />
                  ) : (
                    false
                  )
                ) : null}
              </div>
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  const renderTableRow = (data: any) => {
    let rowData: any = {};
    rowData = Object.keys(data);

    return rowData.map((key, index) => {
      if (hideColumns && hideColumns[key]) {
        return null;
      }
      return (
        <CustomTableCell key={`${data[key]}_${index}`} align="left">
          {key === 'moreInfo' ? (
            <IconButton size="small" onClick={(e) => showCommentModal(e, data)}>
              <img src={artifacts.more_icon} color="primary" alt="comment" />
            </IconButton>
          ) : key === 'buttons' && props?.buttons && props?.buttons?.length > 0 ? (
            props?.buttons.map((buttonData) => (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  props.customEvents[buttonData.name](data);
                }}
                variant="contained"
                disabled={buttonData.isDisabled}
                id={buttonData.id}
                name={buttonData.name}
                sx={{
                  paddingY: globalSpacing.xxs,
                  marginY: globalSpacing.xxs,
                  width: '20%',
                }}>
                <Typography variant="body2" sx={{textTransform: 'none'}}>
                  {buttonData.fieldLabel}
                </Typography>
              </Button>
            ))
          ) : (
            <Typography variant="body1">{data[key] || ''}</Typography>
          )}
        </CustomTableCell>
      );
    });
  };

  const renderTableData = () => {
    return (props.tableData || []).map((item: any, index: number) => {
      const moreInfo = '';
      let extraItem = props?.isCommentColumn ? {...item, moreInfo} : {...item};
      return (
        <TableRow
          hover={true}
          sx={{
            '&:last-child td, &:last-child th': {border: 0},
            '&.MuiTableRow-hover:hover': {backgroundColor: 'primary.light'},
          }}
          onClick={() => onRowClick(item)}
          key={index}>
          {renderTableRow(extraItem)}
        </TableRow>
      );
    });
  };

  return (
    <>
      <CommentsDialog
        openCommentsDialog={openCommentsDialog}
        setOpenCommentsDialog={hideCommentModal}
        comments={commentData}
      />
      <TableContainer component={Paper} sx={styles.paper}>
        <Table>
          <TableHead sx={styles.table}>{renderTableHeaders()}</TableHead>
          <TableBody sx={styles.table}>{renderTableData()}</TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TableView;
