import {Visibility, VisibilityOff} from '@mui/icons-material';
import {IconButton, InputAdornment} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const PasswordIcon = ({toggle, showPassword}) => {
  //TODO get this icon from CS

  return (
    <InputAdornment position="end">
      <IconButton aria-label="toggle password visibility" onClick={() => toggle()} edge="end">
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  );
};

PasswordIcon.propTypes = {
  toggle: PropTypes.func.isRequired,
  showPassword: PropTypes.bool.isRequired,
};

export default PasswordIcon;
