import {SS} from 'Utils/constants/SessionStorage';

const isIframeRequest = (searchParams) => {
  const externalId = searchParams?.get('externalId');
  const internalId = searchParams?.get('internalId');
  const token = searchParams?.get('token');
  const email = searchParams?.get('email');
  const status = searchParams?.get('memberStatus');
  const tenantId = searchParams?.get('tenantId');

  return {
    state: !!(externalId && internalId && token && tenantId && status),
    params: {internalId, externalId, email, status},
    token: token,
    tenant: tenantId,
  };
};

const createMemberObj = ({internalId, externalId, email, status}) => {
  return {
    id: internalId,
    externalRefId: externalId,
    communication: [{communicationType: 'EMAIL', communicationValue: email}],
    status: status,
  };
};

const setStorageForIframe = (memberData) => {
  sessionStorage.setItem(SS.IFRAME, 'true');
  sessionStorage.setItem(SS.MEMBER, JSON.stringify(memberData));
};

export {createMemberObj, setStorageForIframe, isIframeRequest};
