import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box, Divider, Paper, Stack, Typography} from '@mui/material';
import {useFormik} from 'formik';
import {
  Wrapper,
  CustomDatePicker,
  CustomTextInput,
  NoSearchResults,
  SimpleAlert,
  SnackBar,
  InfiniteScrollerCustom,
  Loading,
  BackButtonWText,
} from 'Components';
import globalSpacing from 'Theme/Spacing';
import styles from './styles';
import {useCSContext} from 'Context/ContentStackContext';
import {gqlSRHistory} from 'GQL/MethodsBFF/SRHistory';
import {createRowData} from 'Utils/helpers/CreateRowData';
import createQuerySearchValues from 'Utils/helpers/QueryDashboardSearchMap';
import {LoadingButton} from '@mui/lab';
import ResultsTable from './components/ResultsTable';
import useSnackBar from 'Hooks/SnackBarErrorHook';
import useInfiniteScroll from 'Hooks/InfiniteScrollHook';
import {getMissingSearchField, isPassingSearchCriteria} from 'Utils/helpers/QuerySearchValidation';
import onlyDigitsValidation from 'Utils/helpers/OnlyDigitsValidation';
import {useNavigate} from 'react-router-dom';
import {DASHBOARD} from 'Utils/constants/Paths';
import {setTenantDetails} from 'Redux/Slices/login';
import {getErrorMsg} from 'Utils/helpers/ErrorHandler';
import {SR_DASHBOARD} from './srDashboardConstants';

const SRDashboard = () => {
  const dispatch = useDispatch();
  const {artifacts} = useCSContext();
  const clientArray = useSelector((state: any) => state?.login?.loginInfo?.configs);
  const client = clientArray.map((item) => item['name']);

  const navigate = useNavigate();
  const [SRList, setSRList] = useState<any[]>([]);
  const [noRecords, setNoRecords] = useState(false);
  const {selectedMember} = useSelector((state: any) => state?.selectedMember);
  const [requiredDialog, setRequiredDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isSnackBarOpen, closeSnackBar] = useSnackBar(error);
  const [mappedValues, setMappedValues] = useState<any>(null);
  const [searchCriteriaMessage, setSearchCriteriaMessage] = useState('');
  const [totalRecords, setTotalRecords] = useState(0);
  const [{pageNo, nextPage, pageSize, resetPage}] = useInfiniteScroll();

  useEffect(() => {
    dispatch(setTenantDetails(client[0]));
  }, []);

  const toggleRequiredDialog = () => {
    setRequiredDialog(!requiredDialog);
  };

  const validate = () => {
    if (!isPassingSearchCriteria(formik.values, queryStatusValue)) {
      const missingField = getMissingSearchField(formik.values, queryStatusValue);
      setRequiredDialog(true);
      setSearchCriteriaMessage(SR_DASHBOARD.required_dialog.error_message[missingField]);
      formik.setSubmitting(false);
      return {errors: 'error'};
    }
  };

  const formik: any = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      client: client?.[0],
      status: ['Select Status', 'Open', 'Closed', 'Pending'],
    },
    validate,
    onSubmit: async () => SRList.length !== 0 && (await resetPage(), setSRList([])),
  });

  /**
   * Default value for Query Status is 'Select Status' or
   * it can be set to 'Select Status' as to clear that input field.name
   * So we check if that is the value of the field and treat it as if it was empty.
   * The disabling of the Submit button also depends on the same logic
   */
  const queryStatusValue = formik.values?.status
    ? formik.values.status === 'Select Status'
      ? null
      : formik.values.status
    : null;

  const _isButtonDisabled =
    !Object.keys(formik.values).some(
      (value) => !['status', 'client'].includes(value) && formik.values[value],
    ) && !queryStatusValue;

  const _handleResponse = (responseList, error) => {
    setLoading(false);
    pageNo === 0 && setSRList([]);
    error && setError(getErrorMsg(error.message));
    if (responseList) {
      const mappedResponseRow = responseList.map((row) => createRowData(row));
      mappedResponseRow.length
        ? pageNo === 0
          ? setSRList(mappedResponseRow)
          : setSRList([...SRList, ...mappedResponseRow])
        : setNoRecords(true);
    }
  };

  useEffect(() => {
    if (pageNo === 0 && SRList.length === 0 && formik.isSubmitting) {
      noRecords && setNoRecords(false);
      setLoading(true);
      (async () => {
        const reqBody = createQuerySearchValues(formik.values, queryStatusValue);
        setMappedValues(reqBody);
        const {data, error}: any = await gqlSRHistory({
          srSearchRequestInput: {
            pageNumber: 0,
            pageSize: 10,
            ...reqBody,
          },
        });
        data?.searchSR?.pagingInfo && setTotalRecords(data.searchSR.pagingInfo.totalRecords);
        _handleResponse(data?.searchSR[0].responseList, error);
      })();
    }
  }, [formik.isSubmitting, SRList]);

  useEffect(() => {
    if (pageNo > 0) {
      (async () => {
        const {data, error} = await gqlSRHistory({
          srSearchRequestInput: {
            pageNumber: pageNo,
            pageSize: pageSize,
            ...mappedValues,
          },
        });
        _handleResponse(data?.searchSR?.responseList, error);
      })();
    }
  }, [pageNo]);

  return (
    <Wrapper>
      <Paper sx={{marginY: globalSpacing.md}}>
        <Box p={globalSpacing.md} sx={styles.topContainer}>
          <img src={artifacts.query_dashboard_logo} />
          <Typography variant="h1" px={globalSpacing.xs}>
            {SR_DASHBOARD.heading}
          </Typography>
        </Box>
        <Divider />
        <Stack p={globalSpacing.md} spacing={globalSpacing.s}>
          <Box sx={styles.inputRow}>
            <CustomTextInput
              label="Client"
              name="client"
              queryTypesMenu={client}
              onChange={(e) => {
                dispatch(setTenantDetails(e.target.value));
                formik.handleChange(e);
              }}
              value={formik.values['client']}
            />
            {SR_DASHBOARD.input_fields[0].map((field, index) => {
              return (
                <Box display={'flex'} flex={1} key={index}>
                  <CustomTextInput
                    name={field.name}
                    label={field.label}
                    value={formik.values[field.name]}
                    onChange={
                      field.name === 'srId'
                        ? (e) => onlyDigitsValidation(e, formik.handleChange)
                        : formik.handleChange
                    }
                    reset={() => formik.setFieldValue(field.name, null)}
                  />
                </Box>
              );
            })}
          </Box>
          <Box sx={styles.inputRow}>
            {SR_DASHBOARD.input_fields[1].map((field, index) => {
              if (field.name === 'period') {
                return (
                  <Typography key={index} variant="body1" alignSelf={'center'}>
                    {field.label}
                  </Typography>
                );
              }
              if (field.name === 'fromDate' || field.name === 'toDate') {
                return (
                  <CustomDatePicker
                    key={index}
                    date={formik.values[field.name] || null}
                    handleChange={(searchDate) => formik.setFieldValue(field.name, searchDate)}
                    label={field.label}
                    position="start"
                    reset={() => formik.setFieldValue([field.name], null)}
                    {...(formik.values?.fromDate &&
                      field.name === 'toDate' && {minDate: formik?.values?.fromDate})}
                  />
                );
              }
              return (
                <Box display={'flex'} flex={2} key={index}>
                  <CustomTextInput
                    name={field.name}
                    label={field.label}
                    queryStatusMenu={field.options}
                    value={formik.values[field.name]}
                    onChange={formik.handleChange}
                  />
                </Box>
              );
            })}
            <LoadingButton
              disabled={_isButtonDisabled}
              onClick={(e) => formik.handleSubmit()}
              variant="contained"
              sx={{display: 'flex', width: '15%'}}
              loading={formik.isSubmitting}
              type="button">
              {SR_DASHBOARD.search_button_title}
            </LoadingButton>
          </Box>
          {selectedMember && (
            <Box display={'flex'} justifyContent="end">
              <BackButtonWText
                text={SR_DASHBOARD.back_button_title}
                callback={() => navigate(DASHBOARD)}
              />
            </Box>
          )}
        </Stack>
      </Paper>

      {noRecords && <NoSearchResults CSTexts={SR_DASHBOARD.no_records} />}
      {loading && <Loading />}
      {SRList.length ? (
        <InfiniteScrollerCustom
          dataLength={SRList?.length}
          next={() => nextPage()}
          hasMore={SRList.length < totalRecords}>
          <ResultsTable SRList={SRList} />
        </InfiniteScrollerCustom>
      ) : null}
      <SimpleAlert
        isOpen={requiredDialog}
        toggleDialog={toggleRequiredDialog}
        text={{heading: SR_DASHBOARD.required_dialog.heading, subtitle: searchCriteriaMessage}}
      />
      <SnackBar
        isSnackBarOpen={isSnackBarOpen}
        closeSnackBar={closeSnackBar}
        errorMessage={error}
      />
    </Wrapper>
  );
};

export default SRDashboard;
