import {Box, Typography} from '@mui/material';
import React, {Fragment} from 'react';
import globalSpacing from 'Theme/Spacing';
import {BackButtonWText} from 'Components';

interface IProps {
  image: string;
  data: any;
  resetAfterSubmitting?(): any;
  srId?: number | null;
}
const SubmissionResult: React.FC<IProps> = (props) => {
  const {image, data, resetAfterSubmitting, srId} = props;
  return (
    <Box
      sx={{
        marginX: globalSpacing.sm,
        marginY: globalSpacing.sm,
        display: 'flex',
        flexDirection: 'column',
        height: '40vh',
      }}>
      <Box sx={{position: 'absolute'}}>
        <BackButtonWText text={data.back_text} callback={resetAfterSubmitting} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <img src={image} />
        <Typography variant="h5" m={globalSpacing.s}>
          {srId ? data.success_text : data.updated_text}
        </Typography>
        {srId && (
          <Fragment>
            <Typography variant="subtitle2">
              <strong>{data.ticket_text} </strong>
              {srId}
            </Typography>
          </Fragment>
        )}
      </Box>
    </Box>
  );
};

export default SubmissionResult;
