import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import globalSpacing from 'Theme/Spacing';
import styles from './styles';

interface IProps {
  isOpen: boolean;
  toggleDialog: any;
  text: any;
}

const SimpleAlert: React.FC<IProps> = (props) => {
  const {isOpen, toggleDialog, text} = props;
  return (
    <Dialog open={isOpen} onClose={toggleDialog} sx={styles.dialog}>
      <DialogContent sx={styles.content}>
        <DialogTitle
          sx={{
            paddingTop: globalSpacing.md,
          }}>
          <Typography color="primary" variant="h3">
            {text.heading}
          </Typography>
        </DialogTitle>
        <DialogContentText sx={{paddingBottom: globalSpacing.md}}>
          <Typography
            variant="body1"
            sx={{color: useTheme().palette.text.primary}}
            textAlign="center">
            {text.subtitle}
          </Typography>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default SimpleAlert;
