const createAvatar = (name) => {
  if (name) {
    let n = name;
    let sn = n.split(' ');
    const fname = sn[0];
    const lname = sn[sn.length - 1];
    return `${fname[0]?.toUpperCase()}${lname[0]?.toUpperCase()}`;
  }
};

export default createAvatar;
