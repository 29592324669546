import {Box, Checkbox, Typography, useTheme} from '@mui/material';
import React from 'react';
import globalSpacing from 'Theme/Spacing';
import {dateConversionIsoToLocal, isValidISO8601} from 'Utils/helpers/DateValidation';

interface IProps {
  label: string;
  value?: any;
  type?: string;
}

const AdminDetailListItem: React.FC<IProps> = (props) => {
  const {label, value, type} = props;
  const theme = useTheme();

  return (
    <Box sx={styles.container}>
      <Box sx={styles.itemContainer}>
        <Typography variant="body1" component="h3" sx={styles.labelContainer}>
          {label}
        </Typography>
      </Box>
      {/* <Divider orientation="vertical" /> */}
      {type === 'checkbox' ? (
        <Checkbox
          sx={{
            width: '100%',
            '&.Mui-disabled': {
              color: theme.palette.primary.main,
            },
          }}
          checked={value}
          disabled
          size="small"
        />
      ) : (
        <Typography variant="body1" sx={styles.valueContainer}>
          {isValidISO8601(value) ? dateConversionIsoToLocal(value) : value || '-'}
        </Typography>
      )}
    </Box>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    // justifyContent: 'space-between',
    flex: 1,
    alignItems: 'center',
    marginBottom: '5px',
  },
  itemContainer: {
    // display: 'flex',
    // flex: 1,
    // paddingY: globalSpacing.s,
    // paddingX: globalSpacing.m,
  },
  labelContainer: {
    width: '100px',
  },
  valueContainer: {
    display: 'flex',
    flex: 2,
    marginLeft: globalSpacing.xxs,
    border: '1px solid rgba(196, 196, 196, 1)',
    justifyContent: 'center',
    borderRadius: '10px',
    padding: '0 4px 0 4px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.08)',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
  },
};

export default AdminDetailListItem;
