import {Grid, MenuItem, TextField, useTheme} from '@mui/material';
import {Field, ErrorMessage} from 'formik';
import React from 'react';
import globalSpacing from 'Theme/Spacing';

const SelectBox = (props: any) => {
  const {formikProp} = props;
  const theme = useTheme();
  const selectField: any = props.field;
  const customEvents: any = props.customEvents
    ? props.customEvents[selectField.name] && props.customEvents[selectField.name]
    : {};

  const menu = selectField.options?.map((option, index) => (
    <MenuItem key={index} value={option?.value}>
      {option?.text}
    </MenuItem>
  ));
  return (
    <>
      <Grid
        item
        xs={selectField.xs}
        sm={selectField.sm}
        p={globalSpacing.xs}
        sx={{display: selectField.display}}>
        <Field
          as={TextField}
          InputLabelProps={{
            shrink: true,
            sx: {
              fontSize: 17,
              fontWeight: 'bold',
              color: theme.palette.text.primary,
              '&.MuiInputLabel-shrink.Mui-disabled': {
                color: theme.palette.text.disabled,
              },
            },
          }}
          sx={{
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.08)',
            borderRadius: `${theme.shape.borderRadius}px`,
            marginRight: globalSpacing.s,
            '& .Mui-disabled': {
              color: '#979797',
            },
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused': {
                '& fieldset': {
                  borderColor: theme.palette.action.active,
                },
              },
            },
            '& .MuiInputLabel-root': {
              color: theme.palette.text.primary,
            },
            '& .MuiFormLabel-asterisk': {
              color: theme.palette.error.main,
              fontSize: '24px',
            },
          }}
          label={selectField?.fieldLabel}
          disabled={selectField.isDisabled ?? false}
          name={selectField?.name}
          required={selectField?.required}
          select={true}
          size="small"
          error={
            props.formikProp.touched[selectField?.name] &&
            Boolean(props.formikProp.errors[selectField?.name])
          }
          fullWidth
          color="primary"
          onChange={(e) => {
            formikProp.setFieldValue(selectField.name, e.target.value);
            customEvents &&
              customEvents.onChange &&
              customEvents['onChange'](e, formikProp.setFieldValue, formikProp.values);
          }}>
          {menu}
        </Field>
        <ErrorMessage name={selectField.name} component="div" />
      </Grid>
    </>
  );
};

export default SelectBox;
