export const formDetails = {
  cssClass: 'form_wrapper',
};
export const serviceRequestSummarySection = {
  sectionClass: '',
  title: 'Service Request Summary',
  titleClass: '',
  fields: [
    {
      fieldType: 'select',
      name: 'srService',
      id: 'srService',
      fieldLabel: 'Service',
      options: [],
      wrapperCssClass: '',
      optionCssClass: '',
      cssClass: '',
      dataTest: 'srService',
      xs: 3,
      sm: 3,
      isDisabled: false,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'srId',
      id: 'srId',
      fieldLabel: 'SR #',
      cssClass: '',
      dataTest: 'srId',
      xs: 3,
      sm: 3,
      isDisabled: true,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'srCreatedBy',
      id: 'srCreatedBy',
      fieldLabel: 'Created By',
      cssClass: '',
      dataTest: 'srCreatedBy',
      xs: 3,
      sm: 3,
      isDisabled: true,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'srCreatedDate',
      id: 'srCreatedDate',
      fieldLabel: 'Created Date',
      cssClass: '',
      dataTest: 'srCreatedDate',
      xs: 3,
      sm: 3,
      isDisabled: true,
    },
    {
      fieldType: 'select',
      name: 'srType',
      id: 'srType',
      fieldLabel: 'SR Type',
      options: [],
      wrapperCssClass: '',
      optionCssClass: '',
      cssClass: '',
      dataTest: 'srType',
      xs: 3,
      sm: 3,
      isDisabled: false,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'srModifiedBy',
      id: 'srModifiedBy',
      fieldLabel: 'Modified By',
      cssClass: '',
      dataTest: 'srModifiedBy',
      xs: 3,
      sm: 3,
      isDisabled: true,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'srModifiedDate',
      id: 'srModifiedDate',
      fieldLabel: 'Modified Date',
      cssClass: '',
      dataTest: 'srModifiedDate',
      xs: 3,
      sm: 3,
      isDisabled: true,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'srClosedDate',
      id: 'srClosedDate',
      fieldLabel: 'Closed Date',
      cssClass: '',
      dataTest: 'srClosedDate',
      xs: 3,
      sm: 3,
      isDisabled: true,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'srStatus',
      id: 'srStatus',
      fieldLabel: 'Status',
      cssClass: '',
      dataTest: 'srStatus',
      xs: 3,
      sm: 3,
      isDisabled: true,
    },
  ],
};
