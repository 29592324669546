import {Paper, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import globalSpacing from '../../Theme/Spacing';

const NoResults = ({text}) => {
  return (
    <Paper
      sx={{
        marginY: globalSpacing.sm,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '30vh',
        // boxShadow: '0 5px 21px 5px rgba(0, 0, 0, 0.06)',
      }}>
      <Typography variant="body1" color="primary">
        {text}
      </Typography>
    </Paper>
  );
};

NoResults.propTypes = {
  text: PropTypes.string,
};

export default NoResults;
