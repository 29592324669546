import React from 'react';
import OfferRow from './OfferRow';

const OfferParentRow = (props: {
  row: any;
  backgroundColor: string;
  onReFulfillClick: (ClaimedOfferAdditionalData, string, boolean) => void;
}) => {
  const [open, setOpen] = React.useState(false);

  const children: JSX.Element[] = [];
  let currentChild = props.row.child;

  while (currentChild) {
    children.push(
      <OfferRow
        key={currentChild?.id}
        row={currentChild}
        isParent={false}
        isOpen={open}
        onReFulfillClick={props.onReFulfillClick}
        backgroundColor={props.backgroundColor}
      />,
    );

    currentChild = currentChild.child;
  }

  return (
    <React.Fragment>
      <OfferRow
        key={props.row.id}
        row={props.row}
        isParent={true}
        backgroundColor={props.backgroundColor}
        onReFulfillClick={props.onReFulfillClick}
        onExpandRowClick={setOpen}
        isOpen={open}
      />

      {children}
    </React.Fragment>
  );
};

export default OfferParentRow;
