import {createSlice, createAsyncThunk, createEntityAdapter} from '@reduxjs/toolkit';
import {gqlSearchSRByType} from 'GQL/MethodsBFF/ServiceRequest';

interface ArchivedSRList {}

const selectedArchiveSRAdapter: any = createEntityAdapter<ArchivedSRList>({});

const initialState = selectedArchiveSRAdapter.getInitialState({
  status: 'idle',
  error: '',
});

export const searchSRByTypeArchived: any = createAsyncThunk(
  'serviceRequest/searchSRByTypeArchived',
  async (data: any, {getState}) => {
    const state: any = getState();
    const headers = {
      'tenant-id': state?.login.tenantID,
      'user-access-token': state?.login.loginInfo?.access_token,
      'client-access-token': state?.login.loginInfo?.service_token,
    };
    return await gqlSearchSRByType(data, headers);
  },
);

const archivedSRList = createSlice({
  name: 'archivedSRList',
  initialState,
  reducers: {
    resetArchivedSRData: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(searchSRByTypeArchived.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(searchSRByTypeArchived.fulfilled, (state, action) => {
        state.status = 'succeeded';
        selectedArchiveSRAdapter.setAll(
          state,
          action?.payload?.data?.getServiceRequestsByType?.searchServiceRequestsOutputDtoList || [],
        );
      })
      .addCase(searchSRByTypeArchived.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default archivedSRList.reducer;

export const {selectAll: selectAllArchivedSRList} = selectedArchiveSRAdapter.getSelectors(
  (state) => state.archivedSRList,
);

export const {resetArchivedSRData} = archivedSRList.actions;
