import React, {createContext, useContext} from 'react';
import PropTypes from 'prop-types';
import {SS} from 'Utils/constants/SessionStorage';
import {useSessionStorage} from 'Hooks';

const DataContext = createContext({});

const DataContextProvider = ({children}) => {
  const [member, setMember] = useSessionStorage(SS.MEMBER, null, true);
  const [multipleResults, setMultipleResults] = useSessionStorage(SS.MULTIPLE_SEARCH, null, true);
  const [storedIdentifier, setStoredIdentifier] = useSessionStorage(SS.SEARCH, '');
  const [activeTab, setActiveTab] = useSessionStorage(SS.TAB, 0);
  const [iframe, setIframe] = useSessionStorage(SS.IFRAME, null);

  const value = {
    member,
    setMember,
    multipleResults,
    setMultipleResults,
    storedIdentifier,
    setStoredIdentifier,
    setActiveTab,
    activeTab,
    iframe,
    setIframe,
  };

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

const useData: any = () => useContext(DataContext);

DataContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export {DataContextProvider, useData};
