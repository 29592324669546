import {Card, CardContent, CardMedia, Typography, useTheme} from '@mui/material';
import {CardActionArea} from '@mui/material';
import React from 'react';
import globalSpacing from 'Theme/Spacing';

interface IProps {
  item: any;
  toggleView?: any;
  handleItem?: any;
}

const GMGiftCard: React.FC<IProps> = (props) => {
  const {item, toggleView, handleItem} = props;
  const toggleAndHandle = () => {
    toggleView();
    handleItem(item);
  };

  return (
    <Card sx={styles.card}>
      <CardActionArea
        disableRipple
        sx={{...styles.cardAction, borderColor: useTheme().palette.grey.A200}}
        onClick={() => toggleAndHandle()}>
        <CardMedia
          sx={{display: 'flex'}}
          component="img"
          image={item.images[1].imageUrl}
          alt={item.images[1].altText}
          height="140px"
        />
        <Typography variant="subtitle2" sx={styles.textWithColor}>
          {item?.redemptionDetails[0].method === 'PHYSICAL' ? 'Plastic' : 'eGift'}
        </Typography>
        <CardContent sx={styles.cardContent}>
          <Typography variant="subtitle2" sx={styles.text}>
            {item.titleShort}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const styles = {
  card: {display: 'flex', flex: '0 0 23%', flexDirection: 'row'},
  cardAction: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  text: {display: 'flex', flex: 1, textAlign: 'center', alignSelf: 'center'},
  textWithColor: {
    backgroundColor: 'black',
    color: 'white',
    textAlign: 'center',
    padding: '0px',
    width: '100%',
    marginTop: '10px',
  },
  cardContent: {
    display: 'flex',
    flex: 1,
    width: '100%',
    gap: globalSpacing.s,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
};
export default GMGiftCard;
