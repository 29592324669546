export const newPaymentSection1 = {
  sectionClass: '',
  title: 'Payment',
  titleClass: '',
  fields: [
    {
      fieldType: 'select',
      name: 'paymentMethod',
      id: 'paymentMethod',
      fieldLabel: 'Payment Method',
      options: [
        {value: 'Bank Account', text: 'Bank Account'},
        {value: 'Credit Card', text: 'Credit Card'},
      ],
      dataTest: 'paymentMethod',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'account',
      id: 'account',
      fieldLabel: 'Account',
      dataTest: 'account',
      xs: 4,
      sm: 4,
      isDisabled: true,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'cheque',
      id: 'cheque',
      fieldLabel: 'Cheque #',
      dataTest: 'cheque',
      xs: 4,
      sm: 4,
      isDisabled: true,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'paymentType',
      id: 'paymentType',
      fieldLabel: 'Payment Type',
      dataTest: 'paymentType',
      xs: 4,
      sm: 4,
      isDisabled: false,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'bankCode',
      id: 'bankCode',
      fieldLabel: 'Bank Code',
      dataTest: 'bankCode',
      xs: 4,
      sm: 4,
      isDisabled: true,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'chequeBankCode',
      id: 'chequeBankCode',
      fieldLabel: 'Cheque Bank Code',
      dataTest: 'chequeBankCode',
      xs: 4,
      sm: 4,
      isDisabled: true,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'amount',
      id: 'amount',
      fieldLabel: 'Amount',
      dataTest: 'amount',
      xs: 4,
      sm: 4,
      isDisabled: false,
      required: true,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'sortCode',
      id: 'sortCode',
      fieldLabel: 'Sort Code',
      dataTest: 'sortCode',
      xs: 4,
      sm: 4,
      isDisabled: true,
      required: false,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'chequeAc',
      id: 'chequeAc',
      fieldLabel: 'Cheque Acct #',
      dataTest: 'chequeAc',
      xs: 4,
      sm: 4,
      isDisabled: true,
      required: false,
    },
    {
      fieldType: 'select',
      name: 'flowType',
      id: 'flowType',
      fieldLabel: 'Payment Flow',
      options: [
        {value: 'Debit', text: 'Debit'},
        {value: 'Credit', text: 'Credit'},
      ],
      dataTest: 'flowType',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'cardNumber',
      id: 'cardNumber',
      fieldLabel: 'Card',
      dataTest: 'cardNumber',
      variant: 'filled',
      xs: 4,
      sm: 4,
      isDisabled: false,
      required: true,
      readonly: true,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'eftDescription',
      id: 'eftDescription',
      fieldLabel: 'EFT Description',
      dataTest: 'eftDescription',
      xs: 4,
      sm: 4,
      isDisabled: false,
      required: false,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'authCode',
      id: 'authCode',
      fieldLabel: 'Auth Code',
      dataTest: 'authCode',
      xs: 4,
      sm: 4,
      isDisabled: false,
      required: false,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'transactionReason',
      id: 'transactionReason',
      fieldLabel: 'Transaction Reason',
      dataTest: 'transactionReason',
      xs: 4,
      sm: 4,
      isDisabled: false,
      required: false,
    },
    {
      fieldType: 'commentBox',
      htmlInputType: 'text',
      name: 'comments',
      id: 'comments',
      fieldLabel: 'Comments',
      xs: 6,
      sm: 6,
      rows: 4,
      multiline: true,
      dataTest: 'comments',
    },
  ],
};

export const buttonList = {
  direction: 'row',
  spacing: 2,
  fields: [
    {
      fieldType: 'button',
      htmlInputType: 'submit',
      name: 'resetButton',
      id: 'resetButton',
      fieldLabel: 'Reset',
      variant: 'contained',
      wrapperClass: '',
      dataTest: 'resetButton',
      xs: 2,
      sm: 2,
    },
    {
      fieldType: 'button',
      htmlInputType: 'submit',
      name: 'saveButton',
      id: 'saveButton',
      fieldLabel: 'Save',
      variant: 'contained',
      wrapperClass: '',
      dataTest: 'saveButton',
      xs: 2,
      sm: 2,
    },
    {
      fieldType: 'button',
      htmlInputType: 'submit',
      name: 'confirmPayment',
      id: 'confirmPayment',
      fieldLabel: 'Confirm Payment',
      variant: 'contained',
      wrapperClass: '',
      dataTest: 'confirmPayment',
      xs: 2,
      sm: 2,
    },
  ],
};
