import {useEffect, useState} from 'react';

interface IProps {
  isDialogVisible: boolean;
}

const useDialogScrollBar: (props: IProps) => {
  showScrollBar: (evt: any) => void;
  isScrollbarVisible: boolean;
} = (props) => {
  const {isDialogVisible} = props;
  //START scrollbar controls
  const [timer, setTimer] = useState<any>(null);
  const [isScrollbarVisible, setIsScrollbarVisible] = useState(true);
  //on dialog visible show scroll bar and settimeot to hide it, on component dismount cleartimer
  useEffect(() => {
    if (isDialogVisible) setIsScrollbarVisible(true);
    setTimer(setTimeout(() => setIsScrollbarVisible(false), 3000));
    return () => clearTimeout(timer || undefined);
  }, [isDialogVisible]);
  //function runs on scroll - show scrollbar and settimeout to hide it, also in case the previous timer is still running cancel it and set it to null
  const showScrollBar = (evt) => {
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    const el = evt.currentTarget;
    clearTimeout(el._scrolling);
    setIsScrollbarVisible(true);
    el._scrolling = setTimeout(() => {
      setIsScrollbarVisible(false);
    }, 2000);
  };
  //END scrollbar controls

  return {showScrollBar, isScrollbarVisible};
};

export default useDialogScrollBar;
