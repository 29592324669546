import {Grid, TextField, useTheme} from '@mui/material';
import {DateTimePicker} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import globalSpacing from 'Theme/Spacing';
import {DATE_TIME_LOCALE} from 'Utils/constants/DateFormat';
import {Field, useFormikContext} from 'formik';
import React from 'react';
import {useSelector} from 'react-redux';

export default function CustomDateTimePicker(props) {
  const textField = props.field;
  const theme = useTheme();
  const {setFieldValue} = useFormikContext();
  const tenantConfig = useSelector((state: any) => state?.login?.tenantConfig);
  const inputDateFormat = DATE_TIME_LOCALE[tenantConfig.locale];

  return (
    <Grid item xs={textField.xs} sm={textField.sm} p={globalSpacing.xs}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Field
          InputLabelProps={{
            shrink: true,
            sx: {
              fontSize: 17,
              fontWeight: 'bold',
              color: theme.palette.text.primary,
              '&.MuiInputLabel-shrink.Mui-disabled': {
                color: theme.palette.text.disabled,
              },
            },
          }}
          as={DateTimePicker}
          inputFormat={textField?.dateFormat || inputDateFormat}
          fullWidth
          label={textField?.fieldLabel}
          name={textField?.name}
          onChange={(value) => setFieldValue(textField.name, value)}
          PopperProps={{
            sx: {
              '.MuiDayPicker-weekDayLabel': {
                color: theme.palette.text.primary,
              },
            },
          }}
          disablePast={textField?.disablePast}
          renderInput={(params) => (
            <TextField
              sx={{
                boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.08)',
                borderRadius: `${theme.shape.borderRadius}px`,
                marginRight: globalSpacing.s,
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused': {
                    '& fieldset': {
                      borderColor: theme.palette.action.active,
                    },
                  },
                },
                '& .MuiInputLabel-root': {
                  color: theme.palette.text.primary,
                },
                '&.MuiFormControlLabel-root': {
                  marginLeft: '0px',
                },
                '& .MuiFormLabel-asterisk': {
                  color: theme.palette.error.main,
                  fontSize: '24px',
                },
                '& .Mui-disabled': {
                  color: theme.palette.text.disabled,
                },
                '.MuiDayPicker-weekDayLabel': {
                  color: '#070707',
                },
              }}
              size="small"
              {...params}
              required={textField?.required}
              disabled={textField?.isDisabled ?? false}
            />
          )}
        />
      </LocalizationProvider>
      {/* <ErrorMessage name={textField.name} component="div" /> */}
    </Grid>
  );
}
