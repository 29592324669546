import {FORMAT_TYPE} from 'Utils/constants/DateFormat';
import {dateConversionIsoToLocal} from 'Utils/helpers/DateValidation';
import {parseData} from 'Utils/helpers/SRListHelpers';

export const getActivityData = (tableData, sortOrder, currentSortCriteria, isSortingEnabled) => {
  const header = [
    {label: 'Activity #', value: 'id'},
    {label: 'Activity Type', value: 'type'},
    {label: 'Activity Subtype', value: 'subType'},
    {label: 'Status', value: 'status'},
    {label: 'Priority', value: 'priority'},
    {label: 'plannedStartDate', value: 'plannedDate'},
  ];

  let hideColumns = {
    summary: true,
    phoneNumber: true,
    channel: true,
    activity: true,
    freeText: true,
    templateId: true,
    statusDate: true,
    createdDate: true,
    createdBy: true,
    modifiedDate: true,
    modifiedBy: true,
    completionDate: true,
    details: true,
    sendDate: true,
    comments: true,
    createdOn: true,
    plannedStartDate: true,
  };

  return parseData(
    header,
    tableData,
    sortOrder,
    currentSortCriteria,
    isSortingEnabled,
    hideColumns,
  );
};

export const mapTableData = (tableData) => {
  let newTableData: any[] = [];
  if (tableData.length > 0) {
    (tableData || []).forEach((data) => {
      let {additionalData, audit} = data;
      let row = {
        id: data.id,
        type: data.type,
        subType: data.subType,
        comments: additionalData?.comments,
        status: data.status,

        channel: data.channel,
        priority: data.priority,
        createdOn: audit.createdOn,
        templateId: data.templateId,
        freeText: data.freeText,
        plannedDate:
          dateConversionIsoToLocal(additionalData?.plannedStartDate, FORMAT_TYPE.DATE_TIME) || '',
        plannedStartDate: additionalData?.plannedStartDate || '',
      };
      newTableData.push(row);
    });
  }
  return newTableData;
};
