/* eslint-disable no-import-assign */
import {GoogleOAuthProvider} from '@react-oauth/google';
import {InitializeAppWithKeyCloak} from 'Components/InitializeAppWithKeyCloak';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {ErrorBoundary} from 'react-error-boundary';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {PersistGate} from 'redux-persist/integration/react';
import {persistor, store} from 'Redux/PersistStore';
import {SSO_ERROR_TEXT} from 'Utils/constants/genericConstants';
import App from './App';
import ErrorFallback from './Components/ErrorBoundary';
import {globalConfig, globalConfigUrl} from './Config/config';
import './index.css';
import reportWebVitals from './reportWebVitals';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement!);

const renderApplication = (config, value) => {
  root.render(
    <GoogleOAuthProvider clientId={config.googleOAuth.clientID}>
      <React.StrictMode>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <ErrorBoundary fallbackRender={ErrorFallback}>
                <App token={value?.access_token} refreshToken={value?.refresh_token} />
              </ErrorBoundary>
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </React.StrictMode>
    </GoogleOAuthProvider>,
  );
  document.getElementById('root');
};
//TODO: REmove the hard coding of client details
fetch(globalConfigUrl)
  .then((settings) => settings.json())
  .then((config) => {
    const {
      ContentStack,
      BFF,
      clientDetails: {connect: client},
      cinemaECodeSRPollingConfig,
      cxRewardsEndpoint,
      internalSSOConfig,
      googleOAuth,
    } = config;
    globalConfig.config = {
      ContentStack,
      BFF,
      client,
      cinemaECodeSRPollingConfig,
      cxRewardsEndpoint,
      internalSSOConfig,
      googleOAuth,
    };
    if (!window.location.pathname.includes('sso')) {
      InitializeAppWithKeyCloak().then(
        (value) => {
          if (value) {
            renderApplication(config, value);
          }
        },
        (error) => {
          console.log(error);
        },
      );
    } else {
      renderApplication(config, '');
    }
  })
  .catch((err) => {
    console.warn(err);
    root.render(<p style={{textAlign: 'center'}}>{SSO_ERROR_TEXT.default}</p>);
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(null);
