import {createSlice, createAsyncThunk, createEntityAdapter} from '@reduxjs/toolkit'; //createSelector,
import {gqlGMClaimedOrderHistory} from 'GQL/MethodsBFF/GMGiftingHistory';

interface IGMGiftingHistroy {
  calimedOffers: any;
}

const gmGiftingHistroyAdapter: any = createEntityAdapter<IGMGiftingHistroy>({});

const initialState = gmGiftingHistroyAdapter.getInitialState({
  status: 'idle',
  error: '',
  mapping: null,
});

export const getGMGiftingHistroy: any = createAsyncThunk(
  'giftcard/getGMGiftingHistroy',
  async (data: any, {getState}) => {
    const state: any = getState();
    const headers = {
      'tenant-id': state?.login.tenantID,
      Authorization: `Bearer ${state?.impersonateToken?.impersonateToken?.accessToken}`,
    };
    return await gqlGMClaimedOrderHistory(data, headers);
  },
);

const gmOrderHistory = createSlice({
  name: 'gmOrderHistory',
  initialState,
  reducers: {
    resetGMOrderHistory: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getGMGiftingHistroy.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getGMGiftingHistroy.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.mapping = action?.payload?.data?.claimedOffers;
      })
      .addCase(getGMGiftingHistroy.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default gmOrderHistory.reducer;

export const {resetGMOrderHistory} = gmOrderHistory.actions;
