export const cardSection1 = {
  sectionClass: '',
  title: 'Card',
  titleClass: '',
  fields: [
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'cardNumber',
      id: 'cardNumber',
      fieldLabel: 'Card Number',
      dataTest: 'cardNumber',
      xs: 6,
      sm: 6,
      isDisabled: false,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'cardType',
      id: 'cardType',
      fieldLabel: 'Card Type',
      dataTest: 'cardType',
      xs: 6,
      sm: 4,
      isDisabled: true,
    },
    {
      fieldType: 'datePicker',
      name: 'expiryDate',
      id: 'expiryDate',
      fieldLabel: 'Expiry Date',
      xs: 4,
      sm: 4,
      dataTest: 'expiryDate',
      isDisabled: false,
      format: 'MMMM YYYY',
      views: ['month', 'year'],
      disablePast: false,
      required: true,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'nameOnCard',
      id: 'nameOnCard',
      fieldLabel: 'Name On Card',
      dataTest: 'nameOnCard',
      required: true,
      xs: 4,
      sm: 4,
      disabled: false,
    },
    {
      fieldType: 'checkbox',
      name: 'saveForLater',
      id: 'saveForLater',
      fieldLabel: 'Save for later',
      xs: 6,
      sm: 6,
      dataTest: 'saveForLater',
    },
    {
      fieldType: 'checkbox',
      name: 'isMemberAddressBillingAddress',
      id: 'isMemberAddressBillingAddress',
      fieldLabel: 'Use member Address',
      xs: 6,
      sm: 6,
      dataTest: 'isMemberAddressBillingAddress',
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      required: true,
      name: 'addressLineOne',
      id: 'addressLineOne',
      fieldLabel: 'Address Line1',
      xs: 4,
      sm: 4,
      dataTest: 'addressLineOne',
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      required: false,
      name: 'addressLineTwo',
      id: 'addressLineTwo',
      fieldLabel: 'Address Line2',
      xs: 4,
      sm: 4,
      dataTest: 'addressLineTwo',
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      required: false,
      name: 'addressLineThree',
      id: 'addressLineThree',
      fieldLabel: 'Address Line3',
      xs: 4,
      sm: 4,
      dataTest: 'addressLineThree',
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      required: true,
      name: 'city',
      id: 'city',
      fieldLabel: 'City',
      xs: 4,
      sm: 4,
      dataTest: 'city',
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      required: true,
      name: 'postalCode',
      id: 'postalCode',
      fieldLabel: 'Postal Code',
      xs: 4,
      sm: 4,
      dataTest: 'postalCode',
    },
    {
      fieldType: 'select',
      name: 'country',
      id: 'country',
      fieldLabel: 'Country',
      required: true,
      xs: 4,
      sm: 4,
      options: [],
      dataTest: 'country',
    },
  ],
};

export const buttonList = {
  direction: 'row',
  spacing: 2,
  fields: [
    {
      fieldType: 'button',
      htmlInputType: 'submit',
      name: 'saveButton',
      id: 'saveButton',
      fieldLabel: 'Save',
      variant: 'contained',
      dataTest: 'save',
      xs: 2,
      sm: 2,
    },
    {
      fieldType: 'button',
      htmlInputType: 'submit',
      name: 'resetButton',
      id: 'resetButton',
      fieldLabel: 'Reset',
      variant: 'contained',
      dataTest: 'resetButton',
      xs: 2,
      sm: 2,
    },
  ],
};
