import {Box, ButtonBase, Tab, useTheme} from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import React, {Fragment, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import TabBio from '../Tabs/Profile';
import TabAdminProfile from '../Tabs/AdminMemberProfile';
import TabTransactions from '../Tabs/Transactions';
import TabGifting from '../Tabs/Gifting';
import TabBenefits from '../Tabs/Benefits';
import TabSubscriptions from '../Tabs/Subscription';
import styles from './styles';
import TabQueryForm from '../Tabs/QueryForm';
import TabGiftingHistory from '../Tabs/GiftingHistory';
import TabServiceRequests from '../Tabs/ServiceRequests';
import globalSpacing from 'Theme/Spacing';
import {useData} from 'Context/DataContext';
import Loading from 'Components/Loading';
import MemberDetailsPanel from 'Pages/Tabs/MemberDetailsPanel';
import {useLocation} from 'react-router-dom';
import {TabPanelType} from 'Utils/enums';
import GMGifitngHistory from 'Pages/Tabs/GMGiftingHistory';
import TabGMGifting from 'Pages/Tabs/GMGifting';

const Dashboard = () => {
  const location: any = useLocation();
  const state = location?.state;
  const {iframe: isIframe} = useData();
  const [loading, setLoading] = useState(true);
  const tabFontSize = useTheme().typography.h6.fontSize;
  const {selectedMember} = useSelector((state: any) => state?.selectedMember);
  const roleConfig = useSelector((state: any) => state?.login?.roleConfig);
  var selectedTab = TabPanelType.memberProfile;
  for (let tab in TabPanelType) {
    if (tab in roleConfig) {
      selectedTab = TabPanelType[tab];
      break;
    }
  }
  const [activeTab, setActiveTab] = React.useState(state?.tab || selectedTab);

  /**
   * IFRAME setup part 2
   *
   * in useEffect we check if the request is from iframe, if first load we set the member details in context
   * once the member details are present in context we set loading to false and show the page, if it's a reload then the member is there in session storage
   * and will get picked up by the context on app load - so we just set loading to false
   *
   * IMPORTANT
   * the isIframe flag stored in storage is used to control what components are shown in the iframe and what tabs from the dashboard
   */

  useEffect(() => {
    setLoading(false);
  }, []);

  const handleChange = (event: React.SyntheticEvent, newActiveTab) => {
    setActiveTab(newActiveTab);
  };

  let customStyleObj: any = {
    display: 'flex',
    flex: 1,
    marginX: '10px',
    marginY: '10px',
    textTransform: 'none',
    color: 'inherit',
    fontFamily: 'headline-regular',
    '&.Mui-selected': {
      color: 'inherit',
      fontFamily: 'headline-bold',
    },
    fontSize: tabFontSize,
  };

  if (loading) {
    return <Loading />;
  } else {
    return (
      <Box sx={styles.container}>
        <TabContext value={activeTab}>
          <Box bgcolor="background.paper" sx={styles.tabsContainer}>
            {!isIframe ? (
              <TabList
                centered={true}
                sx={styles.tabs}
                onChange={handleChange}
                indicatorColor="primary">
                {roleConfig?.subscription ? (
                  <Tab
                    sx={customStyleObj}
                    disableRipple
                    label="Subscription"
                    tabIndex={0}
                    component={ButtonBase}
                    value={TabPanelType.subscription}
                  />
                ) : null}
                {roleConfig?.benefits ? (
                  <Tab
                    sx={customStyleObj}
                    disableRipple
                    label="Benefits"
                    tabIndex={0}
                    component={ButtonBase}
                    value={TabPanelType.benefits}
                  />
                ) : null}
                {roleConfig?.memberProfile ? (
                  <Tab
                    sx={customStyleObj}
                    disableRipple
                    label="Profile"
                    tabIndex={0}
                    component={ButtonBase}
                    value={TabPanelType.memberProfile}
                  />
                ) : null}
                {roleConfig?.adminMemberProfile ? (
                  <Tab
                    sx={customStyleObj}
                    disableRipple
                    label="Member Profile"
                    tabIndex={0}
                    component={ButtonBase}
                    value={TabPanelType.adminMemberProfile}
                  />
                ) : null}
                {roleConfig?.serviceRequests ? (
                  <Tab
                    sx={customStyleObj}
                    label="Service Requests"
                    tabIndex={0}
                    component={ButtonBase}
                    value={TabPanelType.serviceRequests}
                  />
                ) : null}
                {roleConfig?.transaction ? (
                  <Tab
                    disabled={selectedMember?.[0]?.status.toLowerCase() === 'inactive'}
                    sx={customStyleObj}
                    disableRipple
                    label="Transactions"
                    tabIndex={0}
                    component={ButtonBase}
                    value={TabPanelType.transaction}
                  />
                ) : null}
                {roleConfig?.reissuance ? (
                  <Tab
                    sx={customStyleObj}
                    disableRipple
                    label="Reissuance"
                    tabIndex={0}
                    component={ButtonBase}
                    value={TabPanelType.reissuance}
                  />
                ) : null}
                {roleConfig?.reissuanceHistory ? (
                  <Tab
                    sx={customStyleObj}
                    disableRipple
                    label="Reissuance History"
                    tabIndex={0}
                    component={ButtonBase}
                    value={TabPanelType.reissuanceHistory}
                  />
                ) : null}
                {roleConfig?.giftCardCatalog ? (
                  <Tab
                    sx={customStyleObj}
                    disableRipple
                    label="Gift Cards"
                    tabIndex={0}
                    component={ButtonBase}
                    value={TabPanelType.giftCardCatalog}
                  />
                ) : null}
                {roleConfig?.giftCardHistory ? ( // need to render according to role config -- TO DO
                  <Tab
                    sx={customStyleObj}
                    disableRipple
                    label="Gifting History"
                    tabIndex={0}
                    component={ButtonBase}
                    value={TabPanelType.giftingHistory}
                  />
                ) : null}
                {roleConfig?.queryForm ? (
                  <Tab
                    sx={customStyleObj}
                    disableRipple
                    label="Query Form"
                    tabIndex={0}
                    component={ButtonBase}
                    value={TabPanelType.queryForm}
                  />
                ) : null}
              </TabList>
            ) : (
              <TabList
                centered={true}
                sx={styles.tabs}
                onChange={handleChange}
                indicatorColor="primary">
                {roleConfig?.transaction ? (
                  <Tab
                    disabled={selectedMember?.[0]?.status.toLowerCase() === 'inactive'}
                    sx={customStyleObj}
                    disableRipple
                    label="Transactions"
                    tabIndex={0}
                    component={ButtonBase}
                    value={TabPanelType.transaction}
                  />
                ) : null}
                {roleConfig?.reissuance ? (
                  <Tab
                    sx={customStyleObj}
                    disableRipple
                    label="Reissuance"
                    tabIndex={0}
                    component={ButtonBase}
                    value={TabPanelType.reissuance}
                  />
                ) : null}
                {roleConfig?.reissuanceHistory ? (
                  <Tab
                    sx={customStyleObj}
                    disableRipple
                    label="Reissuance History"
                    tabIndex={0}
                    component={ButtonBase}
                    value={TabPanelType.reissuanceHistory}
                  />
                ) : null}
              </TabList>
            )}
          </Box>
          <div style={{display: 'flex'}}>
            {roleConfig?.adminMemberProfile ? (
              <Box style={{position: 'sticky'}}>
                <MemberDetailsPanel />
              </Box>
            ) : null}
            {!isIframe ? (
              <Fragment>
                {roleConfig?.subscription ? (
                  <TabPanel value={TabPanelType.subscription} sx={styles.tabPanelContainer}>
                    <Box>
                      <TabSubscriptions />
                    </Box>
                  </TabPanel>
                ) : null}
                {roleConfig?.benefits ? (
                  <TabPanel value={TabPanelType.benefits} sx={styles.tabPanelContainer}>
                    <Box>
                      <TabBenefits />
                    </Box>
                  </TabPanel>
                ) : null}
                {roleConfig?.memberProfile ? (
                  <TabPanel value={TabPanelType.memberProfile} sx={styles.tabPanelContainer}>
                    <Box py={globalSpacing.lg}>
                      <TabBio />
                    </Box>
                  </TabPanel>
                ) : null}
                {roleConfig?.adminMemberProfile ? (
                  <TabPanel value={TabPanelType.adminMemberProfile} sx={styles.tabPanelContainer}>
                    <Box>
                      <TabAdminProfile />
                    </Box>
                  </TabPanel>
                ) : null}
                {roleConfig?.serviceRequests ? (
                  <TabPanel value={TabPanelType.serviceRequests} sx={styles.tabPanelContainer}>
                    <Box>
                      <TabServiceRequests />
                    </Box>
                  </TabPanel>
                ) : null}
                {roleConfig?.transaction ? (
                  <TabPanel value={TabPanelType.transaction} sx={styles.tabPanelContainer}>
                    <Box py={globalSpacing.lg}>
                      <TabTransactions />
                    </Box>
                  </TabPanel>
                ) : null}
                {roleConfig?.reissuance ? (
                  <TabPanel value={TabPanelType.reissuance} sx={styles.tabPanelContainer}>
                    <Box py={globalSpacing.lg}>
                      <TabGifting />
                    </Box>
                  </TabPanel>
                ) : null}
                {roleConfig?.reissuanceHistory ? (
                  <TabPanel value={TabPanelType.reissuanceHistory} sx={styles.tabPanelContainer}>
                    <Box py={globalSpacing.lg}>
                      <TabGiftingHistory />
                    </Box>
                  </TabPanel>
                ) : null}
                {roleConfig?.giftCardCatalog ? (
                  <TabPanel value={TabPanelType.giftCardCatalog} sx={styles.tabPanelContainer}>
                    <Box py={globalSpacing.xs}>
                      <TabGMGifting />
                    </Box>
                  </TabPanel>
                ) : null}

                {roleConfig?.giftCardHistory ? (
                  <TabPanel value={TabPanelType.giftingHistory} sx={styles.tabPanelContainer}>
                    <Box py={globalSpacing.xs}>
                      <GMGifitngHistory />
                    </Box>
                  </TabPanel>
                ) : null}
                {roleConfig?.queryForm ? (
                  <TabPanel value={TabPanelType.queryForm} sx={styles.tabPanelContainer}>
                    <Box py={globalSpacing.lg}>
                      <TabQueryForm />
                    </Box>
                  </TabPanel>
                ) : null}
              </Fragment>
            ) : (
              <Fragment>
                {roleConfig?.transaction ? (
                  <TabPanel value={TabPanelType.transaction} sx={styles.tabPanelContainer}>
                    <Box py={globalSpacing.lg}>
                      <TabTransactions />
                    </Box>
                  </TabPanel>
                ) : null}
                {roleConfig?.reissuance ? (
                  <TabPanel value={TabPanelType.reissuance} sx={styles.tabPanelContainer}>
                    <Box py={globalSpacing.lg}>
                      <TabGifting />
                    </Box>
                  </TabPanel>
                ) : null}
                {roleConfig?.reissuanceHistory ? (
                  <TabPanel value={TabPanelType.reissuanceHistory} sx={styles.tabPanelContainer}>
                    <Box py={globalSpacing.lg}>
                      <TabGiftingHistory />
                    </Box>
                  </TabPanel>
                ) : null}
              </Fragment>
            )}
          </div>
        </TabContext>
      </Box>
    );
  }
};

export default Dashboard;
