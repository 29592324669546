import PropTypes from 'prop-types';
import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  useTheme,
} from '@mui/material';
import parse from 'html-react-parser';
import globalSpacing from 'Theme/Spacing';
import {CloseIconDialog} from 'Components';
import {useCSContext} from 'Context/ContentStackContext';
import {useDialogScrollBar} from 'Hooks';

const TnCDialog = ({toggleTnDDialog, isDialogVisible, transactionDetails}) => {
  const {artifacts} = useCSContext();

  const {showScrollBar, isScrollbarVisible} = useDialogScrollBar(isDialogVisible);

  return (
    <Dialog
      open={isDialogVisible}
      maxWidth={'md'}
      onClose={toggleTnDDialog}
      scroll="paper"
      sx={{paddingY: globalSpacing.lg, height: '70%', margin: 'auto'}}>
      <CloseIconDialog callback={toggleTnDDialog} image={artifacts.cross_icon} />
      <DialogTitle
        sx={{
          paddingTop: globalSpacing.md,
        }}>
        <Typography color="primary" variant="h3">
          {transactionDetails.titleShort}
        </Typography>
      </DialogTitle>

      <DialogTitle sx={{paddingTop: '0rem'}}>
        <Typography variant="h4">Terms and Conditions</Typography>
      </DialogTitle>

      <DialogContent
        onScrollCapture={(evt) => showScrollBar(evt)}
        sx={{
          '&.MuiDialogContent-root': {marginBottom: '1.5rem', marginRight: '1.5rem'},
          '&:hover&.MuiDialogContent-root': {border: '1rem'},
          '&::-webkit-scrollbar': {
            width: '0.3rem',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'lightgrey',
            borderRadius: 15,
            visibility: isScrollbarVisible ? 'visible' : 'hidden',
          },
        }}>
        <DialogContentText sx={{paddingBottom: globalSpacing.md}}>
          <Typography variant="body1" sx={{color: useTheme().palette.text.primary}}>
            {parse(transactionDetails.termsAndConditions)}
          </Typography>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

TnCDialog.propTypes = {
  toggleTnDDialog: PropTypes.func.isRequired,
  isDialogVisible: PropTypes.bool.isRequired,
  transactionDetails: PropTypes.object.isRequired,
};
export default TnCDialog;
