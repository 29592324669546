import React, {useCallback, useEffect, useRef, useState} from 'react';
import OfferTable from './OfferTable';
import {useDispatch, useSelector} from 'react-redux';
import {
  REDEEM_CLAIMED_OFFER_TYPE,
  REFILL_REASONS,
  REFULFILL_BUTTON_TEXT,
  REFULFILL_CLAIMED_OFFER_TYPE,
  REQUEST_REFULFILL_BUTTON_TEXT,
} from 'Utils/constants/genericConstants';
import {selectAllRecentSRList} from 'Redux/Slices/RecentSRList';
import {getImpersonateToken} from 'Redux/Slices/impersonateToken';
import {getGMGiftingHistroy, resetGMOrderHistory} from 'Redux/Slices/gmGiftingHistory';
import RefulfillDialog from 'Components/RefulfillDialog';
import {createSR, updateSR} from 'Redux/Slices/serviceRequest';
import {gqlRefulfillClaim} from 'GQL/MethodsBFF/GMGiftingHistory';
import {Loading} from 'Components';
import {Box, ButtonBase, Tab} from '@mui/material';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import MemberAccountInfo from '../GMGifting/components/MemberAccountInfo';
import {isUpdateSRList} from 'Redux/Slices/SRListFilter';

const GMGifitngHistory = () => {
  const [offers, setOffers] = useState<any[]>([]); //useState<ClaimedOffer[]>([]);
  const memberHasReFulfilled = useRef(false);
  const firstParentDate = useRef('');

  const srList: any = useSelector(selectAllRecentSRList);
  const {selectedMember} = useSelector((state: any) => state?.selectedMember);
  const impersonateToken = useSelector((state: any) => {
    return state?.impersonateToken.impersonateToken;
  });

  const agentHasGoodwillGroup = true; // need to change from tenant config --TO DO
  const agentHasLevel2Group = true;
  const ownerID = useSelector((state: any) => state?.login?.userName);
  const tenantConfig = useSelector((state: any) => state?.login?.tenantConfig);
  const eGiftWaitDays = tenantConfig?.goodwilleGiftWaitDays;
  const pGiftWaitDays = tenantConfig?.goodwillPGiftWaitDays;
  const tenantID = useSelector((state: any) => state?.login?.tenantID);
  const dispatch = useDispatch();
  const [updateAccount] = useState(true);
  //use effect validate Token

  const CLOSED_SR_STATUS = 'Closed';
  const PENDING_SR_STATUS = 'Pending';
  const DEFAULT_ERROR = 'An unexpected error occured';

  const requestForRefullfillSRs = srList
    ? srList.filter((item) => item.type === 'Re-fulfill Gift Card Request')
    : [];

  const [modalType, setModalType] = useState(REFULFILL_BUTTON_TEXT);
  const [modalClaimData, setModalClaimData] = useState<any>([]);
  const [isSpecialCase, setIsSpecialCase] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checkedState, setCheckedState] = useState(new Array(REFILL_REASONS.length).fill(false));
  const [selectedReason, setSelectedReason] = useState('');
  const additionalComments = useRef('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState('All');

  const handleChange = (eventt, newValue) => {
    setTabValue(newValue);
  };
  let tabStyle: any = {
    display: 'flex',
    marginX: '10px',
    textTransform: 'none',
    color: 'inherit',
    fontFamily: 'headline-regular',
    '&.Mui-selected': {
      color: 'inherit',
      fontFamily: 'headline-bold',
    },
  };

  /**
   * Submits either the Refulfill Request or the Request for Refulfill
   *
   */
  const onSubmitModal = async () => {
    setLoading(true);

    const srType =
      modalType === REFULFILL_BUTTON_TEXT ? 'Re-fulfill Gift Card' : 'Re-fulfill Gift Card Request';
    let srId: string | null = null;

    const additionalData = {
      orderNumber: modalClaimData?.orderNumber,
      service: 'General',
      comments: additionalComments.current,
    };

    const notificationParams = {
      firstName: null,
      lastName: null,
      membershipNumber: null,
      toEmail: null,
      packageId: null,
      solicitationId: null,
      externalRefId: selectedMember?.currentMemberDetails?.externalRefId,
      membershipEnabled: tenantConfig?.membershipEnabled,
    };

    try {
      let srRequest: any = {
        serviceRequest: {
          id: srId,
          type: srType,
          subType: selectedReason,
          member: {
            id: selectedMember?.currentMemberDetails?.memberId,
            membershipId: selectedMember?.currentMemberDetails?.memberId,
          },
          owner: {id: ownerID},
          description: srType,
          summary: additionalComments.current,
          status: modalType === REFULFILL_BUTTON_TEXT ? CLOSED_SR_STATUS : PENDING_SR_STATUS,
          additionalData,
          audit: {
            createdBy: ownerID?.toLowerCase(),
          },
        },
        notificationParams,
      };

      if (isSpecialCase && modalClaimData && modalClaimData.srNumber) {
        srId = modalClaimData.srNumber;
        const updatedSrRequest = {
          serviceRequest: {
            status: CLOSED_SR_STATUS,
            type: modalClaimData?.srType,
            description: modalClaimData?.srType,
            summary: modalClaimData?.additionalComments,
            subType: modalClaimData?.reason,
            additionalData: {
              comments: modalClaimData?.additionalComments,
            },
          },
        };
        delete srRequest?.notificationParams;
        delete srRequest?.serviceRequest.id;

        let activityPayload = {
          id: modalClaimData.srNumber,
          requestObj: {
            commands: [
              {
                commandName: 'updateSR',
                commandId: 'updateSR2',
                ...{
                  ...srRequest.serviceRequest,
                  ...updatedSrRequest.serviceRequest,
                },
              },
            ],
          },
          notificationParams,
        };

        let resposne = await dispatch(updateSR(activityPayload)).unwrap();
        if (!resposne.isSuccessful) {
          console.log('update Service Request failed with error status code');
        }
      } else {
        let response = await dispatch(createSR(srRequest)).unwrap();

        if (!response.isSuccessful) {
          console.log('Creating Service Request failed with error status code');
        }
        srId = response?.data?.createServiceRequest?.id;
      }

      const reFulfilRequest = {
        claimId: modalClaimData?.claimId,
        agentId: ownerID,
        srId: String(srId),
        reason: selectedReason,
        additionalComments: additionalComments.current,
        email: modalClaimData?.sentToEmail,
        address: modalClaimData?.sentToAddress,
      };

      if (modalType === REFULFILL_BUTTON_TEXT) {
        const headers = {
          'tenant-id': tenantID,
          Authorization: `Bearer ${impersonateToken?.accessToken}`,
        };

        let refullfillResponse = await gqlRefulfillClaim(
          {
            reFulfilRequest: reFulfilRequest,
          },
          headers,
        );

        if (!refullfillResponse.isSuccessful) {
          console.log('Refulfilling claim failed with error status code');
        }
      }

      resetGMOrderHistory();
      fetchOrderHistoryHandler(); // to be removed once mapping done from redux
      setIsSpecialCase(false);
      setIsModalOpen(false);
    } catch (error: any) {
      console.error(error);

      const userException = (error as {error: {graphQLErrors: [{message: string}]}})?.error
        ?.graphQLErrors;
      let errorMessage = DEFAULT_ERROR;
      if (userException && userException[0] && userException[0]?.message) {
        errorMessage = userException[0]?.message;
      }
      setError(errorMessage);
      console.log('Refulfilling claim failed');
    }
    dispatch(
      isUpdateSRList({
        isRefreshSRList: true,
      }),
    );
    setLoading(false);
  };

  const formatGiftCardType = (giftCardType: string): string => {
    if (giftCardType === 'PHYSICAL') {
      return 'Plastic';
    } else if (giftCardType === 'DIGITAL') {
      return 'eGift';
    }
    return giftCardType;
  };

  const formatDisplayDateTime = (date: string) => {
    const dateAndTime = new Date(date)
      .toLocaleString([], {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      })
      .split(', ');

    const dateString = dateAndTime[0];
    const timeString = dateAndTime[1];

    return dateString + '\n' + timeString;
  };
  const getDaysBetweenDates = (date1: Date, date2: Date): number => {
    const difference = date1.getTime() - date2.getTime();
    return difference / (1000 * 3600 * 24);
  };

  const getButtonDetails = useCallback(
    (offer: any, giftCardType: string, hasChildren: boolean, isSpecialCase?: boolean): any => {
      let waitDays: number;

      const claimDateTime = new Date(
        offer.claimDateTime && offer.claimDateTime,
      ).toLocaleDateString();

      if (isSpecialCase && agentHasLevel2Group && !hasChildren) {
        return {
          type: REFULFILL_BUTTON_TEXT,
          visible: true,
          isSpecialCase: isSpecialCase,
        };
      }

      if (
        hasChildren ||
        !agentHasGoodwillGroup ||
        (offer.attributes &&
          offer.attributes[0] &&
          offer.attributes[0].BHResponseDetails?.orderStatus !== 'Complete')
      ) {
        return {type: REQUEST_REFULFILL_BUTTON_TEXT, visible: false};
      }

      if (giftCardType === 'eGift') waitDays = eGiftWaitDays ? eGiftWaitDays : 1;
      else waitDays = pGiftWaitDays ? pGiftWaitDays : 10;

      if (getDaysBetweenDates(new Date(), new Date(offer.claimDateTime)) > waitDays) {
        if (agentHasLevel2Group) {
          return {type: REFULFILL_BUTTON_TEXT, visible: true};
        } else if (!memberHasReFulfilled.current && offer.claimType === REDEEM_CLAIMED_OFFER_TYPE) {
          return {type: REFULFILL_BUTTON_TEXT, visible: true};
        } else if (
          claimDateTime &&
          claimDateTime === firstParentDate.current &&
          offer.claimType === REDEEM_CLAIMED_OFFER_TYPE
        ) {
          return {type: REFULFILL_BUTTON_TEXT, visible: true};
        } else if (isSpecialCase) {
          return {
            type: REQUEST_REFULFILL_BUTTON_TEXT,
            visible: false,
            isSpecialCase: isSpecialCase,
          };
        } else {
          return {type: REQUEST_REFULFILL_BUTTON_TEXT, visible: true};
        }
      }
      return {type: REQUEST_REFULFILL_BUTTON_TEXT, visible: false};
    },
    [
      agentHasGoodwillGroup,
      agentHasLevel2Group,
      firstParentDate,
      memberHasReFulfilled,
      eGiftWaitDays,
      pGiftWaitDays,
    ],
  );

  /**
   * Function that maps a raw claim to an ClaimedOffer object
   * Accepts a mapped ClaimedOffer child
   *
   * Notes (reverse cronological order):
   *
   *  Function has been modified to support new mapping logic, and is no longer recursive
   *
   *  Total cost has been removed
   *
   *  Total cost has been changed to the claimed offer amount
   *    previous value: claimedOffer.attributes[0].BHResponseDetails?.totalCost
   *
   *  Changed OrderNumber to Claim ID (id)
   *
   *  SRMatch has been removed as date should be the same as the Refulfill date
   *    SR Status for Refulfill can be assumed as Closed
   */
  const transformClaimedOffer = useCallback(
    (claimedOffer: any, child?: any, offerList?: any[]): any => {
      const correspondingOffer = offerList?.find((offer) => offer.id === claimedOffer.offerId);

      let giftCardType = formatGiftCardType(
        correspondingOffer &&
          correspondingOffer.redemptionDetails &&
          correspondingOffer.redemptionDetails[0].method,
      );

      let merchantData: any = {
        imageUrl: correspondingOffer?.images?.[0].imageUrl,
        altText: correspondingOffer?.images?.[0]?.altText,
        merchant: correspondingOffer?.merchant?.name,
      };

      // This was originally orderNumber from BlackHawk response
      const orderNumber = claimedOffer.id;

      const claimedDate = formatDisplayDateTime(claimedOffer.claimDateTime);

      const secondRowSRMatch = requestForRefullfillSRs.find(
        (sr) => sr.additionalData?.orderNumber === orderNumber && sr.status !== CLOSED_SR_STATUS,
      );

      return {
        id: claimedOffer.id,
        parentId: claimedOffer.data?.reFulfilledForClaimId,
        orderNumber: orderNumber,
        date: claimedDate,
        type: giftCardType,
        reedemedBy: claimedOffer.byAgent ? claimedOffer.byAgent : 'member',
        status:
          claimedOffer.attributes && claimedOffer.attributes[0].BHResponseDetails?.orderStatus,
        orderType: claimedOffer.claimType,
        merchantData: merchantData,
        cardCount:
          claimedOffer.attributes && claimedOffer.attributes[0].BHResponseDetails?.numberOfCards
            ? claimedOffer.attributes[0].BHResponseDetails?.numberOfCards
            : 1,
        cardValue: claimedOffer.attributes && claimedOffer.data?.amount,
        buttonDetails: getButtonDetails(
          claimedOffer,
          giftCardType,
          Boolean(child),
          Boolean(secondRowSRMatch),
        ),
        additionalData: claimedOffer.data
          ? {
              sentToEmail: claimedOffer.data.emailId,
              sentToAddress: claimedOffer.data.address,
              srDate: claimedDate,
              srNumber: claimedOffer.data.srId,
              srStatus: CLOSED_SR_STATUS,
              reason: claimedOffer.data.reason,
              additionalComments: claimedOffer.data.additionalComments,
              claimId: claimedOffer.id,
              orderNumber: orderNumber,
            }
          : undefined,
        additionalDataSecondRow: secondRowSRMatch
          ? {
              srDate: formatDisplayDateTime(secondRowSRMatch?.audit?.createdOn),
              srNumber: secondRowSRMatch.id,
              srStatus: secondRowSRMatch.status,
              reason: secondRowSRMatch.subType,
              additionalComments: secondRowSRMatch.summary,
              claimId: claimedOffer.id,
              orderNumber: orderNumber,
              srType: secondRowSRMatch.type,
              sentToEmail: claimedOffer.data.emailId,
            }
          : undefined,
        child: child,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getButtonDetails, requestForRefullfillSRs.length],
  );

  const findFirstParentDate = (redeemRows: any[], reFulfillRows: any[]): string => {
    for (let i = redeemRows.length - 1; i >= 0; i--) {
      for (let j = 0; j < reFulfillRows.length; j++) {
        if (
          redeemRows[i].id.toString() === reFulfillRows[j].data?.reFulfilledForClaimId &&
          redeemRows[i].claimDateTime
        ) {
          return new Date(redeemRows[i].claimDateTime).toLocaleDateString();
        }
      }
    }
    return '';
  };

  /**
   * Maps the claimed offer Re-Fulfill rows to the parent Redeem offers.
   *
   * Sets the values for memberHasReFulfilled & firstParentDate which
   * get used for the button visibility logic
   *
   * Notes:
   *  The offers from the API being in reverse chronological order is critical
   *  for the mapping logic to work as expected.
   *
   * Todo: Full regression with stage US
   */
  const mapClaimedOffers = useCallback(
    (data: any[], offerList?: any[]): any[] => {
      const redeemRows: any[] = [];
      const reFulfillRows: any[] = [];
      const mappedChildren: any[] = [];
      const mappedParents: any[] = [];

      // Split the claimed offer array into two arrays - Redeem & Refulfill
      data.forEach((item) => {
        if (item.claimType === REDEEM_CLAIMED_OFFER_TYPE) {
          redeemRows.push(item);
        }

        if (item.claimType === REFULFILL_CLAIMED_OFFER_TYPE) {
          reFulfillRows.push(item);
        }
      });

      // If there is a reFulfill order than memberHasReFulfilled
      // Also, set the firstParentDate prior to mapping
      if (reFulfillRows.length > 0) {
        memberHasReFulfilled.current = true;

        firstParentDate.current = findFirstParentDate(redeemRows, reFulfillRows);
      }

      // map children to "parent" children
      reFulfillRows.forEach((row) => {
        const mappedChildRow = mappedChildren.find((child) => child.parentId === row.id.toString());

        if (mappedChildRow) {
          mappedChildren.push(transformClaimedOffer(row, mappedChildRow, offerList));
        } else {
          const childRow = reFulfillRows.find(
            (child) => child.data?.reFulfilledForClaimId === row.id.toString(),
          );

          mappedChildren.push(
            transformClaimedOffer(
              row,
              childRow ? transformClaimedOffer(childRow) : undefined,
              offerList,
            ),
          );
        }
      });

      // maps top-level "parent children" to redeem order
      redeemRows.forEach((row) => {
        const childRow = mappedChildren.find((child) => child.parentId === row.id.toString());

        mappedParents.push(transformClaimedOffer(row, childRow ? childRow : undefined, offerList));
      });

      return mappedParents;
    },
    [transformClaimedOffer],
  );

  const fetchOrderHistoryHandler = async () => {
    try {
      // toggleLoader(true);
      setLoading(true);

      //TO DO---- Once api is up call this two api
      if (!impersonateToken) {
        const data1 = {externalRefId: selectedMember?.focusedMemberDetails?.externalRefId};
        await dispatch(getImpersonateToken(data1)).unwrap();
      }

      let response = await dispatch(
        getGMGiftingHistroy({
          pageNo: 0,
          pageSize: 100,
          offerType: 'GIFTCARD',
        }),
      ).unwrap();

      if (response.data?.claimedOffers?.claimedOffers.length > 0) {
        const mappedOrderHistory = mapClaimedOffers(
          response.data.claimedOffers.claimedOffers,
          response.data.claimedOffers.offers,
        );

        setOffers(mappedOrderHistory);
      } else {
        setOffers([]);
      }

      if (!response.isSuccessful) {
        console.log('Fetching order history returned with an error status code');
      }
    } catch (error: any) {
      console.error(error);
      console.log('Fetching order history failed');
      // toggleLoader(false);
      setLoading(false);
      return error;
    }
    // toggleLoader(false);
    setLoading(false);
  };
  const onReFulfillClick = (data: any, modalType: string, isSpecialCase: boolean) => {
    setModalType(modalType);
    setModalClaimData(data);
    setIsSpecialCase(isSpecialCase);
    setIsModalOpen(true);
  };

  useEffect(() => {
    fetchOrderHistoryHandler();
  }, []);

  const onModalClose = () => {
    setIsModalOpen(false);
  };
  const handleModalSelectChange = (position: number): any => {
    const updatedCheckedState = checkedState.map((item, index) => {
      return index === position ? !item : false;
    });
    setSelectedReason(REFILL_REASONS[position]);
    setCheckedState(updatedCheckedState);
  };

  const handleModalTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    additionalComments.current = e.currentTarget.value;
  };

  if (loading) {
    return <Loading />;
  } else {
    return (
      <>
        <RefulfillDialog
          visible={isModalOpen}
          onModalClose={onModalClose}
          onModalSelectChange={handleModalSelectChange}
          onModalTextChange={handleModalTextChange}
          onSubmitModal={onSubmitModal}
          checkedState={checkedState}
          type={modalType}
          error={error}
          setError={setError}
        />

        <TabContext value={tabValue}>
          <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="All" value="All" component={ButtonBase} sx={tabStyle} disableRipple />
              <Tab label="eGift" value="eGift" component={ButtonBase} sx={tabStyle} disableRipple />
              <Tab label="pGift" value="pGift" component={ButtonBase} sx={tabStyle} disableRipple />
            </TabList>
            <MemberAccountInfo updateAccount={updateAccount} />
          </Box>

          <Box sx={{marginTop: '10px'}}>
            <TabPanel value="All">
              <OfferTable rows={offers} onReFulfillClick={onReFulfillClick} />
            </TabPanel>
            <TabPanel value="eGift">
              <OfferTable
                rows={offers.filter((offer) => offer.type === 'eGift')}
                onReFulfillClick={onReFulfillClick}
              />
            </TabPanel>
            <TabPanel value="pGift">
              <OfferTable
                rows={offers.filter((offer) => offer.type === 'Plastic')}
                onReFulfillClick={onReFulfillClick}
              />
            </TabPanel>
          </Box>
        </TabContext>
      </>
    );
  }
};

export default GMGifitngHistory;
