import {Box, Button, DialogContent} from '@mui/material';
import DialogWrapper from 'Pages/Tabs/Gifting/components/dialogs/DialogWrapper';
import {resetPassword} from 'Redux/Slices/serviceRequest';
import {getSolicitationId} from 'Utils/helpers/MemberDetails';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {isUpdateSRList} from 'Redux/Slices/SRListFilter';

const ResetPassword = (props) => {
  const {openResetPasswordDialog, isOpenResetPasswordDialog} = props;

  const {selectedMember} = useSelector((state: any) => state?.selectedMember);
  const currentMemberData = selectedMember.currentMemberDetails;
  const solicitationId = getSolicitationId(selectedMember);
  const ownerID = useSelector((state: any) => state?.login?.userName);
  const dispatch = useDispatch();

  const callResetPasswordApi = () => {
    let variable = {
      requestDto: {
        memberId: currentMemberData?.memberId,
        membershipId: selectedMember?.membershipId,
        createdBy: ownerID,
        solicitationId: solicitationId,
      },
    };
    resetPasswordApiCall(variable);
  };

  const resetPasswordApiCall = async (variable) => {
    try {
      let resp = await dispatch(resetPassword(variable)).unwrap();

      if (resp.data.forgotPassword?.code === '200') {
        dispatch(
          isUpdateSRList({
            isRefreshSRList: true,
          }),
        );
        closeModal('');
      }
    } catch (error: any) {
      console.log(error);
      if (error) {
        closeModal('');
      }
    }
  };
  const closeModal = (e) => {
    isOpenResetPasswordDialog && isOpenResetPasswordDialog(e);
  };

  return (
    <DialogWrapper closeDialogMethod={isOpenResetPasswordDialog} open={openResetPasswordDialog}>
      <DialogContent>
        <Box sx={{paddingTop: '5px'}}>Are you sure you want to Reset user password?</Box>
        <Button onClick={callResetPasswordApi}>Yes</Button>
        <Button onClick={closeModal}>No</Button>
      </DialogContent>
    </DialogWrapper>
  );
};

export default ResetPassword;
