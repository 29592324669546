import * as Yup from 'yup';

export const validationSchema = Yup.object({
  service: Yup.string().required(),
});

export const initialValues = {
  srService: '',
  srStatus: '',
  srType: '',
  srClosedDate: '',
  srId: '',
  srCreatedDate: '',
  srCreatedBy: '',
  srModifiedDate: '',
  srModifiedBy: '',
};
