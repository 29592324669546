import moment from 'moment';
import {
  LOCALE,
  DATE_FORMAT_LOCALE,
  DATE_TIME_FORMAT_LOCALE,
  FORMAT_TYPE,
} from 'Utils/constants/DateFormat';
import {parseToFloat} from './Numeric';

export const dateFormatConversionToLocale = (formatType = '', locale) => {
  if (formatType === FORMAT_TYPE.DATE_TIME) {
    if (locale === LOCALE.US) {
      return DATE_TIME_FORMAT_LOCALE.US;
    }

    return DATE_TIME_FORMAT_LOCALE.EU;
  }

  if (locale === LOCALE.US) {
    return DATE_FORMAT_LOCALE.US;
  }

  return DATE_FORMAT_LOCALE.EU;
};

export const dateConversionIsoToLocal = (date, formatType?, format?, locale = LOCALE.EU) => {
  if (date) {
    const isValidDate = moment(date).isValid();
    if (isValidDate) {
      const dateFormat = format ? format : dateFormatConversionToLocale(formatType, locale);
      const localDate = moment(new Date(date)).format(dateFormat);

      return localDate;
    }
    return date;
  } else return '';
};

export function isValidISO8601(dateTimeString) {
  const iso8601Pattern =
    /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/;
  return iso8601Pattern.test(dateTimeString);
}

export const dateConversionToISOFormatIgnoreTimeZone = (date) => {
  if (date) {
    const offset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() - offset).toISOString();
  }
  return;
};

export const getDate = (dateString) => {
  if (dateString) {
    var arr = dateString.split('T');
    if (arr.length < 2) {
      return null;
    }
    var date = arr[0].split('-');
    var time = arr[1].split(':');
    console.log(parseToFloat(date[0]));
    let testDate = new Date(
      parseToFloat(date[0]),
      parseToFloat(date[1]) - 1,
      parseToFloat(date[2]),
      parseToFloat(time[0]),
      parseToFloat(time[1]),
      0,
    );
    console.log(testDate);
    return testDate;
  }

  return null;
};

export const dateConversionToISOFormatIgnoreTimeZoneForEndDate = (dateParam) => {
  if (dateParam) {
    let date = new Date(new Date(dateParam).getTime() + 60 * 60 * 24 * 1000);
    const offset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() - offset).toISOString();
  }
  return;
};

export const localFormattedDateToIso = (date) => {
  if (date) {
    let convertedDate = moment(date, 'DD/MM/YYYY hh:mm:ss A').toISOString();
    return convertedDate;
  }
  return null;
};

export const convertMonthToStringFormat = (value) => {
  var months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  var selectedMonthName = months[value - 1] || '';
  return selectedMonthName;
};

export const isExpiryDateValid = (expiryDateYear, expiryDateMonth) => {
  let currentYear = new Date().getFullYear();
  let currentMonth = new Date().getMonth() + 1;
  expiryDateMonth = Number(expiryDateMonth);
  expiryDateYear = Number(expiryDateYear);
  if (
    (expiryDateMonth >= currentMonth && expiryDateYear === currentYear) ||
    expiryDateYear > currentYear
  ) {
    return true;
  }
  return false;
};

export const isExpiryYearValid = (expiryDateYear) => {
  let currentYear = new Date().getFullYear();
  if (expiryDateYear < currentYear + 100) {
    return true;
  } else {
    return false;
  }
};
