import {CssBaseline} from '@mui/material';
import {ThemeProvider} from '@mui/material/styles';
import SSOIframe from 'Api/SSOIframe';
import {AuthContextProvider} from 'Context/AuthContext';
import IdleContextProvider from 'Context/IdleTimerContext';
import {agentInternalSSOLogin} from 'Redux/Slices/login';
import {LOGIN, SEARCH} from 'Utils/constants/Paths';
import {SSO_ERROR_TEXT} from 'Utils/constants/genericConstants';
import {createMemberObj, isIframeRequest, setStorageForIframe} from 'Utils/helpers/IFrameMethods';
import {setAccessToken, setServiceToken} from 'Utils/helpers/TokenStorage';
import React, {useEffect, useLayoutEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import Loading from './Components/Loading';
import {ContentStackContextProvider} from './Context/ContentStackContext';
import {DataContextProvider} from './Context/DataContext';
import NavigationRoutes from './Routes/Routes';
import {setGlobal} from './Utils/helpers/CSfetchGlobalData';

function App(props) {
  interface CustomizedState {
    from: {
      pathname: string;
    };
  }
  const [loading, setLoading] = useState(true);
  const [dynamicTheme, setDynamicTheme] = useState<any>(null);
  const [header, setHeader] = useState(null);
  const [artifacts, setartifacts] = useState(null);
  const [isIframeAccessible, setIsIframeAccessible] = useState(true);
  const [error, setError] = useState<boolean | string>();
  const location = useLocation();
  const state = location.state as CustomizedState;
  const from = state?.from?.pathname || LOGIN;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener('mousedown', function () {
      document.body.classList.add('using-mouse');
    });

    window.addEventListener('keydown', function (event) {
      if (event.target instanceof HTMLInputElement) {
        if (event?.target?.tagName !== 'INPUT') {
          document.body.classList.remove('using-mouse');
        }
      }
    });
  }, []);

  useEffect(() => {
    if (props.token) {
      (async () => {
        let payload = {
          access_token: props.token,
          refresh_token: props.refreshToken,
        };
        let resp = await dispatch(agentInternalSSOLogin(payload)).unwrap();
        if (resp?.error || resp?.response?.data?.error) {
          navigate(from, {replace: true});
        } else {
          navigate(SEARCH, {replace: true});
        }
      })();
    }
  }, [props.token]);

  useLayoutEffect(() => {
    const CScall = async () => {
      const CSGlobalData: any = await setGlobal();
      if (CSGlobalData.error) {
        console.error(CSGlobalData.error);
        setError(true);
      }
      setartifacts(CSGlobalData.artifacts);
      setHeader(CSGlobalData.header);
      setDynamicTheme(CSGlobalData.finalTheme);
      setLoading(false);
    };
    CScall();
  }, []);

  /**
   * IFRAME setup part 1/2
   *
   * the request comes from iframe if 4 params are present: internaId, externalId, token, memberStatus and name.
   * Email is optional as there is a chance there is no email associated to the member,
   * in that case the gifting will not be possible.
   * If the tool is available for the Agent with specific role the useEffect saves the params in the session storage,
   * if not it shows a message to the agent.
   * next step is in dashboard file
   */
  const [searchParams] = useSearchParams();
  const {state: isIFrame, params, token, tenant} = isIframeRequest(searchParams);

  useEffect(() => {
    if (isIFrame) {
      (async () => {
        const data = await SSOIframe(tenant, token);
        if (data?.access_token) {
          const memberData = createMemberObj(params);
          setAccessToken(data?.access_token);
          setServiceToken(data?.access_token);
          setStorageForIframe(memberData);
        } else {
          setIsIframeAccessible(false);
        }
      })();
    }
  }, []);

  if (loading) return <Loading isOpen={true} />;
  if (error) return <p style={{textAlign: 'center'}}>{SSO_ERROR_TEXT.default}</p>;
  if (!isIframeAccessible) {
    return <p style={{textAlign: 'center'}}>Transactions Support not available.</p>;
  }
  return (
    <ContentStackContextProvider value={{header, artifacts}}>
      <ThemeProvider theme={dynamicTheme}>
        <AuthContextProvider>
          <DataContextProvider>
            <IdleContextProvider>
              <CssBaseline />
              <NavigationRoutes />
            </IdleContextProvider>
          </DataContextProvider>
        </AuthContextProvider>
      </ThemeProvider>
    </ContentStackContextProvider>
  );
}

export default App;
