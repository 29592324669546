import * as Yup from 'yup';

export const validationSchema = Yup.object({
  quantity: Yup.string().required('Required'),
  type: Yup.string().required('Required'),
});

export const initialValues = {
  pricePerUnit: '',
  quantity: '',
  totalPrice: '',
  type: '',
  status: '',
  bookingFee: '',
  isNoCashback: false,
};
