export const LOCALE = {
  US: 'en-US',
  EU: 'en-GB',
};

export const DATE_FORMAT_LOCALE = {
  US: 'MM/DD/YYYY',
  EU: 'DD/MM/YYYY',
};

export const DATE_TIME_FORMAT_LOCALE = {
  US: 'MM/DD/YYYY hh:mm:ss A',
  EU: 'DD/MM/YYYY hh:mm:ss A',
};

export const DATE_TIME_LOCALE = {
  'en-US': 'MM/dd/yyyy HH:mm',
  'en-GB': 'dd/MM/yyyy HH:mm',
};

export const FORMAT_TYPE = {
  DATE_TIME: 'DateTime',
};

export const DATE_FORMAT = {
  dateFormat: 'dd/MM/yyyy',
};
