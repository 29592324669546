import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import TableView from 'Components/TableView';
import {getBenefitsGirdData} from './BenefitsGridDataSource';
import {SortOrder} from 'Utils/enums';

interface IProps {}
const Benefits: React.FC<IProps> = (props) => {
  const member = useSelector((state: any) => state?.selectedMember?.selectedMember);
  const [tableData, setTableData] = useState<any[]>([]);

  useEffect(() => {
    const tempTableData: any = [];
    (member?.benefits || []).forEach((benefit) => {
      tempTableData.push({label: benefit.name, code: benefit.vendorName, value: benefit.vendorId});
    });

    setTableData(tempTableData);
  }, [member]);

  const onRowClick = () => {};

  return (
    <TableView
      {...getBenefitsGirdData(tableData, SortOrder.DESCENDING, '', false)}
      onRowClick={onRowClick}
    />
  );
};
export default Benefits;
