const styles = {
  giftCardListContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    gap: '1.5rem 2%',
  },
  textWithColor: {
    backgroundColor: '#070707',
    color: 'white',
    textAlign: 'center',
    padding: '0px',
    width: '100%',
    marginTop: '10px',
    borderRadius: '8px',
  },
  pricing_table: {
    display: 'inline-table',
    marginTop: '15px',
    fontWeight: 'bold',
    border: '2px solid',
    borderRadius: '10px',
    width: '100%',
  },
  pricing_table_col: {
    display: 'table-cell',
    padding: '10px 20px',
    cursor: 'pointer',
    borderRadius: '8px',
  },
};

export default styles;
