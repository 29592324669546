import {parseData} from 'Utils/helpers/SRListHelpers';

export const getSubscriptionGridData = (
  tableData,
  sortOrder,
  currentSortCriteria,
  isSortingEnabled,
) => {
  const header = [
    {label: 'Solicitation Name', value: 'solicitationId'},
    {label: 'Package Name', value: 'packageName'},
    {label: 'Start Date(V)', value: 'startDate'},
    {label: 'Cancel Date', value: 'endDate'},
    {label: 'Status', value: 'code'},
    {label: 'Tier To', value: 'tierTo'},
    {label: 'Source', value: 'source'},
    {label: 'Cancel Reason', value: 'reason'},
    {label: 'Reinstate Reason', value: 'reinstate'},
  ];
  return parseData(header, tableData, sortOrder, currentSortCriteria, isSortingEnabled);
};
