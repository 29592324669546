export const activityFormSection = {
  sectionClass: '',
  title: 'Activity Form',
  titleClass: '',
  fields: [
    {
      fieldType: 'select',
      name: 'type',
      id: 'type',
      fieldLabel: 'Type',
      required: true,
      options: [],
      dataTest: 'type',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'select',
      name: 'subType',
      id: 'subType',
      fieldLabel: 'Corresp. Type',
      options: [],
      dataTest: 'correspType',
      xs: 4,
      sm: 4,
      display: 'none',
    },
    {
      fieldType: 'select',
      name: 'status',
      id: 'status',
      fieldLabel: 'Status',
      options: [
        {value: 'Acknowledged', text: 'Acknowledged'},
        {value: 'Alerted', text: 'Alerted'},
        {value: 'Approved', text: 'Approved'},
        {value: 'Auto Responded', text: 'Auto Responded'},
        {value: 'Awaiting Activation', text: 'Awaiting Activation'},
        {value: 'Awaiting Ticket Release', text: 'Awaiting Ticket Release'},
        {value: 'Awaiting response (3rd party)', text: 'Awaiting response (3rd party)'},
        {value: 'Awaiting response (member)', text: 'Awaiting response (member)'},
        {value: 'Cancelled', text: 'Cancelled'},
        {value: 'Cancelled by member', text: 'Cancelled by member'},
        {value: 'Complete', text: 'Complete'},
        {value: 'Completed', text: 'Completed'},
        {value: 'Correspondence', text: 'Correspondence'},
        {value: 'Declined', text: 'Declined'},
        {value: 'Deferred', text: 'Deferred'},
        {value: 'Delivery Failed', text: 'Delivery Failed'},
        {value: 'Delivery Failure', text: 'Delivery Failure'},
        {value: 'Dispatch Failed', text: 'Dispatch Failed'},
        {value: 'Dispatched', text: 'Dispatched'},
        {value: 'Done', text: 'Done'},
        {value: 'Failed', text: 'Failed'},
        {value: 'Failed at Provider', text: 'Failed at Provider'},
        {value: 'Incomplete', text: 'Incomplete'},
        {value: 'No Correspondence', text: 'No Correspondence'},
        {value: 'Not Acknowledged', text: 'Not Acknowledged'},
        {value: 'Not Applicable', text: 'Not Applicable'},
        {value: 'Not Requested', text: 'Not Requested'},
        {value: 'Not Required', text: 'Not Required'},
        {value: 'Not Started', text: 'Not Started'},
        {value: 'Not Suppliable', text: 'Not Suppliable'},
        {value: 'Offer Sent', text: 'Offer Sent'},
        {value: 'Pending', text: 'Pending'},
        {value: 'Queued', text: 'Queued'},
        {value: 'Quoted', text: 'Quoted'},
        {value: 'Reassigned', text: 'Reassigned'},
        {value: 'Requested', text: 'Requested'},
        {value: 'Scheduled', text: 'Scheduled'},
        {value: 'Sent', text: 'Sent'},
        {value: 'Sent Promotion', text: 'Sent Promotion'},
        {value: 'Sent to Partner for Analysis', text: 'Sent to Partner for Analysis'},
        {value: 'Suppressed', text: 'Suppressed'},
        {value: 'Totally Incomplete', text: 'Totally Incomplete'},
        {value: 'Unassigned', text: 'Unassigned'},
        {value: 'Unscheduled', text: 'Unscheduled'},
        {value: 'With Provider', text: 'With Provider'},
      ],
      dataTest: 'status',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'select',
      name: 'priority',
      id: 'priority',
      fieldLabel: 'Priority',
      options: [
        {value: '', text: ''},
        {value: 'ASAP', text: 'ASAP'},
        {value: 'High', text: 'High'},
        {value: 'Low', text: 'Low'},
        {value: 'Medium', text: 'Medium'},
        {value: 'Normal', text: 'Normal'},
        {value: 'Urgent', text: 'Urgent'},
      ],
      dataTest: 'priority',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'select',
      name: 'channel',
      id: 'channel',
      fieldLabel: 'Channel',
      options: [
        {value: '', text: ''},
        {value: 'Call Inbound', text: 'Call - Inbound'},
        {value: 'Call - Outbound', text: 'Call - Outbound'},
        {value: 'Courier - Inbound', text: 'Courier - Inbound'},
        {value: 'Courier - Outbound', text: 'Courier - Outbound'},
        {value: 'Customer Satisfaction', text: 'Customer Satisfaction'},
        {value: 'Diagnostic', text: 'Diagnostic'},
        {value: 'Email - Inbound', text: 'Email - Inbound'},
        {
          value: 'Email - Outboundl',
          text: 'Email - Outbound',
        },
        {value: 'Fax &amp; Call - Outbound', text: 'Fax &amp; Call - Outbound'},
        {value: 'Fax - Inbound', text: 'Fax - Inbound'},
        {value: 'Fax - Outbound', text: 'Fax - Outbound'},
        {value: 'Field Engineer Activity', text: 'Field Engineer Activity'},
        {value: 'Mail - Inbound', text: 'Mail - Inbound'},
        {value: 'Mail - Outbound', text: 'Mail - Outbound'},
        {value: 'Manual Fax &amp; Call - Outbound', text: 'Manual Fax &amp; Call - Outbound'},
        {value: 'Manual Fax - Outbound', text: 'Manual Fax - Outbound'},
        {value: 'Online - Outbound', text: 'Online - Outbound'},
        {value: 'Other', text: 'Other'},
        {value: 'Preventive Maintenance', text: 'Preventive Maintenance'},
        {value: 'Registered Mail - Outbound', text: 'Registered Mail - Outbound'},
        {value: 'Repair Activity', text: 'Repair Activity'},
        {value: 'SMS - Inbound', text: 'SMS - Inbound'},
        {value: 'SMS - Outbound', text: 'SMS - Outbound'},
        {value: 'Telex - Outbound', text: 'Telex - Outbound'},
      ],
      dataTest: 'channel',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'dateTimePicker',
      name: 'plannedStartDate',
      id: 'plannedStartDate',
      fieldLabel: 'PlannedStartDate',
      disableFutureDate: true,
      inputClass: '',
      dataTest: 'plannedStartDate',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'textarea',
      htmlInputType: 'text',
      name: 'comments',
      id: 'comments',
      fieldLabel: 'Comments',
      dataTest: 'comments',
      xs: 6,
      sm: 6,
      multiline: true,
      rows: 4,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'freeText',
      id: 'freeText',
      fieldLabel: 'Free Text',
      dataTest: 'freeText',
      multiline: true,
      rows: 2,
      xs: 3,
      sm: 3,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'templateId',
      id: 'templateId',
      fieldLabel: 'Template',
      dataTest: 'templateId',
      multiline: true,
      rows: 2,
      xs: 3,
      sm: 3,
      isDisabled: true,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'id',
      id: 'id',
      fieldLabel: 'Activity #',
      dataTest: 'id',
      xs: 3,
      sm: 3,
      isDisabled: true,
    },
  ],
};

export const buttonList = {
  direction: 'row',
  spacing: 2,
  fields: [
    {
      fieldType: 'button',
      htmlInputType: 'button',
      name: 'cancel',
      id: 'cancel',
      fieldLabel: 'Cancel',
      variant: 'contained',
      dataTest: 'cancel',
      color: 'error',
      xs: 2,
      sm: 2,
    },
    {
      fieldType: 'button',
      htmlInputType: 'submit',
      name: 'save',
      id: 'save',
      fieldLabel: 'Save',
      variant: 'contained',
      wrapperClass: '',
      dataTest: 'save',
      xs: 2,
      sm: 2,
    },
  ],
};
