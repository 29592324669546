import {createSlice, createAsyncThunk, createEntityAdapter} from '@reduxjs/toolkit'; //createSelector,
import {login, loginWithSSO, loginWithInternalSSO, logout, refreshToken} from 'Api/Login';
import {globalConfig} from 'Config/config';
import GetAgentNameAndId from 'Utils/helpers/GetAgentNameAndId';

interface Login {
  id: String;
  userName: string;
  name: string;
  loginInfo: {};
  groups: string[];
  tenantId: string;
  lastUpdated: Date;
  env: string;
  roleConfig?: any;
  tenantConfig?: any;
  role?: any;
}

const loginAdapter: any = createEntityAdapter<Login>({});

const initialState = loginAdapter.getInitialState({
  status: 'idle',
  error: '',
});

export const agentLogin: any = createAsyncThunk('login/fetchAccessToken', async (data: any) => {
  const isSSO = window.location.pathname.includes('sso');
  const resp = (await !isSSO)
    ? login(data.email.trim(), data.password.trim())
    : loginWithSSO(data.token);
  return isSSO ? resp : !resp?.error ? JSON.parse(resp) : resp.error;
});

export const agentInternalSSOLogin: any = createAsyncThunk(
  'login/loginWithInternalSSO',
  async (data: any) => {
    const resp = await loginWithInternalSSO(
      data,
      globalConfig?._config?.internalSSOConfig?.tenantId,
    );
    return !resp?.error ? JSON.parse(resp) : resp.error;
  },
);

export const agentLogout: any = createAsyncThunk('login/logoutAgent', async (args, {getState}) => {
  const state: any = getState();
  const response = await logout(state.login.loginInfo.refresh_token);
  return response;
});

export const agentTokenRefresh: any = createAsyncThunk(
  'login/agentTokenRefresh',
  async (data, {getState}) => {
    const state: any = getState();
    const response = await refreshToken({refreshToken: state?.login.loginInfo?.refresh_token});
    return response;
  },
);

const loginSlice = createSlice({
  name: 'Login',
  initialState,
  reducers: {
    assignGroupsToAgent: (state, action) => {
      state.groups.push(action.payload);
    },
    updateLoginDetails: (state, action) => {
      for (const key in state.loginInfo) {
        if (action.payload.hasOwnProperty(key)) {
          state[key] = action.payload[key];
        }
      }
    },
    setTenantDetails: (state, action) => {
      let tenantConfig = state.loginInfo.configs.filter((config) => action.payload === config.name);
      state.tenantConfig = tenantConfig[0].tenantConfigs;
      state.tenantID = tenantConfig[0]['tenant-id'];
      state.roleConfig = tenantConfig[0].roleConfigs;
      state.role = tenantConfig[0].role;
    },
    updateTenantID: (state, action) => {
      state.tenantID = action.payload;
    },
    resetLoginData: (state) => {
      Object.assign(state, initialState);
    },
    setLoginFromSSO: (state, action) => {
      if (action.payload.data) {
        state.status = 'succeeded';
        state.loginInfo = action.payload.data;
        const {name, preferred_username} = GetAgentNameAndId(action.payload.data.access_token);
        state.name = name;
        state.userName = preferred_username;
        state.roleConfig = action.payload.data.configs[0].roleConfigs;
        state.role = action.payload.data.configs[0].role;
      } else {
        state.status = 'failed';
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(agentLogin.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(agentLogin.fulfilled, (state, action) => {
        if (action.payload.data) {
          state.status = 'succeeded';
          state.loginInfo = action.payload.data;
          const {name, preferred_username} = GetAgentNameAndId(action.payload.data.access_token);
          state.name = name;
          state.userName = preferred_username;
          state.roleConfig = action.payload.data.configs[0].roleConfigs;
          state.role = action.payload.data.configs[0].role;
        } else {
          state.status = 'failed';
        }
      })
      .addCase(agentLogin.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      })
      .addCase(agentLogout.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(agentLogout.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(agentTokenRefresh.fulfilled, (state, action) => {
        if (action.payload.data) {
          state.loginInfo.access_token = action.payload.data.access_token;
          state.loginInfo.refresh_token = action.payload.data.refresh_token;
          state.loginInfo.service_token = action.payload.data.access_token;
          state.loginInfo.refresh_expires_in = action.payload.data.refresh_expires_in;

          state.status = 'succeeded';
        } else {
          state.status = 'error';
        }
      })
      .addCase(agentInternalSSOLogin.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(agentInternalSSOLogin.fulfilled, (state, action) => {
        if (action.payload.data) {
          state.status = 'succeeded';
          state.loginInfo = action.payload.data;
          const {name, preferred_username} = GetAgentNameAndId(action.payload.data.access_token);
          state.name = name;
          state.userName = preferred_username;
          state.roleConfig = action.payload.data.configs[0].roleConfigs;
          state.role = action.payload.data.configs[0].role;
        } else {
          state.status = 'failed';
        }
      })
      .addCase(agentInternalSSOLogin.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      });
  },
});

export const {
  assignGroupsToAgent,
  updateLoginDetails,
  setTenantDetails,
  resetLoginData,
  updateTenantID,
  setLoginFromSSO,
} = loginSlice.actions;

export default loginSlice.reducer;
