import globalSpacing from '../../../Theme/Spacing';

const styles = {
  paper: {
    marginTop: globalSpacing.sm,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    // border: '1px solid black',
    padding: '10px',
    maxHeight: 'calc(75vh - 150px)',
    '&::-webkit-scrollbar': {
      width: '0.3rem',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'lightgrey',
      borderRadius: 15,
    },
  },
  gridConatiner: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)' /* Creates 5 equal columns */,
    maxHeight: '100%',
    gap: '20px' /* Add spacing between grid items */,
    // justifyItems: 'center' /* Centers the grid items horizontally */,
    alignItems: 'center' /* Centers the grid items vertically */,
  },
  gridItem: {
    // border: '1px solid #ccc',
    padding: '15px',
    textAlign: 'center',
    display: 'flex',
    height: '70px',
  },
};

export default styles;
