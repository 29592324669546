import {SnackBar, Wrapper} from 'Components';
import React, {useState} from 'react';
import ActivityListView from './ActivitiesListView';
import {Box, Button, Grid, Paper, Stack, Typography} from '@mui/material';
import ActivityDetailViewDialog from './ActivityDetailViewDialog';
import globalSpacing from 'Theme/Spacing';
import {useDispatch, useSelector} from 'react-redux';
import {getNotificationParams} from './ActivityDetailViewDialog/activitiesUtil';
import {updateSR} from 'Redux/Slices/serviceRequest';
import {searchSRByID} from 'Redux/Slices/selectedSR';
import {useSnackBar} from 'Hooks';
import {isUpdateSRList} from 'Redux/Slices/SRListFilter';

const Activities = (props, ref) => {
  const [activity, setActivity] = useState<any>([]);
  const [error, setError] = useState('');
  const [isSnackBarOpen, closeSnackBar] = useSnackBar(error);
  const [openAcitvityDialog, isOpenAcitvityDialog] = useState(false);
  const [creatingNewActivity, setCreatingNewActivity] = useState(true);
  const dispatch = useDispatch();
  const selectedSRData = useSelector((state: any) => state.selectedSR.getServiceRequestById);
  const {selectedMember} = useSelector((state: any) => state?.selectedMember);
  const ownerID = useSelector((state: any) => state?.login?.userName);

  const handleNewActivityClick = async () => {
    setActivity([]);
    isOpenAcitvityDialog(true);
    setCreatingNewActivity(true);
  };
  const onSubmit = (formValues) => {
    if (creatingNewActivity && selectedSRData?.id) {
      createNewActivity(formValues);
    } else {
      updateActivity(formValues);
    }
  };
  const createNewActivity = async (formValues) => {
    let todaysDate = new Date().toISOString();
    let additionalData = {
      completionDate: formValues.status === 'Completed' ? todaysDate : '',
      comments: formValues?.comments,
      plannedStartDate: formValues?.plannedStartDate,
    };
    let audit = {
      createdBy: ownerID,
      modifiedBy: ownerID,
    };

    let activityPayload = {
      id: selectedSRData?.id,
      requestObj: {
        commands: [
          {
            commandName: 'addActivity',
            commandId: 'New Activity',
            ...formValues,
            additionalData: additionalData,
            audit: audit,
          },
        ],
      },
      notificationParams: getNotificationParams(selectedMember),
    };
    try {
      let resp = await dispatch(updateSR(activityPayload)).unwrap();
      let srUpdateStatus = resp?.data?.updateServiceRequest[0]?.status;
      if (srUpdateStatus?.toUpperCase() === 'SUCCESS') {
        let payload = {
          id: selectedSRData?.id,
        };
        await dispatch(searchSRByID(payload)).unwrap();
        dispatch(
          isUpdateSRList({
            isRefreshSRList: true,
          }),
        );
        setError('');
      } else if (srUpdateStatus?.toUpperCase() === 'FAILURE') {
        let srUpdateErrors = resp?.data?.updateServiceRequest?.[0]?.errors.message;
        setError(srUpdateErrors);
      } else console.log('Something Went Wrong');
    } catch (err) {
      console.warn(err);
    }
  };

  const updateActivity = async (formValues) => {
    let todaysDate = new Date().toISOString();
    let additionalData = {
      completionDate: formValues.status === 'Completed' ? todaysDate : '',
      comments: formValues?.comments,
      plannedStartDate: formValues?.plannedStartDate,
    };
    let audit = {modifiedBy: ownerID};

    let activityPayload = {
      id: selectedSRData?.id,
      requestObj: {
        commands: [
          {
            commandName: 'updateActivity',
            commandId: 'Command1',
            ...formValues,
            additionalData: additionalData,
            audit: audit,
          },
        ],
      },
      notificationParams: getNotificationParams(selectedMember),
    };
    try {
      let resp = await dispatch(updateSR(activityPayload)).unwrap();
      let srUpdateStatus = resp?.data?.updateServiceRequest[0]?.status;
      if (srUpdateStatus?.toUpperCase() === 'SUCCESS') {
        let payload = {
          id: selectedSRData?.id,
        };
        await dispatch(searchSRByID(payload)).unwrap();
        dispatch(
          isUpdateSRList({
            isRefreshSRList: true,
          }),
        );
        setError('');
      } else if (srUpdateStatus?.toUpperCase() === 'FAILURE') {
        let srUpdateErrors = resp?.data?.updateServiceRequest?.[0]?.errors.message;
        setError(srUpdateErrors);
      } else console.log('Something Went Wrong');
    } catch (err) {
      console.warn(err);
    }
  };

  const onRowClick = (data) => {
    setActivity(data);
    isOpenAcitvityDialog(true);
    setCreatingNewActivity(false);
  };

  const clearMessageOnClose = () => setError('');

  return (
    <Wrapper>
      <div ref={ref}>
        <Paper sx={{marginY: globalSpacing.s}}>
          <Stack spacing={globalSpacing.s} p={globalSpacing.m} mt={0}>
            <Box
              component="span"
              mt={0}
              display="flex"
              justifyContent="space-between"
              alignItems="center">
              <Typography variant="h6">Activity Details</Typography>
              <Button
                variant="contained"
                onClick={handleNewActivityClick}
                disabled={!selectedSRData?.id}>
                New Activity
              </Button>
            </Box>

            <Grid container spacing={globalSpacing.s}>
              <ActivityListView onRowClick={onRowClick} />
            </Grid>
          </Stack>
          <ActivityDetailViewDialog
            openAcitvityDialog={openAcitvityDialog}
            isOpenAcitvityDialog={isOpenAcitvityDialog}
            setActivity={onSubmit}
            activity={activity}
          />
        </Paper>
        <SnackBar
          isSnackBarOpen={isSnackBarOpen}
          closeSnackBar={closeSnackBar}
          errorMessage={error}
          clearMessageOnClose={clearMessageOnClose}
        />
      </div>
    </Wrapper>
  );
};

export default React.forwardRef(Activities);
