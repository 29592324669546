export const rightPanelData = [
  {
    key: 'externalRefId',
    value: 'External Ref#',
  },
  {
    key: 'memberNumber',
    value: 'Member#',
  },
  {
    key: 'status',
    value: 'Status',
  },
  {
    key: 'groupType',
    value: 'Group Type',
  },
  {
    key: 'memberType', //outer Obejct
    value: 'Member Type',
  },
  {
    key: 'memberId',
    value: 'Member GUiD',
  },
  {
    key: 'cancelReason',
    value: 'Cancel Reason',
  },
  {
    key: 'reinstate', //Not available
    value: 'Reinstate Reason',
  },
  {
    key: 'isPrimary',
    value: 'Primary',
    type: 'checkbox',
  },
  {
    key: 'namePrefix',
    value: 'Title',
  },
  {
    key: 'firstName',
    value: 'First Name',
  },
  {
    key: 'middleName',
    value: 'Middle Name',
  },
  {
    key: 'lastName',
    value: 'Last Name',
  },
  {
    key: 'addressLine1',
    value: 'Address 1',
  },
  {
    key: 'addressLine2',
    value: 'Address 2',
  },
  {
    key: 'addressLine3',
    value: 'Address 3',
  },
  {
    key: 'city',
    value: 'City',
  },
  {
    key: 'postalCode',
    value: 'Post Code',
  },
  {
    key: 'county',
    value: 'County',
  },
  {
    key: 'state',
    value: 'State',
  },
  {
    key: 'country',
    value: 'Country',
  },
  {
    key: 'homePhone',
    value: 'Home Phone',
  },
  {
    key: 'businessPhone',
    value: 'Bus. Phone',
  },
  {
    key: 'mobilePhone',
    value: 'Mobile Phone',
  },
  {
    key: 'dateOfBirth',
    value: 'D.O.B',
  },
  {
    key: 'externalemail', //Not available
    value: 'Ext Email',
  },
  {
    key: 'clientEmail',
    value: 'Client Email',
  },
  {
    key: 'primaryEmail',
    value: 'Primary Email',
  },
  {
    key: 'placeOfBirth',
    value: 'Place Of Birth',
  },
  {
    key: 'nameSuffix',
    value: 'Suffix',
  },
  {
    key: 'gender',
    value: 'Gender',
  },
  {
    key: 'relationship',
    value: 'Relationship',
  },
  {
    key: 'preferredLanguage',
    value: 'Preferred Language',
  },
  {
    key: 'salutation',
    value: 'Salutation',
  },
  {
    key: 'mailName1',
    value: 'Mail Name 1',
  },
  {
    key: 'mailName2',
    value: 'Mail Name 2',
  },
  {
    key: 'mailName3',
    value: 'Mail Name 3',
  },
  {
    key: 'suppressedCorresspondence.marketingMailSuppressed',
    value: 'Marketing Mail',
    type: 'checkbox',
  },
  {
    key: 'suppressedCorresspondence.marketingEmailSuppressed',
    value: 'Marketing Email',
    type: 'checkbox',
  },
  {
    key: 'suppressedCorresspondence.marketingSmsSuppressed',
    value: 'Marketing SMS',
    type: 'checkbox',
  },
  {
    key: 'suppressedCorresspondence.marketingCallsSuppressed',
    value: 'Marketing Calls',
    type: 'checkbox',
  },
  {
    key: 'suppressedCorresspondence.dataSuppressed',
    value: 'Suppress Data',
    type: 'checkbox',
  },
  {
    key: 'suppressedCorresspondence.ntpsuppressed',
    value: 'Suppress NTP',
    type: 'checkbox',
  },
  {
    key: 'suppressedCorresspondence.suppressStartDate',
    value: 'Suppress All Start Date',
  },
  {
    key: 'suppressedCorresspondence.suppressEndDate',
    value: 'Suppress All End Date',
  },
  {
    key: 'noOnlineAccess',
    value: 'No Online Access',
    type: 'checkbox',
  },
  {
    key: 'restrictedMember',
    value: 'Restricted Member',
    type: 'checkbox',
  },
  {
    key: 'suppressedCorresspondence.onboardingEmailSuppressed',
    value: 'Onboarding Email',
    type: 'checkbox',
  },
  {
    key: 'goneAwayStartDate',
    value: 'Gone Away Start Date',
  },
  {
    key: 'password1',
    value: 'Password 1',
  },
  {
    key: 'password2',
    value: 'Password 2',
  },
  {
    key: 'password3',
    value: 'Password 3',
  },
  {
    key: 'fiscalCode',
    value: 'Fiscal Code/SSN',
  },
  {
    key: 'externalRef1',
    value: 'Ext Ref. 1',
  },
  {
    key: 'externalRef2',
    value: 'Ext Ref. 2',
  },
  {
    key: 'vendorExt',
    value: 'Vendor Ext. lc',
  },
  {
    key: 'companyName',
    value: 'Company Name',
  },
  {
    key: 'directDebit',
    value: 'Direct Debit',
    type: 'checkbox',
  },
  {
    key: 'travelInsurance',
    value: 'Travel Insurance',
    type: 'checkbox',
  },
  {
    key: 'emergencyCB',
    value: 'Emergency CB',
    type: 'checkbox',
  },
  {
    key: 'specialVIP',
    value: 'Special VIP',
    type: 'checkbox',
  },
  {
    key: 'sms',
    value: 'SMS',
  },
  {
    key: 'smsService',
    value: 'SMS Service',
  },
  {
    key: 'travelBenefit',
    value: 'Travel Benefit',
  },
  {
    key: 'foreignHealthIns',
    value: 'Foreign Health Ins.',
  },
  {
    key: 'publicTransport',
    value: 'Public Transport',
  },
  {
    key: 'eventAlertPref',
    value: 'Event Alert Pref.',
  },
  {
    key: 'enrollmentDate',
    value: 'Date Created',
  },
  {
    key: 'createdBy',
    value: 'Created By',
  },
  {
    key: 'editProfile',
    value: 'Edit Profile',
  },
];
