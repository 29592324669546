import {globalConfig} from '../../Config/config';
import {Queries, Mutations} from '../GQLConstants';
import {gqlCall, GQLType} from '../utils';

export const gqlGetGMGiftingCards = async (payload, headers) => {
  const {cxRewardsEndpoint} = globalConfig._config;
  try {
    const response = await gqlCall(
      GQLType.QUERY,
      Queries.GET_FILTERED_GMGIFTCARDS,
      payload,
      headers,
      cxRewardsEndpoint.graphqlCxRewardsEndpoint,
      false,
    );
    return response;
  } catch (error) {
    return {error};
  }
};

export const gqlGetMemberRewardsInfo = async (payload, headers) => {
  const {cxRewardsEndpoint} = globalConfig._config;
  try {
    const response = await gqlCall(
      GQLType.QUERY,
      Queries.GET_MEMBER_REWARDS_INFO,
      payload,
      headers,
      cxRewardsEndpoint.graphqlCxRewardsEndpoint,
      false,
    );
    return response;
  } catch (error) {
    return {error};
  }
};

export const gqlBuyGiftCard = async (payload, headers) => {
  const {cxRewardsEndpoint} = globalConfig._config;
  try {
    const response = await gqlCall(
      GQLType.MUTATION,
      Mutations.BUY_GIFT_CARD,
      payload,
      headers,
      cxRewardsEndpoint.graphqlCxRewardsEndpoint,
      false,
    );
    return response;
  } catch (error) {
    return {error};
  }
};
