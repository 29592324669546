import React from 'react';
import {Paper, Table, TableBody, TableContainer} from '@mui/material';
import CustomRow from './CustomRow';
import styles from './styles';
import HeaderRow from './HeaderRow';
import {SR_DASHBOARD} from 'Pages/SRDashboard/srDashboardConstants';

interface IProps {
  SRList: any;
}

const ResultsTable: React.FC<IProps> = (props) => {
  const {SRList} = props;
  return (
    <TableContainer component={Paper} sx={styles.paper}>
      <Table>
        <HeaderRow headers={SR_DASHBOARD.result_row} />
        <TableBody>
          {SRList.map((row, index) => (
            <CustomRow key={index} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ResultsTable;
