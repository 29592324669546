import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import globalSpacing from 'Theme/Spacing';
import styles from './styles';

interface IProps {
  isOpen: boolean;
  toggleDialog(readyToSubmit: boolean): any;
  text: any;
}

const PersonalDataDialog: React.FC<IProps> = (props) => {
  const {isOpen, toggleDialog, text} = props;
  return (
    <Dialog sx={styles.dialog} open={isOpen}>
      <DialogContent sx={styles.content}>
        <DialogTitle
          sx={{
            paddingTop: globalSpacing.md,
          }}>
          <Typography color="primary" variant="h3">
            {text.heading}
          </Typography>
        </DialogTitle>

        <DialogContentText sx={{paddingBottom: globalSpacing.md}}>
          <Typography variant="body1" sx={{color: useTheme().palette.text.primary}}>
            {text.description}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button onClick={() => toggleDialog(false)} sx={{width: '25%'}} variant="outlined">
          {text.button_text_true}
        </Button>
        <Button onClick={() => toggleDialog(true)} variant="contained" sx={{width: '25%'}}>
          {text.button_text_false}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PersonalDataDialog;
