import axios from 'axios';
import {globalConfig} from 'Config/config';
import {v4 as uuidv4} from 'uuid';

const login: any = async (userName, password) => {
  try {
    const response = await axios.post(
      globalConfig._config.BFF.loginBFF,
      {userName, password},
      {
        headers: {
          'tenant-id': 'internal', //Always internal
        },
      },
    );
    return JSON.stringify(response);
  } catch (error) {
    return {error};
  }
};

const loginWithSSO: any = async (token) => {
  try {
    const response = await axios.post(globalConfig._config.BFF.ssoLoginBFF, token, {
      headers: {
        'x-correlation-id': uuidv4(),
        'Content-Type': 'text/plain',
      },
    });
    return response;
  } catch (error) {
    return {error};
  }
};

const loginWithInternalSSO: any = async (token, tenantID) => {
  try {
    const response = await axios.post(globalConfig._config.BFF.internalSSOLoginBFF, token, {
      headers: {
        'x-correlation-id': uuidv4(),
        'Content-Type': 'application/JSON',
        'tenant-id': tenantID,
      },
    });

    return JSON.stringify(response);
  } catch (error) {
    return {error};
  }
};

const refreshToken = async (payload) => {
  try {
    const response = await axios.post(globalConfig._config.BFF.refreshBFF, payload, {
      headers: {
        'tenant-id': 'internal',
        'x-correlation-id': uuidv4(),
      },
    });

    return response;
  } catch (error) {
    return {error};
  }
};

const logout: any = async (refreshToken) => {
  try {
    const response = await axios.post(
      globalConfig._config.BFF.logoutBFF,
      {refreshToken},
      {
        headers: {
          'tenant-id': 'internal',
          'x-correlation-id': uuidv4(),
        },
      },
    );
    return response;
  } catch (error) {
    return {error};
  }
};

export {login, loginWithSSO, logout, refreshToken, loginWithInternalSSO};
