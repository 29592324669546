import {ComponentGroups, PageGroups} from 'Utils/enums/textTypes';

const Strings = {
  [ComponentGroups.ErrorBoundary]: {
    SomethingWentWrong: 'Something went wrong:',
  },
  [ComponentGroups.Footer]: {
    ByTenerity: (currentYear) => `&copy; ${currentYear} by Capillary Technologies.`,
  },
  [ComponentGroups.NoMemberData]: {
    PleaseSearchForMember: 'Please search for a Member',
  },
  [PageGroups.Dashboard]: {},
};

export const stringAscSort = (a, b) => {
  return a.localeCompare(b);
};

export default Strings;
