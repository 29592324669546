export const GIFTING = {
  chip: 'Give a gift',
  detail_view: {
    button_text: 'SEND TO CUSTOMER',
    heading1: 'Details',
    heading2: 'Terms and Conditions',
    back_button_text: 'Back to list',
  },
  gifting_dialog: {
    button_text: 'CONTINUE',
    email_label: 'Email Address',
    heading: 'Click "Continue" to send this offer.',
    subtitle: 'The offer will be sent to the email address below.',
  },
  gifting_dialog_error: {
    button_text: 'CLOSE',
    heading: 'Oops!',
    error_map: {
      MO1013: 'Sorry, Offer is not present now for claim!',
      MO1007: 'Offer is not found or invalid',
    },
  },
  gifting_dialog_success: {
    button_text: 'CLOSE',
    heading: 'You have sent this offer!',
    subtitle: 'Instructions on how to use the code have been sent to',
  },
  heading: 'Send an offer',
  subtitle: 'Select the offer you would like to send below.',
  title: 'Gifting Tab',
};
