import React from 'react';
import {TableCell} from '@mui/material';

interface IProps {
  children?: React.ReactNode;
  align?: any | 'center';
}

const CustomTableCell: React.FC<IProps> = (props) => {
  const {align, children} = props;
  return (
    <TableCell style={{fontFamily: 'regular'}} align={align}>
      {children}
    </TableCell>
  );
};

export default CustomTableCell;
