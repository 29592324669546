import React, {useState} from 'react';
import {ErrorMessage, Field} from 'formik';
import {Grid, TextField, useTheme} from '@mui/material';
import globalSpacing from 'Theme/Spacing';

const TextBox = (props) => {
  const {formikProp} = props;
  const theme = useTheme();
  const textField = props.field;
  const customEvents: any = props.customEvents
    ? props.customEvents[textField.name] && props.customEvents[textField.name]
    : {};
  const [isFormInvalid, setIsFormInvalid] = useState(false);

  return (
    <>
      <Grid
        item
        xs={textField.xs}
        sm={textField.sm}
        p={globalSpacing.xs}
        sx={{display: textField.display}}>
        <Field
          as={TextField}
          // variant={textField.variant ?? 'outlined'}
          sx={{
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.08)',
            borderRadius: `${theme.shape.borderRadius}px`,
            marginRight: globalSpacing.s,
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused': {
                '& fieldset': {
                  borderColor: theme.palette.action.active,
                },
              },
            },
            '& .MuiInputLabel-root': {
              color: theme.palette.text.primary,
            },
            '& .MuiFormControlLabel-root': {
              marginLeft: '0px',
            },
            '& .MuiFormHelperText-root': {
              color: theme.palette.error.main,
            },
            '& .MuiFormLabel-asterisk': {
              color: theme.palette.error.main,
              fontSize: '24px',
            },
            '& .Mui-disabled': {
              color: theme.palette.text.disabled,
            },
          }}
          label={textField.fieldLabel}
          type={textField.htmlInputType}
          disabled={textField.isDisabled ?? false}
          InputLabelProps={{
            shrink: true,
            sx: {
              fontSize: 17,
              fontWeight: 'bold',
              color: theme.palette.text.primary,
              '&.MuiInputLabel-shrink.Mui-disabled': {
                color: theme.palette.text.disabled,
              },
            },
          }}
          inputProps={{
            readOnly: textField?.readonly,
          }}
          rows={textField.rows}
          multiline={textField.multiline}
          name={textField.name}
          fullWidth
          size="small"
          required={textField.required}
          error={textField.required ? isFormInvalid : false}
          helperText={textField.note}
          hidden={textField.hidden}
          onChange={(e) => {
            setIsFormInvalid(e.target.value.length <= 0 ? true : false);
            formikProp.setFieldValue(textField.name, e.target.value);
            customEvents &&
              customEvents.onChange &&
              customEvents['onChange'](e, formikProp.setFieldValue);
          }}
          onClick={(e) => {
            setIsFormInvalid(e.target.value.length <= 0 ? true : false);
            customEvents &&
              customEvents.onClick &&
              customEvents['onClick'](e, formikProp.setFieldValue, formikProp.values);
          }}
        />
        <ErrorMessage name={textField.name} component="div" />
      </Grid>
    </>
  );
};

export default TextBox;
