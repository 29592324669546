import {createSlice, createAsyncThunk, createEntityAdapter} from '@reduxjs/toolkit'; //createSelector,
import {gqlSearchSRByID} from 'GQL/MethodsBFF/ServiceRequest';

interface SelectedSRDetails {
  id: string;
  getServiceRequestById: any;
}

const selectedSRAdapter: any = createEntityAdapter<SelectedSRDetails>({
  //selectId: (member: Members) => member.memberId,
});

const initialState = selectedSRAdapter.getInitialState({
  status: 'idle',
  error: '',
  getServiceRequestById: null,
});

export const searchSRByID: any = createAsyncThunk(
  'serviceRequest/searchSRByID',
  async (data: any, {getState}) => {
    const state: any = getState();
    const headers = {
      'tenant-id': state?.login.tenantID,
      'user-access-token': state?.login.loginInfo?.access_token,
      'client-access-token': state?.login.loginInfo?.service_token,
    };
    return await gqlSearchSRByID(data, headers);
  },
);

const selectedSR = createSlice({
  name: 'selectedSR',
  initialState,
  reducers: {
    resetSelectedSRData: (state) => {
      Object.assign(state, initialState);
    },
    setSelectedSRData: (state, action) => {
      state.getServiceRequestById = action?.payload?.data?.getServiceRequestById || null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(searchSRByID.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(searchSRByID.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.getServiceRequestById = action?.payload?.data?.getServiceRequestById || null;
      })
      .addCase(searchSRByID.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default selectedSR.reducer;

export const {resetSelectedSRData, setSelectedSRData} = selectedSR.actions;
