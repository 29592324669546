import React, {useEffect, useState} from 'react';
import {Field} from 'formik';
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import globalSpacing from 'Theme/Spacing';

const CustomCheckbox = (props) => {
  const {formikProp} = props;
  const checkBoxField = props.field;
  const [checked, setChecked] = useState(props.formikProp.values[checkBoxField.name]);
  const customEvents: any = props.customEvents
    ? props.customEvents[checkBoxField.name] && props.customEvents[checkBoxField.name]
    : {};
  const theme = useTheme();

  useEffect(() => {
    setChecked(props.formikProp.values[checkBoxField.name]);
  }, [props.formikProp.values[checkBoxField.name]]);

  return (
    <>
      <Grid item xs={checkBoxField.xs} sm={checkBoxField.sm} p={globalSpacing.xs}>
        <Field
          name={checkBoxField.name}
          disabled={checkBoxField?.isDisabled || false}
          required={checkBoxField.required}
          color="primary"
          sx={{
            '& .MuiCheckbox-root': {
              color: theme.palette.primary.dark,
            },
            '& .MuiFormControlLabel-root .MuiFormControlLabel-label.Mui-disabled': {
              color: checkBoxField?.highLight
                ? theme.palette.warning.dark
                : theme.palette.text.disabled,
            },
            '& .Mui-disabled': {
              color: checkBoxField?.highLight
                ? theme.palette.warning.dark
                : theme.palette.text.disabled,
            },
            '& .MuiFormControlLabel-asterisk': {
              color: theme.palette.error.main,
              fontSize: '24px',
            },
          }}
          as={FormControlLabel}
          control={<Checkbox />}
          label={checkBoxField.fieldLabel}
          checked={checked}
          onChange={(e) => {
            formikProp.setFieldValue(checkBoxField.name, e.target.checked);
            setChecked(e.target.checked);
            customEvents &&
              customEvents.onChange &&
              customEvents['onChange'](e, formikProp.setFieldValue);
          }}
        />
        {checked && checkBoxField.note ? (
          <FormHelperText color="primary">
            <Typography variant="body1" color="error">
              {checkBoxField.note}
            </Typography>
          </FormHelperText>
        ) : (
          ''
        )}
      </Grid>
    </>
  );
};

export default CustomCheckbox;
