import {globalConfig} from 'Config/config';
import {agentTokenRefresh} from 'Redux/Slices/login';

let handleRefreshInterval;

export const startTokenRefreshTimer = (loginInfo, store, navigate) => {
  let bufferTimer = 3 * 60;
  handleRefreshInterval = setInterval(async () => {
    try {
      let response = store.dispatch(agentTokenRefresh()).unwrap();
      if (response.error || response.errors) {
        stopTokenRefreshTimer();
        sessionStorage.clear();
        localStorage.clear();
        window.location.replace(
          `${globalConfig._config.internalSSOConfig.REACT_APP_KEYCLOAK_ENDPOINT}/realms/${globalConfig._config.internalSSOConfig.REACT_APP_REALM}/protocol/openid-connect/logout?redirect_uri=${window.location.href}`,
        );
      }
    } catch (error) {
      stopTokenRefreshTimer();
      sessionStorage.clear();
      localStorage.clear();
      window.location.replace(
        `${globalConfig._config.internalSSOConfig.REACT_APP_KEYCLOAK_ENDPOINT}/realms/${globalConfig._config.internalSSOConfig.REACT_APP_REALM}/protocol/openid-connect/logout?redirect_uri=${window.location.href}`,
      );
    }
  }, (loginInfo?.expires_in - bufferTimer) * 1000);
};

export const stopTokenRefreshTimer = () => {
  clearInterval(handleRefreshInterval);
};
