import {Box, Divider, Typography} from '@mui/material';
import React from 'react';
import globalSpacing from 'Theme/Spacing';

interface IProps {
  label: string;
  value?: string;
}
const DetailListItem: React.FC<IProps> = (props) => {
  const {label, value = 'Not available'} = props;
  return (
    <Box sx={styles.container}>
      <Box sx={styles.itemContainer} bgcolor="primary.light">
        <Typography variant="subtitle1" component="h3">
          {label}
        </Typography>
      </Box>
      <Divider orientation="vertical" light />
      <Typography variant="body1" sx={styles.valueContainer}>
        {value}
      </Typography>
    </Box>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  itemContainer: {
    display: 'flex',
    flex: 0.5,
    paddingY: globalSpacing.s,
    paddingX: globalSpacing.m,
  },
  valueContainer: {display: 'flex', flex: 2, marginLeft: globalSpacing.lg},
};

export default DetailListItem;
