import {SortOrder} from 'Utils/enums';
import {parseData} from 'Utils/helpers/SRListHelpers';
export const getCinemaeCodeGridData = (
  tableData,
  sortOrder = SortOrder.DESCENDING,
  currentSortCriteria,
  isSortingEnabled = true,
) => {
  const header = [
    {label: 'Code', value: 'ecode'},
    {label: 'Description', value: 'description'},
    {label: 'Expiry Date', value: 'expiryDate'},
    {label: 'Price', value: 'price'},
    {label: 'IblCodeOnline', value: 'ecodeOnline'},
  ];

  return parseData(header, tableData, sortOrder, currentSortCriteria, isSortingEnabled);
};
