import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import AdminDetailListItem from './components/AdminDetailListItem';
import styles from './styles';
import {Box, Paper} from '@mui/material';
import {rightPanelData} from './item';
import {getAddressGridData} from './AddressListGridDataSource';
import TableView from 'Components/TableView';
import {sortTable} from 'Utils/helpers/SRListHelpers';
import {SortOrder} from 'Utils/enums';

const TabAdminProfile = () => {
  const member = useSelector((state: any) => state?.selectedMember?.selectedMember);
  const tenantConfig = useSelector((state: any) => state?.login?.tenantConfig);
  const [currentSortCriteria, setCriteriaSort] = useState('endDate');
  const [tableData, setTableData] = useState<any[]>([]);
  const [sortOrder, setSortOrder] = useState<any>();

  const onRowClick = () => {};
  const onSortIconClick = (sortCriteria: string) => {
    let resp = sortTable(
      tableData,
      sortCriteria,
      currentSortCriteria,
      sortOrder,
      tenantConfig.locale,
    );
    setCriteriaSort(resp.currentSortCriteria);
    setTableData(resp.tableData || []);
    setSortOrder(resp.newSortOrder);
  };

  useEffect(() => {
    let currentMember = member && member.currentMemberDetails;
    let previousAddressData = currentMember && currentMember.previousAddresses;
    if (Array.isArray(previousAddressData)) {
      let resp = sortTable(
        previousAddressData,
        'endDate',
        currentSortCriteria,
        SortOrder.ASCENDING,
        tenantConfig.locale,
      );
      setCriteriaSort(resp.currentSortCriteria);
      setTableData(resp.tableData);
      setSortOrder(resp.newSortOrder);
    } else {
      setTableData([]);
      setCriteriaSort('');
    }
  }, [member]);

  const getValueFromKey = (object, key) => {
    return key.split('.').reduce((o, i) => o[i], object);
  };

  return (
    <div>
      <Paper sx={styles.paper}>
        <Box sx={styles.gridConatiner}>
          {rightPanelData.map((item, index) => {
            let data = getValueFromKey(member?.currentMemberDetails, item?.key);
            if (data) {
              return (
                <Box key={item.key} sx={styles.gridItem}>
                  <AdminDetailListItem label={item.value} value={data} type={item?.type} />
                </Box>
              );
            } else {
              return (
                <Box key={item.key} sx={styles.gridItem}>
                  <AdminDetailListItem
                    label={item.value}
                    value={member?.[item?.key] ?? ''}
                    type={item?.type}
                  />
                </Box>
              );
            }
          })}
        </Box>
      </Paper>
      <Box>
        <TableView
          {...getAddressGridData(tableData, SortOrder.DESCENDING, {}, false)}
          onRowClick={onRowClick}
          sortTable={onSortIconClick}
        />
      </Box>
    </div>
  );
};

export default TabAdminProfile;
