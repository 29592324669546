const globalSpacing = {
  xxs: '0.25rem',
  xs: '0.5rem',
  s: '1rem',
  sm: '1.5rem',
  m: '2rem',
  md: '2rem',
  l: '3rem',
  lg: '3rem',
};

export default globalSpacing;
