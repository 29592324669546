import {SR_TABS} from 'Utils/constants/genericConstants';
import {SRPageSize} from 'Utils/enums';
import {getCurrentSubscription} from './MemberDetails';

export const searchSRByTypePayload = (memberDetails, type) => {
  let payload = {
    searchServiceRequestByTypeInput: {
      memberId: memberDetails?.currentMemberDetails?.memberId,
      membershipId: memberDetails?.membershipId,
      ownerId: '',
      type: type,
      subType: '',
      status: [],
      createdBy: '',
      membershipSubscriptionDate:
        getCurrentSubscription(memberDetails?.subscriptions)?.startDate || null,
      paging: {
        pageNumber: 0,
        pageSize: SRPageSize[type.toUpperCase()],
        orderBy: 'id',
        sortOrder: 'descending',
      },
      dateRange: {
        startDate: getCurrentSubscription(memberDetails?.subscriptions)?.startDate || null,
        endDate: getCurrentSubscription(memberDetails?.subscriptions)?.endDate || null,
      },
    },
  };
  if (type === SR_TABS.ARCHIVED) {
    payload.searchServiceRequestByTypeInput.dateRange = {
      startDate: null,
      endDate: null,
    };
  }

  return payload;
};
