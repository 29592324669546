import React from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {LOGIN} from 'Utils/constants/Paths';

const RequireAuth = ({children}) => {
  const location = useLocation();
  const accessT = useSelector((state: any) => state?.login.loginInfo?.access_token);

  if (!accessT) {
    return <Navigate to={LOGIN} state={{from: location}} replace />;
  }

  return children;
};

export default RequireAuth;
