import React, {useEffect, useState} from 'react';
import {Box, Button, Grid, Paper, Stack, Typography} from '@mui/material';
import globalSpacing from 'Theme/Spacing';
import PaymentListView from './PaymentListView';
import PaymentDetailViewDialog from './PaymentDetailViewDialog';
import {useSelector} from 'react-redux';
import {SR_ACCEPTING_PAYMENTS} from 'Utils/constants/genericConstants';
import {Wrapper} from 'Components';

const Payment = (props, ref) => {
  const [openPaymentDetailViewDialog, isOenPaymentDetailViewDialog] = useState(false);
  const [disableMemberPayment, setDisableMemberPayment] = useState(true);
  const selectedSRData = useSelector((state: any) => state.selectedSR.getServiceRequestById);

  const [selectedPayment, setSelectedPayment]: any = useState({});

  useEffect(() => {
    setMemberPaymentButtonStatus();
  }, [selectedSRData]);

  const handlePaymentRowClick = (data) => {
    setSelectedPayment(data);
    isOenPaymentDetailViewDialog(true);
  };

  const handleNewPaymentClick = async () => {
    setSelectedPayment({});
    isOenPaymentDetailViewDialog(true);
  };

  const setMemberPaymentButtonStatus = () => {
    if (SR_ACCEPTING_PAYMENTS.includes(selectedSRData?.type)) setDisableMemberPayment(false);
    else setDisableMemberPayment(true);
  };

  return (
    <Wrapper>
      <div ref={ref}>
        <Paper sx={{marginY: globalSpacing.s}}>
          <Stack spacing={globalSpacing.s} p={globalSpacing.m} mt={0}>
            <Box
              component="span"
              mt={0}
              mb={0}
              display="flex"
              justifyContent="space-between"
              alignItems="center">
              <Typography variant="h6">Payment Details</Typography>
              <Button
                variant="contained"
                onClick={handleNewPaymentClick}
                disabled={disableMemberPayment}
                sx={{
                  paddingY: globalSpacing.xxs,
                  marginY: globalSpacing.sm,
                  width: '15%',
                }}>
                New Payment
              </Button>
            </Box>
            <Grid container spacing={globalSpacing.xxs} mt={0}>
              <PaymentListView handlePaymentRowClick={handlePaymentRowClick} />
            </Grid>
            <PaymentDetailViewDialog
              openPaymentDetailViewDialog={openPaymentDetailViewDialog}
              isOenPaymentDetailViewDialog={isOenPaymentDetailViewDialog}
              selectedPayment={selectedPayment}
            />
          </Stack>
        </Paper>
      </div>
    </Wrapper>
  );
};

export default React.forwardRef(Payment);
