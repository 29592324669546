import {Box, Typography, useTheme} from '@mui/material';
import React from 'react';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const theme = useTheme();
  return (
    <Box
      bgcolor={theme.palette.secondary.main}
      display="flex"
      height={50}
      justifyContent="center"
      alignItems="center">
      <Typography data-testid="footer-text" variant="caption">
        &copy; {currentYear} by Capillary Technologies.
      </Typography>
    </Box>
  );
};

export default Footer;
