export const SS = {
  MEMBER: 'member',
  SEARCH: 'search',
  IFRAME: 'iFrame',
  TAB: 'activeTab',
  MULTIPLE_SEARCH: 'multipleSearchResults',
  ACCESS_T: 'access_token',
  REFRESH_T: 'refresh_token',
  SERVICE_T: 'service_token',
  AGENT: 'agent',
};
