import {IconButton, TableRow} from '@mui/material';
import React, {Fragment, useState} from 'react';
import CustomTableCell from './CustomTableCell';
import CommentsDialog from './CommentsDialog';
import {useCSContext} from 'Context/ContentStackContext';
import {format, isDate} from 'date-fns';
import {DATE_FORMAT} from 'Utils/constants/DateFormat';

interface IProps {
  row: any;
}

const CustomRow: React.FC<IProps> = (props) => {
  const {row} = props;
  const {artifacts} = useCSContext();

  const [openCommentsDialog, setOpenCommentsDialog] = useState(false);
  return (
    <Fragment>
      <TableRow
        hover={true}
        sx={{
          '&:last-child td, &:last-child th': {border: 0},
          '&.MuiTableRow-hover:hover': {backgroundColor: 'primary.light'},
        }}>
        <CustomTableCell align="left">{row.memberId}</CustomTableCell>
        <CustomTableCell>{row.ticketNumber}</CustomTableCell>
        <CustomTableCell>{row.type}</CustomTableCell>
        <CustomTableCell>{row.reason}</CustomTableCell>
        <CustomTableCell>
          {row.createdOn &&
            isDate(new Date(row.createdOn)) &&
            format(new Date(row.createdOn), DATE_FORMAT.dateFormat)}
        </CustomTableCell>
        <CustomTableCell>{row.status}</CustomTableCell>
        <CustomTableCell>
          {row.comments && (
            <IconButton size="small" onClick={() => setOpenCommentsDialog(true)}>
              <img src={artifacts.more_icon} color="primary" />
            </IconButton>
          )}
        </CustomTableCell>
      </TableRow>
      {row.comments && (
        <CommentsDialog
          openCommentsDialog={openCommentsDialog}
          setOpenCommentsDialog={setOpenCommentsDialog}
          comments={row.comments}
        />
      )}
    </Fragment>
  );
};

export default CustomRow;
