import {useEffect, useState} from 'react';

const useSnackBar = (error) => {
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const closeSnackBar = () => setIsSnackBarOpen(false);
  useEffect(() => {
    if (error) {
      setIsSnackBarOpen(true);
    }
  }, [error]);

  return [isSnackBarOpen, closeSnackBar];
};

export default useSnackBar;
