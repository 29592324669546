import globalSpacing from '../../../Theme/Spacing';

const styles = {
  container: {
    flexDirection: 'column',
    display: 'flex',
  },
  paper: {
    marginTop: globalSpacing.sm,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
  },
};

export default styles;
