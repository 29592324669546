export const TRANSACTIONS = {
  card_field_map: {
    claimDate: 'Claim Date:',
    expiryDate: 'Expiry Date:',
    offerEndDate: 'Offer End Date:',
    type: 'Type:',
    status: 'Status:',
  },
  date_search_label: 'Search by Claim Date',
  detail_view: {
    field_map: {
      offerType: 'Type',
      transactionDate: 'Transaction Date',
      claimDate: 'Claim Date',
      clickDate: 'Click Date',
      expireDate: 'Expiry Date',
      offerStartDate: 'Offer Start Date',
      offerEndDate: 'Offer End Date',
      typeRedeem: 'Type',
      channel: 'Channel',
      merchantName: 'Merchant',
      rebate: 'Rebate',
      transactionStatus: 'Status',
      userClaimCount: 'User Claim Count',
      claimLimit: 'Claim Limit',
    },
    fields_heading: 'Fields',
    tnc_button_title: 'Offers Terms and Conditions',
  },
  filter: ['Show All'],
  filter_label: 'Filter By',
  heading: 'Transaction History',
  no_results_text: 'There are no transactions to show for this customer',
  subtitle: 'Includes Cashback, CLO & Offers history Up to 6 Months.',
  title: 'Transactions Tab',
};
