import {Typography} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from '../Loading';
import globalSpacing from '../../Theme/Spacing';

const InfiniteScrollerCustom = ({hasMore, dataLength, next, children}) => {
  return (
    <InfiniteScroll
      style={{overflow: 'visible'}}
      dataLength={dataLength}
      next={() => next()}
      endMessage={
        <Typography textAlign={'center'} p={globalSpacing.l} variant="body2">
          No more items to display
        </Typography>
      }
      hasMore={hasMore}
      loader={<Loading />}>
      {children}
    </InfiniteScroll>
  );
};

InfiniteScrollerCustom.propTypes = {
  hasMore: PropTypes.bool.isRequired,
  dataLength: PropTypes.number.isRequired,
  next: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default InfiniteScrollerCustom;
