import React from 'react';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers';
import {Grid, TextField, useTheme} from '@mui/material';
import globalSpacing from 'Theme/Spacing';
import {Field, useFormikContext, ErrorMessage} from 'formik';
import {useSelector} from 'react-redux';
import localeMap from 'Utils/helpers/LocaleMap';

export default function DatePickerBox(props) {
  const textField = props.field;
  const theme = useTheme();
  const {setFieldValue} = useFormikContext();
  const tenantConfig = useSelector((state: any) => state?.login?.tenantConfig);
  let inputProp = {
    disablePast: textField?.disablePast || false,
  };
  if (textField?.format) {
    inputProp['placeholder'] = textField?.format;
  }
  return (
    <Grid item xs={textField.xs} sm={textField.sm} p={globalSpacing.xs}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={localeMap[tenantConfig?.locale]}>
        <Field
          as={DatePicker}
          views={textField.views}
          label={textField?.fieldLabel}
          inputProps={inputProp}
          name={textField?.name}
          disabled={textField.isDisabled ?? false}
          onChange={(value) => setFieldValue(textField.name, value)}
          PopperProps={{
            sx: {
              '.MuiDayPicker-weekDayLabel': {
                color: theme.palette.text.primary,
              },
            },
          }}
          disablePast={textField?.disablePast}
          renderInput={(params) => (
            <TextField
              InputLabelProps={{
                shrink: true,
                sx: {
                  fontSize: 17,
                  fontWeight: 'bold',
                  color: theme.palette.text.primary,
                  '&.MuiInputLabel-shrink.Mui-disabled': {
                    color: theme.palette.text.disabled,
                  },
                },
              }}
              sx={{
                boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.08)',
                borderRadius: `${theme.shape.borderRadius}px`,
                marginRight: globalSpacing.s,
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused': {
                    '& fieldset': {
                      borderColor: theme.palette.action.active,
                    },
                  },
                },
                '& .MuiInputLabel-root': {
                  color: theme.palette.text.primary,
                },
                '&.MuiFormControlLabel-root': {
                  marginLeft: '0px',
                },
                '& .MuiFormLabel-asterisk': {
                  color: theme.palette.error.main,
                  fontSize: '24px',
                },
                '& .Mui-disabled': {
                  color: theme.palette.text.disabled,
                },
              }}
              size="small"
              {...params}
              fullWidth
              required={textField?.required}
            />
          )}
        />
      </LocalizationProvider>
      <ErrorMessage name={textField.name} component="div" />
    </Grid>
  );
}
