import * as Yup from 'yup';

export const validationSchema = Yup.object({
  eventDateTime: Yup.date().required(),
  eventCategory: Yup.string().required(),
  phoneNumber: Yup.string().required('Home phone is a required field'),
});

export const initialValues = {
  channel: '',
  subStatus: '',
  ticketProvider: '',
  deliveryType: '',
  cancellationReason: '',
  isEligibleForCashback: '',
  isLastCashbackBooking: '',
  isUseMailingAddress: true,
  phoneNumber: '',
  mobileNumber: '',
  alternatePhoneNumber: '',
  alternateEmail: '',
  comments: '',
  eventName: '',
  venueName: '',
  eventDateTime: null,
  eventCategory: '',
  bookingDate: null,
  seatDetails: '',
  bookingReference: '',
  postageFee: '',
  totalBookingFee: '',
  totalActualPrice: '',
  extraCashback: '',
  totalCashback: '',
  currency: '',
  totalPriceAfterCashback: '',
  extraCashbackPercentage: '',
  buttonAction: '',
  deliveryName: '',
  addressLineOne: '',
  addressLineTwo: '',
  addressLineThree: '',
  city: '',
  postalCode: '',
  county: '',
  country: '',
  state: '',
};
