import {parseData} from 'Utils/helpers/SRListHelpers';
import {dateConversionIsoToLocal} from 'Utils/helpers/DateValidation';
import {FORMAT_TYPE} from 'Utils/constants/DateFormat';
import {SortOrder} from 'Utils/enums';

export const getAddressGridData = (
  tableData,
  sortOrder = SortOrder.DESCENDING,
  currentSortCriteria,
  isSortingEnabled,
) => {
  let newTableData: any = [];
  (tableData || []).forEach((item) => {
    let row = {
      addressLine1: item.addressLine1,
      addressLine2: item.addressLine2,
      addressLine3: item.addressLine3 || '',
      city: item.city,
      postalCode: item.postalCode,
      country: item.country,
      addressMovedDate: '',
      endDate: item?.endDate ? dateConversionIsoToLocal(item?.endDate, FORMAT_TYPE.DATE_TIME) : '',
    };
    newTableData.push(row);
  });

  const header = [
    {label: 'Address1', value: 'addressLine1'},
    {label: 'Address2', value: 'addressLine2'},
    {label: 'Address3', value: 'addressLine3'},
    {label: 'City', value: 'city'},
    {label: 'Post Code', value: 'postalCode'},
    {label: 'Country', value: 'country'},
    {label: 'Address Moved Date', value: 'addressMovedDate'},
    {label: 'Address Changed Date', value: 'endDate'},
  ];

  return parseData(header, newTableData, sortOrder, currentSortCriteria, isSortingEnabled);
};
