import {Divider, OutlinedInput, Paper, Stack, Typography} from '@mui/material';
import {Box} from '@mui/system';
import React, {Fragment, useEffect, useState} from 'react';
import {
  CustomTextInput,
  Wrapper,
  CustomDatePicker,
  SnackBar,
  SimpleAlert,
  TabHeading,
} from 'Components';
import {useFormik} from 'formik';
import {useCSContext} from 'Context/ContentStackContext';
import PersonalDataDialog from './components/PersonalDataDialog';
import styles from './styles';
import globalSpacing from 'Theme/Spacing';
import {LoadingButton} from '@mui/lab';
import {gqlQuery, gqlUpdateQuery} from 'GQL/MethodsBFF/Query';
import SubmissionResult from './components/SubmissionResult';
import {useSnackBar} from 'Hooks';
import {createQueryValues, updateQueryValues} from 'Utils/helpers/QueryFormRequestMap';
import onlyDigitsValidation from 'Utils/helpers/OnlyDigitsValidation';
import {gqlSRHistory} from 'GQL/MethodsBFF/SRHistory';
import {useSelector} from 'react-redux';
import {DATE_FORMAT} from 'Utils/constants/DateFormat';
import {QUERY_FORM} from './queryFormConstants';
import {PERSONAL_DIALOG, QUERY_ALERT, QUERY_TICKET_ALERT} from 'Utils/constants/genericConstants';

const TabQueryForm = () => {
  const {artifacts} = useCSContext();
  const preferred_username = useSelector((state: any) => state?.login?.userName);
  const {queryOfferTypes} = useSelector((state: any) => state?.login?.tenantConfig);
  const queryList = queryOfferTypes.split(',').map((offer) => offer.trim());

  const {externalRefId, memberId, clientEmail, primaryEmail} = useSelector(
    (state: any) => state?.selectedMember?.selectedMember?.focusedMemberDetails,
  );
  const customerEmail = primaryEmail || clientEmail;

  const [typeMenuDisabled, setTypeMenuDisabled] = useState(true);

  const [errTicketN, setErrTicketN] = useState(false);
  const [showSubmissionResult, setShowSubmissionResult] = useState(false);
  const [srId, setSrId] = useState(null);
  const [haveYouEnteredPersonalDataDialog, setHaveYouEnteredPersonalDataDialog] = useState(false);
  const [requiredDialog, setRequiredDialog] = useState(false);

  const [error, setError] = useState(null);
  const [isSnackBarOpen, closeSnackBar] = useSnackBar(error);

  const toggleHaveYouEnteredPersonalData = (readyToSubmit) => {
    setHaveYouEnteredPersonalDataDialog(!haveYouEnteredPersonalDataDialog);
    formik.setSubmitting(readyToSubmit);
  };

  const toggleRequiredDialog = () => {
    setRequiredDialog(!requiredDialog);
  };

  const toggleErrTicketNDialog = () => {
    setErrTicketN(!errTicketN);
  };

  const resetFormValuesForFields = (e) => {
    formik.handleReset(e);
    //persist customer_email data
    formik.setFieldValue('customer_email', formik.values.customer_email);
    formik.handleChange(e);
  };

  const validate = (values) => {
    const errors: any = {};
    const errorM = 'Required field';
    !values.customer_email && (errors.customer_email = errorM);
    !values.type && (errors.type = errorM);
    if (queryFieldsByType) {
      queryFieldsByType.field?.map((field) => {
        !values[field.key] && (errors[field.key] = errorM);
      });
    }
    if (!Object.keys(errors).length) {
      toggleHaveYouEnteredPersonalData(true);
      formik?.setSubmitting(false);
    } else {
      formik?.setSubmitting(false);
      setRequiredDialog(true);
    }
    return errors;
  };

  const formik: any = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    validate,
    initialValues: {
      customer_email: customerEmail || '',
    },
    onSubmit: () => {},
  });

  const resetAfterSubmitting = () => {
    formik.resetForm();
    setShowSubmissionResult(false);
    setSrId(null);
  };

  const _handleResponse = (data, error) => {
    formik.setSubmitting(false);
    (error || !data) && setError(error?.message || 'Undefined error');
    if (data?.id) {
      data.id && setSrId(data.id);
      setShowSubmissionResult(true);
    } else if (data?.data) {
      setShowSubmissionResult(true);
    } else if (data?.errors) {
      data.errors[0]?.code === '404' && setErrTicketN(true);
    }
  };

  //submission starts after agent clicks 'no' to the last personal data dialog
  useEffect(() => {
    if (formik.isSubmitting) {
      if (formik.values.type.toLowerCase().includes('existing')) {
        (async () => {
          const srIdInfo = await gqlSRHistory({
            srSearchRequestInput: {srId: formik.values.ticketN, pageNumber: 0, pageSize: 1},
          });
          if (!srIdInfo?.data?.searchSR?.[0]?.responseList.length) {
            formik.setSubmitting(false);
            setErrTicketN(true);
            return;
          }
          const existingComments =
            srIdInfo?.data?.searchSR?.[0]?.responseList[0]?.additionalData?.comments;
          const body = updateQueryValues(
            formik.values,
            preferred_username,
            DATE_FORMAT.dateFormat,
            existingComments,
          );
          const {data, error} = await gqlUpdateQuery(body);
          _handleResponse(data?.updateServiceRequest?.[0], error);
        })();
      } else {
        (async () => {
          const body = createQueryValues(
            formik.values,
            memberId,
            externalRefId,
            preferred_username,
            DATE_FORMAT.dateFormat,
          );
          const {data, error} = await gqlQuery(body);
          _handleResponse(data?.createServiceRequest?.[0], error);
        })();
      }
    }
  }, [formik.isSubmitting]);

  useEffect(() => {
    if (queryList) {
      setTypeMenuDisabled(false);
    }
  }, [queryList]);

  //get all the fields related to selected query type
  const queryFieldsByType = QUERY_FORM.mapped_fields.find(
    (query) => query.title === formik.values?.type,
  );

  return (
    <Wrapper>
      <TabHeading data={QUERY_FORM} />
      <Paper sx={styles.paper}>
        {!showSubmissionResult ? (
          <Fragment>
            <Box bgcolor="primary.light" sx={styles.topFieldContainer}>
              <Typography variant="subtitle1" component="h3">
                {QUERY_FORM.customer_identifier}
              </Typography>
              <OutlinedInput
                value={formik.initialValues.customer_email}
                disabled
                sx={{display: 'flex', flex: 1}}
              />
            </Box>
            <Divider light />
            <Box sx={styles.form}>
              <Typography variant="h4">{QUERY_FORM.form_title}</Typography>
              <form style={styles.form}>
                <Stack spacing={globalSpacing.s} py={globalSpacing.sm}>
                  <CustomTextInput
                    value={formik.values?.type}
                    label={QUERY_FORM.query_type_select[0].value}
                    onChange={resetFormValuesForFields}
                    name={QUERY_FORM.query_type_select[0].key}
                    queryTypesMenu={queryList || ['Loading ...']}
                    disabled={formik.isSubmitting || typeMenuDisabled}
                  />
                  {formik.values?.type &&
                    queryFieldsByType?.field?.map((field, index) => {
                      if (field.key === 'transactionDate') {
                        return (
                          <CustomDatePicker
                            key={index}
                            label={field.value}
                            date={formik.values[field.key] || null}
                            handleChange={(searchDate) =>
                              formik.setFieldValue([field.key], searchDate)
                            }
                          />
                        );
                      } else {
                        return (
                          <CustomTextInput
                            key={index}
                            disabled={formik.isSubmitting}
                            label={field.value}
                            name={field.key}
                            onChange={
                              field.key === 'ticketN'
                                ? (e) => onlyDigitsValidation(e, formik.handleChange)
                                : formik.handleChange
                            }
                            reasonsMenu={queryFieldsByType.reason}
                            value={formik.values[field.key]}
                          />
                        );
                      }
                    })}
                </Stack>
                <LoadingButton
                  onClick={() => formik.handleSubmit()}
                  loading={formik.isSubmitting}
                  variant="contained"
                  sx={{width: '35%'}}>
                  {QUERY_FORM.button_text}
                </LoadingButton>
              </form>
            </Box>
          </Fragment>
        ) : (
          <SubmissionResult
            image={artifacts.check_circle_icon}
            data={QUERY_FORM.query_result}
            resetAfterSubmitting={resetAfterSubmitting}
            srId={srId}
          />
        )}
      </Paper>
      {error && (
        <SnackBar
          errorMessage={error}
          isSnackBarOpen={isSnackBarOpen}
          closeSnackBar={closeSnackBar}
        />
      )}
      <PersonalDataDialog
        isOpen={haveYouEnteredPersonalDataDialog}
        toggleDialog={toggleHaveYouEnteredPersonalData}
        text={PERSONAL_DIALOG}
      />
      <SimpleAlert isOpen={requiredDialog} toggleDialog={toggleRequiredDialog} text={QUERY_ALERT} />
      <SimpleAlert
        isOpen={errTicketN}
        toggleDialog={toggleErrTicketNDialog}
        text={QUERY_TICKET_ALERT}
      />
    </Wrapper>
  );
};

export default TabQueryForm;
