import {globalConfig} from '../../Config/config';
import {Queries} from '../GQLConstants';
import {gqlCall, GQLType} from '../utils';

/**
 * @param {object} variables {referenceId: string, pageSize: number, pageNo: number}
 * @description
 * referenceId is internal member reference
 */
const gqlTransactions = async (variables) => {
  try {
    const data = await gqlCall(
      GQLType.QUERY,
      Queries.GET_TRANSACTIONS,
      variables,
      null,
      globalConfig._config.BFF.astBFF,
      false,
    );
    return data;
  } catch (error) {
    return {error};
  }
};
/**
 * @param {object} variables {referenceId: string, claimDate: string, pageSize: number, pageNo: number}
 * @description
 * referenceId is internal member reference
 * claimDate: '2021-09-13T22:38:43Z'
 */
const gqlTransactionsByDate = async (variables) => {
  try {
    const {data} = await gqlCall(
      GQLType.QUERY,
      Queries.GET_TRANSACTIONS_DATE,
      variables,
      null,
      globalConfig._config.BFF.astBFF,
      false,
    );
    return data;
  } catch (error) {
    return {error};
  }
};

/**
 * @param {object} variables {referenceId: string, offerType: string, pageSize: number, pageNo: number}
 * @description
 * referenceId is internal member reference
 */
const gqlTransactionsByType = async (variables) => {
  try {
    const {data} = await gqlCall(
      GQLType.QUERY,
      Queries.GET_TRANSACTIONS_TYPE,
      variables,
      null,
      globalConfig._config.BFF.astBFF,
      false,
    );
    return data;
  } catch (error) {
    return {error};
  }
};

/**
 * @param {object} variables {referenceId: string, claimDate: string, offerType: 'string', pageSize: number, pageNo: number}
 * @description
 * claimDate: '2021-09-13T22:38:43Z'
 */
const gqlTransactionsByDateAndType = async (variables) => {
  try {
    const {data} = await gqlCall(
      GQLType.QUERY,
      Queries.GET_TRANSACTIONS_DATE_TYPE,
      variables,
      null,
      globalConfig._config.BFF.astBFF,
      false,
    );
    return data;
  } catch (error) {
    return {error};
  }
};

/**
 * @param {object} variables {referenceId: string, offerId: string}
 * @description
 * claimDate: '2021-09-13T22:38:43Z'
 */
const gqlTransactionDetails = async (variables) => {
  try {
    const {data} = await gqlCall(
      GQLType.QUERY,
      Queries.GET_TRANSACTION_DETAILS,
      variables,
      null,
      globalConfig._config.BFF.astBFF,
      false,
    );
    return data;
  } catch (error) {
    return {error};
  }
};

export const api = {
  gqlTransactions,
  gqlTransactionsByDate,
  gqlTransactionsByType,
  gqlTransactionsByDateAndType,
  gqlTransactionDetails,
};
