import {createSlice, createAsyncThunk, createEntityAdapter} from '@reduxjs/toolkit'; //createSelector,
import {gqlAdminSRDashboardSearch} from 'GQL/MethodsBFF/ServiceRequest';

interface SRList {
  id: any;
}

const srLitAdapter: any = createEntityAdapter<SRList>({
  selectId: (srData: SRList) => srData.id,
});

const initialState = srLitAdapter.getInitialState({
  status: 'idle',
  error: '',
});

export const srDashboardSearch: any = createAsyncThunk(
  'serviceRequest/srDashboardSearch',
  async (data: any, {getState}) => {
    const state: any = getState();
    const headers = {
      'tenant-id': state?.login.tenantID,
      'user-access-token': state?.login.loginInfo?.access_token,
      'client-access-token': state?.login.loginInfo?.service_token,
    };
    return await gqlAdminSRDashboardSearch(data, headers);
  },
);

const srDashboardSearchSlice = createSlice({
  name: 'srDashboardSearch',
  initialState,
  reducers: {
    resetSRSearchData: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(srDashboardSearch.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(srDashboardSearch.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (action?.payload?.data) {
          srLitAdapter.setAll(state, action?.payload?.data?.searchMember?.memberDetails || {});
        }
      })
      .addCase(srDashboardSearch.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default srDashboardSearchSlice.reducer;

export const {selectAll: selectAllMember} = srLitAdapter.getSelectors((state) => state.member);

export const {resetSRSearchData} = srDashboardSearchSlice.actions;
