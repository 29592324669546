/* eslint-disable no-unused-vars */
import React, {createContext, useContext} from 'react';
import {loginWithSSO} from 'Api/Login';
import {useSessionStorage} from 'Hooks';
import PropTypes from 'prop-types';
import {SS} from 'Utils/constants/SessionStorage';
//import GetAgentNameAndId from 'Utils/helpers/GetAgentNameAndId';

const AuthContext = createContext({});

const AuthContextProvider = ({children}) => {
  //const [agent, setAgent] = useSessionStorage(SS.AGENT, null, true);
  const [accessT, setAccessT] = useSessionStorage(SS.ACCESS_T, null);
  const [serviceT, setServiceT] = useSessionStorage(SS.SERVICE_T, null);
  const [refreshT, setRefreshT] = useSessionStorage(SS.REFRESH_T, null);

  // const _getAgentCreds: any = (token) => {
  //   const { name, preferred_username } = GetAgentNameAndId(token);
  //   return { name, preferred_username };
  // };

  // const logIn: any = async (agentCredentials, callback) => {
  //   try {
  //     const {data, error} = await login(
  //       agentCredentials.email.trim(),
  //       agentCredentials.password.trim(),
  //     );
  //     if (data?.access_token && data?.refresh_token && data?.service_token) {
  //       setAgent(_getAgentCreds(data?.access_token));
  //       setAccessT(data?.access_token);
  //       setServiceT(data?.service_token);
  //       setRefreshT(data?.refresh_token);
  //       callback();
  //     }
  //     if (error) {
  //       return error;
  //     }
  //   } catch (error) {
  //     return {error};
  //   }
  // };

  const ssoLogIn: any = async (token, callback) => {
    try {
      const {data, error} = await loginWithSSO(token);
      if (data?.access_token && data?.service_token && data?.refresh_token) {
        //setAgent(_getAgentCreds(data?.access_token));
        setAccessT(data?.access_token);
        setRefreshT(data?.refresh_token);
        setServiceT(data?.service_token);
        callback();
      }
      if (error) {
        return error;
      }
    } catch (error) {
      return {error};
    }
  };

  // const logOut: any = async (callback) => {
  //   try {
  //     const { status, error } = await logout(refreshT);
  //     if (status === 200) {
  //       setAgent(null);
  //       setAccessT(null);
  //       setServiceT(null);
  //       setRefreshT(null);
  //       callback && callback();
  //     } else {
  //       return { error };
  //     }
  //   } catch (error) {
  //     return { error };
  //   }
  // };

  const value = {
    //agent,
    ssoLogIn,
    //logOut,
    accessT,
    refreshT,
    serviceT,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth: any = () => {
  return useContext(AuthContext);
};

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export {AuthContextProvider, useAuth};
