/* eslint-disable no-unused-vars */
import React from 'react';
import {DialogContent, DialogActions, Typography, Stack, OutlinedInput} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import globalSpacing from 'Theme/Spacing';
import {useCSContext} from 'Context/ContentStackContext';
import DialogWrapper from './DialogWrapper';
import {CSUIDList} from 'Utils/constants/CSclientMapping';
import styles from './styles';
import {GIFTING} from '../../giftingConstants';

interface IProps {
  loading: boolean;
  openGiftingDialog: boolean;
  setOpenGiftingDialog: any;
  handleSubmitGift: any;
  customerEmail: any;
  manualEmailEntry: string;
  setManualEmailEntry: any;
}

function GiftDialog(props: IProps) {
  const {
    openGiftingDialog,
    setOpenGiftingDialog,
    handleSubmitGift,
    customerEmail,
    loading,
    manualEmailEntry,
  } = props;
  const {artifacts} = useCSContext(CSUIDList);

  return (
    <DialogWrapper closeDialogMethod={setOpenGiftingDialog} open={openGiftingDialog}>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}>
        <Stack spacing={globalSpacing.s} paddingBottom={globalSpacing.xs}>
          <img src={artifacts.gift_icon} style={{fontSize: 70, alignSelf: 'center'}} />
          <Typography variant="h3" sx={{textAlign: 'center'}}>
            {GIFTING.gifting_dialog.heading}
          </Typography>
          <Typography variant="body1">{GIFTING.gifting_dialog.subtitle}</Typography>
        </Stack>
        {/* TODO to confirm */}
        {/* <CustomTextInput
          label={data.gifting_dialog.email_label}
          name="gift_recipient_email"
          disabled={!!customerEmail}
          onChange={(e) => setManualEmailEntry(e.target.value)}
          value={customerEmail || manualEmailEntry}
        /> */}
        <Typography variant="body2" py={globalSpacing.xs}>
          {GIFTING.gifting_dialog.email_label}
        </Typography>
        <OutlinedInput value={customerEmail} disabled />
      </DialogContent>
      <DialogActions sx={styles.buttonBox}>
        <LoadingButton
          disableRipple
          disabled={!customerEmail && !manualEmailEntry}
          onClick={handleSubmitGift}
          sx={styles.button}
          variant="contained"
          loading={loading}>
          {GIFTING.gifting_dialog.button_text}
        </LoadingButton>
      </DialogActions>
    </DialogWrapper>
  );
}

export default GiftDialog;
