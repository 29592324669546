export const SR_DASHBOARD = {
  back_button_title: 'Return to Member Details',
  comments_dialog: {
    heading: 'More Info',
    subtitle: 'Comments',
  },
  heading: 'Query Dashboard',
  input_fields: [
    [
      {
        name: 'externalRefId',
        label: 'Member Unique Identifier',
      },
      {
        name: 'srId',
        label: 'Ticket #',
      },
    ],
    [
      {
        name: 'status',
        label: 'Query Status',
        options: ['Select Status', 'Open', 'Closed', 'Pending'],
      },
      {
        name: 'period',
        label: 'Period',
      },
      {
        name: 'fromDate',
        label: 'From',
      },
      {
        name: 'toDate',
        label: 'To',
      },
    ],
  ],
  no_records: {
    description:
      'We searched our records and did not find any matches for your search. Please search again.',
    heading: 'No matching records found',
  },
  required_dialog: {
    heading: 'Alert',
    error_message: {
      to: 'Please provide a `To Date` to complete your search.',
      statusNTo: 'Please provide a `Query Status` and `To Date` to complete your search.',
      from: 'Please provide a `From Date` to complete your search.',
      statusNFrom: 'Please provide a `Query Status` and `From Date` to complete your search.',
      fromNTo: 'Please provide a `Query Status` to complete your search.',
      status: 'Please provide a `From Date` and `To Date` to complete your search.',
    },
  },
  result_row: [
    {
      name: 'memberId',
      label: 'Unique Identifier',
    },
    {
      name: 'ticketNumber',
      label: 'Ticket #',
    },
    {
      name: 'type',
      label: 'Query Type',
    },
    {
      name: 'reason',
      label: 'Reason',
    },
    {
      name: 'createdOn',
      label: 'Created On',
    },
    {
      name: 'status',
      label: 'Status',
    },
  ],
  search_button_title: 'SEARCH',
  title: 'Query Dashboard',
};
