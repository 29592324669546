import React, {useState, useEffect, Fragment} from 'react';
import {Box, Stack} from '@mui/material';
import GiftCard from './components/GiftCard';
import GiftDetailView from './components/GiftDetailView';
import globalSpacing from 'Theme/Spacing';
import {gqlGifting} from 'GQL/MethodsBFF/Gifting';
import {
  Wrapper,
  InfiniteScrollerCustom,
  SnackBar,
  Loading,
  TabHeading,
  CustomTextInput,
} from 'Components';
import styles from './styles';
import {useSnackBar, useInfiniteScroll, useDetailView} from 'Hooks';
import {GIFTING} from './giftingConstants';

interface Iprops {
  showFilter?: boolean;
}

function TabGifting(props: Iprops) {
  const [isDetailView, toggleDetailView] = useDetailView();
  const [selectedGiftOffer, setSelectedGiftOffer] = useState(null);
  const [giftData, setGiftData] = useState<any[]>([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSnackBarOpen, closeSnackBar] = useSnackBar(error);
  //for infinite scroll
  const [totalResults, setTotalResults] = useState(0);
  const [{pageNo, nextPage, pageSize}] = useInfiniteScroll(12);

  const fiterData = ['All', 'eGift', 'Plastic'];

  const [filterByType, setFilterByType] = useState(fiterData[0]);

  const handleItem = (item) => {
    setSelectedGiftOffer(item);
  };

  //methods used in useeffect
  const _showLoading = () => pageNo === 0 && setLoading(true);
  const _handleResponse = (data, loading, error) => {
    setLoading(loading);
    error && setError(error.message);
    if (data) {
      const {pagingInfo, offers} = data;
      pageNo === 0 && setTotalResults(pagingInfo?.totalResults);
      offers && pageNo === 0 ? setGiftData(offers) : setGiftData([...giftData, ...offers]);
    }
  };

  useEffect(() => {
    _showLoading();
    (async () => {
      const {data, loading, error} = await gqlGifting({
        name: 'GIFTING',
        pageSize: pageSize,
        pageNo: pageNo,
      });
      _handleResponse(data?.offersByCategory?.[0], loading, error);
    })();
  }, [pageNo]);

  return (
    <Wrapper>
      {!isDetailView ? (
        <Fragment>
          <Stack spacing={1}>
            <TabHeading data={GIFTING} />
          </Stack>
          <Stack spacing={1} style={{maxWidth: '250px', marginTop: '30px'}}>
            {props.showFilter && (
              <CustomTextInput
                disabled={false}
                inline
                label={'Filter By'}
                name="filter"
                filterMenu={fiterData || ['Loading...']}
                value={filterByType}
                onChange={(e) => {
                  setFilterByType(e.target.value);
                }}
              />
            )}
          </Stack>
          {loading ? (
            <Loading />
          ) : error ? (
            <SnackBar
              errorMessage={error}
              closeSnackBar={closeSnackBar}
              isSnackBarOpen={isSnackBarOpen}
            />
          ) : (
            <InfiniteScrollerCustom
              dataLength={giftData.length}
              next={() => nextPage()}
              hasMore={giftData.length < totalResults}>
              <Box py={globalSpacing.md} sx={styles.giftCardListContainer}>
                {giftData.map((item, index) => (
                  <GiftCard
                    key={index}
                    item={item}
                    toggleView={toggleDetailView}
                    handleItem={handleItem}
                  />
                ))}
              </Box>
            </InfiniteScrollerCustom>
          )}
        </Fragment>
      ) : (
        <GiftDetailView toggleView={toggleDetailView} selectedGiftOffer={selectedGiftOffer} />
      )}
    </Wrapper>
  );
}

export default TabGifting;
