/* eslint-disable no-unused-vars */
import React, {ReactNode} from 'react';
import {Dialog, DialogTitle} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {useCSContext} from 'Context/ContentStackContext';

interface IProps {
  children: ReactNode;
  closeDialogMethod: any;
  open: any;
  id?: any;
  isEscapEnable?: any;
}

function DialogWrapper(props: IProps) {
  const {children, closeDialogMethod, open, id = 'input-dialog', isEscapEnable} = props;
  const {artifacts} = useCSContext();

  const handleClose = (reason?: String) => {
    if (reason === 'backdropClick') return;
    closeDialogMethod(false);
  };
  return (
    <Dialog
      open={open}
      aria-label="dialog-input"
      id={id}
      onClose={({reason}: any) => handleClose(reason)}
      disableEscapeKeyDown={isEscapEnable ?? true}>
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => handleClose()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}>
          <img src={artifacts.cross_icon} alt={'close'} />
        </IconButton>
      </DialogTitle>
      {children}
    </Dialog>
  );
}

export default DialogWrapper;
