import {Box} from '@mui/material';
import Footer from 'Components/Footer';
import Header from 'Components/Header';
import RequireAuth from 'Components/RequireAuth';
import RequireMember from 'Components/RequireMember';
import {useData} from 'Context/DataContext';
import Dashboard from 'Pages/Dashboard';
import SRDashboard from 'Pages/SRDashboard';
import SSOLogin from 'Pages/SSOLogin';
import SearchDashboard from 'Pages/SearchDashboard';
import SearchMember from 'Pages/SearchMember';
import {DASHBOARD, QUERY_DASHBOARD, SEARCH, SSO} from 'Utils/constants/Paths';
import {agentRole} from 'Utils/constants/genericConstants';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';

interface CustomizedState {
  action: string;
}
const NavigationRoutes = () => {
  const location = useLocation();
  const {pathname} = useLocation();
  const state = location?.state as CustomizedState;
  const {setMember, setActiveTab, iframe: isIframeRequest, setStoredIdentifier} = useData();
  const roleConfig = useSelector((state: any) => state?.login?.roleConfig);
  const role = useSelector((state: any) => state?.login?.loginInfo?.role);

  useEffect(() => {
    if (pathname !== DASHBOARD && pathname !== QUERY_DASHBOARD && !isIframeRequest) {
      setActiveTab(0);
      setMember(null);
    }
    //Remove searched member from storage if agent logged out manually
    if (state?.action === 'logout') {
      setStoredIdentifier('');
    }
  }, [pathname]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}>
      {!isIframeRequest && pathname !== SSO && <Header />}
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          alignItems: 'flex-start',
          justifyContent: 'center',
        }}>
        <Routes>
          {!isIframeRequest && <Route path={SSO} element={<SSOLogin />} />}
          {!isIframeRequest && (
            <Route
              path={SEARCH}
              element={
                <RequireAuth>
                  {role === agentRole.level1 ? <SearchMember /> : <SearchDashboard />}
                </RequireAuth>
              }
            />
          )}
          {!isIframeRequest && roleConfig?.queryDashboard && (
            <Route
              path={QUERY_DASHBOARD}
              element={
                <RequireAuth>
                  <SRDashboard />
                </RequireAuth>
              }
            />
          )}
          <Route
            path={DASHBOARD}
            element={
              <RequireAuth>
                <RequireMember>
                  <Dashboard />
                </RequireMember>
              </RequireAuth>
            }
          />
          <Route path={'*'} element={<Navigate to={SEARCH} replace />} />
        </Routes>
      </Box>
      {!isIframeRequest && <Footer />}
    </Box>
  );
};

export default NavigationRoutes;
