import {Box, Typography, useTheme, CircularProgress} from '@mui/material';
import React from 'react';
import globalSpacing from 'Theme/Spacing';

const LogoutBackdrop = (props) => {
  const colorPalette = useTheme().palette.primary;

  return (
    <Box
      sx={{
        position: 'absolute',
        height: '100vh',
        width: '100vw',
        zIndex: 9999,
        opacity: '0.9',
        backgroundColor: colorPalette.light,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: globalSpacing.l,
      }}>
      <CircularProgress id="circular" color="primary" title={props.text} size={50} thickness={6} />
      <Typography variant="body2">{props.text}</Typography>
    </Box>
  );
};

export default LogoutBackdrop;
