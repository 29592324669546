import {globalConfig} from '../../Config/config';
import {Queries} from '../GQLConstants';
import {gqlCall, GQLType} from '../utils';

/**
 * @param {object} variables
 * @description
 * https://confluence.tenerity.com/pages/viewpage.action?spaceKey=CPROD&title=Service+Request+BFF+Contracts
 */
const gqlQuery = async (variables) => {
  try {
    const data = await gqlCall(
      GQLType.QUERY,
      Queries.SUBMIT_QUERY,
      variables,
      null,
      globalConfig._config.BFF.astBFF,
      false,
    );
    return data;
  } catch (error) {
    return {error};
  }
};

const gqlUpdateQuery = async (variables) => {
  try {
    const data = await gqlCall(
      GQLType.QUERY,
      Queries.SUBMIT_UPDATE_QUERY,
      variables,
      null,
      globalConfig._config.BFF.astBFF,
      false,
    );
    return data;
  } catch (error) {
    return {error};
  }
};

export {gqlQuery, gqlUpdateQuery};
