import SnackBar from 'Components/SnackBar';
import {globalConfig} from 'Config/config';
import useSnackBar from 'Hooks/SnackBarErrorHook';
import {INACTIVITY, INACTIVITY_FOR_ADMIN} from 'Utils/constants/Inactivity';
import {agentRole} from 'Utils/constants/genericConstants';
import {stopTokenRefreshTimer} from 'Utils/helpers/RefreshTokenScheduler';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {IdleTimerProvider} from 'react-idle-timer';
import {useSelector} from 'react-redux';

const IdleContextProvider = ({children}) => {
  const accessToken = useSelector((state: any) => state?.login.loginInfo?.access_token);
  const role = useSelector((state: any) => state?.login?.loginInfo?.role);
  const inactivityTimer = role === agentRole.level1 ? INACTIVITY : INACTIVITY_FOR_ADMIN;

  const [error, setError] = useState('');
  const [isSnackBarOpen, closeSnackBar] = useSnackBar(error);

  /**
   * Logout agent on idle - 15 min of inactivity - if refresh token is there
   * this excludes the iframe because the iframe sets the access token only
   */
  const onIdle = async () => {
    if (accessToken) {
      stopTokenRefreshTimer();
      sessionStorage.clear();
      localStorage.clear();
      window.location.replace(
        `${globalConfig._config.internalSSOConfig.REACT_APP_KEYCLOAK_ENDPOINT}/realms/${globalConfig._config.internalSSOConfig.REACT_APP_REALM}/protocol/openid-connect/logout?redirect_uri=${window.location.href}`,
      );
    }
  };

  //TODO refresh token? - token needs to be short lifespan 15 min cca and refresh token can be longer

  const onAction = () => {
    // console.log('onaction');
    // Do something when a user triggers a watched event
  };

  const handlePrompt = () => {
    setError("Your session is about to expire. You'll be automatically signed out.");
  };

  return (
    <IdleTimerProvider
      timeout={inactivityTimer}
      promptBeforeIdle={inactivityTimer / 2}
      onPrompt={handlePrompt}
      onIdle={onIdle}
      onAction={onAction}>
      {children}
      <SnackBar
        isSnackBarOpen={isSnackBarOpen}
        closeSnackBar={closeSnackBar}
        errorMessage={error}
      />
    </IdleTimerProvider>
  );
};

IdleContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default IdleContextProvider;
