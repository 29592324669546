import React, {useEffect, useState} from 'react';
import TableView from 'Components/TableView';
import {getPaymentData} from './PaymentListGridDataSource';
import {useSelector} from 'react-redux';
import {SortOrder} from 'Utils/enums';
import {sortTable} from 'Utils/helpers/SRListHelpers';

const PaymentListView = (props) => {
  const {handlePaymentRowClick} = props;
  const [sortOrder, setSortOrder] = useState(SortOrder.ASCENDING);
  const [tableData, setTableData] = useState<any[]>([]);
  const [currentSortCriteria, setCriteriaSort] = useState('assetType');
  const selectedSRData = useSelector((state: any) => state.selectedSR.getServiceRequestById);
  const tenantConfig = useSelector((state: any) => state?.login?.tenantConfig);

  let payments = selectedSRData?.additionalData?.payments || [];
  let savedPayments = selectedSRData?.additionalData?.savedPayments;

  useEffect(() => {
    let paymentsArray = [...payments];
    if (savedPayments && Array.isArray(savedPayments)) {
      paymentsArray = [...paymentsArray, ...savedPayments];
    }
    setTableData(paymentsArray);
  }, [selectedSRData]);

  const onSortIconClick = (sortCriteria: string) => {
    let resp = sortTable(
      tableData,
      sortCriteria,
      currentSortCriteria,
      sortOrder,
      tenantConfig.locale,
    );
    setCriteriaSort(resp.currentSortCriteria);
    setTableData(resp.tableData);
    setSortOrder(resp.newSortOrder);
  };

  return (
    <div>
      <TableView
        {...getPaymentData(selectedSRData, tableData, sortOrder, currentSortCriteria, true)}
        onRowClick={handlePaymentRowClick}
        sortTable={onSortIconClick}
      />
    </div>
  );
};

export default PaymentListView;
