import {ulsterRIPackages} from 'Utils/constants/genericConstants';

export const updateFormContent = (formContent, targetFormFields) => {
  let tempFormContent = Object.assign(formContent, {});
  if (tempFormContent && tempFormContent['fields']) {
    tempFormContent.fields.forEach((field) => {
      if (targetFormFields[field.name]) {
        field[targetFormFields[field.name].targetAttribute] =
          targetFormFields[field.name].targetValue;
        field[targetFormFields[field.name].targetAttribute1] =
          targetFormFields[field.name].targetValue1;
      }
    });
  }

  return tempFormContent;
};

export const getCurrency = (selectedMemberData) => {
  if (ulsterRIPackages.data.includes(selectedMemberData?.packageId)) {
    return 'EUR';
  } else {
    return 'GBP';
  }
};

export const stringAscSort = (a, b) => {
  return a.localeCompare(b);
};

export const trimFormValues = (formValues) => {
  Object.keys(formValues).forEach((formKey) => {
    if (formValues[formKey]) {
      formValues[formKey] = formValues[formKey].trim();
    }
  });
  return formValues;
};
