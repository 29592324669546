import {
  CARD_CITY_NUMBER_SEQUENCE_ERROR,
  CARD_LINE1_NUMBER_SEQUENCE_ERROR,
  CARD_NUMBER_INVALID_ERROR,
  CARD_POSTCODE_NUMBER_SEQUENCE_ERROR,
  EXPIRY_DATE_INVALID_ERROR,
  EXPIRY_YEAR_INVALID_ERROR,
 // INVALID_NAME_ON_CARD_ERROR,
  MASTERCARD_REGEX,
  VISA_REGEX,
} from 'Utils/constants/genericConstants';
import {dateConversionIsoToLocal} from 'Utils/helpers/DateValidation';
import {
  isExpiryDateValid,
  isExpiryYearValid,
  isViolatesNumberConstraint,
} from 'Utils/helpers/PaymentHelper';
import * as Yup from 'yup';

export const createValidationSchema = (paymentVehiclesDetails) => {
  return Yup.object({
    nameOnCard: Yup.string()
    //  .matches(/^[a-zA-Z]+ [a-zA-Z]+$/, INVALID_NAME_ON_CARD_ERROR)
      .required("Name on card is a required field"),
    cardNumber: Yup.string()
      .trim()
      .max(16, CARD_NUMBER_INVALID_ERROR)
      .test('cardNumberValidator', function (value) {
        if (!(value?.match(VISA_REGEX) || value?.match(MASTERCARD_REGEX))) {
          return this.createError({
            path: this.path,
            message: 'Invalid Card number',
          });
        } else {
          return true;
        }
      })
      .required(),
    addressLineOne: Yup.string()
      .test('addressValidator', function (value) {
        if (isViolatesNumberConstraint(value)) {
          return this.createError({
            path: this.path,
            message: CARD_LINE1_NUMBER_SEQUENCE_ERROR,
          });
        } else {
          return true;
        }
      })
      .required('Address Line1 is a required field'),
    city: Yup.string()
      .test('addressValidator', function (value) {
        if (isViolatesNumberConstraint(value)) {
          return this.createError({
            path: this.path,
            message: CARD_CITY_NUMBER_SEQUENCE_ERROR,
          });
        } else {
          return true;
        }
      })
      .required('City is a required field'),
    postalCode: Yup.string()
      .test('addressValidator', function (value) {
        if (isViolatesNumberConstraint(value)) {
          return this.createError({
            path: this.path,
            message: CARD_POSTCODE_NUMBER_SEQUENCE_ERROR,
          });
        } else {
          return true;
        }
      })
      .required('Postal Code is a required field'),
    country: Yup.string().required('Country is a required field'),
    expiryDate: Yup.string()
    .required('Expiry Date is a required field')
      .test('expiryDate', function (value) {
        let newDate: any = dateConversionIsoToLocal(value);
        let date = newDate.split('/');
        let expiryDateYear = date[2];
        let expiryDateMonth = date[1];
        if (!isExpiryDateValid(expiryDateYear, expiryDateMonth)) {
          return this.createError({
            path: this.path,
            message: EXPIRY_DATE_INVALID_ERROR,
          });
        }
        if (!isExpiryYearValid(expiryDateYear)) {
          return this.createError({
            path: this.path,
            message: EXPIRY_YEAR_INVALID_ERROR,
          });
        }
        return true;
      }),
  });
};

export const initialValidState = {
  cardNumber: false,
  expiryDate: false,
};

export const initialValues = {
  cardNumber: '',
  cardType: '',
  expiryDate: null,
  nameOnCard: '',
  addressLineOne: '',
  addressLineTwo: '',
  addressLineThree: '',
  city: '',
  country: '',
  postalCode: '',
  saveForLater: false,
  isMemberAddressBillingAddress: false,
  buttonAction: '',
};
