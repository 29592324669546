import {Table, TableBody, TableCell, TableHead, TableRow, useTheme} from '@mui/material';
// import {ClaimedOfferAdditionalData} from 'Models/ClaimedOfferModel';
import React from 'react';
// import {TableCell, TableCell} from './StyledTableCell';

interface Props {
  data: any;
  buttonJSX?: JSX.Element;
  sentToAddressMarkUp?: JSX.Element;
}

const RedeemAdditionaDataRow = (props: Props) => {
  const theme = useTheme();
  const styles = {
    table: {
      '& .MuiTableCell-root': {
        border: `1px solid ${theme.palette.secondary.light}`,
      },
    },
  };
  return (
    <Table aria-label="history" sx={styles.table}>
      <TableHead>
        <TableRow>
          <TableCell width={160}>
            <b>Sent to</b>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell
            style={{
              lineHeight: '12px',
            }}>
            <span
              style={{
                textOverflow: 'word-wrap',
                overflowWrap: 'break-word',
                lineHeight: '12px',
              }}>
              {props.data.sentToEmail}
            </span>
            {props.sentToAddressMarkUp}
          </TableCell>
          <TableCell />
          <TableCell />
          <TableCell />
          <TableCell />
          <TableCell />
          <TableCell colSpan={5} style={{textAlign: 'right'}}>
            {props.buttonJSX}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default RedeemAdditionaDataRow;
