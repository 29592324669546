import {createSlice, createEntityAdapter} from '@reduxjs/toolkit';

interface SRListFilter {
  isRefreshSRList: boolean;
  filterType: string;
}

const SRListFilterAdapter: any = createEntityAdapter<SRListFilter>({});

const initialState = SRListFilterAdapter.getInitialState({
  status: 'idle',
  error: '',
  isRefreshSRList: false,
  filterType: '',
});

const SRListFilterSlice = createSlice({
  name: 'SRListFilterDetails',
  initialState,
  reducers: {
    isUpdateSRList: (state, action) => {
      for (const key in state) {
        if (action.payload.hasOwnProperty(key)) {
          state[key] = action.payload[key];
        }
      }
    },
    updateFilterType: (state, action) => {
      state.filterType = action.payload;
    },
  },
});

export const {isUpdateSRList, updateFilterType} = SRListFilterSlice.actions;

export default SRListFilterSlice.reducer;
