import React, {Fragment, useEffect, useState} from 'react';
import parse from 'html-react-parser';
import BuyGiftCard from './BuyGiftCard';
import {Box, Divider, Grid, Paper, Stack, Typography} from '@mui/material';
import {BackButtonWText, SnackBar} from 'Components';
import {useSelector} from 'react-redux';
import globalSpacing from 'Theme/Spacing';
import styles from './../styles';
import MemberAccountInfo from './MemberAccountInfo';
import {gqlBuyGiftCard} from 'GQL/MethodsBFF/GMGifting';
import {resetGMOrderHistory} from 'Redux/Slices/gmGiftingHistory';
import {useSnackBar} from 'Hooks';
const DEFAULT_BUYNOW_ERROR = 'An unexpected error occured';

interface IBuyGiftCardResponse {
  offerCompositeKey: OfferCompositeKey;
  agentId: string;
}

export interface OfferCompositeKey {
  offerId: string;
  externalId: string;
  offerSource: string;
  details: Details;
}

export interface Details {
  giftCardType: string;
  amount: number;
  quantity: number;
  contentProvider: string;
  email: string;
  firstName?: string;
  lastName?: string;
  line1?: string;
  line2?: string;
  city?: string;
  region?: string;
  postalCode?: string;
  country?: string;
  phoneNumber?: string;
  lastFourCardNum?: string;
}

interface ISingleGiftCardProps {
  offer: any;
  toggleView: any;
}

function GMGiftDetailView(props: ISingleGiftCardProps) {
  useEffect(() => {
    setAccount(false);
  }, [props.offer]);
  const authtoken = useSelector((state: any) => {
    return state?.impersonateToken.impersonateToken?.accessToken;
  });
  const tenantID = useSelector((state: any) => {
    return state?.login.tenantID;
  });
  const {offer, toggleView} = props;
  const [errorMessage, setErrorMessage] = useState('');
  const [giftErrorMessage, setgiftErrorMessage] = useState('');
  const [errorType, setErrorType] = useState('');
  const [updateAccount, setAccount] = useState(true);
  const [isSnackBarOpen, closeSnackBar] = useSnackBar(errorMessage);
  const selectedMember = useSelector((state: any) => state?.selectedMember);
  const buyOffer = async (
    amount: number,
    quantity: number,
    cc4digit: string,
    agentUserName: string,
  ) => {
    setErrorMessage('');
    const selectedOffer: IBuyGiftCardResponse = {
      offerCompositeKey: {
        offerId: offer?.id,
        externalId: offer?.externalId,
        offerSource: offer?.offerSource,
        details: {
          giftCardType: offer?.redemptionDetails[0].method === 'DIGITAL' ? 'egift' : 'physical',
          amount: amount,
          quantity: quantity,
          contentProvider: offer?.externalId,
          email: selectedMember && selectedMember.primaryEmail ? selectedMember.primaryEmail : '',
          lastFourCardNum: cc4digit,
          firstName: '',
          lastName: '',
          line1: '',
          line2: '',
          city: '',
          region: '',
          postalCode: '',
          country: '',
          phoneNumber: '',
        },
      },
      agentId: agentUserName ? agentUserName : '',
    };

    try {
      const headers = {
        'tenant-id': tenantID,
        Authorization: `Bearer ${authtoken}`,
      };
      let response = await gqlBuyGiftCard(selectedOffer, headers);
      if (response?.data?.claimOfferAsAgent) {
        toggleView(true);
        setAccount(true);
        setErrorType('success');
        setErrorMessage('ORDER COMPLETE');
        resetGMOrderHistory();
      } else {
        setgiftErrorMessage(response.error?.graphQLErrors[0].message);
      }
    } catch (error: any) {
      let errorMessage: string = DEFAULT_BUYNOW_ERROR;
      setErrorMessage(errorMessage);
    }
  };

  return (
    <Fragment>
      <SnackBar
        severity={errorType}
        errorMessage={errorMessage}
        closeSnackBar={closeSnackBar}
        isSnackBarOpen={isSnackBarOpen}
      />
      <Box>
        <Box display="flex" justifyContent="space-between">
          <BackButtonWText text="Back to list" callback={toggleView} />{' '}
          <MemberAccountInfo updateAccount={updateAccount} />
        </Box>
        <Paper sx={{marginY: globalSpacing.s}}>
          <Stack spacing={globalSpacing.s} p={globalSpacing.m} mt={0}>
            <Grid container spacing={2}>
              <Grid item xs={6} spacing={2}>
                <Box textAlign="center">
                  <img
                    src={offer?.images[0]?.imageUrl}
                    alt="giftcard"
                    className="single_gift_img"
                  />
                </Box>
                <Box textAlign="center" sx={styles.textWithColor}>
                  <span>
                    {offer?.redemptionDetails[0].method === 'PHYSICAL' ? 'Plastic' : 'eGift'}
                  </span>
                </Box>
                <Typography variant="subtitle1" paddingTop="20px">
                  <strong>{offer?.title}</strong>
                </Typography>
                <Box marginTop="10px">{offer?.description}</Box>
              </Grid>
              <Grid item xs={5} marginLeft="32px">
                <BuyGiftCard
                  min={offer?.valueRestrictions?.minimum}
                  max={offer?.valueRestrictions?.maximum}
                  buyOffer={buyOffer}
                  giftCardType={
                    offer?.redemptionDetails[0].method === 'PHYSICAL' ? 'Plastic' : 'eGift'
                  }
                  error={giftErrorMessage}
                  clearError={() => setgiftErrorMessage('')}
                  loginData={[]}
                />
              </Grid>
            </Grid>
            <Divider />
            <Box>
              <Typography variant="subtitle1" paddingTop="20px">
                Special Conditions
              </Typography>
              {parse(offer?.termsAndConditions)}
            </Box>
          </Stack>
        </Paper>
      </Box>
    </Fragment>
  );
}

export default GMGiftDetailView;
