import globalSpacing from '../../../Theme/Spacing';

const styles = {
  container: {
    flexDirection: 'column',
    display: 'flex',
  },
  paper: {
    marginTop: globalSpacing.sm,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
  },
  topFieldContainer: {
    display: 'flex',
    paddingY: globalSpacing.s,
    paddingX: globalSpacing.m,
    alignItems: 'center',
    gap: globalSpacing.s,
  },
  form: {
    display: 'flex',
    flexFlow: 'column',
    paddingY: globalSpacing.s,
    paddingX: globalSpacing.m,
  },
};

export default styles;
