import globalSpacing from 'Theme/Spacing';

const styles = {
  paper: {
    overflow: 'hidden',
    marginY: globalSpacing.md,
  },
  adminPaper: {
    overflow: 'hidden',
  },
  topContainer: {display: 'flex', flexDirection: 'row', alignItems: 'center'},
  rowContainer: {
    flexDirection: 'row',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
};

export default styles;
