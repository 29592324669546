import globalSpacing from 'Theme/Spacing';

const styles = {
  paper: {
    marginTop: globalSpacing.sm,
    marginBottom: globalSpacing.sm,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
  },
  boxRow: {
    display: 'flex',
    paddingY: globalSpacing.sm,
    paddingX: globalSpacing.md,
    justifyContent: 'space-between',
  },
  cardAction: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingY: globalSpacing.sm,
    paddingX: globalSpacing.md,
  },
  statusBox: {
    display: 'flex',
    flexDirection: 'row',
    width: '20%',
    justifyContent: 'space-between',
  },
  table: {
    '& .MuiTableCell-root': {
      padding: '10px',
      fontFamily: 'regular',
    },
  },
};

export default styles;
