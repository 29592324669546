import {IconButton, TableCell, TableRow} from '@mui/material';
// import {makeStyles} from 'tss-react/mui';
// import {REDEEM_CLAIMED_OFFER_TYPE, REFULFILL_CLAIMED_OFFER_TYPE} from 'Constants/constants';
// import {ClaimedOffer} from 'Models/ClaimedOfferModel';
import React, {Dispatch, SetStateAction} from 'react';
// import NestedRowContainer from './NestedRowContainer';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  REDEEM_CLAIMED_OFFER_TYPE,
  REFULFILL_CLAIMED_OFFER_TYPE,
} from 'Utils/constants/genericConstants';
import NestedRowContainer from './NestedRowContainer';

// const useStyles = makeStyles()((theme) => {
//   return {
//     childMargin: {
// marginLeft: 30,
// display: 'block',
// paddingLeft: 0,
//     },
//     giftCard: {
// display: 'block',
// maxWidth: 60,
// width: 'auto',
// height: 'auto',
//     },
//   };
// });

const formatOrderType = (string): string => {
  if (string === REFULFILL_CLAIMED_OFFER_TYPE) {
    return 'Re-fulfill';
  }

  if (string === REDEEM_CLAIMED_OFFER_TYPE) {
    return 'Redeem';
  }

  return string;
};

const OfferRow = (props: {
  row: any;
  backgroundColor: string;
  isParent: boolean;
  onReFulfillClick: (IOrderHistoryAdditionalData, string, boolean) => void;
  onExpandRowClick?: Dispatch<SetStateAction<boolean>>;
  isOpen?: boolean;
}) => {
  const {row} = props;
  //   const {classes} = useStyles();

  return (
    <>
      <TableRow style={{background: props.backgroundColor}}>
        <TableCell
        // className={!props.isParent ? classes.childMargin : ''}
        >
          {!props.isParent && <span>&#x21B3;</span>}
          {row.orderNumber}
        </TableCell>
        <TableCell>
          <span style={{whiteSpace: 'pre-line'}}>{row.date}</span>
        </TableCell>
        <TableCell>{row.type}</TableCell>
        <TableCell sx={{maxWidth: '50px', wordWrap: 'break-word'}}>{row.reedemedBy}</TableCell>
        <TableCell>{row.status}</TableCell>
        <TableCell>{formatOrderType(row.orderType)}</TableCell>
        <TableCell>
          <img
            style={{display: 'block', maxWidth: 60, width: 'auto', height: 'auto'}}
            // className={classes.giftCard}
            alt={row.merchantData.altText}
            src={row.merchantData.imageUrl}
          />
        </TableCell>
        <TableCell>{row.cardCount}</TableCell>
        <TableCell>${row.cardValue}</TableCell>
        <TableCell>
          {props.onExpandRowClick && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => props.onExpandRowClick && props.onExpandRowClick(!props.isOpen)}>
              {props.isOpen ? (
                <KeyboardArrowUpIcon sx={{fontSize: 24}} />
              ) : (
                <KeyboardArrowDownIcon sx={{fontSize: 24}} />
              )}
            </IconButton>
          )}
        </TableCell>
      </TableRow>

      <NestedRowContainer
        row={row}
        backgroundColor={props.backgroundColor}
        onReFulfillClick={props.onReFulfillClick}
        isOpen={props.isOpen}
      />
    </>
  );
};

export default OfferRow;
