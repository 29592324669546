import React from 'react';
import {TableRow, Table, TableBody, TableHead, TableCell, useTheme} from '@mui/material';
// import {ClaimedOfferAdditionalData} from 'Models/ClaimedOfferModel';

interface ReFulfillAdditionaDataRowProps {
  data: any;
  buttonJSX?: JSX.Element;
  sentToAddressMarkUp?: JSX.Element;
  isSecondRow?: boolean;
}

const ReFulfillAdditionaDataRow = (props: ReFulfillAdditionaDataRowProps) => {
  const theme = useTheme();
  const styles = {
    table: {
      '& .MuiTableCell-root': {
        border: `1px solid ${theme.palette.secondary.light}`,
      },
    },
  };
  return (
    <Table aria-label="history" sx={styles.table}>
      <TableHead>
        <TableRow>
          <TableCell>
            <b>{!props.isSecondRow && 'Sent to'}</b>
          </TableCell>
          <TableCell style={{minWidth: 90}}>
            <b>SR date</b>
          </TableCell>
          <TableCell>
            <b>SR number</b>
          </TableCell>
          <TableCell>
            <b>SR status</b>
          </TableCell>
          <TableCell>
            <b>Reason for Re-Fulfill</b>
          </TableCell>
          <TableCell>
            <b>Additional Comments</b>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell
            style={{
              lineHeight: '12px',
              maxWidth: 160,
              width: 160,
            }}>
            <span
              style={{
                textOverflow: 'word-wrap',
                overflowWrap: 'break-word',
                lineHeight: '12px',
              }}>
              {!props.isSecondRow && props.data.sentToEmail}
            </span>
            {!props.isSecondRow && props.sentToAddressMarkUp}
          </TableCell>

          <TableCell style={{width: 100}}>
            <span style={{whiteSpace: 'pre-line'}}>{props.data.srDate}</span>
          </TableCell>
          <TableCell style={{width: 120}}>{props.data.srNumber}</TableCell>
          <TableCell style={{width: 80}}>{props.data.srStatus}</TableCell>
          <TableCell style={{width: 80}}>{props.data.reason}</TableCell>
          <TableCell>{props.data.additionalComments}</TableCell>
          <TableCell style={{textAlign: 'right', width: 160}}>{props.buttonJSX}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default ReFulfillAdditionaDataRow;
