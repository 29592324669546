import axios from 'axios';
import {v4 as uuidv4} from 'uuid';
import {globalConfig} from 'Config/config';

const SSOIframe = async (tenant, token) => {
  const url = globalConfig._config.BFF.iframeAPI;
  try {
    const response = await axios.post(
      url,
      {token: token},
      {
        headers: {
          'x-correlation-id': uuidv4(),
          'tenant-id': String(tenant),
          'Content-Type': 'application/json',
        },
      },
    );
    return response.data;
  } catch (error) {
    return {error};
  }
};

export default SSOIframe;
