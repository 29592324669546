import globalSpacing from 'Theme/Spacing';

const styles = {
  main: {
    borderBottom: 1,
    borderColor: 'divider',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    height: 80,
    paddingX: '5vw',
  },
  containerLeft: {
    display: 'flex',
    gap: globalSpacing.l,
    alignItems: 'center',
  },
  containerRight: {
    display: 'flex',
    gap: globalSpacing.l,
  },
  wrapperIconText: {
    gap: globalSpacing.xs,
    textDecoration: 'none',
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  borderStyle: (color) => ({
    borderBottom: `0.3rem solid ${color}`,
    marginTop: '0.3rem',
  }),
};

export default styles;
