export const formDetails = {
  cssClass: 'form_wrapper',
};

export const serviceRequestDetailsSection = {
  sectionClass: '',
  title: 'Service Request Details',
  titleClass: '',
  fields: [
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'summary',
      id: 'summary',
      fieldLabel: 'Summary',
      cssClass: '',
      dataTest: 'summary',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'select',
      name: 'subType',
      id: 'subType',
      fieldLabel: 'Subtype',
      options: [],
      wrapperCssClass: '',
      optionCssClass: '',
      cssClass: '',
      dataTest: 'subType',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'select',
      name: 'reason',
      id: 'reason',
      fieldLabel: 'Reason',
      options: [],
      wrapperCssClass: '',
      optionCssClass: '',
      cssClass: '',
      dataTest: 'reason',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'select',
      name: 'channel',
      id: 'channel',
      fieldLabel: 'Channel',
      defaultValue: 'Mail',
      options: [
        {value: 'Authorities', text: 'Authorities'},
        {value: 'Bank Branch', text: 'Bank Branch'},
        {value: 'Call direct to CA', text: 'Call direct to CA'},
        {value: 'Call direct to CR', text: 'Call direct to CR'},
        {value: 'Call to CC', text: 'Call to CC'},
        {value: 'Call transfer to CA', text: 'Call transfer to CA'},
        {value: 'Call transfer to CR', text: 'Call transfer to CR'},
        {value: 'Client - Call', text: 'Client - Call'},
        {
          value: 'Client - Paper / Email',
          text: 'Client - Paper / Email',
        },
        {value: 'Contact Center', text: 'Contact Center'},
        {value: 'Email', text: 'Email'},
        {value: 'Engage', text: 'Engage'},
        {value: 'Fax', text: 'Fax'},
        {value: 'Form', text: 'Form'},
        {value: 'Interface', text: 'Interface'},
        {value: 'IVR', text: 'IVR'},
        {value: 'Letter', text: 'Letter'},
        {value: 'Mail', text: 'Mail'},
        {value: 'MobileCarrier', text: 'MobileCarrier'},
        {value: 'Online', text: 'Online'},
        {value: 'Partner', text: 'Partner'},
        {value: 'Phone', text: 'Phone'},
        {value: 'RBSApp', text: 'RBSApp'},
        {value: 'Unknown', text: 'Unknown'},
      ],
      wrapperCssClass: '',
      optionCssClass: '',
      cssClass: '',
      dataTest: 'channel',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'merchantName',
      id: 'merchantName',
      fieldLabel: 'Merchant Name',
      cssClass: '',
      dataTest: 'merchantName',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'orderNumber',
      id: 'orderNumber',
      fieldLabel: 'Order Number',
      cssClass: '',
      dataTest: 'orderNumber',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'transactionAmount',
      id: 'transactionAmount',
      fieldLabel: 'Transaction Amt ',
      wrapperCssClass: '',
      cssClass: '',
      dataTest: 'transactionAmount',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'datePicker',
      name: 'transactionDate',
      id: 'transactionDate',
      fieldLabel: 'Transaction Date',
      placeholder: 'Select a date',
      disableFutureDate: true,
      wrapperCssClass: '',
      inputClass: '',
      dataTest: 'transactionDate',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'select',
      name: 'owner',
      id: 'owner',
      fieldLabel: 'Owner',
      options: [],
      wrapperCssClass: '',
      optionCssClass: '',
      cssClass: '',
      dataTest: 'owner',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'itemPurchased',
      id: 'itemPurchased',
      fieldLabel: 'Item Purchased',
      wrapperCssClass: '',
      cssClass: '',
      dataTest: 'itemPurchased',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'paymentMethod',
      id: 'paymentMethod',
      fieldLabel: 'Payment Method',
      wrapperCssClass: '',
      cssClass: '',
      dataTest: 'paymentMethod',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'commentBox',
      htmlInputType: 'text',
      rows: 4,
      multiline: true,
      name: 'comments',
      id: 'comments',
      fieldLabel: 'Comments',
      wrapperCssClass: '',
      cssClass: '',
      dataTest: 'comments',
      note: 'Please do not enter any sensitive data',
      xs: 6,
      sm: 6,
    },
    {
      fieldType: 'commentBox',
      htmlInputType: 'text',
      rows: 4,
      multiline: true,
      name: 'resolutionDesc',
      id: 'resolutionDesc',
      fieldLabel: 'Resolution Desc.',
      wrapperCssClass: '',
      cssClass: '',
      dataTest: 'resolutionDesc',
      note: 'Please do not enter any sensitive data',
      xs: 6,
      sm: 6,
    },
  ],
};

export const buttonList = {
  direction: 'row',
  spacing: 2,
  fields: [
    {
      fieldType: 'button',
      htmlInputType: 'submit',
      name: 'saveButton',
      id: 'saveButton',
      fieldLabel: 'Save',
      variant: 'contained',
      wrapperClass: '',
      dataTest: 'save',
      xs: 2,
      sm: 2,
    },
    {
      fieldType: 'button',
      htmlInputType: 'submit',
      name: 'pendingButton',
      id: 'pendingButton',
      fieldLabel: 'Pending',
      variant: 'contained',
      wrapperClass: '',
      dataTest: 'pending',
      xs: 2,
      sm: 2,
    },
    {
      fieldType: 'button',
      htmlInputType: 'submit',
      name: 'closeButton',
      id: 'closeButton',
      fieldLabel: 'Close',
      variant: 'contained',
      wrapperClass: '',
      dataTest: 'close',
      xs: 2,
      sm: 2,
    },
    {
      fieldType: 'button',
      htmlInputType: 'submit',
      name: 'cancelButton',
      id: 'cancelButton',
      fieldLabel: 'Cancel',
      variant: 'contained',
      wrapperClass: '',
      dataTest: 'cancel',
      xs: 2,
      sm: 2,
    },
    {
      fieldType: 'button',
      htmlInputType: 'submit',
      name: 'reopenButton',
      id: 'reopenButton',
      fieldLabel: 'Reopen',
      variant: 'contained',
      wrapperClass: '',
      dataTest: 'Reopen',
      xs: 2,
      sm: 2,
    },
  ],
};
