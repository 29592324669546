import * as Yup from 'yup';

export const createValidationSchema = (isSubTypeMandatory = false) => {
  return Yup.object({
    subType: isSubTypeMandatory
      ? Yup.string().required('Subtype is a required field')
      : Yup.string().notRequired(),
  });
};

export const initialValues = {
  summary: '',
  subtype: '',
  reason: '',
  channel: 'Form',
  merchantName: '',
  orderNumber: '',
  transactionAmount: '',
  transactionDate: null,
  itemPurchased: '',
  paymentMethod: '',
  owner: '',
  resolutionDesc: '',
  comments: '',
};
