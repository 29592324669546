import ErrorIcon from '@mui/icons-material/Error';
import {Box, Typography} from '@mui/material';
import {Loading, SnackBar, Wrapper} from 'Components';
import useSnackBar from 'Hooks/SnackBarErrorHook';
import {agentLogin} from 'Redux/Slices/login';
import globalSpacing from 'Theme/Spacing';
import {SEARCH} from 'Utils/constants/Paths';
import {SSO_ERROR_TEXT, SSO_LOADING_TEXT} from 'Utils/constants/genericConstants';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import styles from './styles';

interface CustomizedState {
  from: {
    pathname: string;
  };
}
const SSOLogin = () => {
  const [searchParams] = useSearchParams();
  const [UIMessage, setUIMessage] = useState(SSO_LOADING_TEXT);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [isSnackBarOpen, closeSnackBar] = useSnackBar(error);
  const location = useLocation();
  const state = location.state as CustomizedState;
  const from = state?.from?.pathname || SEARCH;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    /* 
    The token is base64 format and when fetched from the url the '+' gets turned into whitespace.
    We revert this to have a valid base64 token

    SSO Login with SAML Token
    */
    const token = searchParams?.get('SAMLResponse')?.replace(/\s+/g, '+');

    if (token) {
      (async () => {
        const resp = await dispatch(agentLogin({token})).unwrap();
        if (resp.data) {
          navigate(from, {replace: true});
        } else if (resp.error) {
          const {response: errorResponse} = resp.error;
          if (errorResponse?.status === 401) {
            setError(SSO_ERROR_TEXT[errorResponse?.status]);
            setUIMessage(SSO_ERROR_TEXT[errorResponse?.status]);
          } else {
            setUIMessage(SSO_ERROR_TEXT.default);
            setError(errorResponse?.data?.message || 'Something went wrong');
          }
        }

        setLoading(false);
      })();
    } else {
      setLoading(false);
      setUIMessage('Missing token');
    }
  }, []);

  return (
    <Wrapper>
      <Box p={globalSpacing.md} sx={styles.container}>
        {loading && <Loading />}
        {!loading && (
          <Box m={globalSpacing.l} sx={styles.errorIconBox}>
            <ErrorIcon color="primary" sx={styles.errorIcon} />
          </Box>
        )}
        <Typography variant="h5" p={globalSpacing.s} textAlign="center">
          {UIMessage}
        </Typography>
      </Box>
      <SnackBar
        errorMessage={error}
        isSnackBarOpen={isSnackBarOpen}
        closeSnackBar={closeSnackBar}
      />
    </Wrapper>
  );
};

export default SSOLogin;
