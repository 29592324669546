const giftHistoryMap = (data) => {
  let historyEntries: any = [];
  for (let i = 0; i < data?.length; i++) {
    const {summary, additionalData, type, status} = data[i];
    const mappedValues = mapEntry(
      summary,
      additionalData?.transactionDate,
      additionalData?.merchantName,
      type,
      additionalData?.comments,
      status,
    );
    historyEntries.push(mappedValues);
  }
  return historyEntries;
};

const mapEntry = (
  offerTitle,
  claimDate,
  merchantName,
  offerType,
  expiryDateToFormat,
  transactionStatus,
) => {
  const dateRegex =
    /(expiry date:)([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])T\d{1,2}:\d{1,2}:\d{1,2}\.\d{0,3}Z)/gi;
  const expiryDate = expiryDateToFormat?.split(dateRegex)[2];

  return {
    offerTitle,
    claimDate,
    merchantName,
    offerType,
    expiryDate,
    transactionStatus,
  };
};

export default giftHistoryMap;
