import Keycloak from 'keycloak-js';
import {globalConfig} from 'Config/config';

export const InitializeAppWithKeyCloak = () => {
  return new Promise((resolve, reject) => {
    const {REACT_APP_KEYCLOAK_ENDPOINT, REACT_APP_REALM, REACT_APP_CLIENTID} =
      globalConfig._config.internalSSOConfig;

    let keycloak: any = new Keycloak({
      realm: REACT_APP_REALM,
      url: REACT_APP_KEYCLOAK_ENDPOINT,
      clientId: REACT_APP_CLIENTID,
    });
    keycloak
      .init({onLoad: 'login-required', checkLoginIframe: false})
      .then((auth) => {
        if (!auth) {
          reject(false);
        } else {
          let response = {
            access_token: keycloak.token,
            refresh_token: keycloak.refreshToken,
          };
          resolve(response);
        }

        //let base64Url = keycloak && keycloak.token ? keycloak.token.split('.')[1] : '';
        // let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        // let jsonPayload = decodeURIComponent(
        //   atob(base64)
        //     .split('')
        //     .map(function (c) {
        //       return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        //     })
        //     .join(''),
        // );
        // let parsedToken = JSON.parse(jsonPayload);
      })
      .catch((error) => {
        if (error) console.error('Authenticated Failed - ', error);
        reject(false);
      });
  });
};
