import moment from 'moment';
import {SortOrder} from '../enums';
import {FORMAT_TYPE} from '../constants/DateFormat';
import {dateFormatConversionToLocale} from './DateValidation';

export const parseData = (
  header,
  tableData,
  sortOrder,
  currentSortCriteria,
  sortingEnabled,
  hideColumns = {},
) => {
  let dataSet = {
    headers: header,
    tableData: [...tableData],
    sortOrder: sortOrder,
    currentSortCriteria: currentSortCriteria,
    sortingEnabled: sortingEnabled,
    hideColumns,
  };
  return dataSet;
};

export const evaluateSortOrder = (sortOrder, sortCriteria, currentSortCriteria) => {
  if (currentSortCriteria !== sortCriteria) {
    return sortOrder;
  } else if (sortOrder === SortOrder.ASCENDING) {
    return SortOrder.DESCENDING;
  } else if (sortOrder === SortOrder.DESCENDING) {
    return SortOrder.ASCENDING;
  } else {
    return SortOrder.ASCENDING;
  }
};

const getSortFieldValue = (val) => {
  if (typeof val === 'string') {
    if (val === 'null') {
      val = '';
    } else {
      val = val.toUpperCase();
    }
  }

  return val;
};

export const sortDataByDescending = (data: any[], sortCriteria: string, locale) => {
  data = data.sort((a: any, b: any) => {
    let dataA = a[sortCriteria] ? a[sortCriteria] : '';
    let dataB = b[sortCriteria] ? b[sortCriteria] : '';

    const t1: any = moment(dataA, dateFormatConversionToLocale(FORMAT_TYPE.DATE_TIME, locale));
    const t2: any = moment(dataB, dateFormatConversionToLocale(FORMAT_TYPE.DATE_TIME, locale));

    if (
      t1.isValid() &&
      t2.isValid() &&
      t1?._pf?.unusedTokens?.length === 0 &&
      t2?._pf?.unusedTokens?.length === 0
    ) {
      if (moment(t1).isBefore(t2)) {
        return 1;
      } else if (moment(t1).isAfter(t2)) {
        return -1;
      } else {
        return 0;
      }
    } else {
      let ele1 = getSortFieldValue(a[sortCriteria]);
      let ele2 = getSortFieldValue(b[sortCriteria]);

      if (ele1 < ele2) {
        return 1;
      } else if (ele1 > ele2) {
        return -1;
      } else {
        return 0;
      }
    }
  });
  return data;
};

export const sortDataByAscending = (data: any[], sortCriteria: string, locale) => {
  data = data.sort((a: any, b: any) => {
    let dataA = a[sortCriteria] ? a[sortCriteria] : '';
    let dataB = b[sortCriteria] ? b[sortCriteria] : '';

    const t1: any = moment(dataA, dateFormatConversionToLocale(FORMAT_TYPE.DATE_TIME, locale));
    const t2: any = moment(dataB, dateFormatConversionToLocale(FORMAT_TYPE.DATE_TIME, locale));

    if (
      t1.isValid() &&
      t2.isValid()
      // t1?._pf?.unusedTokens?.length === 0 &&
      // t2?._pf?.unusedTokens?.length === 0
    ) {
      if (moment(t1).isBefore(t2)) {
        return -1;
      } else if (moment(t1).isAfter(t2)) {
        return 1;
      } else {
        return 0;
      }
    } else {
      let ele1 = getSortFieldValue(a[sortCriteria]);
      let ele2 = getSortFieldValue(b[sortCriteria]);

      if (ele1 < ele2) {
        return -1;
      } else if (ele1 > ele2) {
        return 1;
      } else {
        return 0;
      }
    }
  });
  return data;
};

export const sortTable = (tableData, sortCriteria, currentSortCriteria, sortOrder, locale) => {
  if (tableData && tableData.length) {
    let temp: any[] = tableData.slice();
    let newSortOrder = evaluateSortOrder(sortOrder, sortCriteria, currentSortCriteria);
    if (newSortOrder === SortOrder.DESCENDING) {
      temp = sortDataByDescending(temp, sortCriteria, locale);
    } else {
      temp = sortDataByAscending(temp, sortCriteria, locale);
    }
    return {
      tableData: temp,
      currentSortCriteria: sortCriteria,
      newSortOrder: newSortOrder,
    };
  }
  return {
    tableData: [],
    currentSortCriteria: '',
    newSortOrder: 0,
  };
};

export const applyFilter = (data, filters) => {
  let tempData = data;

  filters.forEach((f) => {
    tempData = tempData.filter((i) => {
      return i[f['on']] === f['value'];
    });
  });

  return tempData;
};

export const isDisableFormFields = (fields, value) => {
  let targetFields: any = {};

  fields.forEach((field) => {
    targetFields[field.name] = {
      targetAttribute: 'isDisabled',
      targetValue: value,
    };
  });

  return targetFields;
};
