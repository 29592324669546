import {useEffect} from 'react';
import {useState} from 'react';

const useInfiniteScroll = (customPageSize = 10) => {
  const [pageNo, setPageNo] = useState(0);
  const nextPage = () => setPageNo(pageNo + 1);
  const resetPage = () => setPageNo(0);
  const pageSize = customPageSize;

  useEffect(() => {
    setPageNo(pageNo);
  }, [pageNo]);

  return [{pageNo, nextPage, resetPage, pageSize}];
};

export default useInfiniteScroll;
