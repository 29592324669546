export const formDetails = {
  cssClass: 'form_wrapper',
};
export const serviceRequestDetailsSection = {
  sectionClass: '',
  title: 'Service Request Details',
  titleClass: '',
  fields: [
    {
      fieldType: 'select',
      name: 'benefit',
      id: 'benefit',
      fieldLabel: 'Benefit',
      options: [],
      dataTest: 'benefit',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'select',
      name: 'subType',
      id: 'subType',
      fieldLabel: 'Subtype',
      options: [],
      dataTest: 'subType',
      xs: 4,
      sm: 4,
      required: true,
    },
    {
      fieldType: 'select',
      name: 'subStatus',
      id: 'subStatus',
      fieldLabel: 'Sub-status',
      options: [
        {value: 'declined', text: 'Declined'},
        {value: 'formReceived', text: 'Form Received'},
        {value: 'pending', text: 'Pending'},
      ],
      dataTest: 'subStatus',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'select',
      name: 'channel',
      id: 'channel',
      fieldLabel: 'Channel',
      defaultValue: 'Mail',
      options: [
        {value: 'Authorities', text: 'Authorities'},
        {value: 'Bank Branch', text: 'Bank Branch'},
        {value: 'Call direct to CA', text: 'Call direct to CA'},
        {value: 'Call direct to CR', text: 'Call direct to CR'},
        {value: 'Call to CC', text: 'Call to CC'},
        {value: 'Call transfer to CA', text: 'Call transfer to CA'},
        {value: 'Call transfer to CR', text: 'Call transfer to CR'},
        {value: 'Client - Call', text: 'Client - Call'},
        {
          value: 'Client - Paper / Email',
          text: 'Client - Paper / Email',
        },
        {value: 'Contact Center', text: 'Contact Center'},
        {value: 'Email', text: 'Email'},
        {value: 'Engage', text: 'Engage'},
        {value: 'Fax', text: 'Fax'},
        {value: 'Form', text: 'Form'},
        {value: 'Interface', text: 'Interface'},
        {value: 'IVR', text: 'IVR'},
        {value: 'Letter', text: 'Letter'},
        {value: 'Mail', text: 'Mail'},
        {value: 'MobileCarrier', text: 'MobileCarrier'},
        {value: 'Online', text: 'Online'},
        {value: 'Partner', text: 'Partner'},
        {value: 'Phone', text: 'Phone'},
        {value: 'RBSApp', text: 'RBSApp'},
        {value: 'Unknown', text: 'Unknown'},
      ],
      dataTest: 'channel',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'select',
      name: 'owner',
      id: 'owner',
      fieldLabel: 'Owner',
      options: [],
      dataTest: 'owner',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'select',
      name: 'thirdParty',
      id: 'thirdParty',
      fieldLabel: '3rd Party',
      options: [],
      dataTest: '3rdParty',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'thirdPartyNotes',
      id: 'thirdPartyNotes',
      fieldLabel: '3rd Party Notes',
      dataTest: '3rdPartyNotes',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'datePicker',
      name: 'eventDateTime',
      id: 'eventDateTime',
      fieldLabel: 'Event Date/time',
      disableFutureDate: true,
      placeholder: 'Event Date/time',
      inputClass: '',
      dataTest: 'eventDateTime',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'complaintDetail',
      id: 'complaintDetail',
      fieldLabel: 'Complaint Details',
      dataTest: 'complaintDetail',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'select',
      name: 'complaintNature',
      id: 'complaintNature',
      fieldLabel: 'Complaint Nature',
      options: [
        {value: 'Security Instance', text: 'Security Instance'},
        {value: 'Agent Error', text: 'Agent Error'},
        {value: 'Service Error', text: 'Service Error'},
      ],
      dataTest: 'complaintNature',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'select',
      name: 'complaintReason',
      id: 'complaintReason',
      fieldLabel: 'Complaint Reason',
      options: [],
      dataTest: 'complaintReason',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'select',
      name: 'complainant',
      id: 'complainant',
      fieldLabel: 'Complainant',
      options: [
        {value: 'clientEmployees', text: 'Client - Employees'},
        {value: 'clientManagement', text: 'Client - Management'},
        {value: 'MemberAI', text: 'Member - AI - ServiceCenter'},
        {value: 'MemberPTG', text: 'Member - PTG - FrontOffice'},
        {value: 'Customer', text: 'Customer'},
      ],
      dataTest: 'complainant',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'select',
      name: 'compensationType',
      id: 'compensationType',
      fieldLabel: 'Compensation Type',
      options: [
        {
          value: 'Cinema Voucher',
          text: 'Cinema Voucher',
        },
        {
          value: 'Flowers Voucher',
          text: 'Flowers Voucher',
        },
        {
          value: 'Goodwill Refund',
          text: 'Goodwill Refund',
        },
        {
          value: 'Individual Compensation',
          text: 'Individual Compensation',
        },
        {
          value: 'Refund',
          text: 'Refund',
        },
        {
          value: 'Ticket Voucher',
          text: 'Ticket Voucher',
        },
        {
          value: 'Travel Voucher',
          text: 'Travel Voucher',
        },
      ],
      dataTest: 'compensationType',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'compensationAmount',
      id: 'compensationAmount',
      fieldLabel: 'Compensation Amt',
      dataTest: 'compensationAmount',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'select',
      name: 'receivedBy',
      id: 'receivedBy',
      fieldLabel: 'Received By',
      options: [
        {
          value: 'AI',
          text: 'AI',
        },
        {
          value: 'CC',
          text: 'CC',
        },
        {
          value: 'Phone',
          text: 'Phone',
        },
        {
          value: 'Bank',
          text: 'Bank',
        },
        {
          value: 'Supplier',
          text: 'Supplier',
        },
        {
          value: 'Letter',
          text: 'Letter',
        },
        {
          value: 'Email',
          text: 'Email',
        },
        {
          value: 'Call T/R to CR',
          text: 'Call T/R to CR',
        },
        {
          value: 'Underwriter',
          text: 'Underwriter',
        },
        {
          value: 'Client',
          text: 'Client',
        },
        {
          value: 'NPS',
          text: 'NPS',
        },
        {
          value: 'Other',
          text: 'Other',
        },
      ],
      dataTest: 'receivedBy',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'select',
      name: 'replyTo',
      id: 'replyTo',
      fieldLabel: 'Reply To',
      options: [
        {
          value: 'Complaint SR Reply to Member',
          text: 'Complaint SR Reply to Member',
        },
      ],
      dataTest: 'replyTo',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'select',
      name: 'severity',
      id: 'severity',
      fieldLabel: 'Severity',
      options: [
        {value: 'RBSApp', text: 'RBSApp'},
        {value: 'Critical', text: 'Critical'},
        {value: 'High', text: 'High'},
        {value: 'Low', text: 'Low'},
        {value: 'Medium', text: 'Medium'},
        {value: 'Question', text: 'Question'},
      ],
      dataTest: 'severity',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'datePicker',
      name: 'dateReceived',
      id: 'dateReceived',
      fieldLabel: 'Date Received Fr',
      placeholder: 'Select a date',
      disableFutureDate: true,
      inputClass: '',
      dataTest: 'dateReceived',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'commentBox',
      htmlInputType: 'text',
      name: 'complaintResolution',
      id: 'complaintResolution',
      fieldLabel: 'Complaint Resolution',
      multiline: true,
      rows: 4,
      dataTest: 'complaintResolution',
      note: 'Please do not enter any sensitive data',
      xs: 6,
      sm: 6,
    },
  ],
};

export const serviceRequestDetailsSection2 = {
  sectionClass: '',
  title: 'Service Request Details 2',
  titleClass: '',
  fields: [
    {
      fieldType: 'checkbox',
      htmlInputType: 'text',
      name: 'fosRights',
      id: 'fosRights',
      fieldLabel: 'FOS Rights',
      dataTest: 'fosRights',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'checkbox',
      htmlInputType: 'text',
      name: 'fos1',
      id: 'fos1',
      fieldLabel: 'FOS 1',
      dataTest: 'fos1',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'checkbox',
      htmlInputType: 'text',
      name: 'fos2',
      id: 'fos2',
      fieldLabel: 'FOS 2',
      dataTest: 'fos2',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'checkbox',
      htmlInputType: 'text',
      name: 'upheld',
      id: 'upheld',
      fieldLabel: 'Upheld',
      dataTest: 'upheld',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'checkbox',
      htmlInputType: 'text',
      name: 'isControllable',
      id: 'isControllable',
      fieldLabel: 'Is Controllable',
      dataTest: 'isControllable',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'checkbox',
      htmlInputType: 'text',
      name: 'rework',
      id: 'rework',
      fieldLabel: 'Rework',
      dataTest: 'rework',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'checkbox',
      htmlInputType: 'text',
      name: 'compensation',
      id: 'compensation',
      fieldLabel: 'Compensation',
      dataTest: 'compensation',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'checkbox',
      htmlInputType: 'text',
      name: 'additionalUpdate',
      id: 'additionalUpdate',
      fieldLabel: 'Additional Update',
      dataTest: 'additionalUpdate',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'checkbox',
      htmlInputType: 'text',
      name: 'refund',
      id: 'refund',
      fieldLabel: 'Refund',
      dataTest: 'refund',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'refundAmount',
      id: 'refundAmount',
      fieldLabel: 'Refund Amt.',
      dataTest: 'refundAmount',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'checkbox',
      htmlInputType: 'text',
      name: 'redress',
      id: 'redress',
      fieldLabel: 'Redress',
      dataTest: 'redress',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'redressAmount',
      id: 'redressAmount',
      fieldLabel: 'Redress Amt.',
      dataTest: 'redressAmount',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'redressAction',
      id: 'redressAction',
      fieldLabel: 'Redress Action',
      dataTest: 'redressAction',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'checkbox',
      htmlInputType: 'text',
      name: 'qaFlag',
      id: 'qaFlag',
      fieldLabel: 'QA Flag',
      dataTest: 'qaFlag',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'datePicker',
      name: 'dateAcknowledgement',
      id: 'dateAcknowledgement',
      fieldLabel: 'Date Ack',
      disableFutureDate: true,
      placeholder: 'Date Ack',
      inputClass: '',
      dataTest: 'dateAcknowledgement',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'checkbox',
      htmlInputType: 'text',
      name: 'referredToThirdParty',
      id: 'referredToThirdParty',
      fieldLabel: 'Referred to 3rd party',
      dataTest: 'referredToThirdParty',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'select',
      name: 'resolutionChannel',
      id: 'resolutionChannel',
      fieldLabel: 'Resolution Channel',
      options: [
        {value: 'Call', text: 'Call'},
        {value: 'Email', text: 'Email'},
        {value: 'Mail', text: 'Mail'},
      ],
      dataTest: 'resolutionChannel',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'datePicker',
      name: 'dateResolved',
      id: 'dateResolved',
      fieldLabel: 'Date resolved',
      disableFutureDate: true,
      placeholder: 'Date resolved',
      inputClass: '',
      dataTest: 'dateResolved',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'checkbox',
      htmlInputType: 'text',
      name: 'summaryResolution',
      id: 'summaryResolution',
      fieldLabel: 'Summary Resolution',
      dataTest: 'summaryResolution',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'checkbox',
      htmlInputType: 'text',
      name: 'finalResponse',
      id: 'finalResponse',
      fieldLabel: 'Final Response',
      dataTest: 'finalResponse',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'select',
      name: 'isCompleted',
      id: 'isCompleted',
      fieldLabel: 'Completed',
      options: [
        {text: 'Yes', value: 'Yes'},
        {text: 'No', value: 'No'},
      ],
      dataTest: 'isCompleted',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'commentBox',
      htmlInputType: 'text',
      name: 'comments',
      id: 'comments',
      fieldLabel: 'Comments',
      dataTest: 'comments',
      note: 'Please do not enter any sensitive data',
      xs: 6,
      sm: 6,
      multiline: true,
      rows: 4,
    },
  ],
};

export const buttonList = {
  direction: 'row',
  spacing: 2,
  fields: [
    {
      fieldType: 'button',
      htmlInputType: 'submit',
      name: 'saveButton',
      id: 'saveButton',
      fieldLabel: 'Save',
      variant: 'contained',
      wrapperClass: '',
      dataTest: 'save',
      isDisabled: true,
      xs: 2,
      sm: 2,
    },
    {
      fieldType: 'button',
      htmlInputType: 'submit',
      name: 'pendingButton',
      id: 'pendingButton',
      fieldLabel: 'Pending',
      variant: 'contained',
      wrapperClass: '',
      isDisabled: true,
      dataTest: 'pending',
      xs: 2,
      sm: 2,
    },
    {
      fieldType: 'button',
      htmlInputType: 'submit',
      name: 'closeButton',
      id: 'closeButton',
      fieldLabel: 'Close',
      variant: 'contained',
      wrapperClass: '',
      dataTest: 'close',
      xs: 2,
      sm: 2,
    },
    {
      fieldType: 'button',
      htmlInputType: 'submit',
      name: 'cancelButton',
      id: 'cancelButton',
      fieldLabel: 'Cancel',
      variant: 'contained',
      wrapperClass: '',
      isDisabled: true,
      dataTest: 'cancel',
      xs: 2,
      sm: 2,
    },
    {
      fieldType: 'button',
      htmlInputType: 'submit',
      name: 'reopenButton',
      id: 'reopenButton',
      fieldLabel: 'Reopen',
      variant: 'contained',
      isDisabled: true,
      wrapperClass: '',
      dataTest: 'Reopen',
      xs: 2,
      sm: 2,
    },
  ],
};
