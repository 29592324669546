import React from 'react';
//import {Box} from '@mui/system';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
//import globalSpacing from '../../Theme/Spacing';

const Loading = (props) => {
  return (
    <Backdrop
      sx={{backgroundColor: 'rgba(216, 218, 219, 0.5)', zIndex: (theme) => theme.zIndex.drawer + 1}}
      open={props?.isOpen || false}>
      <CircularProgress id="circular" color="primary" title="loading..." />
    </Backdrop>
  );
};

export default Loading;
