import {parseToFloat, roundOff} from 'Utils/helpers/Numeric';
import {parseData} from 'Utils/helpers/SRListHelpers';

export const getTicketGridData = (
  tableData,
  sortOrder,
  currentSortCriteria,
  isSortingEnabled,
  buttonList,
) => {
  const header = [
    {label: 'Item', value: 'ticketLineId'},
    {label: 'Ticket Type', value: 'type'},
    {label: '# Tickets', value: 'quantity'},
    {label: 'Price/Ticket', value: 'pricePerUnit'},
    {label: 'Status', value: 'status'},
    {label: 'Booking Fee', value: 'bookingFee'},
    {label: 'No Cashback', value: 'isNoCashback'},
    {label: 'Total Line Price', value: 'linePrice'},
    {label: '', value: 'buttons'},
  ];
  tableData = ((Array.isArray(tableData) && tableData) || []).map((data) => {
    return {
      ticketLineId: data?.additionalInfo?.ticketLineId || '',
      type: data.type,
      quantity: data.quantity,
      pricePerUnit: roundOff(parseToFloat(data.pricePerUnit), 2),
      status: data.status,
      bookingFee: roundOff(parseToFloat(data.bookingFee), 2),
      isNoCashback: !data.isAvailCashback ? 'Yes' : 'No',
      totalPrice: roundOff(parseToFloat(data.totalPrice), 2),
      buttons: ['updateButton', 'deleteButton'],
    };
  });
  return parseData(header, tableData, sortOrder, currentSortCriteria, isSortingEnabled);
};
