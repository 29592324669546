import {getSolicitationId} from 'Utils/helpers/MemberDetails';
import {parseToFloat} from 'Utils/helpers/Numeric';

export const getActivityWithType = (activities, type) => {
  for (let index = 0; index < activities.length; index++) {
    const activity = activities[index];

    if (activity.type === type) {
      return activity;
    }
  }

  return null;
};

export const getNotificationParams = (selectedMemberDetails) => {
  const selectedMember = selectedMemberDetails.currentMember;
  return {
    firstName: selectedMember?.firstName,
    lastName: selectedMember?.lastName,
    membershipNumber: selectedMemberDetails?.membershipNumber,
    toEmail: selectedMember?.primaryEmail || selectedMember?.clientEmail,
    packageId: selectedMemberDetails?.packageId,
    solicitationId: parseToFloat(getSolicitationId(selectedMemberDetails)),
    externalRefId: selectedMember?.externalRefId,
  };
};
