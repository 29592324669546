class GlobalConfig {
  public _config: any;
  public constructor(configuration: any) {
    this.config = configuration;
  }

  public get config(): any {
    return this._config;
  }

  public set config(value) {
    this._config = value;
  }
}

export const globalConfigUrl = `${process.env.PUBLIC_URL}/settings.json`;
export const globalConfig = new GlobalConfig({});
