import {Paper, Stack, Typography} from '@mui/material';
import React from 'react';
import globalSpacing from 'Theme/Spacing';
import PropTypes from 'prop-types';
import {useCSContext} from 'Context/ContentStackContext';
import styles from './styles';

const NoSearchResults = ({CSTexts}) => {
  const {artifacts} = useCSContext();

  return (
    <Paper sx={{marginBottom: globalSpacing.l, marginTop: globalSpacing.m}}>
      <Stack spacing={globalSpacing.xs} sx={styles.stack}>
        <img src={artifacts.no_result_image} />
        <Typography variant="subtitle1">{CSTexts.heading}</Typography>
        <Typography variant="h6" textAlign={'center'}>
          {CSTexts.description}
        </Typography>
      </Stack>
    </Paper>
  );
};

NoSearchResults.propTypes = {
  CSTexts: PropTypes.object.isRequired,
};

export default NoSearchResults;
