import React, {Fragment, useEffect, useState} from 'react';
import {Button, Paper, Typography} from '@mui/material';
import {Box} from '@mui/system';
import TnCDialog from './TnCDialog';
import FieldsItem from './FieldsItem';
import globalSpacing from 'Theme/Spacing';
import {api} from 'GQL/MethodsBFF/Transactions';
import {Loading, BackButtonWText, SnackBar} from 'Components';
import parse from 'html-react-parser';
import {useSnackBar} from 'Hooks';
import {useSelector} from 'react-redux';

interface IProps {
  toggleView: any;
  transactionId: string | null;
  csData?: any;
}

const DetailView: React.FC<IProps> = (props) => {
  const {toggleView, transactionId, csData} = props;
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const {selectedMember} = useSelector((state: any) => state?.selectedMember);
  const currentMemberDetails = selectedMember.currentMemberDetails;
  const [transactionDetails, setTransactionDetails] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  const [error, setError] = useState(null);
  const [isSnackBarOpen, closeSnackBar] = useSnackBar(error);

  const toggleTnDDialog = () => {
    setIsDialogVisible(!isDialogVisible);
  };

  const _handleResponse = (data, error) => {
    setLoading(false);
    error && setError(error.message);
    data && setTransactionDetails(data);
  };

  useEffect(() => {
    (async () => {
      const data = await api.gqlTransactionDetails({
        ngMemberId: currentMemberDetails?.memberId,
        referenceId: currentMemberDetails?.externalRefId,
        offerId: transactionId,
      });
      _handleResponse(data.getTransactionDetailsByReferenceId?.[0], data.error);
    })();
  }, []);

  if (loading) {
    return <Loading />;
  } else if (error) {
    return (
      <SnackBar
        errorMessage={error}
        isSnackBarOpen={isSnackBarOpen}
        closeSnackBar={closeSnackBar}
      />
    );
  } else {
    return (
      <Paper sx={{padding: globalSpacing.sm}}>
        <Fragment>
          <Box sx={styles.topRow}>
            <BackButtonWText text={'Back to list'} callback={toggleView} />
            <Typography variant="body1">
              Type: <span style={{fontFamily: 'semi-bold'}}>{transactionDetails.offerType}</span>
            </Typography>
          </Box>
          <Typography variant="h2" py={globalSpacing.s}>
            {transactionDetails.titleShort}
          </Typography>
          <Typography variant="body1">{parse(transactionDetails.description)}</Typography>
          <Button
            onClick={() => toggleTnDDialog()}
            sx={{
              minHeight: '0rem',
              minWidth: '0rem',
              paddingX: '0rem',
              paddingY: globalSpacing.xs,
              marginY: globalSpacing.sm,
            }}>
            <Typography
              variant="body2"
              color="primary"
              sx={{textDecoration: 'underline', textTransform: 'none'}}>
              {csData.tnc_button_title}
            </Typography>
          </Button>

          <Typography variant="subtitle1">{csData.fields_heading}</Typography>
          <Box sx={styles.fieldsContainer}>
            {Object.entries(transactionDetails).map((item: any, index) => (
              <FieldsItem
                fieldKey={item[0]}
                value={item[1]}
                key={index}
                csFieldMap={csData.field_map}
              />
            ))}
            {transactionDetails.offerType.toLowerCase() === 'cashback' && (
              <FieldsItem
                fieldKey={'rebate'}
                value={transactionDetails.titleShort}
                csFieldMap={csData.field_map}
              />
            )}
          </Box>
          <TnCDialog
            toggleTnDDialog={toggleTnDDialog}
            isDialogVisible={isDialogVisible}
            transactionDetails={transactionDetails}
          />
        </Fragment>
      </Paper>
    );
  }
};

const styles = {
  topRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: globalSpacing.sm,
  },
  fieldsContainer: {display: 'flex', flexWrap: 'wrap', gap: '5%'},
};

export default DetailView;
