import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {Box, useTheme} from '@mui/material';
import SearchMember from 'Pages/SearchMember';
import {Wrapper} from 'Components';
import styles from './styles';
import PropTypes from 'prop-types';
import globalSpacing from 'Theme/Spacing';
import SRSearch from 'Pages/SRSearch';
import AdminSRDashboard from 'Pages/AdminSRDashboard';

const TabPanel = ({children, index, activeTab}) => {
  return (
    <Box sx={styles.tabPanelContainer}>
      {activeTab === index && <Box py={globalSpacing.s}>{children}</Box>}
    </Box>
  );
};
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  activeTab: PropTypes.number.isRequired,
};

const SearchDashboard = () => {
  const [value, setValue] = React.useState(0);
  const tabFontSize = useTheme().typography.h6.fontSize;

  const handleChange = (_, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Wrapper>
      <Box sx={styles.container}>
        <Box bgcolor="background.paper" sx={styles.tabsContainer}>
          <Tabs
            centered={true}
            sx={styles.tabs}
            value={value}
            onChange={handleChange}
            indicatorColor="primary">
            <Tab
              disableRipple
              sx={{
                display: 'flex',
                flex: 1,
                marginX: '10px',
                marginY: '10px',
                textTransform: 'none',
                color: 'inherit',
                fontFamily: 'headline-regular',
                fontSize: tabFontSize,
              }}
              label="Membership"
              tabIndex={0}
            />
            <Tab
              disableRipple
              sx={{
                display: 'flex',
                flex: 1,
                marginX: '10px',
                marginY: '10px',
                textTransform: 'none',
                color: 'inherit',
                fontFamily: 'headline-regular',

                fontSize: tabFontSize,
              }}
              label="Service Request"
              tabIndex={0}
            />
            <Tab
              disableRipple
              sx={{
                display: 'flex',
                flex: 1,
                marginX: '10px',
                marginY: '10px',
                textTransform: 'none',
                color: 'inherit',
                fontFamily: 'headline-regular',

                fontSize: tabFontSize,
              }}
              label="SR Dashboard"
              tabIndex={0}
            />
          </Tabs>
        </Box>
        <TabPanel activeTab={value} index={0}>
          <SearchMember />
        </TabPanel>
        <TabPanel activeTab={value} index={1}>
          <SRSearch />
        </TabPanel>
        <TabPanel activeTab={value} index={2}>
          <AdminSRDashboard />
        </TabPanel>
      </Box>
    </Wrapper>
  );
};

export default SearchDashboard;
