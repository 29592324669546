import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import TableView from 'Components/TableView';
import {SortOrder} from 'Utils/enums';
import {getSubscriptionGridData} from './SubscriptionListGridDataSource';
import {sortTable} from 'Utils/helpers/SRListHelpers';
import {dateConversionIsoToLocal} from 'Utils/helpers/DateValidation';
import {Box, Paper} from '@mui/material';
import AdminDetailListItem from '../AdminMemberProfile/components/AdminDetailListItem';
import styles from './styles';
import {getCurrentSubscription, getSolicitationId} from 'Utils/helpers/MemberDetails';

interface IProps {}
const Subscription: React.FC<IProps> = (props) => {
  const member = useSelector((state: any) => state?.selectedMember?.selectedMember);
  const tenantConfig = useSelector((state: any) => state?.login?.tenantConfig);
  const [currentSortCriteria, setCurrentSortCriteria] = useState('solicitationId');
  const [sortOrder, setSortOrder] = useState(SortOrder.ASCENDING);
  const [tableData, setTableData] = useState<any[]>([]);
  useEffect(() => {
    mapTableData(member.subscriptions);
  }, [member]);

  const checkReason = (reason: any) => {
    let defaultReason = reason?.toUpperCase()?.indexOf('REINSTATE');
    let secondaryReason = reason?.toUpperCase()?.indexOf('REINSTATED');
    if (defaultReason === -1 && secondaryReason === -1) {
      return '-';
    } else {
      return reason;
    }
  };
  const mapTableData = (subscriptionHistoryDetails) => {
    let tempTableData: any = [];

    (subscriptionHistoryDetails || []).forEach((data) => {
      tempTableData.push({
        solicitationId: data.packageRefId || '',
        packageName: data.packageName || '',
        startDate: (data.startDate && dateConversionIsoToLocal(data.startDate)) || '',
        endDate:
          data?.code?.toUpperCase() === 'CANCELLED' || data?.code?.toUpperCase() === 'CANCEL'
            ? dateConversionIsoToLocal(data.endDate)
            : '',
        code:
          data.code?.toUpperCase() === 'CANCELLED' || data?.code?.toUpperCase() === 'CANCEL'
            ? 'INACTIVE'
            : data.code,
        tierTo: data.tierTo || '',
        source: data.source ? data.source : 'Interfaces',
        reason:
          data?.code?.toUpperCase() === 'CANCELLED' || data?.code?.toUpperCase() === 'CANCEL'
            ? data?.reason
            : '',
        reinstate: data?.code?.toUpperCase() === 'ACTIVE' ? checkReason(data?.reason) : '',
      });
    });

    const resp = sortTable(
      tempTableData,
      'startDate',
      'startDate',
      SortOrder.ASCENDING,
      tenantConfig.locale,
    );
    setCurrentSortCriteria(resp.currentSortCriteria);
    setTableData(resp.tableData);
    setSortOrder(resp.newSortOrder);
  };
  const onSortIconClick = (sortCriteria: string) => {
    let resp = sortTable(
      tableData,
      sortCriteria,
      currentSortCriteria,
      sortOrder,
      tenantConfig.locale,
    );
    setCurrentSortCriteria(resp.currentSortCriteria);
    setTableData(resp.tableData);
    setSortOrder(resp.newSortOrder);
  };

  return (
    <>
      <Paper sx={styles.paper}>
        <Box sx={styles.gridConatiner}>
          <Box sx={styles.gridItem}>
            <AdminDetailListItem label={'Client'} value={member?.partner} />
          </Box>
          <Box sx={styles.gridItem}>
            <AdminDetailListItem label={'Enrolment Date'} value={member?.enrollmentDate} />
          </Box>
          <Box sx={styles.gridItem}>
            <AdminDetailListItem
              label={'Subscription start date'}
              value={getCurrentSubscription(member?.subscriptions)?.startDate || '-'}
            />
          </Box>
          <Box sx={styles.gridItem}>
            <AdminDetailListItem
              label={'Status'}
              value={member?.currentMemberDetails?.status || '-'}
            />
          </Box>
          <Box sx={styles.gridItem}>
            <AdminDetailListItem
              label={'Cancel Reason'}
              value={
                member?.currentMemberDetails?.cancelReason === null
                  ? '-'
                  : member?.currentMemberDetails?.cancelReason
              }
            />
          </Box>
          <Box sx={styles.gridItem}>
            <AdminDetailListItem
              label={'Re-instate Reason'}
              value={
                member?.status?.[0]?.code?.toUpperCase() === 'ACTIVE'
                  ? checkReason(member?.status?.[0]?.code)
                  : '-'
              }
            />
          </Box>
          <Box sx={styles.gridItem}>
            <AdminDetailListItem label={'Package Name'} value={member?.packageName} />
          </Box>
          <Box sx={styles.gridItem}>
            <AdminDetailListItem label={'Solicitation/ Promo'} value={getSolicitationId(member)} />
          </Box>
        </Box>
      </Paper>
      <TableView
        {...getSubscriptionGridData(tableData, sortOrder, currentSortCriteria, true)}
        sortTable={onSortIconClick}
        enableSubscriptionColor={true}
      />
    </>
  );
};
export default Subscription;
