import {useState} from 'react';
import {SS} from 'Utils/constants/SessionStorage';

const useSessionStorage = (key, initialValue, toParse = false) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item: any = sessionStorage.getItem(key);
      const formattedItem: any = toParse
        ? JSON.parse(item)
        : key.includes('activeTab')
        ? Number(item)
        : item;

      return item ? formattedItem : initialValue;
    } catch (error) {
      console.log(error, key);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to session storage.
  const toRemoveIfNull = SS.ACCESS_T || SS.SERVICE_T || SS.REFRESH_T;

  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      if (typeof window !== 'undefined') {
        if (!value && toRemoveIfNull) {
          sessionStorage.removeItem(key);
        } else {
          const formattedItem = toParse
            ? JSON.stringify(valueToStore)
            : key.includes('activeTab')
            ? Number(valueToStore)
            : valueToStore;
          // Save to session storage
          sessionStorage.setItem(key, formattedItem);
        }
      }
      setStoredValue(valueToStore);
      // Save state
    } catch (error) {
      console.log(error);
    }
  };
  return [storedValue, setValue];
};

export default useSessionStorage;
