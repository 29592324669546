import {createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {gqlGetMemberRewardsInfo} from 'GQL/MethodsBFF/GMGifting';

interface IGMGiftCards {
  data: any;
}

const gmMemberRewardsInfoAdapter: any = createEntityAdapter<IGMGiftCards>({});

const initialState = gmMemberRewardsInfoAdapter.getInitialState({
  status: 'idle',
  error: '',
  data: null,
});

export const getGMMemmberRewardsInfo: any = createAsyncThunk(
  'giftcard/getGMMemmberRewardsInfo',
  async (data: any, {getState}) => {
    const state: any = getState();
    const headers = {
      'tenant-id': state?.login.tenantID,
      Authorization: `Bearer ${state?.impersonateToken?.impersonateToken?.accessToken}`,
    };
    return await gqlGetMemberRewardsInfo(data, headers);
  },
);

const gmMemberRewardsInfo = createSlice({
  name: 'gmMemmberRewardsInfo',
  initialState,
  reducers: {
    resetGMMemberRewardsInfo: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getGMMemmberRewardsInfo.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getGMMemmberRewardsInfo.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action?.payload?.data;
      })
      .addCase(getGMMemmberRewardsInfo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default gmMemberRewardsInfo.reducer;

export const {resetGMMemberRewardsInfo} = gmMemberRewardsInfo.actions;
