import globalSpacing from 'Theme/Spacing';

const styles = {
  dialog: {display: 'flex', justifyContent: 'center'},
  content: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
  },
  actions: {
    paddingBottom: globalSpacing.md,
    display: 'flex',
    gap: '10px',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default styles;
