/* eslint-disable no-unused-vars */
import {Card, Typography, useTheme} from '@mui/material';
import React from 'react';
import {Box} from '@mui/system';
import {CardActionArea} from '@mui/material';
import styles from './styles';
import {format, isDate} from 'date-fns';
import {useData} from 'Context/DataContext';
import {DATE_FORMAT} from 'Utils/constants/DateFormat';
import {TRANSACTIONS} from 'Pages/Tabs/Transactions/transactionConstants';

interface IProps {
  item?: any;
  toggleView?: any;
  handleItem?: any;
  disabled?: boolean;
  showRefulfill?: boolean;
}

const TableItemCard: React.FC<IProps> = (props) => {
  const {item, toggleView = null, handleItem = null, disabled = false} = props;
  const {activeTab} = useData();

  const handleSelection = () => {
    toggleView();
    handleItem(item);
  };

  return (
    <>
      <Card sx={{...styles.card, borderColor: useTheme().palette.grey.A200}}>
        <CardActionArea
          onClick={() => handleSelection()}
          disableRipple
          sx={styles.cardActionArea}
          disabled={disabled}>
          {/* TITLE */}
          <Typography color="primary" variant="h4" sx={styles.title}>
            {item.offerTitle}
          </Typography>
          {/* one row multiple columns */}
          <Box flexDirection="row" display="flex" gap="5%">
            {/* claim and expiry */}
            <Box flexDirection="column" display={'flex'} width="40%" justifyContent="space-between">
              <Typography variant="h6" component="h5">
                <strong>{TRANSACTIONS.card_field_map.claimDate} </strong>
                {item.claimDate &&
                  isDate(new Date(item.claimDate)) &&
                  format(new Date(item.claimDate), DATE_FORMAT.dateFormat)}
              </Typography>
              {/* TODO this field might change name in backend to offerenddate for transactions*/}
              {item.expiryDate && (
                <Typography variant="h6" component="h5">
                  <strong>
                    {activeTab === 1
                      ? TRANSACTIONS.card_field_map.offerEndDate
                      : TRANSACTIONS.card_field_map.expiryDate}{' '}
                  </strong>
                  {item.expiryDate &&
                    isDate(new Date(item.expiryDate)) &&
                    format(new Date(item.expiryDate), DATE_FORMAT.dateFormat)}
                </Typography>
              )}
            </Box>
            {/* vendor name */}
            <Box flexDirection="column" display={'flex'} width="30%">
              <Typography variant="subtitle1">{item.merchantName}</Typography>
            </Box>

            {/* type and status */}
            <Box flexDirection="column" display={'flex'}>
              <Typography variant="h6" component="h5">
                {TRANSACTIONS.card_field_map.type}{' '}
                <strong style={{textTransform: 'capitalize'}}>{item.offerType}</strong>
              </Typography>

              <Typography variant="h6" component="h5">
                {TRANSACTIONS.card_field_map.status}{' '}
                <strong style={{textTransform: 'capitalize'}}>{item.transactionStatus}</strong>
              </Typography>
            </Box>
          </Box>
        </CardActionArea>
      </Card>
    </>
  );
};

export default TableItemCard;
