/* eslint-disable no-unused-vars */
import {Button, DialogContent, DialogActions, Typography, Stack} from '@mui/material';
import globalSpacing from 'Theme/Spacing';
import React from 'react';
import PropTypes from 'prop-types';
import {useCSContext} from 'Context/ContentStackContext';
import DialogWrapper from './DialogWrapper';
import styles from './styles';
import {GIFTING} from '../../giftingConstants';

const GiftDialogError = ({openErrorDialog, setOpenErrorDialog, errorMessage}) => {
  const {artifacts} = useCSContext();

  return (
    <DialogWrapper closeDialogMethod={setOpenErrorDialog} open={openErrorDialog}>
      <DialogContent>
        <Stack spacing={globalSpacing.s} sx={styles.container}>
          <img src={artifacts.gift_icon} style={{fontSize: 70, alignSelf: 'center'}} />
          <Typography variant="h3">{GIFTING.gifting_dialog_error.heading}</Typography>
          <Typography variant="body1" textAlign={'center'}>
            {GIFTING.gifting_dialog_error.error_map[errorMessage]}
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={styles.buttonBox}>
        <Button
          variant="contained"
          disableRipple
          onClick={() => setOpenErrorDialog(false)}
          sx={styles.button}>
          {GIFTING.gifting_dialog_error.button_text}
        </Button>
      </DialogActions>
    </DialogWrapper>
  );
};

GiftDialogError.propTypes = {
  openErrorDialog: PropTypes.bool,
  setOpenErrorDialog: PropTypes.func,
  errorMessage: PropTypes.string,
};

export default GiftDialogError;
