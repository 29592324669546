import {globalConfig} from 'Config/config';
import {Mutations, Queries} from 'GQL/GQLConstants';
import {GQLType, gqlCall} from 'GQL/utils';

export const gqlGMClaimedOrderHistory = async (payload, headers) => {
  const {cxRewardsEndpoint} = globalConfig._config;
  try {
    const response = await gqlCall(
      GQLType.QUERY,
      Queries.GM_CLAIMED_ORDER_HISTORY,
      payload,
      headers,
      cxRewardsEndpoint.graphqlCxRewardsEndpoint,
      false,
    );
    return response;
  } catch (error) {
    return {error};
  }
};

export const gqlImpersonateToken = async (payload, headers) => {
  // const {cxRewardsEndpoint} = globalConfig._config;
  const {
    BFF: {estBFF},
  } = globalConfig._config;

  try {
    const response = await gqlCall(
      GQLType.QUERY,
      Queries.GET_IMPERSONATED_TOKEN,
      payload,
      headers,
      estBFF,
      false,
    );

    return response;
  } catch (error) {
    return {error};
  }
};

export const gqlRefulfillClaim = async (payload, headers) => {
  const {cxRewardsEndpoint} = globalConfig._config;
  try {
    const response = await gqlCall(
      GQLType.MUTATION,
      Mutations.REFULLFILL_CLAIM,
      payload,
      headers,
      cxRewardsEndpoint.graphqlCxRewardsEndpoint,
      false,
    );

    return response;
  } catch (error) {
    return {error};
  }
};
