import {createSlice, createAsyncThunk, createEntityAdapter} from '@reduxjs/toolkit'; //createSelector,
import {globalConfig} from 'Config/config';
import {gqlImpersonateToken} from 'GQL/MethodsBFF/GMGiftingHistory';
import {v4 as uuidv4} from 'uuid';

interface IImpersonateToken {
  token: any;
}

const impersonateTokenAdapter: any = createEntityAdapter<IImpersonateToken>({});

const initialState = impersonateTokenAdapter.getInitialState({
  status: 'idle',
  error: '',
  impersonateToken: null,
});

export const getImpersonateToken: any = createAsyncThunk(
  'giftcard/getImpersonateToken',
  async (data: any, {getState}) => {
    // const state: any = getState();

    const uuid = uuidv4();
    const {cxRewardsEndpoint} = globalConfig._config;
    // const resp = await fetchCxRewardsEndpoint();
    const headers = {
      'x-correlation-id': uuid.toString(),
      'tenant-id': cxRewardsEndpoint.tenantId,
      'content-type': 'application/json',
    };

    return await gqlImpersonateToken(data, headers);
  },
);

const impersonateToken = createSlice({
  name: 'impersonateToken',
  initialState,
  reducers: {
    resetImpersonateTokenData: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getImpersonateToken.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getImpersonateToken.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.impersonateToken = action?.payload?.data?.impersonateToken;
      })
      .addCase(getImpersonateToken.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default impersonateToken.reducer;

export const {resetImpersonateTokenData} = impersonateToken.actions;
