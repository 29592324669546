import {Box, DialogContent} from '@mui/material';
import DialogView from 'Components/DialogView';
import Form from 'Components/Form/FormWrapper';
import React, {useEffect, useState} from 'react';
import {initialValues, validationSchema} from './addActivitySchema';
import {activityFormSection, buttonList} from './activityDetails';
import {useSelector} from 'react-redux';
import {updateFormContent} from 'Utils/helpers/SRDetailViewHelper';

const ActivityDetailViewDialog = (props) => {
  let {openAcitvityDialog, isOpenAcitvityDialog, activity, setActivity} = props;
  const [initialFormValues, setInitialFormValues] = useState<any>(initialValues);
  const mapping = useSelector((state: any) => state.getAllMetadata.mapping);
  const [activitySubTypes, setActivitySubTypes] = useState<any>({});
  const [section1FormContent, setSection1FormContent] = useState(activityFormSection);
  const statusArray = ['Completed', 'Cancelled', 'Failed'];

  useEffect(() => {
    if (activity?.id) {
      if (statusArray.includes(activity.status)) {
        disabledFields(true);
      } else {
        disabledFields(false);
        let targetFormFields = {
          type: {
            targetAttribute: 'isDisabled',
            targetValue: true,
          },
        };
        let updatedFormContent = updateFormContent(section1FormContent, targetFormFields);
        setSection1FormContent(updatedFormContent);
      }

      if (isGenerateType(activity.type)) {
        setActivitySubType(activity.type);
        toggleGenerateSmartFocusField(true);
      } else {
        toggleGenerateSmartFocusField(false);
      }
      setInitialFormValues(activity);
    } else {
      disabledFields(false);
      //    setInitialFormValues(initialValues);
      toggleGenerateSmartFocusField(false);
    }
  }, [activity]);

  useEffect(() => {
    getActivitiesList();
  }, [mapping]);

  const getActivitiesList = async () => {
    let srMetaData = mapping;

    const activitiesSet: any = new Set();
    let subTypes: any = {};

    (srMetaData || []).forEach((data) => {
      (data.activityMetadata || []).forEach((item) => {
        activitiesSet.add(item.type);
        if (item.subType) {
          const type = item.type;
          if (subTypes[type]) {
            let subTypeSet: any = new Set(subTypes[type] || []);
            subTypeSet.add(item.subType);
            subTypes[type] = [...subTypeSet];
          } else {
            subTypes[type] = [item.subType];
          }
        }
      });
    });
    setActivitySubTypes(subTypes);
    const activities: any = [...activitiesSet];
    setFormOptions(activities.sort());
  };

  const setFormOptions = (activitiesList) => {
    let targetFormFields = {
      type: {
        targetAttribute: 'options',
        targetValue: activitiesList.map((i) => {
          return {value: i, text: i};
        }),
      },
    };
    let updatedFormContent = updateFormContent(section1FormContent, targetFormFields);
    setSection1FormContent(updatedFormContent);
  };

  const setActivitySubType = (value) => {
    const options = activitySubTypes[value] || [];
    options.sort();
    let targetFormFields = {
      subType: {
        targetAttribute: 'options',
        targetValue: options.map((i) => {
          return {value: i, text: i};
        }),
      },
    };
    let updatedFormContent = updateFormContent(section1FormContent, targetFormFields);
    setSection1FormContent(updatedFormContent);
  };

  const handleOnSubmit = (values) => {
    setActivity(values);
    isOpenAcitvityDialog(false);
  };

  const handleCancel = () => {
    isOpenAcitvityDialog(false);
  };

  const handleTypeChange = (e) => {
    let type = e.target.value;
    initialValues.type = type;
    setInitialFormValues(initialValues);
    if (isGenerateType(type)) {
      setActivitySubType(type);
      toggleGenerateSmartFocusField(true);
    } else {
      toggleGenerateSmartFocusField(false);
    }
  };

  const toggleGenerateSmartFocusField = (display) => {
    let targetFormFields = {
      freeText: {
        targetAttribute: 'display',
        targetValue: display ? 'block' : 'none',
      },
      templateId: {
        targetAttribute: 'display',
        targetValue: display ? 'block' : 'none',
      },
      subType: {
        targetAttribute: 'display',
        targetValue: display ? 'block' : 'none',
      },
    };

    let updatedFormContent = updateFormContent(section1FormContent, targetFormFields);
    setSection1FormContent(updatedFormContent);
  };

  const disabledFields = (value) => {
    let targetFormFields = {
      type: {
        targetAttribute: 'isDisabled',
        targetValue: value,
      },
      subType: {
        targetAttribute: 'isDisabled',
        targetValue: value,
      },
      freeText: {
        targetAttribute: 'isDisabled',
        targetValue: value,
      },
      status: {
        targetAttribute: 'isDisabled',
        targetValue: value,
      },
      priority: {
        targetAttribute: 'isDisabled',
        targetValue: value,
      },
      channel: {
        targetAttribute: 'isDisabled',
        targetValue: value,
      },
      plannedStartDate: {
        targetAttribute: 'isDisabled',
        targetValue: value,
      },
    };
    let updatedFormContent = updateFormContent(section1FormContent, targetFormFields);
    setSection1FormContent(updatedFormContent);
  };

  const customEvents = {
    cancel: {onClick: handleCancel},
    type: {onChange: handleTypeChange},
  };

  const isGenerateType = (type) => {
    return type == 'Generate Correspondence' || type == 'Generate SmartFocus Message';
  };
  return (
    <DialogView
      closeDialogMethod={isOpenAcitvityDialog}
      open={openAcitvityDialog}
      id="activity-dialog">
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}>
        <Box>
          <Form
            onSubmit={handleOnSubmit}
            validationSchema={validationSchema}
            initialValues={initialFormValues}
            section1={section1FormContent}
            customEvents={customEvents}
            buttonStack={buttonList}
          />
        </Box>
      </DialogContent>
    </DialogView>
  );
};

export default ActivityDetailViewDialog;
