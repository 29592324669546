const createRowData: any = (row) => {
  const {
    status,
    type,
    id: ticketNumber,
    additionalData: {reason = '', externalRefId: memberId = '', comments = ''} = {},
    audit: {createdOn = ''} = {},
  }: any = row;

  return {memberId, status, type, ticketNumber, reason, createdOn, comments};
};

export {createRowData};
