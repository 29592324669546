import React from 'react';
import {ErrorMessage, Field} from 'formik';
import {Grid, TextField, useTheme} from '@mui/material';
import globalSpacing from 'Theme/Spacing';

const TextareaBox = (props) => {
  const {formikProp} = props;
  const theme = useTheme();
  const textareaField = props.field;
  const customEvents: any = props.customEvents
    ? props.customEvents[textareaField.name] && props.customEvents[textareaField.name]
    : {};

  return (
    <>
      <Grid
        item
        xs={textareaField.xs}
        sm={textareaField.sm}
        p={globalSpacing.xs}
        sx={{display: textareaField.display}}>
        <Field
          as={TextField}
          sx={{
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.08)',
            borderRadius: `${theme.shape.borderRadius}px`,
            marginRight: globalSpacing.s,
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused': {
                '& fieldset': {
                  borderColor: theme.palette.action.active,
                },
              },
            },
            '& .MuiInputLabel-root': {
              color: theme.palette.text.primary,
              '&.MuiInputLabel-shrink': {
                color: theme.palette.text.primary,
              },
            },
            '& .MuiFormControlLabel-root': {
              marginLeft: '0px',
            },
            '& .MuiFormHelperText-root': {
              color: theme.palette.error.main,
            },
            '& .MuiFormLabel-asterisk': {
              color: theme.palette.error.main,
              fontSize: '24px',
            },
            '& .Mui-disabled': {
              color: theme.palette.text.disabled,
            },
          }}
          label={textareaField.fieldLabel}
          type={textareaField.htmlInputType}
          disabled={textareaField.isDisabled ?? false}
          readOnly={textareaField.readonly ?? false}
          rows={textareaField.rows}
          multiline
          name={textareaField.name}
          fullWidth
          required={textareaField.required}
          helperText={textareaField.note}
          hidden={textareaField.hidden}
          onChange={(e) => {
            formikProp.setFieldValue(textareaField.name, e.target.value);
            customEvents &&
              customEvents.onChange &&
              customEvents['onChange'](e, formikProp.setFieldValue);
          }}
        />
        <ErrorMessage name={textareaField.name} component="div" />
      </Grid>
    </>
  );
};

export default TextareaBox;
