import {Box, Typography, Button} from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import React from 'react';

interface IProps {
  text: string;
  callback: any;
}

const BackButtonWText: React.FC<IProps> = (props) => {
  const {text, callback} = props;
  return (
    <Box flexDirection="row" alignItems="center" display="flex">
      <Button data-testid="back-button" onClick={() => callback()} sx={{textTransform: 'auto'}}>
        <KeyboardBackspaceIcon color="primary" />
        <Typography
          data-testid="back-button-text"
          variant="body2"
          color="primary"
          sx={{textDecoration: 'underline'}}
          onClick={() => callback()}>
          {text}
        </Typography>
      </Button>
    </Box>
  );
};

export default BackButtonWText;
