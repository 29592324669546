import {resetGMGiftCards} from 'Redux/Slices/gmGiftingCards';
import {resetGMOrderHistory} from 'Redux/Slices/gmGiftingHistory';
import {resetGMMemberRewardsInfo} from 'Redux/Slices/gmMemberRewards';
import {resetImpersonateTokenData} from 'Redux/Slices/impersonateToken';

export const resetReduxOnSearch = (dispatch) => {
  dispatch(resetImpersonateTokenData());
  dispatch(resetGMOrderHistory());
  dispatch(resetGMGiftCards());
  dispatch(resetGMMemberRewardsInfo());
};
