import {Stack} from '@mui/material';
import {Box} from '@mui/system';
import {
  CustomDatePicker,
  CustomTextInput,
  InfiniteScrollerCustom,
  Loading,
  NoResults,
  SnackBar,
  TabHeading,
  TableItemCard,
  Wrapper,
} from 'Components';
import RefreshButton from 'Components/RefeshButton';
import {useCSContext} from 'Context/ContentStackContext';
import {api} from 'GQL/MethodsBFF/Transactions';
import {useDetailView, useInfiniteScroll, useSnackBar} from 'Hooks';
import globalSpacing from 'Theme/Spacing';
import {format} from 'date-fns';
import React, {Fragment, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import DetailView from './components/DetailView';
import styles from './styles';
import {TRANSACTIONS} from './transactionConstants';

const TabTransactions = () => {
  const {artifacts} = useCSContext();

  const member = useSelector(
    (state: any) => state?.selectedMember?.selectedMember?.focusedMemberDetails,
  );
  const {txnOfferTypes} = useSelector((state: any) => state?.login?.tenantConfig);

  const txn = ['Select All', ...txnOfferTypes.split(',').map((offer) => offer.trim())];

  const [filterDisabled, setFilterDisabled] = useState(true);
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState<any[]>([]);
  const [filterByType, setFilterByType] = useState(txn[0]);
  const [filterByDate, setFilterByDate] = useState(null);
  const [refreshFetch, setRefreshFetch] = useState(false);

  const [error, setError] = useState(null);
  const [isSnackBarOpen, closeSnackBar] = useSnackBar(error);

  const [totalResults, setTotalResults] = useState(0);
  const [{pageNo, nextPage, resetPage, pageSize}] = useInfiniteScroll();

  const [isDetailView, toggleDetailView] = useDetailView();
  const [selectedTransactionId, setSelectedTransactionId] = useState(null);

  const handleItem = (transaction) => {
    setSelectedTransactionId(transaction.offerId);
  };
  //methods used in useeffect
  const _showLoading = () => pageNo === 0 && setLoading(true);
  const _handleResponse = (data, loading, error) => {
    setLoading(loading);
    error && setError(error.message);
    if (data) {
      const {pagingInfo, transactionDetails} = data;
      pageNo === 0 && setTotalResults(pagingInfo?.totalResults);
      transactionDetails && pageNo === 0
        ? setTransactions(transactionDetails)
        : setTransactions([...transactions, ...transactionDetails]);
    }
  };

  useEffect(() => {
    if (txn) {
      setFilterDisabled(false);
    }
  }, [txn]);

  useEffect(() => {
    if (refreshFetch) {
      (async () => {
        const {data, loading, error} = await api.gqlTransactions({
          referenceId: member?.externalRefId,
          ngMemberId: member?.memberId,
          pageSize: pageSize,
          pageNo,
        });
        _handleResponse(data?.getTransactionByRefId?.[0], loading, error);
        setRefreshFetch(false);
      })();
    }
  }, [refreshFetch]);

  useEffect(() => {
    if (error) setError(null);
    //Filter by type only
    if (!refreshFetch) {
      _showLoading();
      if (!filterByDate) {
        //if filter = show all
        if (filterByType === txn[0]) {
          (async () => {
            const {data, loading, error} = await api.gqlTransactions({
              referenceId: member?.externalRefId,
              ngMemberId: member?.memberId,
              pageSize: pageSize,
              pageNo,
            });
            _handleResponse(data?.getTransactionByRefId?.[0], loading, error);
          })();
        } else {
          //if filter != show all
          (async () => {
            const data = await api.gqlTransactionsByType({
              referenceId: member?.externalRefId,
              ngMemberId: member?.memberId,
              offerType: filterByType,
              pageSize: pageSize,
              pageNo,
            });
            _handleResponse(data?.getTransactionByType?.[0], data.loading, data.error);
          })();
        }
      }
      //Filter by date only (type = show all)
      if (filterByDate && filterByType === txn[0]) {
        //filterByDate (it's already formatted in datepicker onchange)
        (async () => {
          const data = await api.gqlTransactionsByDate({
            referenceId: member?.externalRefId,
            ngMemberId: member?.memberId,
            claimDate: format(new Date(filterByDate), 'yyyy-MM-dd'),
            pageSize: pageSize,
            pageNo,
          });
          _handleResponse(data?.getTransactionsByClaimDate?.[0], data.loading, data.error);
        })();
      }
      //Filter by date and type
      if (filterByDate && filterByType !== txn[0]) {
        //filterByDate(it's already formatted in datepicker onchange)
        (async () => {
          const data = await api.gqlTransactionsByDateAndType({
            referenceId: member?.externalRefId,
            ngMemberId: member?.memberId,
            claimDate: format(new Date(filterByDate), 'yyyy-MM-dd'),
            offerType: filterByType,
            pageSize: pageSize,
            pageNo,
          });
          _handleResponse(data?.getTransactionsByClaimDateAndType?.[0], data.loading, data.error);
        })();
      }
    }
  }, [filterByType, filterByDate, pageNo]);

  const refresh = () => {
    setRefreshFetch(true);
    resetPage();
    setFilterByType(txn[0]);
    setFilterByDate(null);
  };

  return (
    <Wrapper customStyle={{width: '95%'}}>
      {!isDetailView ? (
        <Fragment>
          <TabHeading
            data={TRANSACTIONS}
            addOn={<RefreshButton image={artifacts.refresh} refresh={refresh} />}
          />
          <Box sx={styles.rowBox}>
            {/* DROP DOWN to filter */}
            <CustomTextInput
              disabled={filterDisabled}
              inline
              label={TRANSACTIONS.filter_label}
              name="filter"
              filterMenu={txn}
              value={filterByType}
              onChange={(e) => {
                setFilterByType(e.target.value);
                resetPage();
              }}
            />
            {/* SEARCH FIELD - by claim date */}
            <CustomDatePicker
              date={filterByDate}
              handleChange={(searchDate) => {
                setFilterByDate(searchDate);
                resetPage();
              }}
              label={TRANSACTIONS.date_search_label}
              flex={0.3}
            />
          </Box>
          {/* LIST OF TRANSACTIONS */}
          {loading ? <Loading isOpen={loading} /> : ''}
          <Box overflow="overflow">
            {!loading ? (
              error ? (
                <SnackBar
                  errorMessage={error}
                  isSnackBarOpen={isSnackBarOpen}
                  closeSnackBar={closeSnackBar}
                />
              ) : transactions.length ? (
                <InfiniteScrollerCustom
                  dataLength={transactions.length}
                  next={() => nextPage()}
                  hasMore={transactions.length < totalResults}>
                  <Stack
                    spacing={globalSpacing.s}
                    mt={globalSpacing.sm}
                    boxShadow="none"
                    tabIndex={0}>
                    {transactions?.map((transaction, index) => (
                      <TableItemCard
                        key={index}
                        item={transaction}
                        toggleView={toggleDetailView}
                        handleItem={handleItem}
                      />
                    ))}
                  </Stack>
                </InfiniteScrollerCustom>
              ) : (
                <NoResults text={TRANSACTIONS.no_results_text} />
              )
            ) : null}
          </Box>
        </Fragment>
      ) : (
        <DetailView
          toggleView={toggleDetailView}
          transactionId={selectedTransactionId}
          csData={TRANSACTIONS.detail_view}
        />
      )}
    </Wrapper>
  );
};

export default TabTransactions;
