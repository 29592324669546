import React from 'react';
import Strings from 'Utils/constants/Strings';

const Texts = {...Strings.ErrorBoundary};

const ErrorBoundary = (props) => {
  return (
    <div role="alert">
      <p data-testid="error-alert">{Texts.SomethingWentWrong}</p>
      <pre style={{color: 'red'}}>{props.error.message}</pre>
    </div>
  );
};

export default ErrorBoundary;
