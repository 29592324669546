import {createSlice, createAsyncThunk, createEntityAdapter} from '@reduxjs/toolkit';
import {gqlCountries} from 'GQL/MethodsBFF/ServiceRequest';

interface Country {}

const countryAdapter: any = createEntityAdapter<Country>({
  selectId: (country: any) => country.name,
});

const initialState = countryAdapter.getInitialState({
  status: 'idle',
  error: '',
});

export const fetchCountries: any = createAsyncThunk(
  'country/fetchCountries',
  async (data: any, {getState}) => {
    const state: any = getState();
    const headers = {
      'tenant-id': state?.login.tenantID,
      'user-access-token': state?.login.loginInfo?.access_token,
      'client-access-token': state?.login.loginInfo?.service_token,
    };
    return await gqlCountries({}, headers);
  },
);

const countriesSlice = createSlice({
  name: 'country',
  initialState,
  reducers: {
    resetCountries: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCountries.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (action?.payload?.data) {
          countryAdapter.setAll(state, action?.payload?.data?.getCountries || []);
        }
      })
      .addCase(fetchCountries.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default countriesSlice.reducer;

export const {selectAll: selectCountries} = countryAdapter.getSelectors((state) => {
  return state.countries;
});

export const {resetCountries} = countriesSlice.actions;
