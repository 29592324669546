import Snackbar from '@mui/material/Snackbar';
import {IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Alert} from '@mui/material';
import globalSpacing from '../../Theme/Spacing';

interface IProps {
  errorMessage: string;
  isSnackBarOpen: any;
  closeSnackBar: any;
  clearMessageOnClose?: any;
  severity?: any;
}
const SnackBar: React.FC<IProps> = (props) => {
  const {
    errorMessage,
    closeSnackBar,
    isSnackBarOpen,
    clearMessageOnClose = () => {},
    severity,
  } = props;

  const handleClose = (event, reason = '') => {
    if (reason === 'clickaway') {
      return;
    }
    closeSnackBar();
    clearMessageOnClose();
  };

  const action = (
    <Fragment>
      <IconButton
        size="medium"
        aria-label="close"
        color="inherit"
        onClick={(e) => handleClose(e, '')}
        type="button">
        <CloseIcon fontSize="medium" />
      </IconButton>
    </Fragment>
  );

  return (
    <Snackbar
      open={isSnackBarOpen}
      autoHideDuration={10000}
      onClose={(e) => handleClose(e, '')}
      sx={{width: '100%', marginBottom: globalSpacing.l}}
      action={action}>
      <Alert
        onClose={(e) => handleClose(e, '')}
        severity={severity ? severity : 'error'}
        sx={{minWidth: '25%', maxWidth: '50%', paddingY: globalSpacing.sm}}>
        {errorMessage}
      </Alert>
    </Snackbar>
  );
};

SnackBar.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  closeSnackBar: PropTypes.func.isRequired,
  clearMessageOnClose: PropTypes.func,
  isSnackBarOpen: PropTypes.bool.isRequired,
};

export default SnackBar;
