import React, {useState, useEffect, Fragment} from 'react';
import {Box} from '@mui/material';
import GMGiftCard from './components/GMGiftCard';
import GMGiftDetailView from './components/GMGiftDetailView';
import globalSpacing from 'Theme/Spacing';
import {Wrapper, InfiniteScrollerCustom, SnackBar, Loading} from 'Components';
import styles from './styles';
import {useSnackBar, useInfiniteScroll, useDetailView} from 'Hooks';
import {useDispatch, useSelector} from 'react-redux';
import {getImpersonateToken} from 'Redux/Slices/impersonateToken';
import {getGMGiftingCards} from 'Redux/Slices/gmGiftingCards';
import MemberAccountInfo from './components/MemberAccountInfo';

function TabGMGifting() {
  const {selectedMember} = useSelector((state: any) => state?.selectedMember);
  const [isDetailView, toggleDetailView] = useDetailView();
  const [selectedGiftOffer, setSelectedGiftOffer] = useState(null);
  const [giftData, setGiftData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [updateAccount, setAccount] = useState(true);
  const [isSnackBarOpen, closeSnackBar] = useSnackBar(error);
  //for infinite scroll
  const [totalResults, setTotalResults] = useState(0);
  const [{pageNo, nextPage, pageSize}] = useInfiniteScroll(12);

  const dispatch = useDispatch();
  const impersonateToken = useSelector((state: any) => {
    return state?.impersonateToken.impersonateToken;
  });

  const handleItem = (item) => {
    setSelectedGiftOffer(item);
  };

  const _handleResponse = (data, loading, error) => {
    setLoading(loading);
    error && setError(error.message);
    if (data) {
      const {pagingInfo, offers} = data;
      pageNo === 0 && setTotalResults(pagingInfo?.totalResults);
      offers && pageNo === 0 ? setGiftData(offers) : setGiftData([...giftData, ...offers]);
    }
  };
  const getGiftcards = async () => {
    try {
      const data1 = {externalRefId: selectedMember?.focusedMemberDetails?.externalRefId};
      if (!impersonateToken) {
        await dispatch(getImpersonateToken(data1)).unwrap();
      }
      let payload = {
        redemptionMethod: null,
        pageNo: pageNo,
        pageSize: pageSize,
      };
      const {data, loading, error} = await dispatch(getGMGiftingCards(payload)).unwrap();
      _handleResponse(data?.filteredGiftCards, loading, error);
    } catch (error: any) {
      console.error(error);
      return error;
    }
  };

  useEffect(() => {
    setAccount(false);
  }, [giftData]);

  useEffect(() => {
    getGiftcards();
  }, [pageNo]);
  return (
    <Wrapper>
      {!isDetailView ? (
        <Fragment>
          {loading ? (
            <Loading />
          ) : error ? (
            <SnackBar
              errorMessage={error}
              closeSnackBar={closeSnackBar}
              isSnackBarOpen={isSnackBarOpen}
            />
          ) : (
            <>
              <MemberAccountInfo updateAccount={updateAccount} />
              <InfiniteScrollerCustom
                dataLength={giftData.length}
                next={() => nextPage()}
                hasMore={giftData.length < totalResults}>
                <Box py={globalSpacing.md} sx={styles.giftCardListContainer}>
                  {giftData.map((item, index) => (
                    <GMGiftCard
                      key={index}
                      item={item}
                      toggleView={toggleDetailView}
                      handleItem={handleItem}
                    />
                  ))}
                </Box>
              </InfiniteScrollerCustom>
            </>
          )}
        </Fragment>
      ) : (
        <GMGiftDetailView toggleView={toggleDetailView} offer={selectedGiftOffer} />
      )}
    </Wrapper>
  );
}

export default TabGMGifting;
