import {globalConfig} from 'Config/config';
import {Queries} from 'GQL/GQLConstants';
import {gqlCall, GQLType} from 'GQL/utils';

const gqlSearchMemberByExtId = async (externalRefId, headers) => {
  const {
    client: {tenantID, brandName},
    BFF: {astBFF},
  } = globalConfig._config;
  try {
    const response = await gqlCall(
      GQLType.QUERY,
      Queries.GET_MEMBER_BY_EXT_ID,
      {
        searchMemberByExtRefId: {
          tenantId: tenantID,
          brandName: brandName,
          ...externalRefId,
        },
      },
      headers,
      astBFF,
      false,
    );
    return response;
  } catch (error) {
    return {error};
  }
};

const gqlSearchMember = async (payload, headers) => {
  const {
    BFF: {estBFF},
  } = globalConfig._config;
  try {
    const response = await gqlCall(
      GQLType.QUERY,
      Queries.SEARCH_MEMBER,
      payload,
      headers,
      estBFF,
      false,
    );
    return response;
  } catch (error) {
    return {error};
  }
};

const gqlGetMemberDetails = async (variable, headers) => {
  const {
    BFF: {estBFF},
  } = globalConfig._config;
  // pass member id to getMemberDetails
  /**
       * {
            "memberId": "83a0bd48-38f1-4433-8bd8-fdfdcc48bc0c",
            "membershipId": "6b7cd1de-b1ce-43b4-a916-081c83900dfe"
          }       
       */
  try {
    const response = await gqlCall(
      GQLType.QUERY,
      Queries.GET_MEMBER_DETAILS,
      variable,
      headers,
      estBFF,
      false,
    );
    return response;
  } catch (error) {
    return {error};
  }
};

export {gqlSearchMemberByExtId, gqlSearchMember, gqlGetMemberDetails};
