import {Box, Grid, IconButton, Slider, Typography} from '@mui/material';
import AddCircleOutlineTwoToneIcon from '@mui/icons-material/AddCircleOutlineTwoTone';
import RemoveCircleOutlineTwoToneIcon from '@mui/icons-material/RemoveCircleOutlineTwoTone';
import RedeemIcon from '@mui/icons-material/Redeem';
import OTPInput from './CreditCardInput';

import React, {useEffect, useMemo, useState} from 'react';
import globalSpacing from 'Theme/Spacing';
import styles from './../styles';
import {useSelector} from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
interface IBuyGiftCardProps {
  min: number;
  max: number;
  buyOffer: Function;
  loginData: any;
  giftCardType: string;
  error?: string;
  clearError: () => void;
}

const getStaticValues = (startingAmount: number): number[] => {
  return startingAmount < 25 ? [startingAmount, 25, 50, 75] : [startingAmount, 50, 75, 100];
};

function BuyGiftCard(props: IBuyGiftCardProps) {
  const agentUserName = useSelector((state: any) => state?.login?.userName);
  const memberAccountInfo = useSelector((state: any) => state?.gmMemmberRewardsInfo);
  const MAXIMUM_RANGE = props.max ? props.max : 75;
  const MINIMUM_RANGE = props.min ? props.min : 15;

  const [amount, setAmount] = useState(MINIMUM_RANGE);
  const [loading, setLoading] = useState(false);
  const [ccDigits, setCcDigits] = useState('');

  const staticValuesArray = getStaticValues(MINIMUM_RANGE);
  const SLIDER_STEP = 5;
  const quantity = 1;

  useEffect(() => {
    setLoading(false);
  }, [props.error]);

  const handleChange = (event: Event, newValue: number | number[]) => {
    props.clearError();
    setAmount(newValue as number);
  };

  const redeemableDollars = memberAccountInfo?.data?.memberAccountInfo?.redeemableDollars;
  const buyNowEnabled =
    props.giftCardType !== 'Plastic' && ccDigits.length >= 4 && amount <= redeemableDollars;

  const sliderProps = useMemo(
    () => ({
      disableSwap: true,
      min: MINIMUM_RANGE,
      max: MAXIMUM_RANGE,
      value: amount,
      step: SLIDER_STEP,
      onChange: (e, v) => handleChange(e, v),
    }),
    [MAXIMUM_RANGE, MINIMUM_RANGE, amount, handleChange],
  );

  const generateStaticValues = (): any => {
    return Array.apply(1, Array(staticValuesArray.length)).map((x: any, index: number) => {
      const value = staticValuesArray[index];
      const color = value === amount ? 'black' : 'white';
      const textColor = value === amount ? 'white' : 'black';
      return (
        <Box
          color="primary"
          key={index}
          onClick={() => selectValidAmount(value)}
          style={styles.pricing_table_col}
          sx={{backgroundColor: color, color: textColor}}>
          ${value}
        </Box>
      );
    });
  };
  const selectValidAmount = (selectedAmount: number) => {
    props.clearError();
    selectedAmount <= MINIMUM_RANGE ? setAmount(MINIMUM_RANGE) : setAmount(selectedAmount);
  };
  return (
    <Grid spacing={globalSpacing.s} textAlign="center" paddingLeft="20px">
      <Typography variant="subtitle1">Select your amount</Typography>

      <Box sx={styles.pricing_table}>{generateStaticValues()}</Box>

      <Box display="flex" justifyContent="space-between">
        {
          <IconButton
            aria-label="minus"
            size="large"
            color="primary"
            onClick={() => {
              props.clearError();
              amount <= MINIMUM_RANGE
                ? setAmount(MINIMUM_RANGE)
                : setAmount(amount - MINIMUM_RANGE);
            }}
            disabled={amount <= MINIMUM_RANGE ? true : false}>
            <RemoveCircleOutlineTwoToneIcon fontSize="inherit" />
          </IconButton>
        }
        <Box alignItems="center" fontWeight="bold" mt="15px">
          {amount}
        </Box>
        {
          <IconButton
            size="large"
            color="primary"
            disabled={amount === MAXIMUM_RANGE ? true : false}
            onClick={() => {
              props.clearError();
              setAmount(amount + MINIMUM_RANGE);
            }}>
            <AddCircleOutlineTwoToneIcon fontSize="inherit" />
          </IconButton>
        }
      </Box>
      <Box mb="20px" paddingLeft="30px">
        <Slider valueLabelDisplay="auto" {...sliderProps} />
      </Box>
      <Box mb={globalSpacing.m}>
        <Box mb={globalSpacing.m}>
          For security purposes, please enter the last four digits of the Rewards card.
        </Box>
        <Box paddingLeft="20px">
          <OTPInput
            autoFocus
            isNumberInput
            length={4}
            onChangeOTP={(otp) => {
              props.clearError();
              setCcDigits(otp.toString());
            }}
            inputClassName="otp_input"
          />
        </Box>
      </Box>
      <LoadingButton
        loadingPosition="start"
        loading={loading}
        onClick={() => {
          props.buyOffer(amount, quantity, ccDigits, agentUserName);
          setLoading(true);
        }}
        disabled={!buyNowEnabled}
        variant="contained"
        startIcon={<RedeemIcon />}>
        Redeem Now
      </LoadingButton>

      <br />
      {amount > redeemableDollars && (
        <Box sx={{color: 'red'}}>Selected amount is greater than available balance</Box>
      )}
      {props.error && <Box sx={{color: 'red'}}>{props.error}</Box>}
    </Grid>
  );
}

export default BuyGiftCard;
