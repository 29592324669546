import React, {useEffect, useState} from 'react';
import TableView from 'Components/TableView';
import {useSelector} from 'react-redux';
import {SortOrder} from 'Utils/enums';
import {sortTable} from 'Utils/helpers/SRListHelpers';
import {getActivityData, mapTableData} from './ActivityListGridDataSource';

const ActivityListView = (props) => {
  const {onRowClick} = props;
  const [sortOrder, setSortOrder] = useState(SortOrder.DESCENDING);
  const [tableData, setTableData] = useState<any[]>([]);
  const [currentSortCriteria, setCriteriaSort] = useState('');
  const selectedSRData = useSelector((state: any) => state.selectedSR.getServiceRequestById);
  const tenantConfig = useSelector((state: any) => state?.login?.tenantConfig);

  useEffect(() => {
    let activities = selectedSRData?.activities;
    if (activities?.length) {
      const tempTableData = mapTableData(activities);
      let resp = sortTable(
        tempTableData,
        'type',
        currentSortCriteria,
        SortOrder.ASCENDING,
        tenantConfig.locale,
      );
      setCriteriaSort(resp.currentSortCriteria);
      setTableData(resp.tableData);
      setSortOrder(resp.newSortOrder);
    } else setTableData([]);
  }, [selectedSRData]);

  const onSortIconClick = (sortCriteria: string) => {
    let resp = sortTable(
      tableData,
      sortCriteria,
      currentSortCriteria,
      sortOrder,
      tenantConfig.locale,
    );
    setCriteriaSort(resp.currentSortCriteria);
    setTableData(resp.tableData);
    setSortOrder(resp.newSortOrder);
  };

  return (
    <div>
      <TableView
        {...getActivityData(tableData, sortOrder, currentSortCriteria, true)}
        onRowClick={onRowClick}
        sortTable={onSortIconClick}
      />
    </div>
  );
};

export default ActivityListView;
