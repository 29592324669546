import {createSlice, createAsyncThunk, createEntityAdapter} from '@reduxjs/toolkit';
import {gqlSearchSRByType} from 'GQL/MethodsBFF/ServiceRequest';

interface RecentSRList {}

const selectedRecentSRAdapter: any = createEntityAdapter<RecentSRList>({});

const initialState = selectedRecentSRAdapter.getInitialState({
  status: 'idle',
  error: '',
});

export const searchSRByTypeRecent: any = createAsyncThunk(
  'serviceRequest/searchSRByTypeRecent',
  async (data: any, {getState}) => {
    const state: any = getState();
    const headers = {
      'tenant-id': state?.login.tenantID,
      'user-access-token': state?.login.loginInfo?.access_token,
      'client-access-token': state?.login.loginInfo?.service_token,
    };
    let resp = await gqlSearchSRByType(data, headers);
    return resp;
  },
);

const recentSRList = createSlice({
  name: 'recentSRList',
  initialState,
  reducers: {
    resetRecentSRData: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(searchSRByTypeRecent.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(searchSRByTypeRecent.fulfilled, (state, action) => {
        state.status = 'succeeded';
        selectedRecentSRAdapter.setAll(
          state,
          action?.payload?.data?.getServiceRequestsByType?.searchServiceRequestsOutputDtoList || [],
        );
      })
      .addCase(searchSRByTypeRecent.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default recentSRList.reducer;

export const {selectAll: selectAllRecentSRList} = selectedRecentSRAdapter.getSelectors((state) => {
  return state.recentSRList;
});

export const {resetRecentSRData} = recentSRList.actions;
