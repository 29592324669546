import {Box, Button, ListItemIcon, Menu, MenuItem, Typography, useTheme} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import SnackBar from 'Components/SnackBar';
import {globalConfig} from 'Config/config';
import {useCSContext} from 'Context/ContentStackContext';
import {useData} from 'Context/DataContext';
import {useSnackBar} from 'Hooks';
import iconSize from 'Theme/Icon';
import {LOGIN, QUERY_DASHBOARD, SEARCH} from 'Utils/constants/Paths';
import createAvatar from 'Utils/helpers/CreateAvatar';
import {stopTokenRefreshTimer} from 'Utils/helpers/RefreshTokenScheduler';
import React, {Fragment, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link, useLocation} from 'react-router-dom';
import LogoutBackdrop from './LogoutBackdrop';
import styles from './styles';

const Header = () => {
  const {header, artifacts} = useCSContext();
  const {pathname} = useLocation();
  const {setStoredIdentifier} = useData();
  const [error, setError] = useState('');
  const [isSnackBarOpen, closeSnackBar] = useSnackBar(error);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const agentName = useSelector((state: any) => {
    return state?.login.name;
  });
  const roleConfig = useSelector((state: any) => state?.login?.roleConfig);
  const open = Boolean(anchorEl);

  const colorPalette = useTheme().palette.primary;

  const openProfileMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const logoutAgent = async () => {
    setAnchorEl(null);
    setLoading(true);
    try {
      stopTokenRefreshTimer();
      sessionStorage.clear();
      localStorage.clear();
      window.location.replace(
        `${globalConfig._config.internalSSOConfig.REACT_APP_KEYCLOAK_ENDPOINT}/realms/${globalConfig._config.internalSSOConfig.REACT_APP_REALM}/protocol/openid-connect/logout?redirect_uri=${window.location.href}`,
      );
    } catch (err) {
      setError('Something went wrong');
      console.warn(err);
    }
    setLoading(false);
  };

  return (
    <Fragment>
      <Box sx={styles.main} bgcolor="background.paper">
        <Box sx={styles.containerLeft}>
          <img src={artifacts.logo} alt="logo" style={{width: '150px'}} />
          <Typography variant="h5">
            <span role="heading" aria-level={1}>
              {header.heading}
            </span>
          </Typography>
        </Box>
        {pathname !== LOGIN && (
          <Box style={styles.containerRight}>
            <Link
              //resets the input field - only when new member is clicked, not on back button
              onClick={() => setStoredIdentifier('')}
              to={SEARCH}
              style={{
                ...styles.wrapperIconText,
                ...(pathname === SEARCH && styles.borderStyle(colorPalette.main)),
              }}>
              <img src={artifacts.search_icon} style={iconSize} alt="New Search" />
              <Typography variant="body2">Member Search</Typography>
            </Link>
            {roleConfig?.queryDashboard && (
              <Link
                to={QUERY_DASHBOARD}
                style={{
                  ...styles.wrapperIconText,
                  ...(pathname === QUERY_DASHBOARD && styles.borderStyle(colorPalette.main)),
                }}>
                <img
                  src={artifacts.query_dashboard_icon}
                  style={iconSize}
                  alt="Query Dashboard icon"
                />
                <Typography variant="body2">{header.query_dashboard_title}</Typography>
              </Link>
            )}
            <Box sx={styles.wrapperIconText}>
              <Button
                disableRipple={true}
                sx={{textTransform: 'none', color: 'inherit'}}
                onClick={openProfileMenu}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                startIcon={
                  <Avatar
                    alt={' '}
                    sx={{
                      ...iconSize,
                      backgroundColor: 'primary.main',
                      fontSize: '14px !important',
                      fontFamily: 'bold',
                    }}>
                    {createAvatar(agentName)}
                  </Avatar>
                }>
                <Typography variant="body2">{agentName}</Typography>
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}>
                <MenuItem onClick={logoutAgent}>
                  <ListItemIcon>
                    <img
                      src={artifacts.logout_icon}
                      style={{height: 25, width: 25}}
                      alt={header.logout_button_title}
                    />
                  </ListItemIcon>
                  <Typography variant="body1">{header.logout_button_title}</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        )}
        <SnackBar
          isSnackBarOpen={isSnackBarOpen}
          closeSnackBar={closeSnackBar}
          errorMessage={error}
        />
      </Box>
      {loading && <LogoutBackdrop text="Logging out" />}
    </Fragment>
  );
};

export default Header;
