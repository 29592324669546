import {createSlice, createAsyncThunk, createEntityAdapter} from '@reduxjs/toolkit'; //createSelector,
import {gqlSearchMember} from 'GQL/MethodsBFF/SearchMember';

interface Members {
  addressLine1: string | null;
  city: string | null;
  dateOfBirth: string;
  firstName: string;
  lastName: string;
  memberNumber: string;
  membershipNumber: string;
  membershipId: string;
  memberId: string;
  packageId: string;
  packageName: string;
  postalCode: string;
  status: string;
  tenantId: string;
}

const memberAdapter: any = createEntityAdapter<Members>({
  selectId: (member: Members) => member.memberId,
});

const initialState = memberAdapter.getInitialState({
  status: 'idle',
  error: '',
});

export const fetchMember: any = createAsyncThunk(
  'member/fetchMember',
  async (data: any, {getState}) => {
    const state: any = getState();
    const headers = {
      'tenant-id': state?.login.tenantID,
      'user-access-token': state?.login.loginInfo?.access_token,
      'client-access-token': state?.login.loginInfo?.service_token,
    };
    return await gqlSearchMember(data?.payload, headers);
  },
);

const memberSlice = createSlice({
  name: 'member',
  initialState,
  reducers: {
    resetMemberData: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchMember.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchMember.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (action?.payload?.data) {
          memberAdapter.setAll(state, action?.payload?.data?.searchMember?.memberDetails || {});
        }
      })
      .addCase(fetchMember.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default memberSlice.reducer;

export const {selectAll: selectAllMember} = memberAdapter.getSelectors((state) => state.member);

export const {resetMemberData} = memberSlice.actions;
