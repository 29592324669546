export const initialValues = {
  channel: '',
  firstName: '',
  status: '',
  lastName: '',
  email: '',
  cinemaChain: '',
  transactionDate: null,
  totalVouchers: '',
  orderPrice: '',
  ticketVouchers: '',
  currency: '',
};
