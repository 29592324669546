import {
  DialogContent,
  Typography,
  Dialog,
  DialogTitle,
  DialogContentText,
  useTheme,
} from '@mui/material';
import React from 'react';
import CloseIconDialog from 'Components/CloseIconDialog';
import {useCSContext} from 'Context/ContentStackContext';
import {useDialogScrollBar} from 'Hooks';
import globalSpacing from 'Theme/Spacing';
import {SR_DASHBOARD} from 'Pages/SRDashboard/srDashboardConstants';

interface IProps {
  openCommentsDialog: any;
  setOpenCommentsDialog: React.Dispatch<React.SetStateAction<boolean>>;
  comments?: string;
}

const CommentsDialog: React.FC<IProps> = (props) => {
  const {openCommentsDialog, setOpenCommentsDialog, comments} = props;
  const {artifacts} = useCSContext();

  const {showScrollBar, isScrollbarVisible} = useDialogScrollBar(openCommentsDialog);

  return (
    <Dialog
      fullWidth
      maxWidth={'md'}
      sx={{height: '70%', margin: 'auto', padding: globalSpacing.lg}}
      open={openCommentsDialog}
      aria-label="dialog-input"
      id="input-dialog"
      onClose={() => setOpenCommentsDialog(false)}
      disableEscapeKeyDown>
      <CloseIconDialog callback={() => setOpenCommentsDialog(false)} image={artifacts.cross_icon} />
      <DialogTitle sx={{paddingTop: globalSpacing.md}}>
        <Typography variant="h5">{SR_DASHBOARD.comments_dialog.heading}</Typography>
      </DialogTitle>

      <DialogTitle sx={{paddingTop: '0rem'}}>
        <Typography variant="subtitle1">{SR_DASHBOARD.comments_dialog.subtitle}</Typography>
      </DialogTitle>

      <DialogContent
        onScrollCapture={(evt) => showScrollBar(evt)}
        sx={{
          '&.MuiDialogContent-root': {marginBottom: '1.5rem', marginRight: '1.5rem'},
          '&:hover&.MuiDialogContent-root': {border: '1rem'},
          '&::-webkit-scrollbar': {
            width: '0.3rem',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'lightgrey',
            borderRadius: 15,
            visibility: isScrollbarVisible ? 'visible' : 'hidden',
          },
        }}>
        <DialogContentText sx={{paddingBottom: globalSpacing.md}}>
          <Typography
            variant="subtitle2"
            sx={{color: useTheme().palette.text.primary, whiteSpace: 'break-spaces'}}>
            {comments}
          </Typography>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default CommentsDialog;
