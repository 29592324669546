import {Box, Typography, Stack} from '@mui/material';
import {getGMMemmberRewardsInfo} from 'Redux/Slices/gmMemberRewards';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useSnackBar} from 'Hooks';
import Loading from 'Components/Loading';
import SnackBar from 'Components/SnackBar';

const MemberAccountInfo = (props) => {
  const {selectedMember} = useSelector((state: any) => state?.selectedMember);
  const [memberAccountInfo, setmemberAccountInfo] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSnackBarOpen, closeSnackBar] = useSnackBar(error);

  const dispatch = useDispatch();

  useEffect(() => {
    getMemberAccountInfo();
  }, [props.updateAccount]);

  const getMemberAccountInfo = async () => {
    try {
      const data1 = {externalRefId: selectedMember?.focusedMemberDetails?.externalRefId};
      let response = await dispatch(getGMMemmberRewardsInfo(data1)).unwrap();
      const {data, loading, error} = response;
      _handleResponse(data, loading, error);
    } catch (error: any) {
      console.error(error);
      return error;
    }
  };

  const _handleResponse = (data, loading, error) => {
    setLoading(loading);
    error && setError(error.message);
    if (data) {
      setmemberAccountInfo(data?.memberAccountInfo);
    }
  };

  const numberWithCommas = (x: number): string => {
    if (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
    }
    return '0';
  };
  return (
    <>
      {loading ? (
        <Loading />
      ) : error ? (
        <SnackBar
          errorMessage={error}
          closeSnackBar={closeSnackBar}
          isSnackBarOpen={isSnackBarOpen}
        />
      ) : (
        <Stack spacing={1}>
          <Box component="div" mt={0} display="flex" justifyContent="end" alignItems="center">
            <Typography variant="body1">
              <Box>{'AVAILABLE POINTS'}</Box>
              <Box justifyContent="end" display="flex" fontWeight="bold">
                {numberWithCommas(memberAccountInfo?.redeemablePoints)}
              </Box>
            </Typography>
            <Typography variant="body1" marginLeft="40px">
              <Box>{'AVAILABLE CASH BALANCE'}</Box>
              <Box justifyContent="end" display="flex" fontWeight="bold">
                ${numberWithCommas(memberAccountInfo?.redeemableDollars)}/$
                {numberWithCommas(memberAccountInfo?.capDollars)}
              </Box>
            </Typography>
          </Box>
        </Stack>
      )}
    </>
  );
};

export default MemberAccountInfo;
