import {createSlice, createAsyncThunk, createEntityAdapter} from '@reduxjs/toolkit';
import {gqlGetMemberDetails} from 'GQL/MethodsBFF/SearchMember';

interface Status {
  isActive: Boolean;
  startDate: String;
}
interface MemberAddress {
  type?: String;
  postalCode: String | null;
  country: String | null;
  addressLine1: String | null;
  addressLine2: String | null;
  addressLine3: String | null;
  streetName: String | null;
  houseNo: String | null;
  houseName: String | null;
  flatNo: String | null;
  city: String | null;
  cityCode: String | null;
  state: String | null;
  stateCode: String | null;
  countryCode: String | null;
  county: String | null;
  status: Status;
}
interface MemberPreference {
  hasOpted: Boolean;
  preferenceType: String;
  status: Status;
  value: String;
}
interface Communication {
  communicationSubType: String;
  communicationType: String;
  communicationValue: String;
  isPrimary: Boolean;
  status: Status;
}
interface Member {
  id: String;
  externalRefId: String;
  firstName?: String;
  lastName?: String;
  middleName?: String | null;
  suffix?: String | null;
  salutation?: String | null;
  gender?: String | null;
  dateOfBirth: String | null;
  addresses: MemberAddress[];
  status: String;
  preferences: MemberPreference[];
  communication: Communication[];
  activeFrom?: String;
  activeTo?: String | null;
  isActive: Boolean;
  isWebEnabled: String | null;
  webEnableDate: String | null;
  memberJoinDate: String;
  addresseeLine1: String;
  addresseeLine2: String | null;
  addresseeLine3: String | null;
  brandName: String;
  languageCode: String | null;
  attributes: {
    clientId: String;
  };
}
interface SelectedMember {
  id: string;
  selectedMember: Member;
}

const memberAdapter: any = createEntityAdapter<SelectedMember>({});

const initialState = memberAdapter.getInitialState({
  status: 'idle',
  error: '',
});

export const fetchMemberDetails: any = createAsyncThunk(
  'member/fetchMemberDetails',
  async (data: any, {getState}) => {
    const state: any = getState();
    const headers = {
      'tenant-id': state?.login.tenantID,
      'user-access-token': state?.login.loginInfo?.access_token,
      'client-access-token': state?.login.loginInfo?.service_token,
    };
    let response = await gqlGetMemberDetails(data.variable, headers);
    let customizedResponse;
    if (response?.error) {
      customizedResponse = response;
    } else if (response?.data && response?.data?.memberDetails) {
      customizedResponse = {
        ...response?.data?.memberDetails,
      };
      if (response?.data?.memberDetails?.focusedMemberDetails) {
        customizedResponse.currentMemberDetails =
          response?.data?.memberDetails?.focusedMemberDetails;
      }
    } else {
      customizedResponse = null;
    }

    return customizedResponse;
  },
);

const selectedMemberSlice = createSlice({
  name: 'selectedMember',
  initialState,
  reducers: {
    resetMemberData: (state) => {
      Object.assign(state, initialState);
    },
    setSelectedMemberData: (state, action) => {
      state.selectedMember = action.payload;
    },
    setCurrentMemberData: (state, action) => {
      state.selectedMember.currentMemberDetails = action.payload;
    },
    setCurrentMemberBenefitDetails: (state, action) => {
      let additionalBenefitList = JSON.parse(action.payload || '[]');
      let benefitDetails = state.selectedMember?.benefits || [];
      if (additionalBenefitList && additionalBenefitList.length > 0) {
        benefitDetails = [...benefitDetails, ...additionalBenefitList];
      }

      state.selectedMember.benefits = benefitDetails;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchMemberDetails.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchMemberDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.selectedMember = action.payload;
      })
      .addCase(fetchMemberDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default selectedMemberSlice.reducer;

export const {
  resetMemberData,
  setSelectedMemberData,
  setCurrentMemberData,
  setCurrentMemberBenefitDetails,
} = selectedMemberSlice.actions;
