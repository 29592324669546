import {Box, Button, Collapse, TableCell, TableRow} from '@mui/material';
// import {makeStyles} from 'tss-react/mui';
// import {REDEEM_CLAIMED_OFFER_TYPE, REFULFILL_CLAIMED_OFFER_TYPE} from 'Constants/constants';
// import {ClaimedOffer, ClaimedOfferAdditionalData} from 'Models/ClaimedOfferModel';
import React from 'react';
import ReFulfillAdditionaDataRow from './RefulfillAdditionalDataRow';
import {
  REDEEM_CLAIMED_OFFER_TYPE,
  REFULFILL_CLAIMED_OFFER_TYPE,
} from 'Utils/constants/genericConstants';
import RedeemAdditionaDataRow from './RedeemAdditionalDataRow';
// import RedeemAdditionaDataRow from './RedeemAdditionaDataRow';
// import ReFulfillAdditionaDataRow from './ReFulfillAdditionaDataRow';
// import {TableCell} from './StyledTableCell';

interface NestedRowContainerProps {
  row: any;
  backgroundColor: string;
  onReFulfillClick: (ClaimedOfferAdditionalData, string, boolean) => void;
  isOpen?: boolean;
}

// const useStyles = makeStyles((theme) => {
//   return {
//     border: {
// marginLeft: 30,
// display: 'block',
// paddingLeft: 0,
//     },
//     innerBorder: {
//       marginLeft: 14,
//     },
//     button: {
// fontWeight: 800,
// minWidth: 170,
// padding: 8,
//     },
//   };
// });

const getAddressJSX = (row: any) => {
  return row.type === 'Plastic' ? (
    <span>
      {row.additionalData?.sentToAddress?.line1 && <p>{row.additionalData.sentToAddress?.line1}</p>}
      {row.additionalData?.sentToAddress?.line2 && <p>{row.additionalData.sentToAddress?.line2}</p>}
      <p>
        {row.additionalData?.sentToAddress?.city},&nbsp;
        {row.additionalData?.sentToAddress?.region},&nbsp;
        {row.additionalData?.sentToAddress?.postalCode}
      </p>
    </span>
  ) : undefined;
};

const NestedRowContainer = (props: NestedRowContainerProps) => {
  //   const {classes} = useStyles();

  const isRedeemOrderType = props.row.orderType === REDEEM_CLAIMED_OFFER_TYPE;

  const isRedeemSpecialCase = isRedeemOrderType && props.row.additionalDataSecondRow;

  const isReFullfillOrderType = props.row.orderType === REFULFILL_CLAIMED_OFFER_TYPE;

  const isReFullfillSpecialCase = isReFullfillOrderType && props.row.additionalDataSecondRow;

  const getButtonJSX = (isSecondRow: boolean, data: any) => {
    if (
      (!isSecondRow &&
        !props.row.buttonDetails.isSpecialCase &&
        props.row.buttonDetails?.visible) ||
      (isSecondRow && props.row.buttonDetails?.visible)
    ) {
      return (
        <Button
          variant="contained"
          onClick={() => {
            props.onReFulfillClick(
              data,
              props.row.buttonDetails?.type,
              props.row?.buttonDetails?.isSpecialCase,
            );
          }}
          style={{fontWeight: 800, minWidth: 170, padding: 8}}
          //   className={classes.button}
        >
          {props.row.buttonDetails?.type}
        </Button>
      );
    }
    return;
  };

  if (props.row.additionalData) {
    return (
      <TableRow style={{background: props.backgroundColor}}>
        <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={11}>
          <Collapse in={props.isOpen} timeout="auto" unmountOnExit>
            <Box
              sx={{marginLeft: 14}}
              // className={classes.innerBorder}
              margin={1}>
              {isRedeemOrderType && (
                <RedeemAdditionaDataRow
                  data={props.row.additionalData}
                  sentToAddressMarkUp={getAddressJSX(props.row)}
                  buttonJSX={getButtonJSX(
                    false,
                    props.row.additionalData,
                  )}></RedeemAdditionaDataRow>
              )}

              {isRedeemSpecialCase && (
                <ReFulfillAdditionaDataRow
                  data={props.row.additionalDataSecondRow!}
                  buttonJSX={getButtonJSX(true, props.row.additionalDataSecondRow!)}
                  isSecondRow={true}></ReFulfillAdditionaDataRow>
              )}

              {isReFullfillOrderType && (
                <ReFulfillAdditionaDataRow
                  data={props.row.additionalData}
                  sentToAddressMarkUp={getAddressJSX(props.row)}
                  buttonJSX={getButtonJSX(
                    false,
                    props.row.additionalData,
                  )}></ReFulfillAdditionaDataRow>
              )}

              {isReFullfillSpecialCase && (
                <ReFulfillAdditionaDataRow
                  data={props.row.additionalDataSecondRow!}
                  buttonJSX={getButtonJSX(true, props.row.additionalDataSecondRow!)}
                  isSecondRow={true}></ReFulfillAdditionaDataRow>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    );
  }
  return null;
};

export default NestedRowContainer;
