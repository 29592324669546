import {ApolloClient, HttpLink, InMemoryCache, from} from '@apollo/client';
import {onError} from '@apollo/client/link/error';
import {globalConfig} from 'Config/config';
import {store} from 'Redux/PersistStore';
import {stopTokenRefreshTimer} from 'Utils/helpers/RefreshTokenScheduler';
import {v4 as uuidv4} from 'uuid';
/**
 * @summary Configures BFF gql client
 * @param {string} url
 * @returns ApolloClient
 *
 * HTTP Headers (Common for all the APIs, all required)
 * x-correlation-id
 * The correlation id is a unique identifier that is generated by the component that initiated the call and carried through all of the components in the call stack.
 * tenant-id
 * It is a id for the source system in Next-Gen platform which is required for multi-tenancy support.
 * Authorization
 * This field requires the bearer token to be passed in the authorization header.
 */
const errorLink = onError(({graphQLErrors, networkError}) => {
  if (graphQLErrors) {
    const errorList: any = graphQLErrors?.[0]?.extensions?.errors;
    if (errorList?.[0]?.code === '401') {
      stopTokenRefreshTimer();
      sessionStorage.clear();
      localStorage.clear();
      window.location.replace(
        `${globalConfig._config.internalSSOConfig.REACT_APP_KEYCLOAK_ENDPOINT}/realms/${globalConfig._config.internalSSOConfig.REACT_APP_REALM}/protocol/openid-connect/logout?redirect_uri=${window.location.href}`,
      );
    }
  }
});

export const configBFFApolloClient: any = (url, headers) => {
  const link = new HttpLink({
    uri: url,
    headers: {
      'x-correlation-id': uuidv4(),
      ...headers,
    },
  });
  return new ApolloClient({
    link: from([errorLink, link]),
    cache: new InMemoryCache({
      addTypename: false,
    }),
  });
};

/**
 * @summary Configures CS gql client
 * @param {object} headers
 * @param {string} url
 * @returns ApolloClient
 */
export const configCSApolloClient: any = (headers, url) => {
  const cache = new InMemoryCache();
  const link = new HttpLink({
    uri: url,
    headers: headers,
  });
  return new ApolloClient({
    link: from([errorLink, link]),
    cache,
  });
};

/**
 * @summary The below function is just to create graphql Query and mutation
 * @param {string} type query or mutation
 * @param {string} query query
 * @param {object} variable params passed to query
 * @param {object} headers for passing tokens and keys
 * @param {string} url api url
 * @param {boolean} isCS flag to indicate wether is ContentStack client
 */
export const gqlCall: any = (type, query, variable, headers, url, isCS) => {
  const state: any = store.getState();
  if (!headers) {
    headers = {
      'tenant-id': state?.login.tenantID,
      Authorization: `Bearer ${state?.login.loginInfo?.access_token}`,
    };
  }
  const client = isCS ? configCSApolloClient(headers, url) : configBFFApolloClient(url, headers);
  switch (type) {
    case 'query': {
      let queryPromise = client
        ? client.query({
            query: query,
            variables: {...variable},
          })
        : new Promise((reject) => {
            reject('client is null');
          });
      return queryPromise;
    }
    case 'mutation': {
      let mutationPromise = client
        ? client.mutate({
            mutation: query,
            variables: {...variable},
          })
        : new Promise((reject) => {
            reject('client is null');
          });
      return mutationPromise;
    }
  }
};

/*Below function processes the error occured  during Graphql calls */
// export const onGqlError = (err) => {
// 	let snackBarMessage = '';
// 	if (err && err.graphQLErrors && err.graphQLErrors.length) {
// 		err.graphQLErrors.forEach((error, index) => {
// 			if (error.message) {
// 				let errorMessage = error.message.split(':');
// 				snackBarMessage = snackBarMessage
// 					? `${snackBarMessage}, ${index + 1}. ${
// 							errorMessage.length > 1 ? errorMessage[1] : errorMessage[0]
// 					  }`
// 					: `${index + 1}. ${errorMessage.length > 1 ? errorMessage[1] : errorMessage[0]}`;
// 			}
// 		});
// 	} else {
// 		snackBarMessage = 'Please try again after some time.';
// 	}
// 	return snackBarMessage;
// };

export const GQLType = {
  QUERY: 'query',
  MUTATION: 'mutation',
};
