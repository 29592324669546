import {IconButton} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import globalSpacing from 'Theme/Spacing';
import iconSize from 'Theme/Icon';

const RefreshButton = ({refresh, image}) => {
  return (
    <IconButton
      color="primary"
      aria-label="Refresh list"
      onClick={refresh}
      sx={{paddingY: '0rem', marginLeft: globalSpacing.xs}}>
      <img src={image} style={iconSize} alt="Refresh list" />
    </IconButton>
  );
};

RefreshButton.propTypes = {
  refresh: PropTypes.func.isRequired,
  image: PropTypes.string.isRequired,
};

export default RefreshButton;
