import {Button, DialogActions, DialogContent, Stack, Typography} from '@mui/material';
import DialogWrapper from 'Components/DialogView';
import React from 'react';
import styles from './styles';

const FlowTypeConfirmationPopup = ({
  openConfirmationDialog,
  setOpenConfirmationDialog,
  handleConfirmation,
}) => {
  return (
    <DialogWrapper
      closeDialogMethod={setOpenConfirmationDialog}
      open={openConfirmationDialog}
      style={{width: '40%', height: '30%', top: '5rem'}}>
      <DialogContent>
        <Stack sx={styles.container}>
          <Typography variant="body1" textAlign={'center'}>
            {'Are you sure you want to Credit the payment?'}
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={styles.buttonBox}>
        <Button
          variant="contained"
          color="error"
          disableRipple
          sx={styles.button}
          onClick={() => handleConfirmation(true, false)}>
          {'Yes'}
        </Button>
        <Button
          variant="contained"
          disableRipple
          sx={styles.button}
          onClick={() => handleConfirmation(false, false)}>
          {'No'}
        </Button>
      </DialogActions>
    </DialogWrapper>
  );
};

export default FlowTypeConfirmationPopup;
