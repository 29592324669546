import {createTheme} from '@mui/material/styles';
import {createSpacing} from '@mui/system';
import {globalConfig} from '../../Config/config';

const getGlobal = async () => {
  const tenant_identity = globalConfig._config.client.CStenantID;

  try {
    const response = await fetch(`theme/${tenant_identity}.json`);
    const branding = await response.json();
    const {fonts, palette, spacingVariable, configurations, artifacts} = branding;

    const CSTheme = configurations.theme;
    const header = branding.headerConnection;
    return {
      fonts,
      palette,
      spacingVariable,
      CSTheme,
      header,
      artifacts,
    };
  } catch (error) {
    return {error};
  }
};

const setGlobal = async () => {
  try {
    const {fonts, CSTheme, spacingVariable, palette, header, artifacts} = await getGlobal();
    const regular = {
      '@font-face': {
        fontFamily: 'regular',
        fontStyle: 'normal',
        fontWeight: 'normal',
        src: `url(${fonts.primary_regularConnection.url}) format('truetype'), local('OpenSans-regular'), local('OpenSans')
			  `,
      },
    };

    const bold = {
      '@font-face': {
        fontFamily: 'bold',
        fontStyle: 'normal',
        fontWeight: 'normal',
        src: `url(${fonts.primary_boldConnection.url}) format('truetype'),
				local('OpenSans-Bold'),
				local('OpenSans-Bold')
			  `,
      },
    };
    const semiBold = {
      '@font-face': {
        fontFamily: 'semi-bold',
        fontStyle: 'normal',
        fontWeight: 'normal',
        src: `url(${fonts.primary_semiboldConnection.url}) format('truetype'), local('OpenSans-SemiBold'), local('OpenSans-Bold')
		`,
      },
    };

    const headLineRegular = {
      '@font-face': {
        fontFamily: 'headline-regular',
        fontStyle: 'normal',
        fontWeight: 'normal',
        src: `url(${fonts.primary_headline_regularConnection.url}) format('truetype'), local('OpenSans-SemiBold'), local('OpenSans-Bold')
			  `,
      },
    };

    const headLineBold = {
      '@font-face': {
        fontFamily: 'headline-bold',
        fontStyle: 'normal',
        fontWeight: 'normal',
        src: `url(${fonts.primary_headline_boldConnection.url}) format('truetype'), local('OpenSans-SemiBold'), local('OpenSans-Bold')
			  `,
      },
    };

    const muiTheme: any = createTheme({...CSTheme});

    muiTheme.palette = palette;
    if (muiTheme?.components?.MuiCssBaseline) {
      muiTheme.components.MuiCssBaseline.styleOverrides = [
        regular,
        headLineBold,
        bold,
        semiBold,
        headLineRegular,
      ];
    }

    muiTheme['spacing'] = createSpacing((factor) => `${spacingVariable * factor}rem`);

    const finalTheme: any = createTheme(muiTheme);

    return {finalTheme, header, artifacts};
  } catch (error) {
    return {error};
  }
};

export {setGlobal};
