import React, {useEffect, useState} from 'react';
import Form from 'Components/Form/FormWrapper';
import {
  buttonList,
  serviceRequestDetailsSection,
  serviceRequestDetailsSection2,
} from './complaintServicesSR';
import {useDispatch, useSelector} from 'react-redux';
import {getCurrency, updateFormContent} from 'Utils/helpers/SRDetailViewHelper';
import {checkDateOrReturnNull, getSolicitationId} from 'Utils/helpers/MemberDetails';
import {createSR, updateSR} from 'Redux/Slices/serviceRequest';
import {UPDATE_SR_ERROR} from 'Utils/constants/genericConstants';
import {initialValues, validationSchema} from './complaintServiceSchema';
import {SR_STATUS} from 'Utils/constants/genericConstants';
import {searchSRByID} from 'Redux/Slices/selectedSR';
import {SnackBar} from 'Components';
import {useSnackBar} from 'Hooks';
import {isDisableFormFields} from 'Utils/helpers/SRListHelpers';
import {isUpdateSRList} from 'Redux/Slices/SRListFilter';

interface IProps {
  srFormDetails?: any;
  closeDialogMethod?: any;
}

const ComplaintSR: React.FC<IProps> = (props) => {
  const [srSubType, setSubType] = useState('');
  let [dependentFields, setDependentFields] = useState<any>(null);
  const [additionalFields, setAdditionalFields] = useState<any>([]);
  const ownerID = useSelector((state: any) => state?.login?.userName);
  const [section1FormContent, setSection1FormContent] = useState(serviceRequestDetailsSection);
  const [section2FormContent, setSection2FormContent] = useState(serviceRequestDetailsSection2);
  const [buttonSection, setButtonSection] = useState(buttonList);
  const [formValues, setFormValues]: any = useState(initialValues);
  const [srStatus, setSrStatus] = useState('');
  const [error, setError] = useState('');

  const {selectedMember} = useSelector((state: any) => state?.selectedMember);
  const selectedSRData = useSelector((state: any) => state.selectedSR.getServiceRequestById);
  const tenantConfig = useSelector((state: any) => state?.login?.tenantConfig);
  const mapping = useSelector((state: any) => state?.TenantServicesMappings?.mapping);
  const [isSnackBarOpen, closeSnackBar] = useSnackBar(error);
  const dispatch = useDispatch();

  const benefitCategories = tenantConfig?.benefitCategories?.split(',') || [
    'travel',
    'ticket',
    'discount',
  ];

  const setComplaintMetaData = () => {
    try {
      if (!mapping.serviceTypes) {
        return;
      }
      const complaintMetaData = mapping?.serviceTypes
        ?.find((d: any) => d.name === 'General')
        ?.serviceRequestTypeDTOS?.find((sr: any) => sr.name === 'Complaint');

      const additionalFields: any[] =
        complaintMetaData?.serviceRequestAdditionalFields.slice() || [];

      const benefitPossibleValues: any = [];
      (selectedMember?.benefits || []).forEach((benefit) => {
        if (benefitCategories?.includes(benefit?.category?.toLowerCase())) {
          benefitPossibleValues.push(benefit.name);
        }
      });

      const indexOfBenefit = additionalFields.findIndex((obj) => obj.name === 'benefit');
      const newbenefit = {
        name: 'benefit',
        possibleValues: benefitPossibleValues,
      };
      if (indexOfBenefit < 0) {
        additionalFields.push(newbenefit);
      } else {
        additionalFields[indexOfBenefit] = newbenefit;
      }

      additionalFields.push({
        name: 'subType',
        possibleValues: complaintMetaData?.allowedSubTypes || [],
      });
      let dependencyMap = {};
      additionalFields.forEach((field) => {
        if (field.linkedToField) {
          if (dependencyMap[field.linkedToField]) {
            dependencyMap[field.linkedToField] = [
              ...dependencyMap[field.linkedToField],
              {
                name: field.name,
                linkedValues: field.linkedValues,
                possibleValues: field.possibleValues,
              },
            ];
          } else {
            dependencyMap[field.linkedToField] = [
              {
                name: field.name,
                linkedValues: field.linkedValues,
                possibleValues: field.possibleValues,
              },
            ];
          }
        }
      });

      setDependentFields(dependencyMap);
      setAdditionalFields(additionalFields);
    } catch (e) {
      console.error(e);
      return e;
    }
  };

  useEffect(() => {
    if (selectedSRData?.subType) {
      setSubType(selectedSRData?.subType);
    }
    setDependentFormContent(dependentFields && dependentFields['subType'], selectedSRData?.subType);
  }, [selectedSRData?.subType]);

  useEffect(() => {
    setComplaintMetaData();
  }, [mapping]);

  useEffect(() => {
    setFormContentValues();
    if (selectedSRData && selectedSRData.additionalData) {
      let {additionalData} = selectedSRData;
      let subType = selectedSRData?.subType || srSubType;
      let srFormValues = {
        benefit: additionalData?.benefit,
        subType: subType,
        subStatus: additionalData?.subStatus,
        channel: additionalData?.channel,
        owner: selectedSRData?.owner?.id,
        eventDateTime: checkDateOrReturnNull(additionalData?.eventDateTime),
        complaintDetail: additionalData?.complaintDetail,
        receivedBy: additionalData?.receivedBy,
        thirdParty: additionalData?.thirdParty,
        thirdPartyNotes: additionalData?.thirdPartyNotes,
        complaintNature: additionalData?.complaintNature,
        severity: additionalData?.severity,
        dateReceived: checkDateOrReturnNull(additionalData?.dateReceived),
        complainant: additionalData?.complainant,
        complaintReason: additionalData?.complaintReason,
        complaintResolution: additionalData?.complaintResolution,
        compensationType: additionalData?.compensationType,
        compensationAmount: additionalData?.compensationAmount,
        replyTo: additionalData?.replyTo,
        dateAcknowledgement: checkDateOrReturnNull(additionalData?.dateAcknowledgement),
        dateResolved: checkDateOrReturnNull(additionalData?.dateResolved),
        isControllable: additionalData?.isControllable,
        isCompleted: parseIsCompletedValue(additionalData?.isCompleted),
        fosRights: additionalData?.fosRights,
        upheld: additionalData?.upheld,
        rework: additionalData?.rework,
        refund: additionalData?.refund,
        redress: additionalData?.redress,
        redressAction: additionalData?.redressAction,
        compensation: additionalData?.compensation,
        fos1: additionalData?.fos1,
        fos2: additionalData?.fos2,
        summaryResolution: additionalData?.summaryResolution,
        finalResponse: additionalData?.finalResponse,
        comments: additionalData?.comments,
        carHireLocation: additionalData?.carHireLocation,
        resolutionChannel: additionalData?.resolutionChannel,
        referredToThirdParty: additionalData?.referredToThirdParty,
        twentyOneDayUpdateDate: additionalData?.twentyOneDayUpdateDate,
        additionalUpdate: additionalData?.additionalUpdate,
        refundAmount: additionalData?.refundAmount,
        redressAmount: additionalData?.redressAmount,
        reimburseTourOpsFlag: additionalData?.reimburseTourOpsFlag,
        reimburseTourOpsMethod: additionalData?.reimburseTourOpsMethod,
        reimburseTourOpsAmount: additionalData?.reimburseTourOpsAmount,
        compTourOpsAmount: additionalData?.compTourOpsAmount,
        compTourOpsFlag: additionalData?.compTourOpsFlag,
        qaFlag: additionalData?.qaFlag,
      };
      setSrStatus(selectedSRData.status);
      setSubType(selectedSRData.subType);
      setFormValues(srFormValues);
      setError('');
    } else {
      setSrStatus(srStatus || '');
      setError('');
      setSubType(srSubType || '');
      setFormValues(initialValues);
    }
  }, [
    selectedSRData,
    props.srFormDetails,
    additionalFields,
    mapping,
    section1FormContent,
    section2FormContent,
  ]);

  const getIsCompletedValue = (isCompletedString: string): boolean => {
    if (isCompletedString && isCompletedString.toLowerCase() === 'yes') {
      return true;
    }
    return false;
  };

  const setDependentFormContent = (fields: any, value: any) => {
    if (!fields) {
      return;
    }
    let targetFields: any = {};
    fields.forEach((field) => {
      let options = (value ? field.linkedValues[value] : []) || [];
      targetFields[field.name] = {
        targetAttribute: 'options',
        targetValue: options?.map((option: any) => ({
          value: option,
          text: option,
        })),
      };
    });

    let updatedFormContent = updateFormContent(section1FormContent, targetFields);
    setSection1FormContent(updatedFormContent);
  };

  const getComplaintReasons = () => {
    if (
      dependentFields &&
      dependentFields['subType'] &&
      dependentFields['subType'].find((field) => field.name === 'complaintReason')
    ) {
      let field = dependentFields['subType'].find((field) => field.name === 'complaintReason');
      return (
        field.linkedValues?.[srSubType]?.map((option: any) => ({
          value: option,
          text: option,
        })) || []
      );
    }
    return (
      additionalFields
        .find((f) => f.name === 'complaintReason')
        ?.possibleValues?.map((option: any) => ({
          value: option,
          text: option,
        })) || []
    );
  };

  const setFormContentValues = () => {
    let {srFormDetails} = props;
    let srSubTypesArray = (
      additionalFields.find((f) => f.name === 'subType')?.possibleValues ||
      srFormDetails.srSubType ||
      []
    ).map((subType) => {
      return {value: subType, text: subType};
    });

    let srOwnerListArray = (srFormDetails?.srOwnerList || []).map((item) => {
      return {value: item.id, text: item.userName};
    });

    let reasonsArray = getComplaintReasons();

    let benefits =
      additionalFields
        .find((f) => f.name === 'benefit')
        ?.possibleValues?.map((option: any) => ({
          value: option,
          text: option,
        })) || [];
    let targetFields1 = {
      benefit: {
        targetAttribute: 'options',
        targetValue: benefits,
      },
      subType: {
        targetAttribute: 'options',
        targetValue: srSubTypesArray,
      },
      owner: {
        targetAttribute: 'options',
        targetValue: srOwnerListArray,
      },
      complaintReason: {
        targetAttribute: 'options',
        targetValue: reasonsArray,
      },
      compensationAmount: {
        targetAttribute: 'fieldLabel',
        targetValue: 'Compen. Amount  ' + tenantConfig?.currencySymbol,
      },
    };
    let targetFields2 = {
      refundAmount: {
        targetAttribute: 'fieldLabel',
        targetValue: 'Refund Amount  ' + tenantConfig?.currencySymbol,
      },
      redressAmount: {
        targetAttribute: 'fieldLabel',
        targetValue: 'Redress Amount  ' + tenantConfig?.currencySymbol,
      },
      reimburseTourOpsAmount: {
        targetAttribute: 'fieldLabel',
        targetValue: {
          text: 'Tour Ops Reimb. Amt.  ' + tenantConfig?.currencySymbol,
        },
      },
      compTourOpsAmount: {
        targetAttribute: 'fieldLabel',
        targetValue: 'Tour Ops Compen. Amt.  ' + tenantConfig?.currencySymbol,
      },
    };
    let targetButtonFields = {
      saveButton: {
        targetAttribute: 'isDisabled',
        targetValue: false,
      },
      pendingButton: {
        targetAttribute: 'isDisabled',
        targetValue: false,
      },
      closeButton: {
        targetAttribute: 'isDisabled',
        targetValue: false,
      },
      cancelButton: {
        targetAttribute: 'isDisabled',
        targetValue: false,
      },
      reopenButton: {
        targetAttribute: 'isDisabled',
        targetValue: true,
      },
    };
    if (
      selectedSRData?.id &&
      (selectedSRData.status === SR_STATUS.CLOSED || selectedSRData.status === SR_STATUS.CANCELLED)
    ) {
      targetButtonFields = {
        saveButton: {
          targetAttribute: 'isDisabled',
          targetValue: true,
        },
        pendingButton: {
          targetAttribute: 'isDisabled',
          targetValue: true,
        },
        closeButton: {
          targetAttribute: 'isDisabled',
          targetValue: true,
        },
        cancelButton: {
          targetAttribute: 'isDisabled',
          targetValue: true,
        },
        reopenButton: {
          targetAttribute: 'isDisabled',
          targetValue: false,
        },
      };
    } else if (selectedSRData?.id && selectedSRData.status === SR_STATUS.PENDING) {
      targetButtonFields = {
        saveButton: {
          targetAttribute: 'isDisabled',
          targetValue: false,
        },
        pendingButton: {
          targetAttribute: 'isDisabled',
          targetValue: true,
        },
        closeButton: {
          targetAttribute: 'isDisabled',
          targetValue: false,
        },
        cancelButton: {
          targetAttribute: 'isDisabled',
          targetValue: false,
        },
        reopenButton: {
          targetAttribute: 'isDisabled',
          targetValue: false,
        },
      };
    }

    let updatedFormContent = updateFormContent(section1FormContent, targetFields1);
    setSection1FormContent(updatedFormContent);
    let updatedForm2Content = updateFormContent(section2FormContent, targetFields2);
    setSection2FormContent(updatedForm2Content);
    let updateButtonList = updateFormContent(buttonSection, targetButtonFields);
    setButtonSection(updateButtonList);

    if (
      selectedSRData?.id &&
      (selectedSRData.status === SR_STATUS.CLOSED || selectedSRData.status === SR_STATUS.CANCELLED)
    ) {
      let disabledFormContent = updateFormContent(
        section1FormContent,
        isDisableFormFields(section1FormContent.fields, true),
      );
      setSection1FormContent(disabledFormContent);

      let disabledForm2Content = updateFormContent(
        section2FormContent,
        isDisableFormFields(section2FormContent.fields, true),
      );
      setSection2FormContent(disabledForm2Content);
    } else {
      let disabledFormContent = updateFormContent(
        section1FormContent,
        isDisableFormFields(section1FormContent.fields, false),
      );
      setSection1FormContent(disabledFormContent);

      let disabledForm2Content = updateFormContent(
        section2FormContent,
        isDisableFormFields(section2FormContent.fields, false),
      );
      setSection2FormContent(disabledForm2Content);
    }
  };

  const parseIsCompletedValue = (isCompleted: boolean): string => {
    return isCompleted ? 'Yes' : 'No';
  };

  const handleOnSubmit = async (values, action) => {
    let status = srStatus || SR_STATUS.OPEN;
    if (values?.buttonAction) {
      switch (values?.buttonAction) {
        case 'Save':
          status = srStatus || SR_STATUS.OPEN;
          break;
        case 'Pending':
          status = SR_STATUS.PENDING;
          break;
        case 'Close':
          status = SR_STATUS.CLOSED;
          break;
        case 'Cancel':
          status = SR_STATUS.CANCELLED;
          break;
        case 'Reopen':
          status = SR_STATUS.OPEN;
          break;
      }
    }

    let {srType, serviceType} = props.srFormDetails;

    const notificationParams = {
      firstName: selectedMember?.currentMemberDetails?.firstName,
      lastName: selectedMember?.currentMemberDetails?.lastName,
      membershipNumber: selectedMember?.membershipNumber,
      toEmail:
        selectedMember?.currentMemberDetails?.primaryEmail ||
        selectedMember?.currentMemberDetails?.clientEmail,
      packageId: selectedMember?.packageId,
      solicitationId: getSolicitationId(selectedMember),
      externalRefId: selectedMember?.currentMemberDetails?.externalRefId,
      membershipEnabled: tenantConfig.membershipEnabled,
    };

    if (selectedSRData?.id) {
      let updateSRVariable = {
        id: selectedSRData?.id,
        requestObj: {
          commands: [
            {
              commandName: 'updateSR',
              commandId: 'updateSR2',
              type: srType,
              subType: values.subType,
              description: 'UpdatedDESCRIPTION',
              summary: values.summary,
              status: status || SR_STATUS.OPEN,
              owner: {
                id: values.owner,
              },
              additionalData: {
                source: 'ng-engage',
                benefit: values.benefit,
                subStatus: values.subStatus,
                channel: values.channel,
                eventDateTime: values.eventDateTime,
                complaintDetail: values.complaintDetail,
                receivedBy: values.receivedBy,
                thirdParty: values.thirdParty,
                thirdPartyNotes: values.thirdPartyNotes,
                complaintNature: values.complaintNature,
                severity: values.severity,
                dateReceived: values.dateReceived,
                complainant: values.complainant,
                complaintReason: values.complaintReason,
                complaintResolution: values.complaintResolution,
                compensationType: values.compensationType,
                compensationAmount: values.compensationAmount,
                replyTo: values.replyTo,
                service: serviceType,
                currency: getCurrency(selectedMember),
                dateAcknowledgement: values.dateAcknowledgement,
                dateResolved: values.dateResolved,
                isControllable: values.isControllable,
                isCompleted: getIsCompletedValue(values.isCompleted),
                fosRights: values.fosRights,
                upheld: values.upheld,
                rework: values.rework,
                refund: values.refund,
                redress: values.redress,
                redressAction: values.redressAction,
                compensation: values.compensation,
                fos1: values.fos1,
                fos2: values.fos2,
                summaryResolution: values.summaryResolution,
                finalResponse: values.finalResponse,
                comments: values.comments,
                carHireLocation: values.carHireLocation,
                resolutionChannel: values.resolutionChannel,
                referredToThirdParty: values.referredToThirdParty,
                twentyOneDayUpdateDate: values.twentyOneDayUpdateDate,
                additionalUpdate: values.additionalUpdate,
                refundAmount: values.refundAmount,
                redressAmount: values.redressAmount,
                reimburseTourOpsFlag: values.reimburseTourOpsFlag,
                reimburseTourOpsMethod: values.reimburseTourOpsMethod,
                reimburseTourOpsAmount: values.reimburseTourOpsAmount,
                compTourOpsAmount: values.compTourOpsAmount,
                compTourOpsFlag: values.compTourOpsFlag,
                qaFlag: values.qaFlag,
              },
              audit: {
                modifiedBy: ownerID?.toLowerCase(),
                modifiedOn: selectedSRData?.audit?.modifiedOn,
              },
            },
          ],
        },
        notificationParams,
      };
      try {
        let resp = await dispatch(updateSR(updateSRVariable)).unwrap();

        let srUpdateStatus = resp?.data?.updateServiceRequest[0]?.status;
        if (srUpdateStatus?.toUpperCase() === 'SUCCESS') {
          let payload = {
            id: selectedSRData?.id,
          };
          await dispatch(searchSRByID(payload)).unwrap();
          dispatch(
            isUpdateSRList({
              isRefreshSRList: true,
            }),
          );
          setError('');
          values?.buttonAction !== 'Reopen' && props.closeDialogMethod(false);
        } else if (srUpdateStatus?.toUpperCase() === 'FAILURE') {
          let srUpdateErrors = resp?.data?.updateServiceRequest?.[0]?.errors;
          setError(srUpdateErrors);
          //toggleLoader(true);
        } else console.log('Something Went Wrong');
        //toggleLoader(false);
      } catch (error: any) {
        setError(UPDATE_SR_ERROR);
        //toggleLoader(false);
      }
    } else {
      let apiVariable = {
        serviceRequest: {
          id: null,
          type: srType,
          subType: values.subType,
          member: {
            id: selectedMember?.currentMemberDetails?.memberId,
            membershipId: selectedMember?.membershipId
              ? selectedMember?.membershipId
              : selectedMember?.currentMemberDetails?.memberId,
          },
          owner: {
            id: values.owner,
          },
          description: 'description',
          summary: values.ccSummary,
          status: status || SR_STATUS.OPEN,
          additionalData: {
            source: 'ng-engage',
            benefit: values.benefit,
            subStatus: values.subStatus,
            channel: values.channel,
            eventDateTime: values.eventDateTime,
            complaintDetail: values.complaintDetail,
            receivedBy: values.receivedBy,
            thirdParty: values.thirdParty,
            thirdPartyNotes: values.thirdPartyNotes,
            complaintNature: values.complaintNature,
            severity: values.severity,
            dateReceived: values.dateReceived,
            complainant: values.complainant,
            complaintReason: values.complaintReason,
            complaintResolution: values.complaintResolution,
            compensationType: values.compensationType,
            compensationAmount: values.compensationAmount,
            replyTo: values.replyTo,
            service: serviceType,
            currency: getCurrency(selectedMember),
            dateAcknowledgement: values.dateAcknowledgement,
            dateResolved: values.dateResolved,
            isControllable: values.isControllable,
            isCompleted: getIsCompletedValue(values.isCompleted),
            fosRights: values.fosRights,
            upheld: values.upheld,
            rework: values.rework,
            refund: values.refund,
            redress: values.redress,
            redressAction: values.redressAction,
            compensation: values.compensation,
            fos1: values.fos1,
            fos2: values.fos2,
            summaryResolution: values.summaryResolution,
            finalResponse: values.finalResponse,
            comments: values.comments,
            carHireLocation: values.carHireLocation,
            resolutionChannel: values.resolutionChannel,
            referredToThirdParty: values.referredToThirdParty,
            twentyOneDayUpdateDate: values.twentyOneDayUpdateDate,
            additionalUpdate: values.additionalUpdate,
            refundAmount: values.refundAmount,
            redressAmount: values.redressAmount,
            reimburseTourOpsFlag: values.reimburseTourOpsFlag,
            reimburseTourOpsMethod: values.reimburseTourOpsMethod,
            reimburseTourOpsAmount: values.reimburseTourOpsAmount,
            compTourOpsAmount: values.compTourOpsAmount,
            compTourOpsFlag: values.compTourOpsFlag,
            qaFlag: values.qaFlag,
          },
          audit: {
            createdBy: ownerID?.toLowerCase(),
          },
        },
        notificationParams,
      };

      let resp = await dispatch(createSR(apiVariable)).unwrap();
      if (resp) {
        dispatch(
          isUpdateSRList({
            isRefreshSRList: true,
          }),
        );
        props.closeDialogMethod(false);
      }
    }
  };

  const onChangeEvent = function (e) {
    let srSubType = e.target.value;
    setDependentFormContent(dependentFields && dependentFields['subType'], e.target.value);
    setSubType(srSubType);
  };

  const customEvents = {
    subType: {onChange: onChangeEvent},
  };

  const clearMessageOnClose = () => setError('');

  return (
    <>
      <Form
        onSubmit={handleOnSubmit}
        initialValues={formValues}
        section1={serviceRequestDetailsSection}
        section2={serviceRequestDetailsSection2}
        buttonStack={buttonSection}
        customEvents={customEvents}
        validationSchema={validationSchema}
      />
      <SnackBar
        isSnackBarOpen={isSnackBarOpen}
        closeSnackBar={closeSnackBar}
        errorMessage={error}
        clearMessageOnClose={clearMessageOnClose}
      />
    </>
  );
};
export default ComplaintSR;
