// import {PropTypes} from 'prop-types';
import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  RadioGroup,
  Box,
  FormControlLabel,
  Radio,
  useTheme,
} from '@mui/material';
import globalSpacing from 'Theme/Spacing';
import {CloseIconDialog} from 'Components';
import {useCSContext} from 'Context/ContentStackContext';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import {REFILL_REASONS} from 'Utils/constants/genericConstants';

// const REFULFILL_MODAL_HEADING = 'Re-Fulfill Order';
// const REFULFILL_MODAL_BUTTON_TEXT = 'Submit Re-fulfillment Order';

// const REQUEST_REFULFILL_MODAL_HEADING = 'Request Re-Fulfill';
// const REQUEST_REFULFILL_MODAL_BUTTON_TEXT = 'Submit Request';

const RefulfillDialog = (props) => {
  const {visible, onModalClose} = props;
  const {artifacts} = useCSContext();
  const theme = useTheme();
  return (
    <Dialog
      open={visible}
      onClose={onModalClose}
      scroll="paper"
      sx={{paddingY: globalSpacing.lg, margin: 'auto'}}>
      <CloseIconDialog callback={onModalClose} image={artifacts.cross_icon} />
      <DialogTitle
        sx={{
          paddingTop: globalSpacing.md,
        }}>
        <Typography color="primary" variant="h3">
          Re-Fulfill Order
        </Typography>
      </DialogTitle>

      <DialogTitle sx={{paddingTop: '0rem'}}>
        <Typography variant="h4">Reason for Re-fulfill</Typography>
      </DialogTitle>

      <DialogContent>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue=""
            name="radio-buttons-group">
            <Box sx={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
              {REFILL_REASONS.map((key, index) => (
                <FormControlLabel
                  value={key}
                  control={
                    <Radio
                      sx={{color: theme.palette.text.primary}}
                      onChange={() => props.onModalSelectChange(index)}
                      checked={props.checkedState[index]}
                    />
                  }
                  label={key}
                />
              ))}
            </Box>
          </RadioGroup>

          <TextField
            id="outlined-multiline-static"
            // label="Add Comments"
            placeholder="Add Comments"
            multiline
            rows={3}
            sx={{marginTop: '10px'}}
            onChange={(e) => props.onModalTextChange(e)}
          />
          <Button onClick={props.onSubmitModal} sx={{marginTop: '20px'}}>
            Submit Re-fulfillment Order
          </Button>
        </FormControl>
      </DialogContent>
    </Dialog>
  );
};

// RefulfillDialog.propTypes = {
//   toggleRefulfillDialog: PropTypes.func.isRequired,
//   isRefulfillDialogVisible: PropTypes.bool.isRequired,
//   //   transactionDetails: PropTypes.object,
// };
export default RefulfillDialog;
