import PropTypes from 'prop-types';
import React, {createContext, useContext} from 'react';
const CSContext = createContext({} as ReturnType<any>);

const ContentStackContextProvider = ({value, children}) => {
  return <CSContext.Provider value={value}> {children} </CSContext.Provider>;
};

const useCSContext = (entry?: any | undefined) => {
  const {artifacts, header}: any = useContext(CSContext);
  return {artifacts, header};
};

ContentStackContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.object,
};

export {ContentStackContextProvider, CSContext, useCSContext};
