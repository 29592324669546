import {format} from 'date-fns';

const createQuerySearchValues = (values, queryStatusValue) => {
  const fromDate = values?.fromDate && format(values.fromDate, 'yyyy-MM-dd');
  const toDate = values?.toDate && format(values.toDate, 'yyyy-MM-dd');

  return {
    srId: Number(values?.srId?.trim()) || undefined, //Interger
    externalRefId: values?.externalRefId?.trim(),
    fromDate: fromDate, //date should be in this format is supported
    toDate: toDate, //date should be in this format is supported
    queryStatus: queryStatusValue, //status can be changed here
  };
};

export default createQuerySearchValues;
