import {parseData} from 'Utils/helpers/SRListHelpers';
import {SortOrder} from 'Utils/enums';

export const getSRGirdData = (
  tableData,
  sortOrder = SortOrder.DESCENDING,
  currentSortCriteria,
  isSortingEnabled = true,
) => {
  const header = [
    {label: 'SR Type', value: 'type'},
    {label: 'SR#', value: 'id'},
    {label: 'Status', value: 'status'},
    {label: 'Service', value: 'serviceType'},
    {label: 'Summary', value: 'summary'},
    {label: 'Created Date', value: 'createdOn'},
    // {label: 'Event Name', value: 'eventName'},
    // {label: 'Total Cashback Amount', value: 'cashbackAmount'},
    // {label: 'Cashback Status', value: 'cashbackStatus'},
  ];

  let hideColumns = {
    srData: true,
  };

  return parseData(
    header,
    [...(tableData || [])],
    sortOrder,
    currentSortCriteria,
    isSortingEnabled,
    hideColumns,
  );
};
