export const QUERY_FORM = {
  button_text: 'SUBMIT',
  customer_identifier: 'Customer Email',
  form_title: 'Query Type',
  heading: 'Customer Query Form',
  mapped_fields: [
    {
      field: [
        {
          key: 'ticketN',
          title: 'Ticket Number',
          value: 'Ticket Number',
        },
        {
          key: 'comments',
          title: 'Additional Comments',
          value: 'Additional Comments',
        },
      ],
      reason: [],
      title: 'Follow up on Existing Ticket',
    },
    {
      field: [
        {
          key: 'escalationReason',
          title: 'Reason for Escalation',
          value: 'Reason for Escalation',
        },
        {
          key: 'comments',
          title: 'Additional Comments',
          value: 'Additional Comments',
        },
      ],
      reason: [
        {
          key: 'prizeDraw',
          title: 'Prize Draw Question/Rules',
          value: 'Prize Draw Question/Rules',
        },
        {
          key: 'problemSharing',
          title: 'Problem sharing Offer',
          value: 'Problem sharing Offer',
        },
        {
          key: 'error',
          title: 'Website Error',
          value: 'Website Error',
        },
      ],
      title: 'General',
    },
    {
      field: [
        {
          key: 'escalationReason',
          title: 'Reason for Escalation',
          value: 'Reason for Escalation',
        },
        {
          key: 'merchantName',
          title: 'Merchant Name',
          value: 'Merchant Name',
        },
        {
          key: 'transactionDate',
          title: 'Transaction Date',
          value: 'Transaction Date',
        },
        {
          key: 'description',
          title: 'Description & Coupon Code',
          value: 'Description & Coupon Code',
        },
      ],
      reason: [
        {
          key: 'codeNotWorkingS',
          title: 'Code not working (In-Store)',
          value: 'Code not working (In-Store)',
        },
        {
          key: 'codeNotWorkingO',
          title: 'Code not working (Online)',
          value: 'Code not working (Online)',
        },
        {
          key: 'codeExp',
          title: 'Code Expired',
          value: 'Code Expired',
        },
        {
          key: 'other',
          title: 'Other',
          value: 'Other',
        },
      ],
      title: 'Coupon',
    },
    {
      field: [
        {
          key: 'escalationReason',
          title: 'Reason for Escalation',
          value: 'Reason for Escalation',
        },
        {
          key: 'merchantName',
          title: 'Merchant Name',
          value: 'Merchant Name',
        },
        {
          key: 'transactionDate',
          title: 'Transaction Date',
          value: 'Transaction Date',
        },
        {
          key: 'orderNumber',
          title: 'Order Number',
          value: 'Order Number',
        },
        {
          key: 'transactionAmount',
          title: 'Sale Amount',
          value: 'Sale Amount',
        },
        {
          key: 'purchasedItems',
          title: 'Items Purchased',
          value: 'Items Purchased',
        },
        {
          key: 'paymentMethod',
          title: 'Payment Method',
          value: 'Payment Method',
        },
      ],
      reason: [
        {
          key: 'missingP',
          title: 'Missing Payment',
          value: 'Missing Payment',
        },
        {
          key: 'diffPAmount',
          title: 'Different Payment Amount',
          value: 'Different Payment Amount',
        },
        {
          key: 'declinedP',
          title: 'Payment Declined',
          value: 'Payment Declined',
        },
        {
          key: 'other',
          title: 'Other',
          value: 'Other',
        },
      ],
      title: 'CLO',
    },
    {
      field: [
        {
          key: 'escalationReason',
          title: 'Reason for Escalation',
          value: 'Reason for Escalation',
        },
        {
          key: 'merchantName',
          title: 'Merchant Name',
          value: 'Merchant Name',
        },
        {
          key: 'transactionDate',
          title: 'Transaction Date',
          value: 'Transaction Date',
        },
        {
          key: 'orderNumber',
          title: 'Order Number',
          value: 'Order Number',
        },
        {
          key: 'transactionAmount',
          title: 'Sale Amount',
          value: 'Sale Amount',
        },
        {
          key: 'purchasedItems',
          title: 'Items Purchased',
          value: 'Items Purchased',
        },
        {
          key: 'paymentMethod',
          title: 'Payment Method',
          value: 'Payment Method',
        },
      ],
      reason: [
        {
          key: 'missingP',
          title: 'Missing Payment',
          value: 'Missing Payment',
        },
        {
          key: 'diffPAmount',
          title: 'Different Payment Amount',
          value: 'Different Payment Amount',
        },
        {
          key: 'declinedP',
          title: 'Payment Declined',
          value: 'Payment Declined',
        },
        {
          key: 'other',
          title: 'Other',
          value: 'Other',
        },
      ],
      title: 'Cashback',
    },
  ],
  query_result: {
    back_text: 'Back to form',
    success_text: 'Ticket successfully sent',
    ticket_text: 'Ticket number:',
    updated_text: 'Ticket successfully updated',
  },
  query_type: [
    {
      key: 'cashback',
      title: 'Cashback',
      value: 'Cashback',
    },
    {
      key: 'clo',
      title: 'CLO',
      value: 'CLO',
    },
    {
      key: 'coupon',
      title: 'Coupon',
      value: 'Coupon',
    },
    {
      key: 'followUp',
      title: 'Follow up on Existing Ticket',
      value: 'Follow up on Existing Ticket',
    },
    {
      key: 'general',
      title: 'General',
      value: 'General',
    },
  ],
  query_type_select: [
    {
      key: 'type',
      title: 'Select Query Type',
      value: 'Select Query Type',
    },
  ],
  subtitle: 'Please do not enter any customer personal data.',
  title: 'Query Tab',
};
