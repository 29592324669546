import * as Yup from 'yup';

export const createValidationSchema = (isSubTypeMandatory = false) => {
  return Yup.object({
    subType: isSubTypeMandatory
      ? Yup.string().required('Subtype is a required field')
      : Yup.string().notRequired(),
  });
};

export const initialValues = {
  summary: '',
  subType: '',
  subStatus: '',
  channel: 'Phone',
  owner: '',
  thirdParty: '',
  thirdPartyPhone: '',
  receivedDate: null,
  transactionAmount: '',
  transactionDate: null,
  statusReason: '',
  comments: '',
  commentsIntern: '',
  buttonAction: '',
};

export const transcriptInitialState = {
  enabled: false,
  transcriptData: null,
};
