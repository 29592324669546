export const formDetails = {
  cssClass: 'form_wrapper',
};
export const serviceRequestDetailsSection = {
  sectionClass: '',
  title: 'Service Request Details',
  titleClass: '',
  fields: [
    {
      fieldType: 'select',
      name: 'channel',
      id: 'channel',
      fieldLabel: 'Channel',
      isDisabled: true,
      defaultValue: 'Mail',
      options: [
        {value: 'Authorities', text: 'Authorities'},
        {value: 'Bank Branch', text: 'Bank Branch'},
        {value: 'Call direct to CA', text: 'Call direct to CA'},
        {value: 'Call direct to CR', text: 'Call direct to CR'},
        {value: 'Call to CC', text: 'Call to CC'},
        {value: 'Call transfer to CA', text: 'Call transfer to CA'},
        {value: 'Call transfer to CR', text: 'Call transfer to CR'},
        {value: 'Client - Call', text: 'Client - Call'},
        {
          value: 'Client - Paper / Email',
          text: 'Client - Paper / Email',
        },
        {value: 'Contact Center', text: 'Contact Center'},
        {value: 'Email', text: 'Email'},
        {value: 'Engage', text: 'Engage'},
        {value: 'Fax', text: 'Fax'},
        {value: 'Form', text: 'Form'},
        {value: 'Interface', text: 'Interface'},
        {value: 'IVR', text: 'IVR'},
        {value: 'Letter', text: 'Letter'},
        {value: 'Mail', text: 'Mail'},
        {value: 'MobileCarrier', text: 'MobileCarrier'},
        {value: 'Online', text: 'Online'},
        {value: 'Partner', text: 'Partner'},
        {value: 'Phone', text: 'Phone'},
        {value: 'RBSApp', text: 'RBSApp'},
        {value: 'Unknown', text: 'Unknown'},
      ],
      wrapperCssClass: '',
      optionCssClass: '',
      cssClass: '',
      dataTest: 'channel',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'firstName',
      id: 'firstName',
      fieldLabel: 'First Name',
      dataTest: 'firstName',
      isDisabled: true,
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'lastName',
      id: 'lastName',
      fieldLabel: 'Last Name',
      dataTest: 'lastName',
      isDisabled: true,
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'status',
      id: 'status',
      fieldLabel: 'Status',
      dataTest: 'status',
      isDisabled: true,
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'cinemaChain',
      id: 'cinemaChain',
      fieldLabel: 'Cinema Chain',
      dataTest: 'cinemaChain',
      isDisabled: true,
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'datePicker',
      name: 'transactionDate',
      id: 'transactionDate',
      fieldLabel: 'Order Date',
      disableFutureDate: true,
      placeholder: 'Order Date',
      isDisabled: true,
      inputClass: '',
      dataTest: 'transactionDate',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'orderPrice',
      id: 'orderPrice',
      fieldLabel: 'Order Price',
      dataTest: 'orderPrice',
      isDisabled: true,
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'email',
      id: 'email',
      isDisabled: true,
      fieldLabel: 'Email',
      dataTest: 'email',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      isDisabled: true,
      name: 'totalVouchers',
      id: 'totalVouchers',
      fieldLabel: 'Total Vouchers',
      dataTest: 'totalVouchers',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'ticketVouchers',
      id: 'ticketVouchers',
      isDisabled: true,
      fieldLabel: 'Ticket Vouchers',
      dataTest: 'ticketVouchers',
      xs: 4,
      sm: 4,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'currency',
      isDisabled: true,
      id: 'currency',
      fieldLabel: 'Currency',
      dataTest: 'currency',
      xs: 4,
      sm: 4,
    },
  ],
};
// export const buttonList = {
//   direction: 'row',
//   spacing: 2,
//   fields: [
//     {
//       fieldType: 'button',
//       htmlInputType: 'submit',
//       name: 'saveButton',
//       id: 'saveButton',
//       fieldLabel: 'Save',
//       variant: 'contained',
//       wrapperClass: '',
//       dataTest: 'save',
//       xs: 2,
//       sm: 2,
//     },
//     {
//       fieldType: 'button',
//       htmlInputType: 'submit',
//       name: 'pendingButton',
//       id: 'pendingButton',
//       fieldLabel: 'Pending',
//       variant: 'contained',
//       wrapperClass: '',
//       dataTest: 'pending',
//       xs: 2,
//       sm: 2,
//     },
//     {
//       fieldType: 'button',
//       htmlInputType: 'submit',
//       name: 'closeButton',
//       id: 'closeButton',
//       fieldLabel: 'Close',
//       variant: 'contained',
//       wrapperClass: '',
//       dataTest: 'close',
//       xs: 2,
//       sm: 2,
//     },
//     {
//       fieldType: 'button',
//       htmlInputType: 'submit',
//       name: 'cancelButton',
//       id: 'cancelButton',
//       fieldLabel: 'Cancel',
//       variant: 'contained',
//       wrapperClass: '',
//       dataTest: 'cancel',
//       xs: 2,
//       sm: 2,
//     },
//     {
//       fieldType: 'button',
//       htmlInputType: 'submit',
//       name: 'reopenButton',
//       id: 'reopenButton',
//       fieldLabel: 'Reopen',
//       variant: 'contained',
//       wrapperClass: '',
//       dataTest: 'Reopen',
//       xs: 2,
//       sm: 2,
//     },
//   ],
// };

export const topButtonList = {
  direction: 'row',
  spacing: 2,
  fields: [
    {
      fieldType: 'button',
      htmlInputType: 'submit',
      name: 'purchaseOnline',
      id: 'purchaseOnline',
      fieldLabel: 'Purchase Online',
      variant: 'contained',
      wrapperClass: '',
      dataTest: 'purchaseOnline',
      xs: 3,
      sm: 3,
    },
    {
      fieldType: 'button',
      htmlInputType: 'submit',
      name: 'complete',
      id: 'complete',
      fieldLabel: 'Complete',
      variant: 'contained',
      wrapperClass: '',
      dataTest: 'complete',
      xs: 2,
      sm: 2,
    },
  ],
};
