import {DialogContent} from '@mui/material';
import DialogView from 'Components/DialogView';
import React from 'react';

const CinemaECodePopup = (props) => {
  const {visible, hideModal} = props;

  return (
    <DialogView
      closeDialogMethod={hideModal}
      open={visible}
      style={{width: '50%', height: '50%', top: '5rem'}}>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          Closing this pop-up will not refresh the SR automatically.
          <br />
          Also if you are closing this window without finishing the eCode reservation process on the
          Portal, you may lose the reservation information and it will not be copied on the SR
        </div>
      </DialogContent>
    </DialogView>
  );
};

export default CinemaECodePopup;
