import {
  SEARCH_NETWORK_ERROR,
  SEARCH_NO_RESULTS_ERROR,
  TOKEN_EXPIRED,
} from 'Utils/constants/genericConstants';

export const getErrorMsg = (errors) => {
  const errorList = errors?.graphQLErrors?.[0]?.extensions?.errors;
  let errorMsg;
  if (errorList?.[0]?.code === '401') {
    errorMsg = TOKEN_EXPIRED;
  } else if (errorList?.[0]?.code > 399 && errorList?.[0]?.code < 600) {
    errorMsg = SEARCH_NETWORK_ERROR;
  } else {
    let msgList = errorList?.map((errorObj) => {
      let errorMsg = errorObj?.message;
      if (errorObj?.message?.length > 80) {
        if (errorObj?.code === 'M3032' || errorObj?.code === 'EG1014') {
          errorMsg = SEARCH_NO_RESULTS_ERROR;
        } else {
          errorMsg = SEARCH_NETWORK_ERROR;
        }
      }
      return errorMsg;
    });
    errorMsg = msgList?.join(',');
  }
  if (!errorMsg) {
    if (errorList?.[0]?.code === 'M3032' || errorList?.[0]?.code === 'EG1014') {
      errorMsg = SEARCH_NO_RESULTS_ERROR;
    } else {
      errorMsg = SEARCH_NETWORK_ERROR;
    }
  }
  return errorMsg;
};
