import globalSpacing from 'Theme/Spacing';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: globalSpacing.lg,
  },
  button: {
    width: '30%',
  },
};

export default styles;
