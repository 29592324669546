/* eslint-disable no-unused-vars */
import {Button, DialogContent, DialogActions, Typography, Stack} from '@mui/material';
import globalSpacing from 'Theme/Spacing';
import React from 'react';
import PropTypes from 'prop-types';
import {useCSContext} from 'Context/ContentStackContext';
import DialogWrapper from './DialogWrapper';
import styles from './styles';
import {GIFTING} from '../../giftingConstants';

const GiftDialogSuccess = ({openSuccessDialog, setOpenSuccessDialog, customerEmail}) => {
  const {artifacts} = useCSContext();

  return (
    <DialogWrapper closeDialogMethod={setOpenSuccessDialog} open={openSuccessDialog}>
      <DialogContent>
        <Stack spacing={globalSpacing.s} sx={styles.container}>
          <img src={artifacts.gift_opened_icon} />
          <Typography variant="h3">{GIFTING.gifting_dialog_success.heading}</Typography>
          <Typography variant="body1" textAlign={'center'}>
            {GIFTING.gifting_dialog_success.subtitle} {customerEmail}.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={styles.buttonBox}>
        <Button
          variant="contained"
          disableRipple
          onClick={() => setOpenSuccessDialog(false)}
          sx={styles.button}>
          {GIFTING.gifting_dialog_success.button_text}
        </Button>
      </DialogActions>
    </DialogWrapper>
  );
};

GiftDialogSuccess.propTypes = {
  openSuccessDialog: PropTypes.bool,
  setOpenSuccessDialog: PropTypes.func,
  customerEmail: PropTypes.any,
};

export default GiftDialogSuccess;
