import {createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {gqlGetGMGiftingCards} from 'GQL/MethodsBFF/GMGifting';

interface IGMGiftCards {
  data: any;
}

const gmGiftCardsAdapter: any = createEntityAdapter<IGMGiftCards>({});

const initialState = gmGiftCardsAdapter.getInitialState({
  status: 'idle',
  error: '',
  data: null,
});

export const getGMGiftingCards: any = createAsyncThunk(
  'giftcard/getGMGiftCards',
  async (data: any, {getState}) => {
    const state: any = getState();
    const headers = {
      'tenant-id': state?.login.tenantID,
      Authorization: `Bearer ${state?.impersonateToken?.impersonateToken?.accessToken}`,
    };
    return await gqlGetGMGiftingCards(data, headers);
  },
);

const gmGiftCards = createSlice({
  name: 'gmGiftCards',
  initialState,
  reducers: {
    resetGMGiftCards: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getGMGiftingCards.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getGMGiftingCards.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action?.payload?.data;
      })
      .addCase(getGMGiftingCards.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default gmGiftCards.reducer;

export const {resetGMGiftCards} = gmGiftCards.actions;
