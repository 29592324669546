const styles = {
  giftCardListContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    gap: '1.5rem 2%',
  },
};

export default styles;
