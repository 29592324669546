import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {getSRGirdData} from './SRListGridDataSource';
import TableView from 'Components/TableView';
import {SortOrder} from 'Utils/enums';
import {dateConversionIsoToLocal} from 'Utils/helpers/DateValidation';
import {FORMAT_TYPE} from 'Utils/constants/DateFormat';
import {searchSRByID} from 'Redux/Slices/selectedSR';
import {sortTable} from 'Utils/helpers/SRListHelpers';
import {NoSearchResults} from 'Components';
import {MEMBER_SEARCH} from 'Pages/SearchMember/searchConstants';

const SRListView = (props) => {
  const dispatch = useDispatch();
  const {SRListData} = props;
  const [currentSortCriteria, setCriteriaSort] = useState('createdOn');
  const [sortOrder, setSortOrder] = useState(SortOrder.ASCENDING);
  const [tableData, setTableData] = useState<any[]>([]);
  const tenantConfig = useSelector((state: any) => state?.login?.tenantConfig);
  const [noResults, setNoResults] = useState(false);

  useEffect(() => {
    if (SRListData && SRListData?.length > 0) {
      setNoResults(false);
      parseUnfilteredData(SRListData);
    } else {
      setNoResults(true);
    }
  }, [SRListData]);

  const parseUnfilteredData = (data) => {
    const temp: any = [];
    (data || []).forEach((item: any) => {
      temp.push({
        type: item?.type,
        id: item?.id,
        status: item?.status,
        serviceType: item?.additionalData?.service,
        summary: item?.summary,
        createdOn: item?.audit?.createdOn
          ? dateConversionIsoToLocal(
              item.audit?.createdOn,
              FORMAT_TYPE.DATE_TIME,
              '',
              tenantConfig.locale,
            )
          : '',
        srData: item,
      });
    });
    let resp = sortTable(temp, 'createdOn', 'createdOn', SortOrder.ASCENDING, tenantConfig.locale);
    setCriteriaSort(resp.currentSortCriteria);
    setTableData(resp.tableData);
    setSortOrder(resp.newSortOrder);
  };

  const onRowClick = async (rowData) => {
    let {srData} = rowData;
    let srPayload = {
      id: srData.id,
    };
    let response = await dispatch(searchSRByID(srPayload));
    if (response) {
      props.handleSRRowClick();
    }
  };

  const onSortIconClick = (sortCriteria) => {
    let resp = sortTable(
      tableData,
      sortCriteria,
      currentSortCriteria,
      sortOrder,
      tenantConfig.locale,
    );

    setCriteriaSort(resp.currentSortCriteria);
    setTableData(resp.tableData);
    setSortOrder(resp.newSortOrder);
  };

  return (
    <div>
      <>
        {noResults ? (
          <NoSearchResults CSTexts={MEMBER_SEARCH.no_search_result} />
        ) : (
          <TableView
            {...getSRGirdData(tableData, SortOrder.DESCENDING, currentSortCriteria, true)}
            onRowClick={onRowClick}
            sortTable={onSortIconClick}
            isCommentColumn={true}
          />
        )}
      </>
    </div>
  );
};

export default SRListView;
