/* eslint-disable no-unused-vars */
import React from 'react';
import {TableCell, TableHead, TableRow} from '@mui/material';
import globalSpacing from 'Theme/Spacing';

interface IProps {
  headers: any[];
}

const HeaderRow: React.FC<IProps> = (props) => {
  const {headers} = props;
  return (
    <TableHead>
      <TableRow
        sx={{
          backgroundColor: 'primary.light',
        }}>
        {headers.map((field, index) => (
          <TableCell
            key={index}
            align={index === 0 ? 'left' : 'center'}
            sx={{paddingY: globalSpacing.sm}}>
            {field.label}
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
};

export default HeaderRow;
