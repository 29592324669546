import {createSlice, createAsyncThunk, createEntityAdapter} from '@reduxjs/toolkit'; //createSelector,
import {gqlFetchAllMetadata} from 'GQL/MethodsBFF/ServiceRequest';
import {RBSG_TENANT_ID} from 'Utils/constants/genericConstants';

interface IAllMetadata {
  matadata: any;
}

const allMetadataAdapter: any = createEntityAdapter<IAllMetadata>({});

const initialState = allMetadataAdapter.getInitialState({
  status: 'idle',
  error: '',
});

export const getAllMetadata: any = createAsyncThunk(
  'serviceRequest/getAllMetadata',
  async (data: any, {getState}) => {
    const state: any = getState();
    const headers = {
      'tenant-id': !RBSG_TENANT_ID.includes(state?.login.tenantID.toString())
        ? state?.login.tenantID
        : 'internal',
      'user-access-token': state?.login.loginInfo?.access_token,
      'client-access-token': state?.login.loginInfo?.service_token,
    };
    return await gqlFetchAllMetadata(data, headers);
  },
);

const allMetadata = createSlice({
  name: 'allMetadata',
  initialState,
  reducers: {
    setAllMetadata: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllMetadata.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getAllMetadata.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.mapping = action.payload.data.getAllMetadata;
      })
      .addCase(getAllMetadata.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default allMetadata.reducer;

export const {setAllMetadata} = allMetadata.actions;
