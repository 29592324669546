import {createSlice, createAsyncThunk, createEntityAdapter} from '@reduxjs/toolkit'; //createSelector,
import {
  gqlCreateSR,
  gqlUpdateSR,
  gqlFetchTranscript,
  gqlPartnerJump,
  gqlResetPassword,
  gqlResetWebEnable,
} from 'GQL/MethodsBFF/ServiceRequest';
import {setSelectedSRData} from 'Redux/Slices/selectedSR';

interface ServiceRequest {}

const serviceRequestAdapter: any = createEntityAdapter<ServiceRequest>({});

const initialState = serviceRequestAdapter.getInitialState({
  status: 'idle',
  error: '',
});

export const createSR: any = createAsyncThunk(
  'serviceRequest/createSR',
  async (data: any, {getState}) => {
    const state: any = getState();
    const headers = {
      'tenant-id': state?.login.tenantID,
      'user-access-token': state?.login.loginInfo?.access_token,
      'client-access-token': state?.login.loginInfo?.service_token,
    };
    let response = await gqlCreateSR(data, headers);
    return response;
  },
);

export const updateSR: any = createAsyncThunk(
  'serviceRequest/updateSR',
  async (data: any, {getState}) => {
    const state: any = getState();
    const headers = {
      'tenant-id': state?.login.tenantID,
      'user-access-token': state?.login.loginInfo?.access_token,
      'client-access-token': state?.login.loginInfo?.service_token,
    };
    return await gqlUpdateSR(data, headers);
  },
);

export const partnerJump: any = createAsyncThunk(
  'serviceRequest/partnerJump',
  async (data: any, {getState}) => {
    const state: any = getState();
    const headers = {
      'tenant-id': state?.login.tenantID,
      'user-access-token': state?.login.loginInfo?.access_token,
      'client-access-token': state?.login.loginInfo?.service_token,
    };
    return await gqlPartnerJump(data, headers);
  },
);

export const resetPassword: any = createAsyncThunk(
  'serviceRequest/resetPassword',
  async (data: any, {getState}) => {
    const state: any = getState();
    const headers = {
      'tenant-id': state?.login.tenantID,
      'user-access-token': state?.login.loginInfo?.access_token,
      'client-access-token': state?.login.loginInfo?.service_token,
    };
    return await gqlResetPassword(data, headers);
  },
);

export const resetWebEnable: any = createAsyncThunk(
  'serviceRequest/resetWebEnable',
  async (data: any, {getState}) => {
    const state: any = getState();
    const headers = {
      'tenant-id': state?.login.tenantID,
      'user-access-token': state?.login.loginInfo?.access_token,
      'client-access-token': state?.login.loginInfo?.service_token,
    };
    return await gqlResetWebEnable(data, headers);
  },
);

export const fetchTranscript: any = createAsyncThunk(
  'serviceRequest/fetchTranscript',
  async (data: any, {getState}) => {
    const state: any = getState();
    const headers = {
      'tenant-id': state?.login.tenantID,
      'user-access-token': state?.login.loginInfo?.access_token,
      'client-access-token': state?.login.loginInfo?.service_token,
    };
    return await gqlFetchTranscript(data, headers);
  },
);

const serviceRequestSlice = createSlice({
  name: 'serviceRequest',
  initialState,
  reducers: {
    serviceRequestData: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createSR.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(createSR.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.createSR = action.payload.data.createSR;
        setSelectedSRData(action.payload.data.createServiceRequest);
      })
      .addCase(createSR.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updateSR.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.updateSR = action.payload.data.createSR;
      })
      .addCase(fetchTranscript.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.transcriptData = action?.payload?.data?.getTranscript?.transcript || null;
      });
  },
});

export default serviceRequestSlice.reducer;

export const {serviceRequestData} = serviceRequestSlice.actions;
