import {PAYMENT_METHOD, TRANSACTION_REASON} from 'Utils/constants/genericConstants';
import * as Yup from 'yup';

export const createValidationSchema = (paymentMethod) => {
  return Yup.object({
    paymentMethod: Yup.string().required('Payment method is a required field'),
    amount: Yup.number().required('Amount is a required field'),
    cardNumber: Yup.string().test('required', function (value) {
      if (paymentMethod === PAYMENT_METHOD.CARD && !value) {
        return this.createError({
          path: this.path,
          message: 'Card is a required field',
        });
      } else {
        return true;
      }
    }),
    sortCode: Yup.string().test('required', function (value) {
      if (paymentMethod === PAYMENT_METHOD.BANK && !value) {
        return this.createError({
          path: this.path,
          message: 'Sort Code is a required field',
        });
      } else {
        return true;
      }
    }),
    account: Yup.string().test('required', function (value) {
      if (paymentMethod === PAYMENT_METHOD.BANK && !value) {
        return this.createError({
          path: this.path,
          message: 'Account is a required field',
        });
      } else {
        return true;
      }
    }),
  });
};

export const initialValues = {
  amount: '',
  cardNumber: '',
  account: '',
  bankCode: '',
  paymentMethod: 'Credit Card',
  paymentType: '',
  sortCode: '',
  flowType: 'Debit',
  cheque: '',
  chequeBankCode: '',
  chequeAc: '',
  eftDescription: '',
  authCode: '',
  transactionReason: TRANSACTION_REASON.PURCHASE,
  comments: '',
  buttonAction: '',
};

export const FLAGGED_PAYMENT_VALUES = [
  'cheque',
  'account',
  'authCode',
  'bankCode',
  'chequeAc',
  'sortCode',
  'chequeBankCode',
  'cardNumber',

  'billNoOfRetires',
  'billNextRetryDate',
  'billProcessedDate',
  'billFirstSoftDecline',
  'paymentFailureReason',
  'billLastProcessedDate',
  'billFirstProcessedDate',

  'paymentCreatedBy',
  'paymentCreatedDate',
  'paymentModifiedBy',
  'paymentModifiedDate',
  'paymentStatus',
];
