import {Stack} from '@mui/material';
import React, {useState, useEffect} from 'react';
import globalSpacing from 'Theme/Spacing';
import {gqlGiftingHistory} from 'GQL/MethodsBFF/GiftingHistory';
import {
  InfiniteScrollerCustom,
  Wrapper,
  TableItemCard,
  Loading,
  SnackBar,
  NoResults,
  TabHeading,
} from 'Components';
import giftHistoryMap from 'Utils/helpers/GiftHistoryMap';
import {useSnackBar, useInfiniteScroll} from 'Hooks';
import {useSelector} from 'react-redux';
import {GIFT_HISTORY} from './giftingHistoryConstants';

const TabGiftingHistory = () => {
  const [giftHistoryData, setGiftHistoryData] = useState<any[]>([]);
  const [noResult, setNoResult] = useState(false);

  const member = useSelector(
    (state: any) => state?.selectedMember?.selectedMember?.focusedMemberDetails,
  );

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSnackBarOpen, closeSnackBar] = useSnackBar(error);

  const [totalResults, setTotalResults] = useState(0);
  const [{pageNo, nextPage, pageSize}] = useInfiniteScroll();

  //methods used in useeffect
  const _showLoading = () => pageNo === 0 && setLoading(true);
  const _handleResponse = (data, loading, error) => {
    setLoading(loading);
    (error || !data) && setError(error?.message || 'Undefined error');
    if (data?.responseList) {
      const mappedList = giftHistoryMap(data.responseList);
      if (pageNo === 0) {
        setGiftHistoryData(mappedList);
        setTotalResults(data.pagingInfo?.totalRecords);
      } else {
        setGiftHistoryData([...giftHistoryData, ...mappedList]);
      }
    }
    if (data?.errors[0]?.code === 'SR1007') {
      setNoResult(true);
    }
  };

  useEffect(() => {
    _showLoading();
    (async () => {
      const {data, loading, error} = await gqlGiftingHistory({
        memberId: member?.memberId,
        type: 'Gifting - Agent',
        pageSize: pageSize,
        pageNo,
      });
      _handleResponse(data?.getSRByMemberIdAndType?.[0], loading, error);
    })();
  }, [pageNo]);

  return (
    <Wrapper>
      <TabHeading data={GIFT_HISTORY} />
      {loading ? (
        <Loading />
      ) : error ? (
        <SnackBar
          errorMessage={error}
          isSnackBarOpen={isSnackBarOpen}
          closeSnackBar={closeSnackBar}
        />
      ) : noResult ? (
        <NoResults text={GIFT_HISTORY.no_results_text} />
      ) : (
        <InfiniteScrollerCustom
          dataLength={giftHistoryData?.length}
          next={() => nextPage()}
          hasMore={giftHistoryData.length < totalResults}>
          <Stack spacing={globalSpacing.s} mt={globalSpacing.sm}>
            {giftHistoryData.map((item, index) => (
              <TableItemCard key={index} item={item} disabled={true} showRefulfill={true} />
            ))}
          </Stack>
        </InfiniteScrollerCustom>
      )}
    </Wrapper>
  );
};

export default TabGiftingHistory;
