import {Box, Button, Card, CardMedia, Stack, Typography} from '@mui/material';
import {BackButtonWText} from 'Components';
import {gqlSetMemberGift} from 'GQL/MethodsBFF/Gifting';
import parse from 'html-react-parser';
import React, {Fragment, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {isUpdateSRList} from 'Redux/Slices/SRListFilter';
import globalSpacing from 'Theme/Spacing';
import {GIFTING} from '../giftingConstants';
import GiftDialog from './dialogs/GiftDialog';
import GiftDialogError from './dialogs/GiftDialogError';
import GiftDialogSuccess from './dialogs/GiftDialogSuccess';

interface IProps {
  toggleView?: any;
  selectedGiftOffer: any;
}

const GiftDetailView: React.FC<IProps> = (props) => {
  const {toggleView, selectedGiftOffer} = props;
  const {selectedMember} = useSelector((state: any) => state?.selectedMember);
  const currentMemberDetails = selectedMember.currentMemberDetails;
  const {externalRefId, memberId, clientEmail} = useSelector(
    (state: any) => state?.selectedMember?.selectedMember?.focusedMemberDetails,
  );
  let dispatch = useDispatch();

  const [openGiftingDialog, setOpenGiftingDialog] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [manualEmailEntry, setManualEmailEntry] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmitGift = async () => {
    setLoading(true);
    const {data, error} = await gqlSetMemberGift({
      GiftingOfferRequestInput: {
        firstName: currentMemberDetails?.firstName,
        emailId: clientEmail || manualEmailEntry,
        offerId: selectedGiftOffer.id,
        merchantName: selectedGiftOffer.merchant?.name,
        titleShort: selectedGiftOffer.titleShort,
        memberId: memberId,
        memberExternalRefId: externalRefId,
      },
    });
    setLoading(false);
    if (data?.giftToCustomer) {
      setOpenGiftingDialog(false);
      setOpenSuccessDialog(true);
      dispatch(
        isUpdateSRList({
          isRefreshSRList: true,
        }),
      );
    } else if (error) {
      if (error.message.includes('MO1013')) {
        setOpenGiftingDialog(false);
        setOpenErrorDialog(true);
        setErrorMessage('MO1013');
      }
      if (error.message.includes('MO1007')) {
        setOpenGiftingDialog(false);
        setOpenErrorDialog(true);
        setErrorMessage('MO1007');
      }
    }
  };

  return (
    <Fragment>
      <Stack direction="row">
        <BackButtonWText text={GIFTING.detail_view.back_button_text} callback={toggleView} />
      </Stack>
      <Box>
        <Card>
          <CardMedia
            component="img"
            height="200"
            image={selectedGiftOffer.images[2].imageUrl}
            alt={selectedGiftOffer.images[2].altText}
          />
          <Box sx={{padding: globalSpacing.md}}>
            <Typography variant="subtitle1" textAlign={'center'}>
              {selectedGiftOffer.titleShort}
            </Typography>
            <Typography variant="body1">{parse(selectedGiftOffer.description)} </Typography>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              mt={globalSpacing.lg}>
              <img src={selectedGiftOffer.images[0].imageUrl} height="40" width="40" />
              <Button
                variant="contained"
                disableRipple
                onClick={() => setOpenGiftingDialog(true)}
                sx={styles.button}>
                {GIFTING.detail_view.button_text}
              </Button>
              &nbsp;
            </Stack>
          </Box>
        </Card>
        <Box sx={styles.infoContainer}>
          <Typography variant="subtitle1">{GIFTING.detail_view.heading1}</Typography>
          <Typography variant="body1">{parse(selectedGiftOffer.keyTerms) || ''}</Typography>
          <Typography variant="subtitle1" mt={globalSpacing.md}>
            {GIFTING.detail_view.heading2}
          </Typography>
          <Typography variant="body1">{parse(selectedGiftOffer.termsAndConditions)}</Typography>
        </Box>
        <GiftDialog
          openGiftingDialog={openGiftingDialog}
          setOpenGiftingDialog={setOpenGiftingDialog}
          handleSubmitGift={handleSubmitGift}
          customerEmail={clientEmail}
          manualEmailEntry={manualEmailEntry}
          setManualEmailEntry={setManualEmailEntry}
          loading={loading}
        />
        <GiftDialogSuccess
          customerEmail={clientEmail || manualEmailEntry}
          openSuccessDialog={openSuccessDialog}
          setOpenSuccessDialog={setOpenSuccessDialog}
        />
        <GiftDialogError
          openErrorDialog={openErrorDialog}
          setOpenErrorDialog={setOpenErrorDialog}
          errorMessage={errorMessage}
        />
      </Box>
    </Fragment>
  );
};

const styles = {
  infoContainer: {marginY: globalSpacing.lg, marginX: globalSpacing.md},
  button: {
    width: '25%',
  },
};
export default GiftDetailView;
