import {SS} from 'Utils/constants/SessionStorage';

//MOSTLY UNUSED
const getAccessToken = () => {
  return sessionStorage.getItem(SS.ACCESS_T);
};

const setAccessToken = (token) => {
  sessionStorage.setItem(SS.ACCESS_T, token);
};

const getRefreshToken = () => {
  return sessionStorage.getItem(SS.REFRESH_T);
};

const setRefreshToken = (token) => {
  sessionStorage.setItem(SS.REFRESH_T, token);
};

const getServiceToken = () => {
  return sessionStorage.getItem(SS.SERVICE_T);
};

const setServiceToken = (token) => {
  sessionStorage.setItem(SS.SERVICE_T, token);
};

const removeTokens = () => {
  sessionStorage.removeItem(SS.ACCESS_T);
  sessionStorage.removeItem(SS.REFRESH_T);
  sessionStorage.removeItem(SS.SERVICE_T);
};

export {
  getAccessToken,
  setAccessToken,
  getRefreshToken,
  setRefreshToken,
  getServiceToken,
  setServiceToken,
  removeTokens,
};
