import React from 'react';
import {Navigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {SEARCH} from 'Utils/constants/Paths';

const RequireMember = ({children}) => {
  const {selectedMember} = useSelector((state: any) => state?.selectedMember);

  if (!selectedMember) {
    return <Navigate to={SEARCH} replace />;
  }

  return children;
};

RequireMember.propTypes = {
  children: PropTypes.node.isRequired,
};
export default RequireMember;
