import {Box, DialogContent} from '@mui/material';
import DialogView from 'Components/DialogView';
import React, {useEffect, useState} from 'react';
import {addTicketSection, buttonList} from './AddTicket';
import {initialValues, validationSchema} from './AddTicketSchema';
import Form from 'Components/Form/FormWrapper';

interface IProps {
  openAddTicketDialog: boolean;
  isOpenTicketDialog: any;
  ticket: any;
  setTicket: any;
  handleOnSubmit: any;
}

const AddTicketDialog: React.FC<IProps> = (props) => {
  let {openAddTicketDialog, isOpenTicketDialog, ticket, setTicket, handleOnSubmit} = props;
  const [initialFormValues, setInitialFormValues] = useState(initialValues);
  let newbuttonList: any = buttonList;
  useEffect(() => {
    if (ticket?.ticketLineId) {
      setInitialFormValues(ticket);
      ticket.isNoCashback =
        ticket.isNoCashback === 'Yes' ? true : ticket.isNoCashback === true ? true : false;
      newbuttonList.fields = [
        {
          fieldType: 'button',
          htmlInputType: 'submit',
          name: 'updateButton',
          id: 'updateButton',
          fieldLabel: 'Update',
          variant: 'contained',
          color: 'primary',
          dataTest: 'updateButton',
          xs: 2,
          sm: 2,
        },
        {
          fieldType: 'button',
          htmlInputType: 'button',
          name: 'cancel',
          id: 'cancel',
          fieldLabel: 'Cancel',
          variant: 'contained',
          dataTest: 'cancel',
          color: 'error',
          xs: 2,
          sm: 2,
        },
      ];
    } else {
      newbuttonList.fields = [
        {
          fieldType: 'button',
          htmlInputType: 'submit',
          name: 'addButton',
          id: 'addButton',
          fieldLabel: 'Add',
          variant: 'contained',
          color: 'primary',
          dataTest: 'addButton',
          xs: 2,
          sm: 2,
        },
        {
          fieldType: 'button',
          htmlInputType: 'button',
          name: 'cancel',
          id: 'cancel',
          fieldLabel: 'Cancel',
          variant: 'contained',
          dataTest: 'cancel',
          color: 'error',
          xs: 2,
          sm: 2,
        },
      ];
      setInitialFormValues(initialValues);
    }
  }, [ticket]);

  const handleFormSubmit = (values) => {
    handleOnSubmit(values);
    if (values?.buttonAction === 'Add') {
      setTicket(values);
      isOpenTicketDialog(false);
    }
    if (values?.buttonAction === 'Update') {
      setTicket(values);
      isOpenTicketDialog(false);
    }
    //setInitialFormValues(initialValues);
  };

  const handleCancel = () => {
    //  setTicket([]);
    isOpenTicketDialog(false);
  };
  const customEvents = {
    cancel: {onClick: handleCancel},
  };
  return (
    <DialogView
      closeDialogMethod={isOpenTicketDialog}
      open={openAddTicketDialog}
      id="ticket-dialog">
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}>
        <Box>
          <Form
            onSubmit={handleFormSubmit}
            validationSchema={validationSchema}
            initialValues={initialFormValues}
            section1={addTicketSection}
            customEvents={customEvents}
            buttonStack={newbuttonList}
          />
        </Box>
      </DialogContent>
    </DialogView>
  );
};

export default AddTicketDialog;
