export const customerDetails = {
  heading: 'Customer Details',
  subtitle: 'View customer details below',
  fields: [
    {
      key: 'memberId',
      value: 'Unique Identifier',
    },
    {
      key: 'firstName',
      value: 'First Name',
    },
    {
      key: 'lastName',
      value: 'Last Name',
    },
    {
      key: 'clientEmail',
      value: 'Email Address',
    },
    {
      key: 'address',
      value: 'Address',
    },
    {
      key: 'postalCode',
      value: 'Post Code',
    },
    {
      key: 'status',
      value: 'Loyalty Status',
    },
  ],
};
