import {
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  useTheme,
} from '@mui/material';
import React from 'react';
import OfferParentRow from './OfferParentRow';
import globalSpacing from 'Theme/Spacing';

interface OfferTableProps {
  rows: any;
  onReFulfillClick: (ClaimedOfferAdditionalData, string, boolean) => void;
}

const OfferTable = (props: OfferTableProps) => {
  const theme = useTheme();
  const styles = {
    paper: {
      marginBottom: globalSpacing.sm,
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      overflow: 'hidden',
    },
    table: {
      '& .MuiTableCell-root': {
        padding: '10px',
        fontFamily: 'regular',
      },
    },
  };

  return (
    <TableContainer component={Paper} sx={styles.paper}>
      <Table aria-label="collapsible table">
        <TableHead sx={{background: theme.palette.primary.light}}>
          <TableRow>
            <TableCell>Confirmation&nbsp;#</TableCell>
            <TableCell>Date&nbsp;Ordered</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Redeemed&nbsp;By</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Order&nbsp;Type</TableCell>
            <TableCell>Merchant</TableCell>
            <TableCell>Cards</TableCell>
            <TableCell>Value</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody sx={styles.table}>
          {props.rows.map((row, index) => (
            <OfferParentRow
              key={row.id}
              onReFulfillClick={props.onReFulfillClick}
              backgroundColor={
                index % 2 !== 0 ? theme.palette.primary.light : theme.palette.common.white
              }
              row={row}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OfferTable;
