import {createSlice, createAsyncThunk, createEntityAdapter} from '@reduxjs/toolkit'; //createSelector,
import {gqlTenantServiceMapping} from 'GQL/MethodsBFF/ServiceRequest';

interface ITenantServiceMappings {
  mapping: any;
}

const serviceMappingAdapter: any = createEntityAdapter<ITenantServiceMappings>({});

const initialState = serviceMappingAdapter.getInitialState({
  status: 'idle',
  error: '',
});

export const getTenantServiceMapping: any = createAsyncThunk(
  'serviceRequest/getTenantServiceMappingAdapter',
  async (data: any, {getState}) => {
    const state: any = getState();
    const headers = {
      'tenant-id': state?.login.tenantID,
      'user-access-token': state?.login.loginInfo?.access_token,
      'client-access-token': state?.login.loginInfo?.service_token,
    };
    return await gqlTenantServiceMapping(data, headers);
  },
);

const serviceMapping = createSlice({
  name: 'serviceMapping',
  initialState,
  reducers: {
    resetServiceMappingData: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getTenantServiceMapping.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getTenantServiceMapping.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.mapping = action?.payload?.data?.getTenantServicesMappings;
      })
      .addCase(getTenantServiceMapping.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default serviceMapping.reducer;

export const {resetServiceMappingData} = serviceMapping.actions;
