import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import {useSelector} from 'react-redux';
import globalSpacing from 'Theme/Spacing';
import {FORMAT_TYPE} from 'Utils/constants/DateFormat';
import {dateConversionIsoToLocal, isValidISO8601} from 'Utils/helpers/DateValidation';

const FieldsItem = ({fieldKey, value, csFieldMap}) => {
  const tenantConfig = useSelector((state: any) => state?.login?.tenantConfig);
  const fieldValue = fieldKey === 'transactionStatus' ? value || ' ' : value;

  return (
    <Fragment>
      {fieldKey && csFieldMap[fieldKey] && fieldValue && (
        <Box
          sx={{
            flex: '0 0 30%',
            borderBottom: 1,
            marginBottom: globalSpacing.xs,
            paddingTop: globalSpacing.s,
          }}>
          <Typography variant="body2">{csFieldMap[fieldKey]}</Typography>
          <Typography variant="body1" py={globalSpacing.xs}>
            {isValidISO8601(fieldValue)
              ? dateConversionIsoToLocal(
                  fieldValue,
                  FORMAT_TYPE?.DATE_TIME,
                  '',
                  tenantConfig?.locale,
                )
              : fieldValue || '-'}
          </Typography>
        </Box>
      )}
    </Fragment>
  );
};

FieldsItem.propTypes = {
  fieldKey: PropTypes.string.isRequired,
  value: PropTypes.string,
  csFieldMap: PropTypes.object.isRequired,
};

export default FieldsItem;
