import {GQLType, gqlCall} from '../../GQL/utils';
import {Queries} from '../../GQL/GQLConstants';
import {globalConfig} from 'Config/config';

export const getMemberCashbackEligibility = async (memberDetails, headers) => {
  let request = {
    memberId: memberDetails?.currentMemberDetails?.memberId,
    membershipId: memberDetails?.membershipId,
    channel: '',
  };
  try {
    // For non membership clients -> cashback eligibility call is not required.
    if (!request?.membershipId) {
      return;
    }
    let resp = await gqlCall(
      GQLType.QUERY,
      Queries.GET_MEMBER_CASHBACK_ELIGIBILITY,
      request,
      headers,
      globalConfig._config.BFF.estBFF,
      false,
    );
    let {cashbackEligibility} = resp?.data;
    return cashbackEligibility;
  } catch (error: any) {
    console.log(error);
  }
};
export const getSolicitationId = (memberDetails) => {
  let solicitationId;
  (memberDetails?.subscriptions || []).forEach((item) => {
    if (item.code.toUpperCase() === 'ACTIVE') {
      solicitationId = parseInt(item.solicitationId);
    }
  });
  if (solicitationId) {
    return solicitationId;
  } else {
    let currentSubscription = getCurrentSubscription(memberDetails?.subscriptions);
    return parseInt(currentSubscription?.solicitationId);
  }
};

export const getCurrentSubscription = (data) => {
  let subscriptions = data?.slice();
  try {
    if (Boolean(subscriptions) === false || subscriptions.length === 0) {
      return null;
    }
    subscriptions.sort(function (a, b) {
      var a_startDate = new Date(a.startDate);
      var b_startDate = new Date(b.startDate);
      if (a_startDate.getTime() === b_startDate.getTime()) {
        return 0;
      }
      return a_startDate > b_startDate ? -1 : 1;
    });
    return subscriptions[0];
  } catch (err) {
    console.log(err);
    return {};
  }
};

export const checkDateOrReturnNull = (date) => {
  return date ? date : null;
};
