import React from 'react';
import {Formik, Form} from 'formik';
import formFieldMap from './FormFieldMaps';
import Wrapper from 'Components/Wrapper';
import {Box, Grid, Paper, Stack, Typography} from '@mui/material';
import globalSpacing from 'Theme/Spacing';

const FormikForm = (props) => {
  let customProps = props;
  return (
    <Formik
      initialValues={customProps.initialValues}
      validationSchema={customProps.validationSchema}
      onSubmit={(values, actions) => {
        customProps.onSubmit(values).then(() => {
          actions.setSubmitting(false);
        });
      }}
      enableReinitialize={true}>
      {(props) => (
        <Form>
          <Wrapper>
            <Paper sx={{marginY: globalSpacing.s}}>
              {customProps.topButtonStack ? (
                <Grid container spacing={globalSpacing.s}>
                  {customProps.topButtonStack.fields.map((field) => {
                    return formFieldMap[field.fieldType](field, props, customProps);
                  })}
                </Grid>
              ) : null}
              {customProps.section1 ? (
                <Stack spacing={globalSpacing.s} p={globalSpacing.m}>
                  <Box>
                    <Typography variant="h6">{customProps.section1.title}</Typography>
                  </Box>
                  <Grid container spacing={globalSpacing.s}>
                    {customProps.section1.fields.map((field) => {
                      return formFieldMap[field.fieldType](field, props, customProps);
                    })}
                  </Grid>
                </Stack>
              ) : null}
              {customProps.section2 ? (
                <Stack spacing={globalSpacing.s} p={globalSpacing.m}>
                  <Box>
                    <Typography variant="h6">{customProps.section2.title}</Typography>
                  </Box>
                  <Grid container spacing={globalSpacing.s}>
                    {customProps.section2.fields.map((field) => {
                      return formFieldMap[field.fieldType](field, props, customProps);
                    })}
                  </Grid>
                </Stack>
              ) : null}
              {/* need to copy at top */}
              {customProps.buttonStack ? (
                <Grid container spacing={globalSpacing.s} justifyContent="end">
                  {customProps.buttonStack.fields.map((field) => {
                    return formFieldMap[field.fieldType](field, props, customProps);
                  })}
                </Grid>
              ) : null}
            </Paper>
          </Wrapper>
        </Form>
      )}
    </Formik>
  );
};

export default FormikForm;
