import {globalConfig} from '../../Config/config';
import {Queries, Mutations} from '../GQLConstants';
import {gqlCall, GQLType} from '../utils';

const gqlPaymentCardDetails = async (payload, headers) => {
  const {
    BFF: {estBFF},
  } = globalConfig._config;

  try {
    const response = await gqlCall(
      GQLType.QUERY,
      Queries.GET_PAYMENT_CARD_DETAILS,
      payload,
      headers,
      estBFF,
      false,
    );
    return response;
  } catch (error) {
    return {error};
  }
};

const gqlChargePayment = async (payload, headers) => {
  const {
    BFF: {estBFF},
  } = globalConfig._config;

  try {
    const response = await gqlCall(
      GQLType.QUERY,
      Queries.CHARGE_PAYMENT,
      payload,
      headers,
      estBFF,
      false,
    );
    return response;
  } catch (error) {
    return {error};
  }
};

const gqlUpdatePayment = async (payload, headers) => {
  const {
    BFF: {estBFF},
  } = globalConfig._config;

  try {
    const response = await gqlCall(
      GQLType.QUERY,
      Queries.UPDATE_PAYMENT,
      payload,
      headers,
      estBFF,
      false,
    );
    return response;
  } catch (error) {
    return {error};
  }
};

const gqlCreatePayments = async (payload, headers) => {
  const {
    BFF: {estBFF},
  } = globalConfig._config;

  try {
    const response = await gqlCall(
      GQLType.MUTATION,
      Mutations.SAVE_OR_UPDATE_PAYMENT_VEHICLE,
      payload,
      headers,
      estBFF,
      false,
    );
    return response;
  } catch (error) {
    return {error};
  }
};

export {gqlPaymentCardDetails, gqlChargePayment, gqlUpdatePayment, gqlCreatePayments};
