import {useState} from 'react';

const useDetailView = () => {
  const [isDetailView, setIsDetailView] = useState(false);
  const toggleDetailView = () => setIsDetailView(!isDetailView);

  return [isDetailView, toggleDetailView];
};

export default useDetailView;
