import TableView from 'Components/TableView';
import React from 'react';
import {getTicketGridData} from './TicketListGridDataSource';

interface IProps {
  tableData?: any[];
  onRemoveItemClick?: any;
  onUpdateItemClick?: any;
}

const TicketListView: React.FC<IProps> = (props) => {
  const {tableData} = props;
  const sortOrder = '';
  const currentSortCriteria = '';

  const onSortIconClick = () => {};

  const customEvents = {
    updateButton: (data) => {
      props.onUpdateItemClick(data);
    },
    deleteButton: (data) => {
      props.onRemoveItemClick(data);
    },
  };

  const buttonList = [
    {
      fieldType: 'button',
      htmlInputType: 'submit',
      name: 'updateButton',
      id: 'updateButton',
      fieldLabel: 'Update',
      variant: 'contained',
      wrapperClass: '',
      dataTest: 'updateButton',
      xs: 2,
      sm: 2,
    },
    {
      fieldType: 'button',
      htmlInputType: 'button',
      name: 'deleteButton',
      id: 'deleteButton',
      fieldLabel: 'Delete',
      variant: 'contained',
      wrapperClass: '',
      dataTest: 'deleteButton',
      xs: 2,
      sm: 2,
    },
  ];

  return (
    <div>
      <TableView
        data-test={'grid'}
        {...getTicketGridData(tableData, sortOrder, currentSortCriteria, false, buttonList)}
        customEvents={customEvents}
        buttons={buttonList}
        sortTable={onSortIconClick}
      />
    </div>
  );
};

export default TicketListView;
