import React, {useState} from 'react';
import {ErrorMessage, Field} from 'formik';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  useTheme,
} from '@mui/material';
import globalSpacing from 'Theme/Spacing';
import {dateConversionIsoToLocal} from 'Utils/helpers/DateValidation';
import {FORMAT_TYPE} from 'Utils/constants/DateFormat';
import {useSelector} from 'react-redux';

const CommentBox = (props) => {
  const {formikProp} = props;
  const theme = useTheme();
  const textField = props.field;
  const [openCommentDialog, isOpenCommentDialog] = useState(false);
  const [commentText, setCommentText] = useState('');
  const userName = useSelector((state: any) => state?.login?.userName);

  const handleSubmit = (e) => {
    const timeStamp = dateConversionIsoToLocal(new Date(), FORMAT_TYPE?.DATE_TIME);
    let existingComment = formikProp.values[textField?.name] ?? '';
    if (commentText !== '') {
      let comments = '';
      const audit = ` \n\n- ${userName} ${timeStamp}`;
      if (existingComment) {
        comments = `${commentText}${textField?.disableAudit ? '' : audit}\n\n${existingComment}`;
      } else {
        comments = `${commentText}${textField?.disableAudit ? '' : audit}`;
      }
      formikProp.setFieldValue(textField?.name, comments);
      hadleModal();
    }
  };

  const hadleModal = () => {
    setCommentText('');
    isOpenCommentDialog(!openCommentDialog);
  };

  return (
    <>
      <Grid
        item
        xs={textField.xs}
        sm={textField.sm}
        p={globalSpacing.xs}
        sx={{display: textField.display}}>
        <Field
          as={TextField}
          // variant='filled'
          sx={{
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.08)',
            borderRadius: `${theme.shape.borderRadius}px`,
            marginRight: globalSpacing.s,
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused': {
                '& fieldset': {
                  borderColor: theme.palette.action.active,
                },
              },
            },
            '& .MuiInputLabel-root': {
              color: theme.palette.text.primary,
              '&.MuiInputLabel-shrink': {
                color: theme.palette.text.primary,
              },
            },
            '& .MuiFormControlLabel-root': {
              marginLeft: '0px',
            },
            '& .MuiFormHelperText-root': {
              color: theme.palette.error.main,
            },
            '& .MuiFormLabel-asterisk': {
              color: theme.palette.error.main,
              fontSize: '24px',
            },
            '& .Mui-disabled': {
              color: theme.palette.text.disabled,
            },
          }}
          label={textField.fieldLabel}
          type={textField.htmlInputType}
          disabled={textField.isDisabled ?? false}
          inputProps={{
            readOnly: true,
          }}
          readOnly
          rows={textField.rows}
          multiline
          name={textField.name}
          fullWidth
          required={textField.required}
          helperText={textField.note}
          hidden={textField.hidden}
          onChange={(e) => {
            formikProp.setFieldValue(textField.name, e.target.value);
          }}
          onClick={(e) => {
            hadleModal();
          }}
        />
        <ErrorMessage name={textField.name} component="div" />
      </Grid>
      <Dialog open={openCommentDialog} onClose={() => isOpenCommentDialog(false)}>
        <DialogTitle variant="h4" align="center">
          Note
        </DialogTitle>
        <DialogContent sx={{width: '600px'}}>
          <Box sx={{padding: '20px'}}>Escape = Discard changes made and exit.</Box>
          <TextField
            label="Comments"
            multiline
            rows={12}
            fullWidth
            autoFocus
            onChange={(e) => {
              setCommentText(e.target.value);
            }}
            sx={{
              marginBottom: '20px',
              boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.08)',
              borderRadius: `${theme.shape.borderRadius}px`,
              marginRight: globalSpacing.s,
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused': {
                  '& fieldset': {
                    borderColor: theme.palette.action.active,
                  },
                },
              },
              '& .MuiInputLabel-root': {
                color: theme.palette.text.primary,
                '&.MuiInputLabel-shrink': {
                  color: theme.palette.text.primary,
                },
              },
            }}
          />
          <DialogActions>
            <Button onClick={hadleModal} variant="contained" color="error">
              Cancel
            </Button>
            <Button onClick={handleSubmit} variant="contained">
              Submit
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CommentBox;
