import {IconButton, InputAdornment} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import {useCSContext} from 'Context/ContentStackContext';

const ClearInputIcon = ({reset}) => {
  const {artifacts} = useCSContext();

  return (
    <InputAdornment position="end">
      <IconButton aria-label="Clear input" onClick={() => reset()} edge="end">
        <img src={artifacts.gray_close} />
      </IconButton>
    </InputAdornment>
  );
};

ClearInputIcon.propTypes = {
  reset: PropTypes.func.isRequired,
};

export default ClearInputIcon;
