import globalSpacing from 'Theme/Spacing';

const styles = {
  paper: {
    marginBottom: globalSpacing.md,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
  },
};

export default styles;
