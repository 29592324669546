import React from 'react';
import {Button, Grid, Typography, useTheme} from '@mui/material';
import {Field} from 'formik';
import globalSpacing from 'Theme/Spacing';

export const CustomButton = (props) => {
  const theme = useTheme();
  const buttonField = props.field;
  const customEvents: any = props.customEvents
    ? props.customEvents[buttonField.name] && props.customEvents[buttonField.name]
    : {};
  return (
    <>
      <Grid item xs={buttonField.xs} sm={buttonField.sm} p={globalSpacing.s}>
        <Field
          as={Button}
          type={buttonField.htmlInputType}
          // disabled={
          //   buttonField.disabled ||
          //   !props.formikProp.isValid ||
          //   props.formikProp.isSubmitting ||
          //   !props.formikProp.dirty
          // }
          variant={buttonField.variant}
          name={buttonField.name}
          spacing={buttonField.spacing}
          id={buttonField.id}
          color={buttonField.color}
          size="small"
          fullWidth
          sx={{
            padding: globalSpacing.s,
            '& .Mui-disabled': {
              color: theme.palette.text.disabled,
            },
          }}
          onClick={() => {
            props.formikProp.values.buttonAction = buttonField.fieldLabel;
            props.setSubmitting(false);
          }}
          disabled={buttonField?.isDisabled || props?.formikProp?.isSubmitting}
          {...customEvents}>
          <Typography variant="body2" sx={{textTransform: 'none'}}>
            {buttonField.fieldLabel}
          </Typography>
        </Field>
      </Grid>
    </>
  );
};
