export const addTicketSection = {
  title: 'Ticket Details',
  fields: [
    {
      fieldType: 'select',
      name: 'type',
      id: 'type',
      fieldLabel: 'Ticket Type',
      required: true,
      disabled: false,
      xs: 4,
      sm: 4,
      options: [
        {
          text: 'Adult',
          value: 'Adult',
        },
        {
          text: 'Child',
          value: 'Child',
        },
        {
          text: 'Family Ticket',
          value: 'Family Ticket',
        },
        {
          text: 'Other',
          value: 'Other',
        },
        {
          text: 'Other Concessions',
          value: 'Other Concessions',
        },
        {
          text: 'Senior Citizen',
          value: 'Senior Citizen',
        },
        {
          text: 'Student',
          value: 'Student',
        },
      ],
      dataTest: 'type',
    },
    {
      fieldType: 'text',
      htmlInputType: 'number',
      name: 'quantity',
      id: 'quantity',
      fieldLabel: '#Tickets',
      required: true,
      xs: 4,
      sm: 4,
      dataTest: 'quantity',
    },
    {
      fieldType: 'text',
      htmlInputType: 'number',
      name: 'pricePerUnit',
      id: 'pricePerUnit',
      fieldLabel: 'Price / Ticket €',
      xs: 4,
      sm: 4,
      dataTest: 'pricePerUnit',
    },
    {
      fieldType: 'text',
      htmlInputType: 'number',
      name: 'bookingFee',
      id: 'bookingFee',
      fieldLabel: 'Booking Fee €',
      xs: 4,
      sm: 4,
      dataTest: 'bookingFee',
    },
    {
      fieldType: 'text',
      htmlInputType: 'number',
      required: false,
      name: 'totalPrice',
      id: 'totalPrice',
      fieldLabel: 'Total Line Price €',
      isDisabled: true,
      xs: 4,
      sm: 4,
      dataTest: 'totalPrice',
    },
    {
      fieldType: 'checkbox',
      name: 'isNoCashback',
      id: 'isNoCashback',
      fieldLabel: 'No Cashback',
      xs: 4,
      sm: 4,
      dataTest: 'isNoCashback',
    },
  ],
};

export const buttonList = {
  direction: 'row',
  spacing: 2,
  fields: [
    // {
    //   fieldType: 'button',
    //   htmlInputType: 'submit',
    //   name: 'addAnotherButton',
    //   id: 'addAnotherButton',
    //   fieldLabel: 'Add Another',
    //   variant: 'contained',
    //   dataTest: 'addAnotherButton',
    //   xs: 2,
    //   sm: 2,
    // },
    {
      fieldType: 'button',
      htmlInputType: 'submit',
      name: 'addButton',
      id: 'addButton',
      fieldLabel: 'Add',
      variant: 'contained',
      color: 'primary',
      dataTest: 'addButton',
      xs: 2,
      sm: 2,
    },
    {
      fieldType: 'button',
      htmlInputType: 'button',
      name: 'cancel',
      id: 'cancel',
      fieldLabel: 'Cancel',
      variant: 'contained',
      dataTest: 'cancel',
      color: 'error',
      xs: 2,
      sm: 2,
    },
  ],
};
