import Form from 'Components/Form/FormWrapper';
import TableView from 'Components/TableView';
import {globalConfig} from 'Config/config';
import {searchSRByTypeRecent} from 'Redux/Slices/RecentSRList';
import {isUpdateSRList} from 'Redux/Slices/SRListFilter';
import {updateTenantID} from 'Redux/Slices/login';
import {searchSRByID} from 'Redux/Slices/selectedSR';
import {partnerJump, updateSR} from 'Redux/Slices/serviceRequest';
import {SR_STATUS} from 'Utils/constants/genericConstants';
import {SRPageSize, SortOrder} from 'Utils/enums';
import {dateConversionIsoToLocal} from 'Utils/helpers/DateValidation';
import {
  checkDateOrReturnNull,
  getCurrentSubscription,
  getSolicitationId,
} from 'Utils/helpers/MemberDetails';
import {parseToFloat} from 'Utils/helpers/Numeric';
import {sortTable} from 'Utils/helpers/SRListHelpers';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getCinemaeCodeGridData} from './CinemaECodeGrid';
import CinemaECodePopup from './CinemaECodePopup';
import {serviceRequestDetailsSection, topButtonList} from './cinemaECodeSR';
import {initialValues} from './cinemaECodeSchema';

interface IProps {
  closeDialogMethod?: any;
}

const CinemaECodeSR: React.FC<IProps> = (props) => {
  const {selectedMember} = useSelector((state: any) => state?.selectedMember);
  const ownerID = useSelector((state: any) => state?.login?.userName);
  const selectedSRData = useSelector((state: any) => state.selectedSR.getServiceRequestById);
  const tenantConfig = useSelector((state: any) => state?.login?.tenantConfig);
  const {membershipEnabled} = tenantConfig;

  const [formValues, setFormValues] = useState<any>(initialValues);
  const [pollingConfig, setPollingConfig] = useState<any>();
  const [pollingTimer, setPollingTimer] = useState<any>();
  const [tableData, setTableData] = useState<any>([]);
  const [currentSortCriteria, setCurrentSortCriteria] = useState('ecode');
  const [sortOrder, setSortOrder] = useState(SortOrder.ASCENDING);
  const [showCinemaPopover, toggleCinemaPopover] = useState(false);

  // const [openPaymentDetailViewDialog, isOpenPaymentDetailViewDialog] = useState(false);
  const dispatch = useDispatch();

  //let pollingStartTime;
  let tempPollingTimer;

  const getCinemaECodeSRPollingConfig = async () => {
    return globalConfig._config.cinemaECodeSRPollingConfig;
  };

  useEffect(() => {
    if (selectedSRData?.additionalData) {
      let {additionalData} = selectedSRData;
      let selectedSRValues = {
        channel: additionalData?.channel,
        firstName: additionalData?.customerFirstName,
        status: selectedSRData?.status,
        lastName: additionalData?.customerLastName,
        email: additionalData?.customerEmail,
        cinemaChain: additionalData?.voucherSupplier,
        transactionDate: checkDateOrReturnNull(additionalData?.transactionDate),
        totalVouchers: additionalData?.transactionVoucherQuantity,
        orderPrice: additionalData?.transactionTotalAmount,
        ticketVouchers: additionalData?.transactionTicketQty,
        currency: additionalData?.currency,
      };

      if (additionalData.voucherDetails) {
        mapTableData(additionalData.voucherDetails);
      } else {
        setTableData([]);
      }

      setFormValues(selectedSRValues);
    }
  }, [selectedSRData]);

  const mapTableData = (voucherDetails) => {
    let tempTableData: any = [];

    voucherDetails.forEach((voucher) => {
      tempTableData.push({
        ecode: voucher.ecode,
        description: voucher.description,
        expiryDate: dateConversionIsoToLocal(voucher?.expiryDate),
        price: voucher.price,
        ecodeOnline: voucher?.ecodeOnline !== '-' ? voucher?.ecodeOnline : '',
      });
    });

    const resp = sortTable(
      tempTableData,
      'ecode',
      currentSortCriteria,
      sortOrder,
      tenantConfig.locale,
    );

    setCurrentSortCriteria(resp.currentSortCriteria);
    setTableData(resp.tableData);
    setSortOrder(resp.newSortOrder);
  };

  useEffect(() => {
    setConfigs();

    return () => {
      clearInterval(pollingTimer);
    };
  }, []);

  const setConfigs = async () => {
    const config = await getCinemaECodeSRPollingConfig();
    setPollingConfig(config);
  };

  const resetPollingTimer = () => {
    if (pollingTimer || tempPollingTimer) {
      clearInterval(pollingTimer);
      clearInterval(tempPollingTimer);
      setPollingTimer(null);
    }
    // toggleLoader(false);
  };

  const getCinemaBenefit = () => {
    let benefitData: any;

    if (selectedMember?.benefits) {
      const benefits = selectedMember?.benefits;
      for (let index = 0; index < benefits.length; index++) {
        const benefit = benefits[index];
        if (
          benefit.name === pollingConfig?.benefitName ||
          benefit.vendorId === pollingConfig?.vendorId
        ) {
          benefitData = benefit;
          break;
        }
      }
    }

    return benefitData;
  };
  const updateSRList = () => {
    dispatch(
      isUpdateSRList({
        isRefreshSRList: true,
      }),
    );
  };

  const getCinemaSrDetails = async (variable, updateStatus = 'SUCCESS') => {
    try {
      // toggleLoader(true);
      // let resp = await graphQlApiCall('query', SR_SEARCH, variable);
      let response = await dispatch(searchSRByID(variable)).unwrap();

      if (response?.error) {
        // setNoResults(true);
        // setError(getErrorMsg(response.error));
      } else if (response && response?.data?.getServiceRequestById) {
        if (response.data.getServiceRequestById.member?.tenantId) {
          await dispatch(updateTenantID(response.data.getServiceRequestById.member?.tenantId));
        }
      }
    } catch (error: any) {
      // const errorObj = {
      //   message: error?.error?.message,
      //   correlationId: error.correlationId,
      // };
      // setErrorState(errorMessages.refreshSRIssue, errorObj);
      // toggleLoader(false);
    }
  };

  const findCinemaSR = (serviceRequests) => {
    const cinemaSr = serviceRequests.find((sr) => {
      if (sr.type === 'Cinema eCode') {
        return sr;
      }
    });

    if (cinemaSr) {
      updateSRList();
      let apiVariable = {
        id: cinemaSr.id,
      };
      getCinemaSrDetails(apiVariable);
      resetPollingTimer();
      toggleCinemaPopover(false);
      // toggleLoader(false);
    }
  };
  const getNewSrs = async (fetchSRByStatus = true) => {
    const payload = {
      searchServiceRequestByTypeInput: {
        memberId: selectedMember?.currentMemberDetails?.memberId,
        membershipId: selectedMember?.membershipId,
        membershipSubscriptionDate: getCurrentSubscription(selectedMember?.subscriptions)
          ?.startDate,
        paging: {
          pageNumber: 0,
          pageSize: SRPageSize['RECENT'],
          orderBy: 'id',
          sortOrder: 'descending',
        },
        dateRange: {
          startDate: getCurrentSubscription(selectedMember?.subscriptions)?.startDate,
          endDate: null,
        },
      },
    };
    try {
      let resp = await dispatch(searchSRByTypeRecent(payload));

      const serviceRequests =
        resp?.data?.getServiceRequestsByType?.searchServiceRequestsOutputDtoList;

      if (serviceRequests?.length) {
        findCinemaSR(serviceRequests);
      }
    } catch {
      console.log('SR not Found');
    }
  };

  const startPolling = () => {
    if (pollingTimer || tempPollingTimer) {
      clearInterval(pollingTimer);
      clearInterval(tempPollingTimer);
    }

    tempPollingTimer = setInterval(() => {
      getNewSrs();
    }, pollingConfig.pollingInterval);

    setPollingTimer(tempPollingTimer);

    setTimeout(() => {
      resetPollingTimer();
      toggleCinemaPopover(false);

      // check if any Cinema SR present in any status
      if (!selectedSRData?.id) {
        getNewSrs(false);
      }
    }, pollingConfig.pollingTime);
  };

  const redirectToPartnerPage = (partners) => {
    let form = document.createElement('form');
    document.body.appendChild(form);

    form.method = partners?.httpMethod;
    form.action = partners?.url;
    form.target = '_blank';

    for (let name in partners?.parameters) {
      let input = document.createElement('input');
      input.type = 'hidden';
      input.name = name;
      input.value = partners?.parameters[name];
      form.appendChild(input);
    }

    form.submit();
    toggleCinemaPopover(true);
    startPolling();
  };

  const partnerJumpApiCall = async (variable) => {
    try {
      const resp = await dispatch(partnerJump(variable)).unwrap();
      const partners = resp?.data?.partners;

      if (partners) {
        redirectToPartnerPage(partners);
      }
      // toggleLoader(false);
    } catch (error: any) {
      console.log(error);
      // toggleLoader(false);
    }
  };

  const getRedirectionData = (benefit) => {
    if (benefit?.id) {
      // make API call to get redirection data
      const variable = {
        memberId: selectedMember?.currentMemberDetails?.memberId,
        membershipId: selectedMember?.membershipId,
        packageId: selectedMember?.packageId,
        packageRefId: selectedMember?.packageRefId,
        partnerJumpType: 'Cinema',
        accountNumber: selectedMember?.accountNumber,
        sortCode: selectedMember?.sortCode,
        benefitId: benefit?.id,
        IsMemberImpersonation: 'true',
        UserImpersonator: ownerID,
        BenefitName: 'Cinema',
      };

      partnerJumpApiCall(variable);
    }
  };

  const completeSr = async () => {
    const newSRStatus = SR_STATUS.COMPLETED;
    const currentMember = selectedMember?.currentMemberDetails;
    let updateSRVariabe = {
      id: selectedSRData?.id,
      requestObj: {
        commands: [
          {
            commandName: 'updateSR',
            commandId: 'updateSR2',
            type: selectedSRData?.type,
            subType: selectedSRData?.subType,
            description: selectedSRData?.description,
            summary: selectedSRData?.summary,
            status: newSRStatus,
            additionalData: selectedSRData?.additionalData,
            audit: {
              modifiedBy: ownerID?.toLowerCase(),
              modifiedOn: selectedSRData?.audit?.modifiedOn,
            },
          },
        ],
      },
      notificationParams: {
        firstName: currentMember?.firstName,
        lastName: currentMember?.lastName,
        membershipNumber: selectedMember?.membershipNumber,
        toEmail: currentMember?.primaryEmail || currentMember?.clientEmail,
        packageId: selectedMember?.packageId,
        solicitationId: parseToFloat(getSolicitationId(selectedMember)),
        externalRefId: currentMember?.externalRefId,
        membershipEnabled: membershipEnabled,
      },
    };

    try {
      // toggleLoader(true);
      let resp = await dispatch(updateSR(updateSRVariabe)).unwrap();

      let srUpdateStatus = resp?.data?.updateServiceRequest[0]?.status;

      if (srUpdateStatus?.toUpperCase() === 'SUCCESS') {
        props.closeDialogMethod(false);
        updateSRList();
        let apiVariable = {
          id: selectedSRData?.id,
        };
        getCinemaSrDetails(apiVariable);
        // setErrorState('');
      } else if (srUpdateStatus?.toUpperCase() === 'FAILURE') {
        // let srUpdateErrors = resp?.data?.updateServiceRequest?.[0]?.errors;
        // let isStale = false;
        // const errorStr = srUpdateErrors.map((err) => {
        //   if (err?.code === errorMessages?.staleDataErrorMessage) {
        //     isStale = true;
        //   }
        //   return err?.message;
        // });
        // setErrorState(errorStr?.join(', '));
        // if (isStale) {
        //   SetEditedData(updateSRVariabe);
        //   setErrorState(errorMessages.staleDataIssue, null, true);
        // }
      } else console.log('Something Went Wrong');

      // toggleLoader(false);
    } catch (error: any) {
      // const errorObj = {
      //   message: error?.error?.message,
      //   correlationId: error.correlationId,
      // };
      // setErrorState(errorMessages.updateSRIssue, errorObj);
      // toggleLoader(false);
    }
  };

  const handleOnSubmit = (values) => {
    if (values?.buttonAction == 'Purchase Online') {
      console.log('Purchase Online');
      const benefitData = getCinemaBenefit();
      if (benefitData) {
        getRedirectionData(benefitData);
      }
    } else if (values?.buttonAction == 'Complete') {
      completeSr();
    }
  };

  const hideCinemaPopover = () => {
    toggleCinemaPopover(false);
    resetPollingTimer();
  };

  return (
    <>
      <Form
        onSubmit={handleOnSubmit}
        initialValues={formValues}
        section1={serviceRequestDetailsSection}
        // buttonStack={buttonList}
        topButtonStack={topButtonList}
      />
      {showCinemaPopover ? (
        <CinemaECodePopup visible={showCinemaPopover} hideModal={hideCinemaPopover} />
      ) : null}
      <TableView
        data-test={'grid'}
        {...getCinemaeCodeGridData(tableData, SortOrder.DESCENDING, {}, true)}
        onRowClick={() => {}}
        // sortTable={onSortIconClick}
      />
    </>
  );
};
export default CinemaECodeSR;
