import {parseData} from 'Utils/helpers/SRListHelpers';

export const getSRDashboardListGirdData = (
  tableData,
  sortOrder,
  currentSortCriteria,
  isSortingEnabled,
) => {
  const header = [
    {label: 'Membership #', value: 'membership'},
    {label: 'SR Type', value: 'srType'},
    {label: 'Sub Type', value: 'subType'},
    {label: 'Status', value: 'status'},
    {label: 'Owner', value: 'owner'},
    {label: 'Created Date', value: 'createdDate'},
    {label: 'Modified Date', value: 'modifiedDate'},
    {label: 'Summary', value: 'summary'},
  ];

  let hideColumns = {
    srData: true,
  };

  return parseData(
    header,
    tableData,
    sortOrder,
    currentSortCriteria,
    isSortingEnabled,
    hideColumns,
  );
};
