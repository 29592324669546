import {convertMonthToStringFormat, dateConversionIsoToLocal} from 'Utils/helpers/DateValidation';
import {cardNumberFormatter, convertToTitleCase} from 'Utils/helpers/PaymentHelper';
import {parseData} from 'Utils/helpers/SRListHelpers';

export const getCardGridData = (tableData, sortOrder, currentSortCriteria, isSortingEnabled) => {
  const hiddenColumns = {
    pvId: true,
    highlight_border: true,
    expiryDateISOFormat: true,
    cardCreatedDate: true,
  };

  const header = [
    {label: 'Card Number', value: 'cardNumber'},
    {label: 'Card Type', value: 'cardType'},
    {label: 'Expiry Date', value: 'expiryDate'},
    {label: 'Name On Card', value: 'nameOnCard'},
    {label: 'Status', value: 'status'},
    {label: '', value: 'buttons'},
  ];

  return parseData(
    header,
    tableData,
    sortOrder,
    currentSortCriteria,
    isSortingEnabled,
    hiddenColumns,
  );
};

export const mapTableData = (tableData, srData) => {
  let newTableData: any = [];
  (tableData || []).forEach((rowData) => {
    if (
      rowData.paymentMethod === 'CREDITCARD' &&
      rowData.status?.toUpperCase() !== 'NOTVALIDFORPAYMENTS' &&
      rowData.status?.toUpperCase() !== 'ANONYMIZED'
    ) {
      let temp = {
        cardNumber: cardNumberFormatter(rowData.paymentMethodDetails.cardNumber) || '',
        cardType: convertToTitleCase(rowData.paymentMethodDetails.paymentMethodType),
        expiryDate:
          convertMonthToStringFormat(rowData.paymentMethodDetails.expirationMonth) +
            ' ' +
            rowData.paymentMethodDetails.expirationYear || '',
        nameOnCard: rowData.paymentMethodDetails.nameOnCard || '',
        status: rowData.paymentMethodDetails.isExpired ? 'EXPIRED' : 'ACTIVE',
        pvId: rowData.paymentVehicleId,
        highlight_border: rowData.paymentVehicleId === srData?.additionalData?.onSalePvId,
        expiryDateISOFormat: dateConversionIsoToLocal(
          convertMonthToStringFormat(rowData.paymentMethodDetails.expirationMonth) +
            ' ' +
            rowData.paymentMethodDetails.expirationYear || '',
        ),
        cardCreatedDate: rowData.dateCreated,
      };

      if (rowData.paymentMethodDetails.isExpired) {
        temp['buttons'] = ['updateButton'];
      } else {
        temp['noButton'] = '';
      }
      newTableData.push(temp);
    }
  });
  return newTableData;
};
